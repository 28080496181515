import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { formatMoney } from "../../../components/FormatMoneyToRupiah";
import moment from "moment";
import CheckFeature from "../../../components/CheckFeature";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CampaignList = (props) => {
  const [campaign, setCampaign] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCampaign, setFilteredCampaign] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [hotel, setHotel] = useState("");
  const [hotelList, setHotelList] = useState([]);

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/marketing-projects`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/marketing-projects/delete/`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Marketing Project",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Marketing Project",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Marketing Project",
    feature: props.feature,
  });

  //get campaign
  const getCampaign = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.end_date.localeCompare(b.end_date)
          );
          setCampaign(sortedData);
          setFilteredCampaign(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getCampaign();
    getHotel();
  }, []);

  //search campaign
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = campaign.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");

      return (
        (!hotel || data.uuid_hotel === hotel) &&
        (!search || data.name.toLowerCase().match(searchRegex))
      );
    });

    setFilteredCampaign(result);
  }, [search, hotel]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("D MMMM YYYY"),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => moment(row.end_date).format("D MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Budget",
      selector: (row) => formatMoney(row.budget),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.budget) - parseInt(b.budget);
      },
    },
    {
      name: "Progress",
      // selector: (row) =>  row.progress + "%",
      cell: (row) => (
        <div style={{ width: "100%" }}>
          {row.progress.toFixed(0) + "%"}
          <div className="progress" style={{ width: "100%" }}>
            <div
              className="progress-bar progress-bar-striped"
              role="progressbar"
              style={{ width: row.progress + "%" }}
              aria-valuenow={row.progress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">Detail</button>
          </Link>
          {hasFeatureEdit && (
            <Link to={`edit/${row.uuid}`}>
              <button className="btn btn-aksi btn-reset mr-1">Edit</button>
            </Link>
          )}
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.name)}
            >
              Delete
            </button>
          )}
        </>
      ),
      width: "225px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredCampaign.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete
  const alertDelete = (uuid, name) => () => {
    Swal.fire({
      title: "Delete Marketing Project?",
      html: `Are you sure want to delete the marketing project named <b class="text-uppercase">${name}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCampaign(uuid, name);
      }
    });
  };

  //function delete campaign
  const deleteCampaign = (uuid, name) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted the marketing project with the name <b class="text-uppercase">${name}</b>`,
            "success"
          );
          getCampaign();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const filterHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Marketing Project List</h1>
      <div className="row">
        <div className="col-12 col-customer-export">
          {hasFeatureAdd && (
            <Link to="create">
              <button className="btn btn-primary btn-grey mr-2">
                <i className="fa fa-plus-square mr-2"></i>
                Create
              </button>
            </Link>
          )}
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredCampaign,
                "Marketing Project",
                "Marketing Project"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredCampaign, "Marketing Project")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
      </div>
      <div className="row filter-search mb-3">
        <div className="col-lg-4 col-md-5 col-sm-6">
          <Select
            placeholder="Filter by hotel"
            isClearable={true}
            options={hotelList}
            name="uuid_hotel"
            value={hotelList.find((obj) => obj.value === hotel)}
            onChange={filterHotel}
            className="select2 input-search mt-2"
            id="filter-hotel"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-4 col-md-5 col-sm-6">
          <input
            type="text"
            className="form-control input-search mt-2"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>

      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredCampaign.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default CampaignList;
