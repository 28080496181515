import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../components/CheckFeature";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CustomerReviewList = (props) => {
  const [reviewList, setReviewList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredReview, setFilteredReview] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [hotel, setHotel] = useState(null);
  const [hotelList, setHotelList] = useState([]);
  const [platform, setPlatform] = useState(null);
  const [platformList, setPlatformList] = useState([]);

  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/reviews`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/reviews/delete/`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointPlatform = `${process.env.REACT_APP_BASE_URL}/platforms`;
  const endpointSync = `${process.env.REACT_APP_BASE_URL}/ota-sync`;

  //check features
  const hasFeatureDelete = CheckFeature({
    name: "Delete Review",
    feature: props.feature,
  });

  //get review list
  const getReview = async () => {
    try {
      const response = await axios.get(endpointView, {
        headers: {
          Authorization: token,
        },
      });
      const data = response.data.content;
      const sortedData = data.sort((a, b) =>
        b.review_date.localeCompare(a.review_date)
      );
      setReviewList(sortedData);
      setFilteredReview(sortedData);
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getReview();
    getHotel();
    getPlatform();
  }, []);

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  //get platform list
  const getPlatform = async () => {
    try {
      await axios
        .get(endpointPlatform, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setPlatformList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected platform
  const changePlatform = (selectedOption) => {
    if (selectedOption) {
      setPlatform(selectedOption.value);
    } else {
      setPlatform(null);
    }
  };

  //search review by hotel
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = reviewList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");

      return (
        (!hotel || data.uuid_hotel === hotel) &&
        (!platform || data.uuid_platform === platform) &&
        (!search || data.customer.toLowerCase().match(searchRegex))
      );
    });

    setFilteredReview(result);
  }, [search, reviewList, hotel, platform]);

  //data table columns
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Date",
      selector: (row) => moment(row.date).format("D MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer,
      sortable: true,
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Platform",
      selector: (row) => row.platform,
      sortable: true,
      id: "email",
    },
    {
      name: "Review Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`../customer-review/${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1" type="button">
              Detail
            </button>
          </Link>
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDeleteReview(row.uuid, row.customer, row.hotel)}
            >
              Delete
            </button>
          )}
        </>
      ),
      width: "170px",
    },
  ];

  const deleteReview = (uuid, cust, hotel) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted the review from <b class="text-uppercase">${cust}</b> for ${hotel}`,
            "success"
          );
          getReview();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete the review from <b class="text-uppercase">${cust}</b> for ${hotel}`,
        "error"
      );
    }
  };

  const alertDeleteReview = (uuid, cust, hotel) => () => {
    Swal.fire({
      title: "Delete Customer Review?",
      html: `Are you sure want to delete the review from <b class="text-uppercase">${cust}</b> for ${hotel}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#f0f0f0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReview(uuid, cust, hotel);
      }
    });
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const lastRowIndex = currentPage * rowsPerPage;

  // Extract the data for the current page using the slice method
  const currentData = filteredReview.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const syncOTA = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .post(
          endpointSync,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          Swal.fire({
            title: "Data Synchronization Successful!",
            html: `Successfully collected all customer reviews from various platforms`,
            icon: "success",
          }).then((result) => {
            getReview();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Data Synchronization Failed!",
            `${error.response.data.message}`,
            "error"
          );
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Please wait..",
        html: `Collecting reviews from all platforms`,
        showCancelButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didRender: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading]);

  return (
    <>
      <h1 className="page-title mb-3">Customer Reviews</h1>
      <div className="row">
        <div className="col-12 col-customer-export">
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={(e) => syncOTA(e)}
          >
            <i className="fas fa-sync-alt mr-2"></i>
            OTA Synchronization
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredReview,
                "Customer Review",
                "Customer Review"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredReview, "Customer Review")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
      </div>
      <div className="row mb-3 filter-search">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <Select
            placeholder="Filter by hotel"
            isClearable={true}
            options={hotelList}
            name="uuid_hotel"
            value={hotelList.find((obj) => obj.value === hotel)}
            onChange={changeHotel}
            className="select2 input-search mt-2"
            id="inputHotel"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <Select
            placeholder="Filter by platform"
            isClearable={true}
            options={platformList}
            name="uuid_platform"
            value={platformList.find((obj) => obj.value === platform)}
            onChange={changePlatform}
            className="select2 input-search mt-2"
            id="inputHotel"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <input
            type="text"
            className="form-control input-search mt-2"
            placeholder="Search by customer name"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredReview.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default CustomerReviewList;
