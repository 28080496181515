import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import TaskDetail from "../TaskDetail";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import moment from "moment";

const ReportActivity = () => {
  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;
  const navigate = useNavigate();

  const [dataActivity, setDataActivity] = useState([]);
  const [dataTask, setDataTask] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const endpointGetSalesTaskDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/`;
  const endpointDeleteActivity = `${process.env.REACT_APP_BASE_URL}/sales-rep-activities/delete/`;

  useEffect(() => {
    if (uuid) {
      getActivityList();
    }
  }, []);

  const getActivityList = async () => {
    try {
      await axios
        .get(endpointGetSalesTaskDetail + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setDataTask(data);
          setDataActivity(data.sales_rep_activities);
        });
    } catch (error) {
      navigate("/404", { replace: true });
    }
  };

  const alertDeleteActivity = (uuid, activityName) => () => {
    Swal.fire({
      title: "Delete Activity?",
      html: `Are you sure want to delete activity with name <b class="text-uppercase"> ${activityName} </b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteActivity(uuid, activityName);
      }
    });
  };

  const DeleteActivity = (uuid, activityName) => {
    try {
      axios
        .delete(endpointDeleteActivity + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted report activity with name <b class="text-uppercase">${activityName}</b>`,
            "success"
          );
          getActivityList();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Start Time",
      selector: (row) => moment(row.start_time).format("DD-MM-YYYY, HH:mm"),
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) => moment(row.end_time).format("DD-MM-YYYY, HH:mm"),
      sortable: true,
    },
    {
      name: "Activity Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "PIC",
      selector: (row) =>
        row.pics?.length > 0 ? (
          <ul className="pl-3 pb-0 mb-0">
            {row.pics.map((data) => (
              <li>{data.name}</li>
            ))}
          </ul>
        ) : (
          "-"
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link to={`edit/${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">Edit</button>
          </Link>
          <button
            className="btn btn-aksi btn-delete"
            onClick={alertDeleteActivity(row.uuid, row.name)}
          >
            Delete
          </button>
        </div>
      ),
      id: "aksi",
      width: "200px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = dataActivity.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  return (
    <>
      <h1 className="page-title mb-3">Report Activity</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/my-task/sales-task">My Sales Task</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Report Activity
        </li>
      </ol>

      <label htmlFor="add" className="font-weight-bold">
        📌 Report Activity Now:
        <Link to="add">
          <button className="btn btn-primary btn-grey mx-2" id="add">
            <i className="fas fa-map-marked-alt mr-2"></i>
            Report Activity
          </button>
        </Link>
      </label>

      <TaskDetail dataTask={dataTask} />

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Activity List
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={dataActivity.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>

      <div className="mb-5 mt-4">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate("/my-task/sales-task")}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default ReportActivity;
