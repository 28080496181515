import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../components/CheckFeature";

const ProductType = (props) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/product-types/`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/product-types/delete/`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/product-types/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/product-types/edit/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Product Type",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Product Type",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Product Type",
    feature: props.feature,
  });

  //get product type
  const getProductType = async () => {
    try {
      const response = await axios.get(endpointView, {
        headers: {
          Authorization: token,
        },
      });
      const data = response.data.content;
      const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
      setData(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getProductType();
  }, []);

  //search product type
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredData(result);
  }, [search, data]);

  //data table columns
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    ...(hasFeatureEdit || hasFeatureDelete
      ? [
          {
            name: "Action",
            cell: (row) => (
              <>
                {hasFeatureEdit && (
                  <button
                    className="btn btn-aksi btn-edit mr-1"
                    onClick={(e) => alertEdit(row.uuid)}
                  >
                    Edit
                  </button>
                )}
                {hasFeatureDelete && (
                  <button
                    className="btn btn-aksi btn-delete"
                    onClick={alertDelete(row.uuid, row.name)}
                  >
                    Delete
                  </button>
                )}
              </>
            ),
            width: "200px",
          },
        ]
      : []),
  ];

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const lastRowIndex = currentPage * rowsPerPage;

  // Extract the data for the current page using the slice method
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete
  const alertDelete = (uuid, name) => (event) => {
    Swal.fire({
      title: "Delete Product Type?",
      html: `Are you sure want to delete <b class="text-uppercase">${name}</b> from the product type list?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProductType(uuid, name);
      }
    });
  };

  //function delete product type
  const deleteProductType = (uuid, name) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted <b class="text-uppercase">${name}</b> from the product type list`,
            "success"
          );
          getProductType();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete <b class="text-uppercase">${name}</b> from the product type list`,
        "error"
      );
    }
  };

  //alert add product type
  const alertAdd = () => {
    Swal.fire({
      title: "Add Product Type",
      input: "text",
      inputLabel: "Product Type Name",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value) {
          return "Product type name cannot be empty!";
        } else {
          const check = data.filter((data) => {
            return value.toLowerCase() === data.name.toLowerCase();
          });
          if (check.length > 0) {
            return "Product type already exists!";
          } else {
            addProductType(value);
          }
        }
      },
    });
  };

  //function add product type
  const addProductType = (value) => {
    try {
      axios
        .post(
          endpointAdd,
          { name: value },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully added <b class="text-uppercase">${value}</b> to product type list`,
            "success"
          );
          getProductType();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //alert edit product type
  const alertEdit = (uuid) => {
    axios
      .get(endpointView + uuid, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        Swal.fire({
          title: "Edit Product Type",
          input: "text",
          inputLabel: "Product Type Name",
          showCancelButton: true,
          inputValue: response.data.content.name,
          confirmButtonColor: "#1c5ddc",
          cancelButtonColor: "#fc351c",
          cancelButtonText: "Cancel",
          confirmButtonText: "Edit",
          inputValidator: (value) => {
            if (!value) {
              return "Product type name cannot be empty!";
            } else if (
              value.toLowerCase() === response.data.content.name.toLowerCase()
            ) {
              return "Product type name cannot be the same as before!";
            } else {
              const check = data.filter((data) => {
                return value.toLowerCase() === data.name.toLowerCase();
              });
              if (check.length > 0) {
                return "Product type already exists!";
              } else {
                editProductType(value, uuid, response.data.content.name);
              }
            }
          },
        });
      });
  };

  //function adit productType
  const editProductType = (value, uuid, oldName) => {
    try {
      axios
        .put(
          endpointEdit + uuid,
          { name: value },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully edited product type <b class="text-uppercase">${oldName}</b> to <b class="text-uppercase">${value}</b>`,
            "success"
          );
          getProductType();
        })
        .catch((error) => {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Product Type List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-12">
          {hasFeatureAdd && (
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={alertAdd}
            >
              <i className="fa fa-plus-square mr-2"></i>
              Add Product Type
            </button>
          )}
        </div>
        <div className="col-lg-4 col-md-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredData.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default ProductType;
