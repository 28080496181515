import React, { useState, useEffect } from "react";
import "./profile.scss";
import UserDetail from "./UserDetail";
import AccountDetail from "./AccountDetail";
import SocialMedia from "./SocialMedia";
import "../../custom-pages-css.scss";
import ProfilePic from "./ProfilePic";
import axios from "axios";

const Profile = () => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const [employee, setEmployee] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [oldImage, setOldImage] = useState();

  const endpointView = `${process.env.REACT_APP_BASE_URL}/users/`;

  const getUserDetail = async () => {
    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid = stringifyUserData["uuid"];
      try {
        await axios
          .get(endpointView + uuid, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            setEmployee(response.data.content.employee);
            setSocialMedia(response.data.content.employee.social_media);
            setOldImage(
              `${process.env.REACT_APP_BASE_URL}/${response.data.content.image}`
            );
          });
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <>
      <h1 className="page-title mb-3">Profile</h1>
      <ul className="nav nav-tabs tab-custom" id="myTab">
        <li className="nav-item">
          <button
            className="nav-link active"
            id="data-diri-tab"
            data-toggle="tab"
            data-target="#data-diri"
            type="button"
            role="tab"
            aria-controls="data-diri"
            aria-selected="true"
          >
            Personal Data
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link"
            id="data-sosmed-tab"
            data-toggle="tab"
            data-target="#data-sosmed"
            type="button"
            role="tab"
            aria-controls="data-sosmed"
            aria-selected="false"
          >
            Social Media
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link"
            id="data-akun-tab"
            data-toggle="tab"
            data-target="#data-akun"
            type="button"
            role="tab"
            aria-controls="data-akun"
            aria-selected="false"
          >
            Account Data
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="data-diri"
          role="tabpanel"
          aria-labelledby="data-diri-tab"
        >
          <ProfilePic
            getUserDetail={getUserDetail}
            employee={employee}
            socialMedia={socialMedia}
            oldImage={oldImage}
          />
          <UserDetail />
        </div>
        <div
          className="tab-pane fade"
          id="data-sosmed"
          role="tabpanel"
          aria-labelledby="data-sosmed-tab"
        >
          <SocialMedia
            getUserDetail={getUserDetail}
            employee={employee}
            socialMedia={socialMedia}
          />
        </div>
        <div
          className="tab-pane fade"
          id="data-akun"
          role="tabpanel"
          aria-labelledby="data-akun-tab"
        >
          <AccountDetail />
        </div>
      </div>
    </>
  );
};

export default Profile;
