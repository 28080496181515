import React from "react";
import AddEditCustomer from "./AddEditCustomer";
import CheckFeature from "../../components/CheckFeature";

const EditCustomer = (props) => {
  const hasFeatureEdit = CheckFeature({
    name: "Update Customer",
    feature: props.feature,
  });
  return (
    <>
      <h1 className="page-title">
        {hasFeatureEdit ? "Edit Customer Data" : "Details Customer Data"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/customer">Customer List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {hasFeatureEdit ? "Edit Customer Data" : "Details Customer Data"}
        </li>
      </ol>
      <AddEditCustomer feature={props.feature} />
    </>
  );
};

export default EditCustomer;
