import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import "../../pages/custom-pages-css.scss";
import DataTable from "react-data-table-component";

const TodaysOccupancy = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/today-occupancy`;

  const getTodaysOccupancy = async () => {
    try {
      setLoading(true);
      await axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setLoading(false);
          const data = response.data.content;
          setData(data);
          setFilteredData(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((dataItem) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const hotel = dataItem.hotel?.toLowerCase().match(searchRegex);
      return hotel;
    });

    setFilteredData(result);
  }, [search, data]);

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
      width: "300px",
    },
    {
      name: "Total Room",
      selector: (row) => row.totalRoom,
      sortable: true,
    },
    {
      name: "Booked Room",
      selector: (row) => row.bookedRoom,
      sortable: true,
    },

    {
      name: "Percentage Booked Room",
      selector: (row) => row.percentageBookedRoom.toFixed(2) + "%",
      sortable: true,
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  useEffect(() => {
    if (show) {
      getTodaysOccupancy();
    }
  }, [show]);

  return (
    <>
      <div className="col-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse9"
            role="button"
            aria-expanded="false"
            aria-controls="collapse9"
            onClick={() => setShow(true)}
          >
            Todays Occupancy
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse9">
            <div className="row filter-search p-3">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <input
                  type="text"
                  className="form-control input-search"
                  placeholder="Search by hotel"
                  aria-label="Recipient's username"
                  aria-describedby="btn-search"
                  id="search"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="px-3 pb-3 w-100 d-flex align-items-center justify-content-center">
              {loading ? (
                "Loading..."
              ) : filteredData.length > 0 ? (
                <div className="w-100 datatable">
                  <DataTable
                    dense
                    columns={columns}
                    data={currentData}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={filteredData.length}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    responsive
                  />
                </div>
              ) : (
                "There is no data to display"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodaysOccupancy;
