import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import CheckFeature from "../../components/CheckFeature";

const AddEditProduct = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [cost, setCost] = useState("");
  const [quantity, setQuantity] = useState("");
  const [availability, setAvailability] = useState("");
  const [status, setStatus] = useState("");
  const [hotel, setHotel] = useState("");
  const [platform, setPlatform] = useState("");
  const [productType, setProductType] = useState("");

  const [platformList, setPlatformList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);

  const token = localStorage.getItem("token");
  const params = useParams();
  const productId = params.id;

  const endpointView = `${process.env.REACT_APP_BASE_URL}/products/${productId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/products/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/products/edit/${productId}`;
  const endpointListPlatform = `${process.env.REACT_APP_BASE_URL}/platforms/`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointProductType = `${process.env.REACT_APP_BASE_URL}/product-types`;
  const endpointAddProductType = `${process.env.REACT_APP_BASE_URL}/product-types/add`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Product",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Product",
    feature: props.feature,
  });

  useEffect(() => {
    if (productId) {
      getProductById();
    }
    getPlatform();
    getHotel();
    getProductTypeList();
  }, []);

  const changeCost = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/\D/g, "");
    setCost(numericValue);
  };

  const changeToRupiah = (value) => {
    const formattedValue = Number(value).toLocaleString("id-ID");
    return `Rp ${formattedValue}`;
  };

  const statusList = [
    { value: "Full", label: "Full" },
    { value: "Available", label: "Available" },
    { value: "Maintenance", label: "Maintenance" },
    { value: "Not Available", label: "Not Available" },
  ];

  const changeStatus = (selectedOption) => {
    if (selectedOption) {
      setStatus(selectedOption.value);
    } else {
      setStatus(null);
    }
  };

  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [hotelList]
  );

  const getProductTypeList = async () => {
    try {
      await axios
        .get(endpointProductType, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setProductTypeList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changeProductType = (selectedOption) => {
    if (selectedOption) {
      setProductType(selectedOption.value);
    } else {
      setProductType(null);
    }
  };

  //create department
  const createProductType = useCallback(
    (inputValue) => {
      alertAddProductType(inputValue);
    },
    [productTypeList]
  );

  //alert add department
  const alertAddProductType = (inputValue) => {
    Swal.fire({
      title: "Add Product Type",
      input: "text",
      inputLabel: "Product Type Name",
      showCancelButton: true,
      inputValue: inputValue,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value) {
          return "Product type name cannot be empty!";
        } else {
          const check = productTypeList.filter((data) => {
            return value.toLowerCase() === data.label.toLowerCase();
          });
          if (check.length > 0) {
            return "Product type already exists!";
          } else {
            addProductType(value);
          }
        }
      },
    });
  };

  const addProductType = (value) => {
    try {
      axios
        .post(
          endpointAddProductType,
          { name: value },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully added <b class="text-uppercase">${value}</b> to product type list`,
            "success"
          );
          getProductTypeList();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  const getPlatform = async () => {
    try {
      await axios
        .get(endpointListPlatform, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const option = data
            .filter((platform) => platform.type === "OTA")
            .map((filteredPlatform) => {
              return {
                value: filteredPlatform.uuid,
                label: filteredPlatform.name,
              };
            });
          setPlatformList(option);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changePlatform = (selectedOption) => {
    if (selectedOption) {
      setPlatform(selectedOption.value);
    } else {
      setPlatform(null);
    }
  };

  const createPlatform = useCallback(
    (inputValue) => {
      window.open("/platform/add", "_blank").focus();
    },
    [platformList]
  );

  const getProductById = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setName(response.data.content.name);
        setCost(response.data.content.cost);
        setQuantity(response.data.content.quantity);
        setAvailability(response.data.content.availability);
        setStatus(response.data.content.status);
        setHotel(response.data.content.uuid_hotel);
        setPlatform(response.data.content.uuid_platform);
        setProductType(response.data.content.uuid_product_type);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/customer", { replace: true });
        }
      });
  };

  const addEdit = async (e) => {
    e.preventDefault();
    const product = {
      name: name,
      cost: cost,
      quantity: quantity,
      status: status,
      uuid_hotel: hotel,
      uuid_platform: platform,
      uuid_product_type: productType,
      availability: availability,
    };
    try {
      if (!productId) {
        await axios
          .post(endpointAdd, product, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added a product named <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/product");
          });
      } else {
        await axios
          .put(endpointEdit, product, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully updated product data with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/product");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  return (
    <>
      <h1 className="page-title">
        {productId
          ? hasFeatureEdit
            ? "Edit Product Data"
            : "Product Details"
          : "Add Produk"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/product">Product List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {productId
            ? hasFeatureEdit
              ? "Edit Product Data"
              : "Product Details"
            : "Add Produk"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (productId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={productId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputProductType"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Product Type <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select product type"
                isClearable={true}
                options={productTypeList}
                name="uuid_hotel"
                value={productTypeList.find((obj) => obj.value === productType)}
                onChange={changeProductType}
                className="select2 select-group"
                id="inputProductType"
                onCreateOption={createProductType}
                isDisabled={productId && !hasFeatureEdit}
                required
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getProductTypeList()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputCost"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Price <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="cost"
              className="form-control"
              id="inputCost"
              value={changeToRupiah(cost)}
              onChange={changeCost}
              required
              disabled={productId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputQty"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Qty <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="number"
              min={0}
              name="qty"
              className="form-control"
              id="inputQty"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              required
              disabled={productId && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputAvailability"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Availability <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="number"
              min={0}
              name="availablility"
              className="form-control"
              id="inputAvailability"
              value={availability}
              onChange={(e) => setAvailability(e.target.value)}
              required
              disabled={productId && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputStatus"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Status <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select status"
              isClearable={true}
              options={statusList}
              name="status"
              value={statusList.find((obj) => obj.value === status)}
              onChange={changeStatus}
              className="select2 select-group"
              id="inputStatus"
              isDisabled={productId && !hasFeatureEdit}
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select hotel"
                isClearable={true}
                options={hotelList}
                name="uuid_hotel"
                value={hotelList.find((obj) => obj.value === hotel)}
                onChange={changeHotel}
                className="select2 select-group"
                id="inputHotel"
                onCreateOption={createHotel}
                isDisabled={productId && !hasFeatureEdit}
                required
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getHotel()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPlatform"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Platform
            {/* <i className="red">*</i> */}
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select platform"
                isClearable={true}
                options={platformList}
                name="uuid_platform"
                value={platformList.find((obj) => obj.value === platform)}
                onChange={changePlatform}
                className="select2 select-group"
                id="inputPlatform"
                onCreateOption={createPlatform}
                isDisabled={productId && !hasFeatureEdit}
                // required
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getPlatform()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/product")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {productId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((productId && hasFeatureEdit) ||
              (!productId && hasFeatureAdd)) && (
              <button className="btn btn-primary btn-blue" type="submit">
                <i
                  className={
                    "fa " + (productId ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {productId ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditProduct;
