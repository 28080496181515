import React from "react";

const ExpandedRow = ({ members, products }) => {
  return (
    <div className="expanded-rows">
      {members.length > 0 && (
        <>
          <div className="title">Task Member List</div>
          <div className="body">
            <div className="table-responsive">
              <table className="table table-sm table-borderless mb-0">
                <tbody>
                  {members.map((row, i) => (
                    <tr key={i}>
                      <td scope="row" width="60px">
                        {i + 1}.
                      </td>
                      <td>{row}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {products.length > 0 && (
        <>
          <div className="title mt-2">Product List</div>
          <div className="body">
            <div className="table-responsive">
              <table className="table table-sm table-borderless mb-0">
                <tbody>
                  {products.map((row, i) => (
                    <tr key={i}>
                      <td scope="row" width="60px">
                        {i + 1}.
                      </td>
                      <td>{row.product}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExpandedRow;
