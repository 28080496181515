import React, { useState } from "react";
import moment from "moment";

const ExpandedRow = ({ task, name }) => {
  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = task.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <>
      <div className="expanded-rows">
        <div className="title">{name} Task List</div>
        <div className="table table-responsive mb-0 mt-3">
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  #
                </th>
                <th scope="col">Description</th>
                <th scope="col">Product</th>
                <th scope="col">Deadline</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((row, i) => (
                <tr key={row.uuid}>
                  <td className="text-center">
                    {(currentPage - 1) * pageSize + i + 1}
                  </td>
                  <td>{row.description ? row.description : "-"}</td>
                  <td>
                    <ul className="pl-3 pb-0 mb-0">
                      {row.marketing_project.products.map((item, index) => (
                        <li key={index}>{item.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td>{moment(row.due_date).format("DD MMMM YYYY")}</td>
                  <td>{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between pagination-table">
          <div>
            Showing {firstIndex + 1} to {Math.min(lastIndex, task.length)} of{" "}
            {task.length} entries
          </div>
          <div>
            <select
              className="form-control form-control-sm"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="pagination pagination-custom justify-content-center">
          <button
            className="page-link"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            &lt;&lt;
          </button>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <button className="page-link active">{currentPage}</button>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              task.length === 0 ||
              currentPage === Math.ceil(task.length / pageSize)
            }
          >
            &gt;
          </button>
          <button
            className="page-link"
            onClick={() => handlePageChange(Math.ceil(task.length / pageSize))}
            disabled={
              task.length === 0 ||
              currentPage === Math.ceil(task.length / pageSize)
            }
          >
            &gt;&gt;
          </button>
        </div>
      </div>
    </>
  );
};

export default ExpandedRow;
