import React, { useEffect, useState } from "react";
import "./login.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";

const Login = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("fas fa-eye");

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    setEyeClass(!eyeClass);
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const isMyTokenExpired = isExpired(token);

  //check if token not expired
  useEffect(() => {
    if (token !== null && !isMyTokenExpired) {
      navigate("/dashboard", { replace: true });
    }
  });

  const auth = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
          username: username,
          password: password,
        })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          props.setIsLogin(true);
          navigate("/dashboard", { replace: true });
        });
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      }
    }
  };

  return (
    <>
      <div className="container container-login">
        <div className="row m-0 justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
            <div className="card rounded-0 o-hidden border-0 shadow-lg card-login">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="box-login">
                      <div className="text-center">
                        <div className="mb-4 d-flex justify-content-center align-items-end">
                          <img
                            src="./img/jxb-black.png"
                            className="logo mr-2"
                            width={"50px"}
                            alt="logo"
                          />
                          <h1 className="title ml-1 mb-0">CRM APP</h1>
                        </div>
                      </div>
                      <form className="form-login" onSubmit={auth}>
                        {message && (
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        )}
                        <div className="form-group mb-4">
                          <label className="form-label" htmlFor="email">
                            Username
                          </label>
                          <input
                            type="text"
                            placeholder="Username"
                            name="username"
                            className="form-control"
                            id="email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="on"
                            required
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type={passwordShown ? "text" : "password"}
                              placeholder="Password"
                              name="password"
                              id="password"
                              className="form-control border-right-0"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              autoComplete="on"
                              required
                            />
                            <button
                              className="btn btn-primary btn-eye"
                              type="button"
                              onClick={togglePassword}
                            >
                              <span
                                className={
                                  eyeClass ? "fas fa-eye" : "fas fa-eye-slash"
                                }
                              ></span>
                            </button>
                          </div>
                        </div>
                        <div className="text-right">
                          <button
                            className="btn btn-primary w-100 btn-login"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
