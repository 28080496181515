import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";
import Select from "react-select";
import { GenerateNext10Year } from "../../../components/GenerateNext10Year";

const AddEditSalesRepTarget = (props) => {
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState("");
  const [employee, setEmployee] = useState("");
  const [numOfDeals, setNumOfDeals] = useState("");
  const [numOfTask, setNumOfTask] = useState("");
  const [revenueTarget, setRevenueTarget] = useState("");
  const [employeeName, setEmployeeName] = useState("");

  const [salesRepList, setSalesRepList] = useState([]);

  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/sales-rep-targets/${uuid}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/sales-rep-targets/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/sales-rep-targets/edit/${uuid}`;
  const endpointSalesRep = `${process.env.REACT_APP_BASE_URL}/sales-reps`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Representative Target",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Representative Target",
    feature: props.feature,
  });

  useEffect(() => {
    if (uuid) {
      getData();
    }
    getSalesRep();
  }, []);

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const dataTarget = {
      year: year,
      uuid_user: employee,
      num_of_deals: parseInt(numOfDeals, 10),
      num_of_tasks: parseInt(numOfTask, 10),
      revenue_target: parseInt(revenueTarget, 10),
    };
    try {
      if (!uuid) {
        await axios
          .post(endpointAdd, dataTarget, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully add ${employeeName} sales representative target for ${year}`,
              "success"
            );
            navigate("/sales-representative-target");
          });
      } else {
        await axios
          .put(endpointEdit, dataTarget, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edit ${employeeName} sales representative target tahun ${year}`,
              "success"
            );
            navigate("/sales-representative-target");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get data
  const getData = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setYear(response.data.content.year);
        setEmployee(response.data.content.uuid_user);
        setNumOfDeals(response.data.content.num_of_deals);
        setNumOfTask(response.data.content.num_of_tasks);
        setRevenueTarget(response.data.content.revenue_target);
        setEmployeeName(response.data.content.user.employee.name);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-representative-target", { replace: true });
        }
      });
  };

  const yearOptions = GenerateNext10Year();

  const changeRevenue = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/\D/g, "");
    setRevenueTarget(numericValue);
  };

  const changeToRupiah = (value) => {
    const formattedValue = Number(value).toLocaleString("id-ID");
    return `Rp ${formattedValue}`;
  };

  const changeYear = (selectedOption) => {
    if (selectedOption) {
      setYear(selectedOption.value);
    } else {
      setYear(null);
    }
  };

  const getSalesRep = async () => {
    try {
      await axios
        .get(endpointSalesRep, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setSalesRepList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected
  const changeSalesRep = (selectedOption) => {
    if (selectedOption) {
      setEmployee(selectedOption.value);
      setEmployeeName(selectedOption.label);
    } else {
      setEmployee(null);
      setEmployeeName(null);
    }
  };

  return (
    <>
      <h1 className="page-title">
        {uuid ? (hasFeatureEdit ? "Edit" : "Detail") : "Add"} Sales
        Representative Target
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-representative-target">
            Sales Representative Target List
          </a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {uuid ? (hasFeatureEdit ? "Edit" : "Detail") : "Add"} Sales
          Representative Target
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (uuid && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="tahun"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Year <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <Select
              placeholder="Choose Year"
              isClearable={true}
              options={yearOptions}
              name="tahun"
              value={yearOptions.find((obj) => obj.value === year)}
              onChange={changeYear}
              className="select2 select-group"
              id="tahun"
              isDisabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year <= currentYear)
              }
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="hotel"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Sales Representative <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <Select
              placeholder="Choose sales representative"
              isClearable={true}
              options={salesRepList}
              name="hotel"
              value={salesRepList.find((obj) => obj.value === employee)}
              onChange={changeSalesRep}
              className="select2 select-group"
              id="hotel"
              isDisabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year <= currentYear)
              }
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="deal"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Deal Targets<i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="number"
              name="deal"
              id="deal"
              className="form-control"
              value={numOfDeals}
              min={0}
              onChange={(e) => setNumOfDeals(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year < currentYear)
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="task"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Task Targets <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="number"
              name="task"
              id="task"
              className="form-control"
              value={numOfTask}
              min={0}
              onChange={(e) => setNumOfTask(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year < currentYear)
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="revenue"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Revenue Targets<i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="text"
              name="revenue"
              className="form-control"
              id="revenue"
              value={changeToRupiah(revenueTarget)}
              onChange={changeRevenue}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year < currentYear)
              }
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-representative-target")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {uuid ? (hasFeatureEdit ? "Cancel" : "Go Back") : "Cancel"}
            </button>
            {((uuid && hasFeatureEdit && year >= currentYear) ||
              (!uuid && hasFeatureAdd)) && (
              <button className="btn btn-primary btn-blue" type="submit">
                <i
                  className={
                    "fa " + (uuid ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {uuid ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditSalesRepTarget;
