import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "../../pages/dashboard/dashboard.scss";
import CheckDataZero from "./CheckDataZero";
import moment from "moment";
import Select from "react-select";
import { generateYearOptions } from "../GenerateYearOption";
import { generateMonthOptions } from "../GenerateMonthOptions";
import { Chart, registerables } from "chart.js";
import { formatMoney } from "../FormatMoneyToRupiah";
Chart.register(...registerables);

const ChartRevenue = () => {
  const [data, setData] = useState([]);
  const currentYear = new Date().getFullYear().toString();
  const currentMonth = new Date().toLocaleString("en-US", { month: "2-digit" });
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions();

  const [hotel, setHotel] = useState("");
  const [hotelList, setHotelList] = useState([]);
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels/maxial-hotel`;

  const fetchData = async (selectedYear, selectedMonth) => {
    try {
      if (token) {
        let endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/chart-revenue?yearMonth=${selectedYear}-${selectedMonth}`;

        const dataPromise = axios.get(endpoint, {
          headers: {
            Authorization: token,
          },
        });

        const [dataResponse] = await Promise.all([dataPromise]);

        const filteredHotelData = dataResponse.data.content.filter(
          (item) => item.hotel === hotel
        );

        const revenueData = filteredHotelData.flatMap((item) => item.revenue);

        const getData = {
          labels: revenueData.map((item) =>
            moment(item.date).format("DD-MM-YYYY")
          ),

          datasets: [
            {
              data: revenueData.map((item) => item.TotalRevenue),
              backgroundColor: "#1c1b20",
              borderColor: "#1c5ddc",
              label: "Revenue",
              fill: false,
            },
          ],
        };

        setData(getData);
      }
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      if (show) {
        fetchData(selectedYear, selectedMonth);
      }
    }
  }, [show, selectedYear, selectedMonth, hotel]);

  useEffect(() => {
    if (token) {
      getHotelList();
    }
  }, [token]);

  const optionsLineChart = {
    onClick: (e) => false,
    responsive: true,
    scales: {
      y: {
        ticks: {
          precision: 0,
          callback: (value) => formatMoney(value),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        onClick: () => {},
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (context) => "Revenue: " + formatMoney(context.parsed.y),
        },
      },
    },
  };

  const filterByYear = (selectedOption) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
    }
  };

  const filterByMonth = (selectedOption) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
    } else {
      setSelectedMonth("");
    }
  };

  const getHotelList = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.code, label: data.name };
          });
          setHotelList(options);
          if (!hotel && options.length > 0) {
            setHotel(options[0].label);
          }
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const filterByHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.label);
    } else {
      setHotel("");
    }
  };

  return (
    <>
      <div className="col-md-6 col-sm-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse7"
            role="button"
            aria-expanded="false"
            aria-controls="collapse7"
            onClick={() => setShow(true)}
          >
            Daily Revenue
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse7">
            <div className="chart scroll-chart d-flex flex-column rounded-0">
              <b className="mb-1">Filter by:</b>
              <div className="row mb-2">
                <label
                  htmlFor="filterHotel"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Hotel
                </label>
                <div className="col">
                  <Select
                    options={hotelList}
                    value={hotelList.find((obj) => obj.label === hotel)}
                    id="filterHotel"
                    className="select2"
                    name="filterHotel"
                    placeholder="Select hotel"
                    onChange={filterByHotel}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label
                  htmlFor="filterYear"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Year
                </label>
                <div className="col">
                  <Select
                    options={yearOptions}
                    value={yearOptions.find(
                      (obj) => obj.value === selectedYear
                    )}
                    id="filterYear"
                    className="select2"
                    name="filterYear"
                    placeholder="Select year"
                    onChange={filterByYear}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label
                  htmlFor="filterByMonth"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Month
                </label>
                <div className="col">
                  <Select
                    options={monthOptions}
                    value={monthOptions.find(
                      (obj) => obj.value === selectedMonth
                    )}
                    id="filterMonth"
                    className="select2"
                    name="filterMonth"
                    placeholder="Select month"
                    onChange={filterByMonth}
                  />
                </div>
              </div>
              <div className="w-100 responsive-chart mt-3 d-flex align-items-center justify-content-center align-self-center flex-grow-1">
                {!CheckDataZero(data) && data.labels && !isLoading && (
                  <Line data={data} options={optionsLineChart} />
                )}
                {CheckDataZero(data) &&
                  !isLoading &&
                  "There is no data to display"}
                {isLoading && "Loading..."}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartRevenue;
