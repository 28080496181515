import React from "react";

export function generateYearOptions() {
  const currentYear = new Date().getFullYear();
  const numberOfYears = 10;

  const yearOptions = [];

  for (let year = currentYear; year >= currentYear - numberOfYears; year--) {
    yearOptions.push({
      value: year.toString(),
      label: year.toString(),
    });
  }

  return yearOptions;
}
