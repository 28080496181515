import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../components/CheckFeature";

const Department = (props) => {
  const [dpt, setDpt] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredDepartment, setFilteredDepartment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/departments/`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/departments/delete/`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/departments/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/departments/edit/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Department",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Department",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Department",
    feature: props.feature,
  });

  //get department
  const getDepartment = async () => {
    try {
      const response = await axios.get(endpointView, {
        headers: {
          Authorization: token,
        },
      });
      const data = response.data.content;
      const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
      setDpt(sortedData);
      setFilteredDepartment(sortedData);
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  //search department
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = dpt.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredDepartment(result);
  }, [search, dpt]);

  //data table columns
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    ...(hasFeatureEdit || hasFeatureDelete
      ? [
          {
            name: "Action",
            cell: (row) => (
              <>
                {hasFeatureEdit && (
                  <button
                    className="btn btn-aksi btn-edit mr-1"
                    onClick={(e) => alertEditDepartment(row.uuid)}
                  >
                    Edit
                  </button>
                )}
                {hasFeatureDelete && (
                  <button
                    className="btn btn-aksi btn-delete"
                    onClick={alertDeleteDepartment(row.uuid, row.name)}
                  >
                    Delete
                  </button>
                )}
              </>
            ),
            width: "200px",
          },
        ]
      : []),
  ];

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const lastRowIndex = currentPage * rowsPerPage;

  // Extract the data for the current page using the slice method
  const currentData = filteredDepartment.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete department
  const alertDeleteDepartment = (uuid, name) => (event) => {
    Swal.fire({
      title: "Delete Department?",
      html: `Are you sure want to delete the <b class="text-uppercase">${name}</b> department?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#f0f0f0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDepartment(uuid, name);
      }
    });
  };

  //function delete department
  const deleteDepartment = (uuid, name) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted the <b class="text-uppercase">${name}</b> department`,
            "success"
          );
          getDepartment();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to remove the <b class="text-uppercase">${name}</b> department`,
        "error"
      );
    }
  };

  //alert add department
  const alertAddDepartment = () => {
    Swal.fire({
      title: "Add Department",
      input: "text",
      inputLabel: "Department Name",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value) {
          return "Department name cannot be empty!";
        } else {
          const check = dpt.filter((data) => {
            return value.toLowerCase() === data.name.toLowerCase();
          });
          if (check.length > 0) {
            return "Department already exists!";
          } else {
            addDepartment(value);
          }
        }
      },
    });
  };

  //function add department
  const addDepartment = (value) => {
    const dataDepartment = {
      name: value,
    };
    try {
      axios
        .post(endpointAdd, dataDepartment, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully added <b class="text-uppercase">${value}</b> department`,
            "success"
          );
          getDepartment();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //alert edit department
  const alertEditDepartment = (uuid) => {
    axios
      .get(endpointView + uuid, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        Swal.fire({
          title: "Edit Department",
          input: "text",
          inputLabel: "Department Name",
          showCancelButton: true,
          inputValue: response.data.content.name,
          confirmButtonColor: "#1c5ddc",
          cancelButtonColor: "#fc351c",
          cancelButtonText: "Cancel",
          confirmButtonText: "Edit",
          inputValidator: (value) => {
            if (!value) {
              return "Department name cannot be empty!";
            } else if (
              value.toLowerCase() === response.data.content.name.toLowerCase()
            ) {
              return "Department name cannot be the same as before!";
            } else {
              const check = dpt.filter((data) => {
                return value.toLowerCase() === data.name.toLowerCase();
              });
              if (check.length > 0) {
                return "Department already exists!";
              } else {
                editDepartment(value, uuid, response.data.content.name);
              }
            }
          },
        });
      });
  };

  //function adit department
  const editDepartment = (value, uuid, oldName) => {
    const dataDepartment = {
      name: value,
    };
    try {
      axios
        .put(endpointEdit + uuid, dataDepartment, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully edited the <b class="text-uppercase">${oldName}</b> department to <b class="text-uppercase">${value}</b>`,
            "success"
          );
          getDepartment();
        })
        .catch((error) => {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Department List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-6 col-sm-5 col-12">
          {hasFeatureAdd && (
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={alertAddDepartment}
            >
              <i className="fa fa-plus-square mr-2"></i>
              Add Department
            </button>
          )}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-7 col-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredDepartment.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default Department;
