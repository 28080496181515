import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";

const RoleUser = (props) => {
  const [role, setRole] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredRole, setFilteredRole] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/roles/`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/roles/delete/`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/roles/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/roles/edit/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Role",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Role",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Role",
    feature: props.feature,
  });

  //get role list
  const getRole = async () => {
    try {
      await axios
        .get(endpointView, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
          setRole(sortedData);
          setFilteredRole(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getRole();
  }, []);

  //search role
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = role.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredRole(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Acces Data",
      selector: (row) => (row.is_data_restricted ? "Restricted" : "All Hotel"),

      sortable: true,
    },
    ...(hasFeatureEdit || hasFeatureDelete
      ? [
          {
            name: "Action",
            cell: (row) => (
              <>
                {hasFeatureEdit && (
                  <button
                    className="btn btn-aksi btn-edit mr-1"
                    onClick={(e) => alertEditRole(row.uuid)}
                  >
                    Edit
                  </button>
                )}
                {hasFeatureDelete && (
                  <button
                    className="btn btn-aksi btn-delete"
                    onClick={alertDeleteRole(row.uuid, row.name)}
                  >
                    Delete
                  </button>
                )}
              </>
            ),
            width: "200px",
          },
        ]
      : []),
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredRole.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //delete role
  const alertDeleteRole = (uuid, name) => (event) => {
    Swal.fire({
      title: "Delete Role?",
      html: `Are you sure want to delete <b class="text-uppercase">${name}</b> role?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRole(uuid, name);
      }
    });
  };

  //function delete role
  const deleteRole = (uuid, name) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted <b class="text-uppercase">${name}</b> role`,
            "success"
          );
          getRole();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete <b class="text-uppercase">${name}</b> role`,
        "error"
      );
    }
  };

  //alert add role
  const alertAddRole = () => {
    Swal.fire({
      title: "Create Role",
      html:
        '<div class="row mr-0 ml-0">' +
        '    <input type="text" id="roleName" class="swal2-input col align-self-center" placeholder="Role Name">' +
        "</div>" +
        '<div class="row mr-0 ml-0">' +
        '    <input type="checkbox" id="someCheckbox" class="swal2-checkbox mt-3 ml-5 mr-3 mb-2"> ' +
        '    <label class="mt-3" for="someCheckbox">Can Access All Hotel Data</label></label>' +
        "</div>",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Create",
      preConfirm: () => {
        const roleName = document.getElementById("roleName").value;
        const checkboxValue = document.getElementById("someCheckbox").checked;

        if (!roleName) {
          Swal.showValidationMessage("Role name cannot be empty!");
        } else {
          const check = role.filter((data) => {
            return roleName.toLowerCase() === data.name.toLowerCase();
          });
          if (check.length > 0) {
            Swal.showValidationMessage("Role already exist!");
          } else {
            addRole(roleName, checkboxValue);
          }
        }
      },
    });
  };

  //function add role
  const addRole = (value, access) => {
    const roleData = {
      name: value,
      is_data_restricted: access,
      features: [],
    };
    try {
      axios
        .post(endpointAdd, roleData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully create <b class="text-uppercase">${value}</b> role ${access}`,
            "success"
          );
          getRole();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //alert edit role
  const alertEditRole = (uuid) => {
    axios
      .get(endpointView + uuid, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        Swal.fire({
          title: "Edit Role",
          html:
            '<div class="row mr-0 ml-0">' +
            '    <input type="text" id="roleName" class="swal2-input col align-self-center" placeholder="Role Name" value="' +
            response.data.content.name +
            '">' +
            "</div>" +
            '<div class="row mr-0 ml-0">' +
            '    <input type="checkbox" id="someCheckbox" class="swal2-checkbox mt-3 ml-5 mr-3 mb-2"' +
            (response.data.content.is_data_restricted
              ? 'checked="checked"'
              : "") +
            "/> " +
            '    <label class="mt-3" for="someCheckbox">Can Access All Hotel Data</label></label>' +
            "</div>",
          inputLabel: "Role Name",
          showCancelButton: true,
          confirmButtonColor: "#1c5ddc",
          cancelButtonColor: "#fc351c",
          cancelButtonText: "Cancel",
          confirmButtonText: "Edit",
          preConfirm: () => {
            const roleName = document.getElementById("roleName").value;
            const checkboxValue =
              document.getElementById("someCheckbox").checked;
            if (!roleName) {
              Swal.showValidationMessage("Role name cannot be empty!");
            } else {
              if (
                roleName.toLowerCase() ===
                  response.data.content.name.toLowerCase() &&
                checkboxValue === response.data.content.is_data_restricted
              ) {
                Swal.showValidationMessage(
                  "Role name cannot be same as before!"
                );
              } else {
                editRole(
                  roleName,
                  checkboxValue,
                  uuid,
                  response.data.content.name,
                  response.data.content.features
                );
              }
            }
          },
        });
      });
  };

  //function edit role
  const editRole = (value, access, uuid, oldName, features) => {
    const roleData = {
      name: value,
      is_data_restricted: access,
      features: features,
    };
    try {
      axios
        .put(endpointEdit + uuid, roleData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully edit the <b class="text-uppercase">${oldName}</b> role to the <b class="text-uppercase">${value}</b> role`,
            "success"
          );
          getRole();
        })
        .catch((error) => {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Role List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-12">
          {hasFeatureAdd && (
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={alertAddRole}
            >
              <i className="fa fa-plus-square mr-2"></i>
              Create
            </button>
          )}
        </div>
        <div className="col-lg-4 col-md-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredRole.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default RoleUser;
