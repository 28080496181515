import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { GenerateNext10Year } from "../../../components/GenerateNext10Year";

const AddEditSalesHotelTarget = (props) => {
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();

  const [year, setYear] = useState("");
  const [hotel, setHotel] = useState("");
  const [numOfDeals, setNumOfDeals] = useState("");
  const [revenueTarget, setRevenueTarget] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [hotelList, setHotelList] = useState([]);

  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/sales-hotel-targets/${uuid}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/sales-hotel-targets/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/sales-hotel-targets/edit/${uuid}`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Target",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Target",
    feature: props.feature,
  });

  useEffect(() => {
    if (uuid) {
      getData();
    }
    getHotel();
  }, []);

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const dataTarget = {
      year: year,
      uuid_hotel: hotel,
      num_of_deals: parseInt(numOfDeals, 10),
      revenue_target: revenueTarget,
    };
    try {
      if (!uuid) {
        await axios
          .post(endpointAdd, dataTarget, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Add Hotel Revenue Target Berhasil!",
              `Berhasil Add Hotel Revenue Target ${hotelName} tahun ${year}`,
              "success"
            );
            navigate("/sales-hotel-target");
          });
      } else {
        await axios
          .put(endpointEdit, dataTarget, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Edit Hotel Revenue Target Berhasil!",
              `Berhasil edit Hotel Revenue Target ${hotelName} tahun ${year}`,
              "success"
            );
            navigate("/sales-hotel-target");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response.data.status === 401) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Harap lengkapi data", "error");
      } else if (
        error.response.data.status === 400 ||
        error.response.data.status === 403
      ) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get data
  const getData = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setYear(response.data.content.year);
        setHotel(response.data.content.uuid_hotel);
        setNumOfDeals(response.data.content.num_of_deals);
        setRevenueTarget(response.data.content.revenue_target);
        setHotelName(response.data.content.hotel);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-hotel-target", { replace: true });
        }
      });
  };

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };
  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };
  //create hotel
  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [hotelList]
  );

  const yearOptions = GenerateNext10Year();

  const changeRevenue = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/\D/g, "");
    setRevenueTarget(numericValue);
  };

  const changeToRupiah = (value) => {
    const formattedValue = Number(value).toLocaleString("id-ID");
    return `Rp ${formattedValue}`;
  };

  const changeYear = (selectedOption) => {
    if (selectedOption) {
      setYear(selectedOption.value);
    } else {
      setYear(null);
    }
  };

  return (
    <>
      <h1 className="page-title">
        {uuid
          ? hasFeatureEdit
            ? "Edit Hotel Revenue Target"
            : "Detail Hotel Revenue Target"
          : "Add Hotel Revenue Target"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-hotel-target">Hotel Revenue Target List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {uuid
            ? hasFeatureEdit
              ? "Edit Hotel Revenue Target"
              : "Detail Hotel Revenue Target"
            : "Add Hotel Revenue Target"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (uuid && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="tahun"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Year <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <Select
              placeholder="Select year"
              isClearable={true}
              options={yearOptions}
              name="tahun"
              value={yearOptions.find((obj) => obj.value === year)}
              onChange={changeYear}
              className="select2 select-group"
              id="tahun"
              isDisabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year <= currentYear)
              }
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="hotel"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select hotel"
                isClearable={true}
                options={hotelList}
                name="hotel"
                value={hotelList.find((obj) => obj.value === hotel)}
                onChange={changeHotel}
                className="select2 select-group"
                id="hotel"
                onCreateOption={createHotel}
                isDisabled={
                  (uuid && !hasFeatureEdit) ||
                  (uuid && hasFeatureEdit && year <= currentYear)
                }
                required
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getHotel()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="revenue"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Revenue Target <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="text"
              name="revenue"
              className="form-control"
              id="revenue"
              value={changeToRupiah(revenueTarget)}
              onChange={changeRevenue}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year < currentYear)
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="deal"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Deal Target<i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="number"
              name="deal"
              id="deal"
              className="form-control"
              value={numOfDeals}
              min={0}
              onChange={(e) => setNumOfDeals(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && year < currentYear)
              }
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-hotel-target")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {uuid ? (hasFeatureEdit ? "Cancel" : "Go Back") : "Cancel"}
            </button>
            {((uuid && hasFeatureEdit && year >= currentYear) ||
              (!uuid && hasFeatureAdd)) && (
              <button className="btn btn-primary btn-blue" type="submit">
                <i
                  className={
                    "fa " + (uuid ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {uuid ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditSalesHotelTarget;
