import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import DataTable from "react-data-table-component";
import moment from "moment";
import ModalShowMap from "../../../components/ModalShowMap";
import ShowAttachment from "../../../components/ShowAttachment";

const AddEditSalesTask = (props) => {
  const navigate = useNavigate();

  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [desc, setDesc] = useState("");
  const [hotel, setHotel] = useState("");
  const [company, setCompany] = useState("");
  const [taskMember, setTaskMember] = useState([]);

  const [companyName, setCompanyName] = useState("");
  const [invalidDate, setInvalidDate] = useState(false);

  const [finishTask, setFinishTask] = useState(false);

  const [completionDate, setCompletionDate] = useState("");
  const [statusDeal, setStatusDeal] = useState(null);
  const [lossReason, setLossReason] = useState(null);

  const [oldCompletionDate, setOldCompletionDate] = useState("");
  const [oldStatusDeal, setOldStatusDeal] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  const [salesStaffList, setSalesStaffList] = useState([]);
  const [filteredSalesStaff, setFilteredSalesStaff] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [activitiesList, setActivitiesList] = useState([]);
  const [filteredActivity, setFilteredActivity] = useState([]);

  const [search, setSearch] = useState("");
  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [searchActivity, setSearchActivity] = useState("");
  const [currentPageActivity, setCurrentPageActivty] = useState(1);
  const [rowsPerPageActivity, setRowsPerPageActivity] = useState(10);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  //endpoint
  const endpointSalesStaff = `${process.env.REACT_APP_BASE_URL}/sales-reps`;
  const endpointCompany = `${process.env.REACT_APP_BASE_URL}/partnerships`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/sales-tasks/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/sales-tasks/edit/${uuid}`;
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/${uuid}`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Task",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Task",
    feature: props.feature,
  });

  useEffect(() => {
    if (uuid) {
      getDetail();
    }
    getCompany();
    getHotel();
  }, []);

  useEffect(() => {
    if (uuid && hotel) {
      getSalesStaff(hotel);
    }
  }, [uuid, hotel]);

  useEffect(() => {
    if (startDate && dueDate) {
      const startDateTime = new Date(startDate);
      const dueDateTime = new Date(dueDate);

      if (dueDateTime < startDateTime) {
        setInvalidDate(true);
      } else {
        setInvalidDate(false);
      }
    }
  }, [startDate, dueDate]);

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = salesStaffList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredSalesStaff(result);
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const resultActivity = activitiesList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(searchActivity), "i");
      const name = data.name?.toLowerCase().match(searchRegex);
      const salesName = data.sales_name?.toLowerCase().match(searchRegex);

      return name || salesName;
    });

    setFilteredActivity(resultActivity);
  }, [searchActivity]);

  // useEffect(() => {
  //   if (statusDeal == "Waiting") {
  //     setCompletionDate(null);
  //   }
  // }, [statusDeal]);

  //get sales task detail
  const getDetail = async () => {
    await axios
      .get(endpointDetail, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setTaskName(response.data.content.name);
        setStartDate(response.data.content.start_date);
        setDueDate(response.data.content.due_date);
        setDesc(response.data.content.description);
        setHotel(response.data.content.uuid_hotel);
        setCompany(response.data.content.uuid_company);
        setTaskMember(
          response.data.content.members.length > 0
            ? response.data.content.members.map(function (data) {
                return data.uuid;
              })
            : []
        );
        setActivitiesList(response.data.content.sales_rep_activities);
        setFilteredActivity(response.data.content.sales_rep_activities);
        setCompanyName(response.data.content.partnership);
        setCompletionDate(response.data.content.completion_date);
        setStatusDeal(response.data.content.status_deals);
        setOldStatusDeal(response.data.content.status_deals);
        setLossReason(response.data.content.loss_reason);
        setOldCompletionDate(response.data.content.completion_date);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-task", { replace: true });
        }
      });
  };

  //get company
  const getCompany = () => {
    try {
      axios
        .get(endpointCompany, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data
            .sort((a, b) => new Date(a.name) - new Date(b.name))
            .map((data) => ({
              value: data.uuid,
              label: data.name,
            }));

          setCompanyList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected company
  const changeCompany = (selectedOption) => {
    if (selectedOption) {
      setCompany(selectedOption.value);
      setCompanyName(selectedOption.label);
    } else {
      setCompany(null);
      setCompanyName(null);
    }
  };

  //create company
  const createCompany = useCallback(
    (inputValue) => {
      window.open("/partnership/add", "_blank").focus();
    },
    [companyList]
  );

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
      getSalesStaff(selectedOption.value);
      setTaskMember([]);
    } else {
      setHotel(null);
      setTaskMember([]);
    }
  };

  //create hotel
  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [hotelList]
  );

  //get sales staff
  const getSalesStaff = (uuid_hotel) => {
    try {
      axios
        .get(endpointSalesStaff, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const mappedData = data
            .filter((item) => item.uuid_hotel === uuid_hotel)
            .map((item) => {
              return {
                uuid: item.uuid,
                name: item.name,
                username: item.username,
                email: item.email,
                phone_number: item.phone_number,
              };
            });

          // Sort the mappedData array by name in ascending order
          const sortedData = mappedData.sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          setSalesStaffList(sortedData);
          setFilteredSalesStaff(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const salesTask = {
      name: taskName,
      start_date: startDate,
      due_date: dueDate,
      description: desc,
      uuid_hotel: hotel,
      uuid_company: company,
      members: taskMember,
      ...(completionDate ? { completion_date: completionDate } : {}),
      ...(statusDeal ? { status_deals: statusDeal } : {}),
      ...(lossReason ? { loss_reason: lossReason } : {}),
    };
    try {
      if (!uuid) {
        await axios
          .post(endpointAdd, salesTask, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added sales task for collaboration with partnership <b class="text-uppercase">${companyName}</b>`,
              "success"
            );
            navigate("/sales-task");
          });
      } else {
        await axios
          .put(endpointEdit, salesTask, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Sales task data for collaboration with partnership <b class="text-uppercase">${companyName}</b> has been successfully edited`,
              "success"
            );
            navigate("/sales-task");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response.data.status === 401) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Gagal!", "Harap lengkapi data", "error");
      } else if (
        error.response.data.status === 400 ||
        error.response.data.status === 403
      ) {
        Swal.fire("Gagal!", `${error.response.data.message}`, "error");
      }
    }
  };

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = filteredSalesStaff.slice(firstIndex, lastIndex);

  const lastRowIndexActivity = currentPageActivity * rowsPerPageActivity;
  const currentDataActivity = filteredActivity.slice(
    (currentPageActivity - 1) * rowsPerPageActivity,
    lastRowIndexActivity
  );
  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageChangeActivity = (pageNumber) => {
    setCurrentPageActivty(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  // Handle checkbox change
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setTaskMember([...taskMember, id]);
    } else {
      setTaskMember(taskMember.filter((selectedId) => selectedId !== id));
    }
  };

  const handleRowClick = (id) => {
    // Toggle the row selection when the row is clicked
    if (taskMember.includes(id)) {
      setTaskMember(taskMember.filter((selectedId) => selectedId !== id));
    } else {
      setTaskMember([...taskMember, id]);
    }
  };

  // Handle "Check All" checkbox change
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const allIds = filteredSalesStaff.map((row) => row.uuid);
      setTaskMember(allIds);
    } else {
      setTaskMember([]);
    }
  };

  const changeFinishTask = () => {
    setFinishTask((current) => !current);
  };

  const statusList = [
    { value: "Waiting", label: "Waiting" },
    { value: "Won", label: "Won" },
    { value: "Lose", label: "Lose" },
  ];

  //selected status
  const changeStatus = (selectedOption) => {
    if (selectedOption) {
      setStatusDeal(selectedOption.value);
      setLossReason(null);
      if (selectedOption.value == "Waiting") {
        setCompletionDate(null);
      }
    } else {
      setStatusDeal(null);
      setLossReason(null);
      setCompletionDate(null);
    }
  };

  const lossReasonList = [
    { value: "Price Doesn't Match", label: "Price Doesn't Match" },
    { value: "Not Interested", label: "Not Interested" },
    { value: "Choose Other Hotel", label: "Choose Other Hotel" },
    { value: "No Replies", label: "No Replies" },
  ];

  //selected status
  const changeLossReason = (selectedOption) => {
    if (selectedOption) {
      setLossReason(selectedOption.value);
    } else {
      setLossReason(null);
    }
  };

  const columnsActivity = [
    {
      name: "#",
      cell: (row, rowIndex) =>
        (currentPageActivity - 1) * rowsPerPageActivity + rowIndex + 1,
      width: "40px",
    },
    {
      name: "Start Time",
      selector: (row) => moment(row.start_time).format("DD/MM/YYYY, HH:mm"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.start_time).format("YYYY MM DD");
        const dateB = moment(b.start_time).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "110px",
    },
    {
      name: "End Time",
      selector: (row) => moment(row.end_time).format("DD/MM/YYYY, HH:mm"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.end_time).format("YYYY MM DD");
        const dateB = moment(b.end_time).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "110px",
    },
    {
      name: "Activity Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Sales Name",
      selector: (row) => row.sales_name,
      sortable: true,
    },
    {
      name: "Location",
      cell: (row) => (
        <ModalShowMap latitude={row.latitude} longitude={row.longitude} />
      ),
    },
    {
      name: "Attachment",
      cell: (row) => (row.file ? <ShowAttachment file={row.file} /> : "-"),
    },
    {
      name: "Pic",
      cell: (row) =>
        row.pics?.length > 0 ? (
          <ul className="pl-3 pb-0 mb-0">
            {row.pics.map((data) => (
              <li>{data.name}</li>
            ))}
          </ul>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <>
      <h1 className="page-title">
        {uuid
          ? hasFeatureEdit && statusDeal === "Waiting"
            ? "Edit"
            : "Detail"
          : "Create"}{" "}
        Sales Task
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-task">Sales Task List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {uuid
            ? hasFeatureEdit && statusDeal === "Waiting"
              ? "Edit"
              : "Detail"
            : "Create"}{" "}
          Sales Task
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (uuid && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="taskName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Task Name <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="taskName"
              className="form-control"
              id="taskName"
              value={taskName || ""}
              onChange={(e) => setTaskName(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (oldStatusDeal !== "Waiting" && oldStatusDeal !== "")
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="startDate"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Start Date <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="startDate"
              className="form-control"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (oldStatusDeal !== "Waiting" && oldStatusDeal !== "")
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="dueDate"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Deadline <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="dueDate"
              className="form-control"
              id="dueDate"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              required
              min={startDate}
              disabled={
                (uuid && !hasFeatureEdit) ||
                (oldStatusDeal !== "Waiting" && oldStatusDeal !== "")
              }
            />
            {invalidDate && (
              <small className="red mt-2">
                Due date cannot be less than start date.
              </small>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="desc"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Description <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="desc"
              id="desc"
              rows="3"
              className="form-control"
              value={desc || ""}
              onChange={(e) => setDesc(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (oldStatusDeal !== "Waiting" && oldStatusDeal !== "")
              }
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="hotel"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <CreatableSelect
              placeholder="Choose hotel"
              options={hotelList}
              name="hotel"
              onChange={changeHotel}
              className="select2 select-group"
              value={hotelList.find((obj) => obj.value === hotel)}
              id="hotel"
              onCreateOption={createHotel}
              isDisabled={
                (uuid && !hasFeatureEdit) ||
                (oldStatusDeal !== "Waiting" && oldStatusDeal !== "")
              }
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="partnership"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Partnership <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <CreatableSelect
              placeholder="Choose partnership"
              isClearable={true}
              options={companyList}
              name="partnership"
              onChange={changeCompany}
              className="select2 select-group"
              value={companyList.find((obj) => obj.value === company)}
              id="partnership"
              onCreateOption={createCompany}
              isDisabled={
                (uuid && !hasFeatureEdit) ||
                (oldStatusDeal !== "Waiting" && oldStatusDeal !== "")
              }
              required
            />
          </div>
        </div>

        {/* task member */}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-0 p-0">
          Task Member
        </div>
        <div className="mb-4">
          {(!uuid || hasFeatureEdit) &&
            (oldStatusDeal === "Waiting" || oldStatusDeal === "") && (
              <small className="red">
                * Please select the hotel first to display the sales list
                representatives
              </small>
            )}
        </div>

        {/* {(!uuid && hasFeatureEdit) ||
          (oldStatusDeal === "Waiting" && (
            <> */}
        <div className="row mb-3 filter-target">
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <input
              type="text"
              className="form-control input-search"
              placeholder="Search by name"
              aria-label="Recipient's username"
              aria-describedby="btn-search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>

        <div className="datatable col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
          Task Member : {taskMember.length > 0 ? taskMember.length : 0}
          <div className="table table-responsive mb-0 mt-3">
            <table className="table table-hover table-sm custom-table td-vertical-align-middle">
              <thead>
                <tr className="th-vertical-align-middle">
                  <th scope="col" className="text-center th-checkbox">
                    <input
                      type="checkbox"
                      onChange={handleCheckAllChange}
                      checked={
                        search !== "" && currentData.length > 0
                          ? filteredSalesStaff.every((row) =>
                              taskMember.includes(row.uuid)
                            )
                          : taskMember.length === salesStaffList.length &&
                            salesStaffList.length > 0
                      }
                      disabled={
                        currentData.length <= 0 ||
                        (uuid &&
                          (!hasFeatureEdit || oldStatusDeal !== "Waiting"))
                      }
                    />
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Username</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, i) => (
                  <tr
                    key={row.uuid}
                    className={`row-item ${
                      taskMember.includes(row.uuid) ? "selected" : ""
                    } ${
                      uuid &&
                      (!hasFeatureEdit || oldStatusDeal !== "Waiting") &&
                      "disabled"
                    }`}
                    onClick={() => handleRowClick(row.uuid)}
                    disabled={
                      uuid && (!hasFeatureEdit || oldStatusDeal !== "Waiting")
                    }
                  >
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={taskMember.includes(row.uuid)}
                        onChange={(event) =>
                          handleCheckboxChange(event, row.uuid)
                        }
                        disabled={
                          uuid &&
                          (!hasFeatureEdit || oldStatusDeal !== "Waiting")
                        }
                      />
                    </td>
                    <td>{row.name}</td>
                    <td>{row.username}</td>
                    <td>{row.phone_number}</td>
                    <td>{row.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between pagination-table">
            <div>
              Showing {firstIndex + 1} to{" "}
              {Math.min(lastIndex, filteredSalesStaff.length)} of{" "}
              {filteredSalesStaff.length} entries
            </div>
            <div>
              <select
                className="form-control form-control-sm"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pagination pagination-custom justify-content-center">
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              &lt;&lt;
            </button>
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <button type="button" className="page-link active">
              {currentPage}
            </button>
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                filteredSalesStaff.length === 0 ||
                currentPage === Math.ceil(filteredSalesStaff.length / pageSize)
              }
            >
              &gt;
            </button>
            <button
              type="button"
              className="page-link"
              onClick={() =>
                handlePageChange(
                  Math.ceil(filteredSalesStaff.length / pageSize)
                )
              }
              disabled={
                filteredSalesStaff.length === 0 ||
                currentPage === Math.ceil(filteredSalesStaff.length / pageSize)
              }
            >
              &gt;&gt;
            </button>
          </div>
        </div>
        {/* </>
          ))} */}

        {/* jika task sudah selesai */}
        {uuid && hasFeatureEdit && oldStatusDeal === "Waiting" && (
          <div className="custom-switch mt-4 mb-3">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitch"
              value={finishTask}
              onChange={changeFinishTask}
            />
            <label className="custom-control-label" htmlFor="customSwitch">
              Mark the task as completes
            </label>
          </div>
        )}

        {uuid &&
          (finishTask || oldCompletionDate !== null) &&
          oldStatusDeal === "Waiting" &&
          hasFeatureEdit && (
            <>
              <div className="mb-3 row mt-4">
                <label
                  htmlFor="statusDeal"
                  className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
                >
                  Status Deal <i className="red">*</i>
                </label>
                <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
                  <Select
                    placeholder="Choose status"
                    isClearable={true}
                    options={statusList}
                    name="statusDeal"
                    onChange={changeStatus}
                    className="select2 select-group"
                    value={statusList.find((obj) => obj.value === statusDeal)}
                    id="statusDeal"
                    required
                    isDisabled={uuid && !hasFeatureEdit}
                  />
                </div>
              </div>
              {statusDeal !== "Waiting" && (
                <div className="mb-3 row">
                  <label
                    htmlFor="completionDate"
                    className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
                  >
                    Completion Date <i className="red">*</i>
                  </label>
                  <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
                    <input
                      type="date"
                      name="completionDate"
                      className="form-control"
                      id="completionDate"
                      value={completionDate || ""}
                      onChange={(e) => setCompletionDate(e.target.value)}
                      required
                      disabled={uuid && !hasFeatureEdit}
                      min={startDate}
                    />
                  </div>
                </div>
              )}
              {statusDeal === "Lose" && (
                <div className="mb-3 row">
                  <label
                    htmlFor="lossReason"
                    className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
                  >
                    Loss Reason <i className="red">*</i>
                  </label>
                  <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
                    <Select
                      placeholder="Choose lose reason"
                      isClearable={true}
                      options={lossReasonList}
                      name="lossReason"
                      onChange={changeLossReason}
                      className="select2 select-group"
                      value={
                        lossReasonList.find(
                          (obj) => obj.value === lossReason
                        ) || ""
                      }
                      id="lossReason"
                      required
                      isDisabled={uuid && !hasFeatureEdit}
                    />
                  </div>
                </div>
              )}
            </>
          )}

        {(!uuid && hasFeatureEdit) ||
          (oldStatusDeal === "Waiting" && (
            <div className="mb-3 mt-4">
              <i className="red">*</i> Required
            </div>
          ))}

        {/* Task Activity */}
        {uuid && (!hasFeatureEdit || oldStatusDeal !== "Waiting") && (
          <>
            <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-0 p-0">
              Task Activity
            </div>
            <div className="row mb-3">
              <div className="offset-lg-8 offset-md-6 offset-sm-5 col-lg-4 col-md-6 col-sm-7 col-12">
                <input
                  type="text"
                  className="form-control input-search"
                  placeholder="Search.."
                  aria-label=""
                  aria-describedby="btn-search"
                  onChange={(e) => setSearchActivity(e.target.value)}
                />
              </div>
            </div>
            <div className="datatable">
              <DataTable
                dense
                columns={columnsActivity}
                data={currentDataActivity}
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={filteredActivity.length}
                paginationDefaultPage={currentPageActivity}
                paginationPerPage={rowsPerPageActivity}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                onChangePage={handlePageChangeActivity}
                onChangeRowsPerPage={setRowsPerPageActivity}
                responsive
              />
            </div>
          </>
        )}
        <div className="mb-3 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-task")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {uuid
                ? hasFeatureEdit && oldStatusDeal === "Waiting"
                  ? "Cancel"
                  : "Go Back"
                : "Cancel"}
            </button>
            {((uuid && hasFeatureEdit && oldStatusDeal === "Waiting") ||
              (!uuid && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={invalidDate}
              >
                <i
                  className={
                    "fa " + (uuid ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {uuid ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditSalesTask;
