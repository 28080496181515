import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CreatableSelect from "react-select/creatable";
import CheckFeature from "../../components/CheckFeature";

const AddEditCustomer = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [hotel, setHotel] = useState(null);
  const [segmentation, setSegmentation] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [listSegmentation, setListSegmentation] = useState([]);
  const [listPlatform, setListPlatform] = useState([]);
  const [hotelList, setHotelList] = useState([]);

  const token = localStorage.getItem("token");
  const params = useParams();
  const customerId = params.id;

  const endpointView = `${process.env.REACT_APP_BASE_URL}/customers/${customerId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/customers/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/customers/edit/${customerId}`;
  const endpointListSegmentation = `${process.env.REACT_APP_BASE_URL}/customer-segmentations/`;
  const endpointAddSegmentation = `${process.env.REACT_APP_BASE_URL}/customer-segmentations/add`;
  const endpointListPlatform = `${process.env.REACT_APP_BASE_URL}/platforms/`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Customer",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Customer",
    feature: props.feature,
  });

  useEffect(() => {
    if (customerId) {
      getCustomerById();
    }
    getSegment();
    getPlatform();
    getHotel();
  }, []);

  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [hotelList]
  );

  const getSegment = async () => {
    try {
      await axios
        .get(endpointListSegmentation, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (segment) {
            return { value: segment.uuid, label: segment.name };
          });
          setListSegmentation(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getPlatform = async () => {
    try {
      await axios
        .get(endpointListPlatform, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const option = data
            .filter((platform) => platform.type === "OTA")
            .map((filteredPlatform) => {
              return {
                value: filteredPlatform.uuid,
                label: filteredPlatform.name,
              };
            });
          setListPlatform(option);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changePlatform = (selectedOption) => {
    if (selectedOption) {
      setPlatform(selectedOption.value);
    } else {
      setPlatform(null);
    }
  };

  const createPlatform = useCallback(
    (inputValue) => {
      window.open("/platform/add", "_blank").focus();
    },
    [listPlatform]
  );

  const changeSegment = (selectedOption) => {
    if (selectedOption) {
      setSegmentation(selectedOption.value);
    } else {
      setSegmentation(null);
    }
  };

  //create customer segmentation
  const createSegmentation = useCallback(
    (inputValue) => {
      alertCreateSegment(inputValue);
    },
    [listSegmentation]
  );

  //alert add segmentation
  const alertCreateSegment = (inputValue) => {
    Swal.fire({
      title: "Add Customer Segmentation",
      input: "text",
      inputLabel: "Segmentation Name",
      showCancelButton: true,
      inputValue: inputValue,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value) {
          return "Segmentation name cannot be empty!";
        } else {
          const check = listSegmentation.filter((data) => {
            return value.toLowerCase() === data.label.toLowerCase();
          });
          if (check.length > 0) {
            return "Segmentation already exists!";
          } else {
            addSegment(value);
          }
        }
      },
    });
  };

  //function add department
  const addSegment = (value) => {
    const dataSegment = {
      name: value,
    };
    try {
      axios
        .post(endpointAddSegmentation, dataSegment, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully added <b class="text-uppercase">${value}</b> segmentation`,
            "success"
          );
          getSegment();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  const getCustomerById = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setName(response.data.content.name);
        setAddress(response.data.content.address);
        setPhoneNumber(response.data.content.phone_number);
        setEmail(response.data.content.email);
        setSegmentation(response.data.content.uuid_segmentation);
        setHotel(response.data.content.uuid_hotel);
        setPlatform(response.data.content.uuid_platform);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/customer", { replace: true });
        }
      });
  };

  const addEdit = async (e) => {
    e.preventDefault();
    const customerData = {
      name: name,
      address: address,
      phone_number: phoneNumber,
      email: email,
      uuid_segmentation: segmentation,
      uuid_hotel: hotel,
      uuid_platform: platform,
    };
    try {
      if (!customerId) {
        await axios
          .post(endpointAdd, customerData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added a customer with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/customer");
          });
      } else {
        await axios
          .put(endpointEdit, customerData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edit the customer with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/customer");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  return (
    <>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (customerId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={customerId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputAddress"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Address <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="address"
              id="inputAddress"
              rows="3"
              className="form-control"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              disabled={customerId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPhoneNumber"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Phone Number <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <PhoneInput
              international
              defaultCountry="ID"
              value={phoneNumber}
              onChange={setPhoneNumber}
              id="inputPhoneNumber"
              disabled={customerId && !hasFeatureEdit}
            />
            <small className="form-text text-danger">
              {phoneNumber
                ? isValidPhoneNumber(phoneNumber)
                  ? undefined
                  : "Invalid phone number"
                : "The phone number is required"}
            </small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEmail"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Email <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="email"
              name="email"
              className="form-control"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={customerId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select hotel"
                isClearable={true}
                options={hotelList}
                name="uuid_hotel"
                autoFocus={true}
                value={hotelList.find((obj) => obj.value === hotel)}
                onChange={changeHotel}
                className="select2 select-group"
                id="inputHotel"
                onCreateOption={createHotel}
                isDisabled={customerId && !hasFeatureEdit}
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getHotel()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputSegment"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Segmentation
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <CreatableSelect
              placeholder="Select segmentation"
              isClearable={true}
              options={listSegmentation}
              autoFocus={true}
              value={listSegmentation.find((obj) => obj.value === segmentation)}
              onChange={changeSegment}
              className="select2 select-group"
              id="inputSegment"
              onCreateOption={createSegmentation}
              isDisabled={customerId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPlatform"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Platform
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select platform"
                isClearable={true}
                options={listPlatform}
                name="uuid_platform"
                autoFocus={true}
                value={listPlatform.find((obj) => obj.value === platform)}
                onChange={changePlatform}
                className="select2 select-group"
                id="inputPlatform"
                onCreateOption={createPlatform}
                isDisabled={customerId && !hasFeatureEdit}
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getPlatform()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/customer")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {customerId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((customerId && hasFeatureEdit) ||
              (!customerId && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={
                  phoneNumber
                    ? isValidPhoneNumber(phoneNumber)
                      ? undefined
                      : "disabled"
                    : "disabled"
                }
              >
                <i
                  className={
                    "fa " +
                    (customerId ? "fa-edit" : "fa-plus-square") +
                    " mr-2"
                  }
                ></i>
                {customerId ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditCustomer;
