import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import moment from "moment";

const ScheduledBroadcastMessageList = () => {
  const [broadcast, setBroadcast] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredBroadcast, setFilteredBroadcast] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/broadcasts/scheduled-list`;

  //get broadcast
  const getBroadcast = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            b.createdAt.localeCompare(a.createdAt)
          );
          setBroadcast(sortedData);
          setFilteredBroadcast(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getBroadcast();
  }, []);

  // search broadcast
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = broadcast.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.template.replace(/_/g, " ").toLowerCase().match(searchRegex);
    });

    setFilteredBroadcast(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "60px",
    },
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).format("D MMMM YYYY HH:mm:ss"),
      sortable: true,
    },
    {
      name: "Template",
      selector: (row) => row.template.replace(/_/g, " "),
      sortable: true,
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Sent At",
      selector: (row) => moment(row.send_at).format("D MMMM YYYY HH:mm:ss"),
      sortable: true,
    },
    {
      name: "Approved by",
      selector: (row) => row.approved_by,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`/whatsapp-broadcast/${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">Detail</button>
          </Link>
        </>
      ),
      id: "aksi",
      width: "120px",
    },
  ];

  return (
    <>
      <h1 className="page-title mb-3">Scheduled Broadcast List</h1>
      <div className="row mb-3">
        <div className="offset-lg-8 offset-md-6 col-lg-4 col-md-6 col-sm-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by template"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={filteredBroadcast}
          highlightOnHover
          pagination
          responsive
        />
      </div>
    </>
  );
};

export default ScheduledBroadcastMessageList;
