import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./ShowAttachment.scss";

const ShowAttachment = ({ file }) => {
  const [extension, setExtension] = useState("");

  useEffect(() => {
    if (file) {
      setExtension(file.split(".").pop());
    }
  }, [file]);

  const openModal = (link, e) => {
    e.preventDefault;
    Swal.fire({
      imageUrl: process.env.REACT_APP_BASE_URL + "/" + link,
      imageWidth: "auto",
      imageHeight: "auto",
      confirmButtonText: "Close",
    });
  };
  return (
    <>
      {extension === "jpeg" || extension === "png" ? (
        <span
          onClick={(e) => openModal(file, e)}
          className="show-modal btn btn-aksi btn-edit text-white"
        >
          Show File
        </span>
      ) : (
        <a
          href={process.env.REACT_APP_BASE_URL + "/" + file}
          target="blank"
          className="show-modal btn btn-aksi btn-edit text-white"
        >
          Show File
        </a>
      )}
    </>
  );
};

export default ShowAttachment;
