import React, { useState, useEffect } from "react";
import "../../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export const ModalAddEditFaq = ({
  show,
  onClose,
  parentType,
  hotel,
  parent,
  isEdit,
  uuid,
  getAllFaq,
}) => {
  const [keyword, setKeyword] = useState("");
  const [isParent, setIsParent] = useState(true);
  const [reply, setReply] = useState("");
  const [isMain, setIsMain] = useState(false);
  const [type, setType] = useState("");
  const [invalidKeyword, setInvalidKeyword] = useState(false);
  const [invalidReply, setInvalidReply] = useState(false);

  const handleRadioChange = () => {
    setIsParent(!isParent);
  };

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/faqs/`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/faqs/edit/`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/faqs/add`;

  useEffect(() => {
    const getDetailFaq = async (uuid) => {
      try {
        const response = await axios.get(endpointDetail + uuid, {
          headers: {
            Authorization: token,
          },
        });
        const data = response.data.content;
        setIsParent(data.reply ? false : true);
        setKeyword(data.keyword);
        setReply(data.reply);
        setIsMain(data.is_main);
        setType(data.type);
      } catch (error) {
        console.log(error.response.data.message);
        navigate("/404", { replace: true });
      }
    };

    if (isEdit && uuid) {
      getDetailFaq(uuid);
    }
  }, [isEdit, uuid]);

  useEffect(() => {
    setIsParent(true);
    setKeyword("");
    setReply("");
    setIsMain(false);
    setType("");
    setInvalidKeyword(false);
    setInvalidReply(false);
  }, [onClose]);

  const addChildFaq = async (e) => {
    e.preventDefault();
    const dataAdd = {
      keyword: keyword,
      is_main: false,
      type: parentType,
      reply: !isParent ? reply : null,
      uuid_hotel: hotel ? hotel : null,
      parent_faq: parent ? parent : null,
    };

    const dataEdit = {
      uuid: uuid,
      keyword: keyword,
      reply: reply ? reply : null,
      is_main: isMain,
      type: type,
    };

    try {
      if (isEdit) {
        await axios
          .put(endpointEdit + uuid, dataEdit, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a <b class="text-uppercase">${keyword}</b> FAQ`,
              "success"
            );

            getAllFaq();
          });
      } else {
        await axios
          .post(endpointAdd, dataAdd, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added a <b class="text-uppercase">${keyword}</b> FAQ`,
              "success"
            );
            getAllFaq();
          });
      }
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    } finally {
      onClose(e);
    }
  };

  const handleChangeKeyword = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > 24) {
      setInvalidKeyword(true);
    } else {
      setInvalidKeyword(false);
    }
    setKeyword(inputValue);
  };

  const handleChangeReply = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > 4000) {
      setInvalidReply(true);
    } else {
      setInvalidReply(false);
    }
    setReply(inputValue);
  };

  return (
    <div
      className={`modal custom-modal-faq${show ? " show" : ""}`}
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content p-0">
          <div className="modal-header p-0">
            <h5 className="modal-title">{isEdit ? "Edit" : "Add"} FAQ</h5>
          </div>
          <form method="post" onSubmit={addChildFaq}>
            <div className="modal-body">
              <div className="form form-employee">
                <div className="mb-3">
                  <label htmlFor="keyword" className="col-12 col-form-label">
                    Keyword <i className="red">*</i>
                  </label>
                  <div className="col-12">
                    <input
                      type="text"
                      name="keyword"
                      className="form-control"
                      id="keyword"
                      value={keyword}
                      onChange={(e) => handleChangeKeyword(e)}
                      required
                    />
                    {invalidKeyword && (
                      <small className="red">Maximum 24 characters</small>
                    )}
                  </div>
                </div>
                {!isEdit && (
                  <div className="row mb-3 mx-0 p-0">
                    <label
                      htmlFor="main_true"
                      className="col-12 col-form-label"
                    >
                      Add to the parent FAQ? <i className="red">*</i>
                    </label>
                    <div className="col-12 align-self-center">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="main_true"
                          name="is_main"
                          className="custom-control-input"
                          onChange={handleRadioChange}
                          checked={isParent}
                          value={true}
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="main_true"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="main_false"
                          name="is_main"
                          className="custom-control-input"
                          onChange={handleRadioChange}
                          checked={!isParent}
                          value={false}
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="main_false"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {!isParent && (
                  <div className="mb-3">
                    <label htmlFor="reply" className="col-12 col-form-label">
                      Reply <i className="red">*</i>
                    </label>
                    <div className="col-12">
                      <textarea
                        type="text"
                        rows={3}
                        name="reply"
                        className="form-control"
                        id="reply"
                        value={reply || ""}
                        onChange={(e) => handleChangeReply(e)}
                        required
                      ></textarea>
                      {invalidReply && (
                        <small className="red">Maximum 4000 characters</small>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <div className="row p-0 m-0">
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-aksi btn-edit"
                    disabled={invalidReply || invalidKeyword}
                  >
                    {isEdit ? "Edit" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-aksi btn-delete"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
