import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const EditProgress = () => {
  const navigate = useNavigate();

  const [completionDate, setCompletionDate] = useState("");
  const [statusDeal, setStatusDeal] = useState(null);
  const [lossReason, setLossReason] = useState(null);

  const [companyName, setCompanyName] = useState("");
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  //endpoint
  const endpointProgress = `${process.env.REACT_APP_BASE_URL}/sales-tasks/progress/${uuid}`;
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/${uuid}`;

  useEffect(() => {
    if (uuid) {
      getDetail();
    }
  }, []);

  //get sales task detail
  const getDetail = async () => {
    await axios
      .get(endpointDetail, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setCompletionDate(
          response.data.content.completion_date
            ? response.data.content.completion_date
            : ""
        );
        setStatusDeal(response.data.content.status_deals);
        setLossReason(
          response.data.content.loss_reason
            ? response.data.content.loss_reason
            : ""
        );
        setCompanyName(response.data.content.partnership);
        setTaskName(response.data.content.name);
        setStartDate(response.data.content.start_date);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-task", { replace: true });
        }
      });
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const data = {
      completion_date: completionDate,
      status_deals: statusDeal,
      loss_reason: lossReason,
    };
    try {
      await axios
        .patch(endpointProgress, data, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Edit Progress Berhasil!",
            `Berhasil ubah progress sales task untuk kerja sama dengan perusahaan <b class="text-uppercase">${companyName}</b>`,
            "success"
          );
          navigate("/sales-task");
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Gagal!", "Harap lengkapi data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Gagal!", `${error.response.data.message}`, "error");
      }
    }
  };

  const statusList = [
    { value: "Waiting", label: "Waiting" },
    { value: "Won", label: "Won" },
    { value: "Lose", label: "Lose" },
  ];

  //selected status
  const changeStatus = (selectedOption) => {
    if (selectedOption) {
      setStatusDeal(selectedOption.value);
      setLossReason(null);
    } else {
      setStatusDeal(null);
      setLossReason(null);
    }
  };

  const lossReasonList = [
    { value: "Price Doesn't Match", label: "Price Doesn't Match" },
    { value: "Not Interested", label: "Not Interested" },
    { value: "Choose Other Hotel", label: "Choose Other Hotel" },
    { value: "No Replies", label: "No Replies" },
  ];

  //selected status
  const changeLossReason = (selectedOption) => {
    if (selectedOption) {
      setLossReason(selectedOption.value);
    } else {
      setLossReason(null);
    }
  };

  return (
    <>
      <h1 className="page-title">Edit Progres Sales Task</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-task">Daftar Sales Task</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Edit Progres Sales Task
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className="mt-4 px-5 form form-employee"
      >
        <div className="mb-3 row">
          <label
            htmlFor="taskName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Task Name
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="taskName"
              className="form-control"
              id="taskName"
              value={taskName}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="companyName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Partnership
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="companyName"
              className="form-control"
              id="companyName"
              value={companyName}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="completionDate"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Completion Date <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="completionDate"
              className="form-control"
              id="completionDate"
              value={completionDate}
              onChange={(e) => setCompletionDate(e.target.value)}
              required
              min={startDate}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="statusDeal"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Status Deal <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select status"
              isClearable={true}
              options={statusList}
              name="statusDeal"
              onChange={changeStatus}
              className="select2 select-group"
              value={statusList.find((obj) => obj.value === statusDeal)}
              id="statusDeal"
              required
            />
          </div>
        </div>
        {statusDeal === "Lose" && (
          <div className="mb-3 row">
            <label
              htmlFor="lossReason"
              className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
            >
              Loss Reason <i className="red">*</i>
            </label>
            <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
              <Select
                placeholder="Select Loss Reason"
                isClearable={true}
                options={lossReasonList}
                name="lossReason"
                onChange={changeLossReason}
                className="select2 select-group"
                value={lossReasonList.find((obj) => obj.value === lossReason)}
                id="lossReason"
                required
              />
            </div>
          </div>
        )}

        <div className="mb-3 mt-4">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-task")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Cancel
            </button>
            <button className="btn btn-primary btn-blue" type="submit">
              <i className="fa fa-edit mr-2"></i>
              Edit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditProgress;
