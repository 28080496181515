import React, { useState, useEffect } from "react";
import axios from "axios";

const UserDetail = () => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  // const [image, setImage] = useState();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [hotel, setHotel] = useState("");
  const [department, setDepartment] = useState("");

  const endpointView = `${process.env.REACT_APP_BASE_URL}/users/`;

  const getUserDetail = async () => {
    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid = stringifyUserData["uuid"];
      try {
        await axios
          .get(endpointView + uuid, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            // setImage(response.data.content.image);
            setName(response.data.content.employee.name);
            setAddress(response.data.content.employee.address);
            setPhoneNumber(response.data.content.employee.phone_number);
            setEmail(response.data.content.employee.email);
            setPosition(response.data.content.employee.position);
            setHotel(response.data.content.employee.hotel.name);
            setDepartment(response.data.content.employee.department.name);
          });
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <form method="post" className="mt-4 px-5 form form-profile">
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Name
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="name"
            className="form-control"
            id="inputName"
            value={name}
            readOnly
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputAddress"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Address
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <textarea
            name="address"
            id="inputAddress"
            rows="3"
            className="form-control"
            value={address}
            readOnly
          ></textarea>
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputPhoneNumber"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Phone Number
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="telp"
            name="phoneNumber"
            className="form-control"
            id="inputPhoneNumber"
            value={phoneNumber}
            readOnly
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputEmail"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Email
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="email"
            name="email"
            className="form-control"
            id="inputEmail"
            value={email}
            readOnly
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputHotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="uuid_hotel"
            className="form-control"
            id="inputHotel"
            value={hotel}
            readOnly
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDepartment"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Department
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="uuid_department"
            className="form-control"
            id="inputDepartment"
            value={department}
            readOnly
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputPosition"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Position
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="position"
            className="form-control"
            id="inputPosition"
            value={position}
            readOnly
          />
        </div>
      </div>
    </form>
  );
};

export default UserDetail;
