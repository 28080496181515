const CheckDataZero = (data) => {
  // Iterate through datasets
  for (const datasetKey in data.datasets) {
    const dataset = data.datasets[datasetKey];
    // Iterate through data array of each dataset
    for (const value of dataset.data) {
      // If any value is not zero, return false
      if (value !== 0) {
        return false;
      }
    }
  }
  // All values are zero
  return true;
};

export default CheckDataZero;
