import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";

const BroadcastMessageTemplate = (props) => {
  const [template, setTemplate] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredTemplate, setFilteredTemplate] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/message-templates`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/message-templates/delete/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Message Template",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Message Template",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Message Template",
    feature: props.feature,
  });

  //get template
  const getTemplate = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setTemplate(data);
          setFilteredTemplate(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getTemplate();
  }, []);

  //search template
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = template.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredTemplate(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Language",
      selector: (row) => row.language,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name.replace(/_/g, " "),
      sortable: true,
    },
    {
      name: "Meta Category",
      selector: (row) => row.meta_category,
      sortable: true,
    },
    {
      name: "Meta Status",
      selector: (row) => row.meta_status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {(row.meta_status === "APPROVED" ||
            row.meta_status === "REJECTED" ||
            row.meta_status === "PAUSED") &&
          hasFeatureEdit ? (
            <Link to={`edit/${row.uuid}`}>
              <button className="btn btn-aksi btn-edit mr-1">Edit</button>
            </Link>
          ) : (
            <Link to={`${row.uuid}`}>
              <button className="btn btn-aksi btn-edit mr-1">Detail</button>
            </Link>
          )}

          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.name)}
            >
              Delete
            </button>
          )}
        </>
      ),
      id: "aksi",
      width: "170px",
    },
  ];

  //alert delete template
  const alertDelete = (uuid, name) => () => {
    Swal.fire({
      title: "Delete Template?",
      html: `Are you sure want to delete the template named <b class="text-uppercase">${name.replace(
        /_/g,
        " "
      )}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#f0f0f0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTemplate(uuid, name);
      }
    });
  };

  //function delete template
  const deleteTemplate = (uuid, name) => {
    // try {
    axios
      .delete(endpointDelete + uuid, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        Swal.fire(
          "Success!",
          `Successfully deleted the template named <b class="text-uppercase">${name.replace(
            /_/g,
            " "
          )}</b>`,
          "success"
        );
        getTemplate();
      })
      .catch((error) => {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      });
  };

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredTemplate.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  return (
    <>
      <h1 className="page-title mb-3">WhatsApp Broadcast Template List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-6 col-sm-5 col-12">
          {hasFeatureAdd && (
            <Link to="create">
              <button className="btn btn-primary btn-grey mr-2">
                <i className="fa fa-plus-square mr-2"></i>
                Create Template
              </button>
            </Link>
          )}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-7 col-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredTemplate.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default BroadcastMessageTemplate;
