import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CreatableSelect from "react-select/creatable";
import CheckFeature from "../../../components/CheckFeature";

const AddEditPic = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [notes, setNotes] = useState("");

  const [companyList, setCompanyList] = useState([]);

  const token = localStorage.getItem("token");
  const params = useParams();
  const picId = params.id;

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/pics/${picId}`;
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/pics/search/${picId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/pics/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/pics/edit/${picId}`;
  const endpointPartnership = `${process.env.REACT_APP_BASE_URL}/partnerships`;

  //check features
  const hasFeatureView = CheckFeature({
    name: "View Person In Charge",
    feature: props.feature,
  });
  const hasFeatureEdit = CheckFeature({
    name: "Update Person In Charge",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Person In Charge",
    feature: props.feature,
  });

  useEffect(() => {
    if (hasFeatureView) {
      if (picId) {
        getPic();
      }
      getPartnership();
    }
    if (picId) {
      getPic();
    }
  }, [hasFeatureView, picId]);

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const picData = {
      name: name,
      phone_number: phoneNumber,
      email: email,
      uuid_company: company,
      notes: notes,
    };
    try {
      if (!picId) {
        await axios
          .post(endpointAdd, picData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added PIC from ${companyName} with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/pic");
          });
      } else {
        await axios
          .put(endpointEdit, picData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited PIC data from ${companyName} with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/pic");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get partnership
  const getPartnership = async () => {
    await axios
      .get(endpointPartnership, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const data = response.data.content;
        const options = data.map(function (company) {
          return { value: company.uuid, label: company.name };
        });
        setCompanyList(options);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/pic", { replace: true });
        }
      });
  };

  //selected partnership
  const changeCompany = (selectedOption) => {
    if (selectedOption) {
      setCompany(selectedOption.value);
      setCompanyName(selectedOption.label);
    } else {
      setCompany(null);
    }
  };

  //create partnership
  const createCompany = useCallback(
    (inputValue) => {
      window.open("/partnership/add", "_blank").focus();
    },
    [companyList]
  );

  //get pic
  const getPic = async () => {
    await axios
      .get(hasFeatureView ? endpointView : endpointDetail, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setName(response.data.content.name);
        setPhoneNumber(response.data.content.phone_number);
        setEmail(response.data.content.email);
        setCompany(response.data.content.uuid_company);
        setCompanyName(response.data.content.partnership);
        setNotes(response.data.content.notes);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/pic", { replace: true });
        }
      });
  };

  return (
    <>
      <h1 className="page-title">
        {picId
          ? hasFeatureEdit
            ? "Edit Person In Charge Data"
            : "Person In Charge Details"
          : "Add Person In Charge"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/pic">Person in Charge List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {picId
            ? hasFeatureEdit
              ? "Edit Person In Charge Data"
              : "Person In Charge Details"
            : "Add Person In Charge"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (picId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={picId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPhoneNumber"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Phone Number <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <PhoneInput
              international
              defaultCountry="ID"
              value={phoneNumber}
              onChange={setPhoneNumber}
              id="inputPhoneNumber"
              disabled={picId && !hasFeatureEdit}
            />
            <small className="form-text text-danger">
              {phoneNumber
                ? isValidPhoneNumber(phoneNumber)
                  ? undefined
                  : "Invalid phone number"
                : "The phone number is required"}
            </small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEmail"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Email <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="email"
              name="inputEmail"
              className="form-control"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={picId && !hasFeatureEdit}
            />
          </div>
        </div>

        {picId && !hasFeatureEdit && (
          <div className="mb-3 row">
            <label
              htmlFor="partnership"
              className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
            >
              Partnership <i className="red">*</i>
            </label>
            <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
              <input
                type="text"
                name="partnership"
                className="form-control"
                id="partnership"
                value={companyName}
                required
                disabled
              />
            </div>
          </div>
        )}
        {!(picId && !hasFeatureEdit) && (
          <div className="mb-3 row">
            <label
              htmlFor="inputCompany"
              className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
            >
              Partnership Name <i className="red">*</i>
            </label>
            <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
              <div className="input-group">
                <CreatableSelect
                  placeholder="Select Partnership"
                  isClearable={true}
                  options={companyList}
                  name="inputCompany"
                  autoFocus={true}
                  value={companyList.find((obj) => obj.value === company)}
                  onChange={changeCompany}
                  className="select2 select-group"
                  id="inputCompany"
                  onCreateOption={createCompany}
                  required
                />
                <button
                  className="btn btn-grey btn-sync"
                  type="button"
                  onClick={() => getPartnership()}
                >
                  <i className="fa fa-sync"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="mb-3 row">
          <label
            htmlFor="notes"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Notes <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="notes"
              className="form-control"
              id="notes"
              value={notes || ""}
              onChange={(e) => setNotes(e.target.value)}
              required
              disabled={picId && !hasFeatureEdit}
              placeholder={!picId ? "PIC Marketing, PIC Sales, Etc" : ""}
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={
                picId
                  ? hasFeatureEdit
                    ? () => navigate("/pic")
                    : () => navigate(-1)
                  : () => navigate("/pic")
              }
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {picId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((picId && hasFeatureEdit) || (!picId && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={
                  phoneNumber
                    ? isValidPhoneNumber(phoneNumber)
                      ? undefined
                      : "disabled"
                    : "disabled"
                }
              >
                <i
                  className={
                    "fa " + (picId ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {picId ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditPic;
