import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const ConversationTimeSetup = () => {
  const [time, setTime] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const endpoint = `${process.env.REACT_APP_BASE_URL}/system-configuration/converstation-hour-limit`;

  useEffect(() => {
    getTime();
  }, []);

  const getTime = async () => {
    try {
      await axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setTime(response.data.content);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const setUpTime = async (e) => {
    e.preventDefault();
    const data = {
      hour: time,
    };
    try {
      await axios
        .post(endpoint, data, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          setTime("");
          Swal.fire(
            "Success!",
            `Successfully setup WhatsApp chat expiration time after <b>${time}</b> hours`,
            "success"
          );
          getTime();
        })
        .catch((error) => {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changeTime = (value) => {
    setTime(value);
  };

  return (
    <>
      <h1 className="page-title mb-3">WhatsApp Chat Expiration Time Set Up</h1>
      <div className="card my-4">
        <div className="card-body div-backup-time-setup">
          <form
            method="post"
            onSubmit={setUpTime}
            className="px-5 form form-employee"
          >
            <div className="mb-3 row">
              <label
                htmlFor="inputName"
                className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
              >
                Set Time (hour) <i className="red">*</i>
              </label>
              <div className="col-xl-10 col-lg-8 col-md-9 col-sm-12">
                <input
                  type="number"
                  id="timeInput"
                  name="timeInput"
                  className="form-control"
                  value={time}
                  min="1"
                  max="12"
                  onChange={(e) => changeTime(+e.target.value)}
                  required
                />
              </div>
            </div>
            <div>
              <i className="red">*</i> Required
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <button className="btn btn-primary btn-blue" type="submit">
                  <i className="fa fa-save mr-2"></i>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ConversationTimeSetup;
