import React, { useState, useEffect } from "react";
import "./modalShowMap.scss";
import MapLocation from "./MapLocation";

const ModalShowMap = ({ latitude, longitude }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const handleShowMap = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-aksi btn-edit"
        onClick={handleShowMap}
      >
        Show Map
      </button>
      {isModalOpen && (
        <div className="modal modal-map" onClick={handleCloseModal}>
          <div
            className="modal-content modal-content-map"
            onClick={(e) => e.stopPropagation()}
          >
            <MapLocation longitude={longitude} latitude={latitude} />

            <button
              className="btn btn-primary btn-dark mt-4"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalShowMap;
