import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";
import moment from "moment";
import Select from "react-select";
import io from "socket.io-client";

const ChatRequestList = (props) => {
  const socket = io(process.env.REACT_APP_WEBSOCKET_URL);
  const [chatRequest, setChatRequest] = useState([]);
  const [continueChatRequest, setContinueChatRequest] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredChatRequest, setFilteredChatRequest] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState("");

  const token = localStorage.getItem("token");
  let userId = null;

  if (token) {
    // props.setSocketChatRequest(true);
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUserData = JSON.parse(user);
      userId = parsedUserData.uuid;
    }
  }

  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/chat-requests`;
  const endpointHandle = `${process.env.REACT_APP_BASE_URL}/chat-requests/handle/`;

  //check features
  const hasFeatureHandle = CheckFeature({
    name: "Handle Chat Request",
    feature: props.feature,
  });

  const hasFeatureView = CheckFeature({
    name: "View Conversation",
    feature: props.feature,
  });

  const hasFeatureCreate = CheckFeature({
    name: "Create Conversation",
    feature: props.feature,
  });

  //get chat request
  const getChatRequest = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.request_at < b.request_at ? 1 : -1
          );
          setChatRequest(sortedData);
          // sortedData
          //   .filter((data1) => data1.status === "Onprogress")
          //   .map((data) =>
          //     socket.emit("joinChatRoom", {
          //       uuid_user: data.handle_by,
          //       uuid_chat_request: data.uuid,
          //     })
          //   );
          setFilteredChatRequest(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getChatRequest();
  }, [props.newChatRequest]);

  //search chat request
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = chatRequest.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return (
        (!search ||
          data.name?.toLowerCase().match(searchRegex) ||
          data.phone_number?.match(searchRegex)) &&
        (!status || data.status === status)
      );
    });

    setFilteredChatRequest(result);
  }, [search, status]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      id: "requsetTime",
      name: "Request Time",
      selector: (row) => moment(row.request_at).format("YYYY MMMM D HH:mm:ss"),
      // selector: (row) => row.request_at,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Handle by",
      selector: (row) => row.handler_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {hasFeatureView && row.status === "Closed" && (
            <Link to={`/history-chat/${row.uuid}`} target="_blank">
              <button className="btn btn-aksi btn-edit mr-1" type="button">
                Chat History
              </button>
            </Link>
          )}
          {hasFeatureCreate &&
            row.status === "Onprogress" &&
            row.handle_by === userId && (
              <Link
                to={`/whatsapp-live-conversation/${row.uuid}`}
                target="_blank"
              >
                <button className="btn btn-aksi btn-reset mr-1" type="button">
                  Continue Chat
                </button>
              </Link>
            )}
          {hasFeatureHandle && !row.handle_by && (
            <button
              className="btn btn-aksi btn-reset"
              onClick={alertHandle(row.uuid, row.name)}
            >
              Handle
            </button>
          )}
        </>
      ),
      id: "aksi",
      width: "170px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredChatRequest.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert handle
  const alertHandle = (uuid, name) => () => {
    Swal.fire({
      title: "Handle Chat Request?",
      html: `Are you sure want to handle chat requests from <b class="text-uppercase">${name}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Handle",
    }).then((result) => {
      if (result.isConfirmed) {
        handleChat(uuid, name);
      }
    });
  };

  const handleChat = (uuid, name) => {
    const uuid_chatRequest = uuid;
    const data = { uuid_user: userId, uuid_chat_request: uuid_chatRequest };
    try {
      axios
        .post(
          endpointHandle + uuid,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          getChatRequest();
          socket.emit("joinChatRoom", data);
          window.open(`/whatsapp-live-conversation/${uuid}`, "_blank");
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to handle chat requests from <b class="text-uppercase">${name}</b>`,
        "error"
      );
    }
  };

  const filterStatus = (selectedOption) => {
    if (selectedOption) {
      setStatus(selectedOption.value);
    } else {
      setStatus(null);
    }
  };

  const statusList = [
    { value: "Onprogress", label: "Onprogress" },
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" },
  ];

  return (
    <>
      <h1 className="page-title mb-3">WhatsApp Chat Request</h1>
      <div className="row mb-3">
        <div className="offset-lg-4 col-lg-4 col-md-6 col-sm-12 mb-1">
          <Select
            placeholder="Filter by status"
            isClearable={true}
            options={statusList}
            name="uuid_status"
            value={statusList.find((obj) => obj.value === status)}
            onChange={filterStatus}
            className="select2 input-search"
            id="inputStatus"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-1">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by name / phone number"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredChatRequest.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
          defaultSortFieldId="requestTime"
        />
      </div>
    </>
  );
};

export default ChatRequestList;
