import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../custom-pages-css.scss";
import CheckFeature from "../../components/CheckFeature";

const AddEditHotel = (props) => {
  const navigate = useNavigate();
  const [oldImage, setOldImg] = useState();
  const [showBtn, setShowBtn] = useState(false);

  const [hotelImage, setHotelImage] = useState();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const token = localStorage.getItem("token");

  const params = useParams();
  const hotelId = params.id;

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/hotels/${hotelId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/hotels/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/hotels/edit/${hotelId}`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Hotel",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Hotel",
    feature: props.feature,
  });

  useEffect(() => {
    if (hotelId) {
      getHotelById();
    }
  }, []);

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("hotelImage", hotelImage);
    formData.append("code", code);
    formData.append("name", name);
    formData.append("address", address);
    formData.append("phone_number", phoneNumber);
    formData.append("email", email);
    if (hotelImage) {
      const fileName = hotelImage.name.toLowerCase();
      var fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);

      if (fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg") {
        if (hotelImage.size > 2048000) {
          Swal.fire(
            "Gagal!",
            "Ukuran file tidak boleh lebih dari 2MB",
            "error"
          );
        } else {
          try {
            if (!hotelId) {
              await axios
                .post(endpointAdd, formData, {
                  headers: {
                    Authorization: token,
                  },
                })
                .then(() => {
                  Swal.fire(
                    "Tambah Hotel Berhasil!",
                    `Berhasil tambah hotel <b class="text-uppercase">${name}</b>`,
                    "success"
                  );
                  navigate("/hotel");
                });
            } else {
              await axios
                .put(endpointEdit, formData, {
                  headers: {
                    Authorization: token,
                  },
                })
                .then(() => {
                  Swal.fire(
                    "Edit Hotel Berhasil!",
                    `Berhasil ubah data hotel <b class="text-uppercase">${name}</b>`,
                    "success"
                  );
                  navigate("/hotel");
                });
            }
          } catch (error) {
            console.log(error.response.data.message);
            if (
              error.response.data.status === 401 ||
              error.response.data.status === 403
            ) {
              navigate("/404", { replace: true });
            } else if (error.response.data.status === 500) {
              Swal.fire("Gagal!", "Harap lengkapi data", "error");
            } else if (error.response.data.status === 400) {
              Swal.fire("Gagal!", `${error.response.data.message}`, "error");
            }
          }
        }
      } else {
        Swal.fire(
          "Ekstensi File Tidak Diizinkan!",
          "Hanya file jpg, png, dan jpeg yang diizinkan",
          "error"
        );
      }
    } else {
      try {
        if (!hotelId) {
          await axios
            .post(endpointAdd, formData, {
              headers: {
                Authorization: token,
              },
            })
            .then(() => {
              Swal.fire(
                "Tambah Hotel Berhasil!",
                `Berhasil tambah hotel <b class="text-uppercase">${name}</b>`,
                "success"
              );
              navigate("/hotel");
            });
        } else {
          await axios
            .put(endpointEdit, formData, {
              headers: {
                Authorization: token,
              },
            })
            .then(() => {
              Swal.fire(
                "Edit Hotel Berhasil!",
                `Berhasil ubah data hotel <b class="text-uppercase">${name}</b>`,
                "success"
              );
              navigate("/hotel");
            });
        }
      } catch (error) {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 500) {
          Swal.fire("Gagal!", "Harap lengkapi data", "error");
        } else if (error.response.data.status === 400) {
          Swal.fire("Gagal!", `${error.response.data.message}`, "error");
        }
      }
    }
  };

  //get detail hotel
  const getHotelById = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setOldImg(
          `${process.env.REACT_APP_BASE_URL}/${response.data.content.image}`
        );
        setCode(response.data.content.code);
        setName(response.data.content.name);
        setAddress(response.data.content.address);
        setPhoneNumber(response.data.content.phone_number);
        setEmail(response.data.content.email);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/hotel", { replace: true });
        }
      });
  };

  const loadFile = async (event) => {
    if (event.target.files[0] != null) {
      const fileName = event.target.files[0].name.toLowerCase();
      var fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      var image = document.getElementById("output");

      if (fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg") {
        image.src = URL.createObjectURL(event.target.files[0]);
      } else {
        image.src = `${process.env.REACT_APP_BASE_URL}/media/images/hotels/default_hotel_logo.png`;
      }
      setHotelImage(event.target.files[0]);
      setShowBtn(true);
    }
  };

  const cancelChange = async (event) => {
    var image = document.getElementById("output");
    image.src = oldImage
      ? oldImage
      : `${process.env.REACT_APP_BASE_URL}/media/images/hotels/default_hotel_logo.png`;
    document.getElementById("inputImage").value = null;
    setShowBtn(false);
    setHotelImage();
  };

  return (
    <>
      <h1 className="page-title">
        {hotelId
          ? hasFeatureEdit
            ? "Edit Hotel Data"
            : "Hotel Details"
          : "Add Hotel"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/hotel">Hotel List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {hotelId
            ? hasFeatureEdit
              ? "Edit Hotel Data"
              : "Hotel Details"
            : "Add Hotel"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (hotelId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={hotelId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputCode"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Code <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="slug"
              className="form-control"
              id="inputCode"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              disabled={hotelId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputAddress"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Address <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="address"
              id="inputAddress"
              rows="3"
              className="form-control"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              disabled={hotelId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPhoneNumber"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Phone Number <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <PhoneInput
              international
              defaultCountry="ID"
              value={phoneNumber}
              onChange={setPhoneNumber}
              id="inputPhoneNumber"
              disabled={hotelId && !hasFeatureEdit}
            />
            <small className="form-text text-danger">
              {phoneNumber
                ? isValidPhoneNumber(phoneNumber)
                  ? undefined
                  : "Invalid phone number"
                : "The phone number is required"}
            </small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEmail"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Email <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="email"
              name="email"
              className="form-control"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={hotelId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputImage"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Image
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="file"
              name="image"
              className="form-control input-file-custom"
              id="inputImage"
              accept=".png, .jpg, .jpeg"
              onChange={(event) => loadFile(event)}
              disabled={hotelId && !hasFeatureEdit}
            />
            <div className="text-center my-3">
              <img
                src={
                  oldImage
                    ? oldImage
                    : `${process.env.REACT_APP_BASE_URL}/media/images/hotels/default_hotel_logo.png`
                }
                id="output"
                alt="logo"
                height="80px"
              />
              <br />
              <i className="small">Logo Preview</i>
            </div>
            {showBtn && (
              <div className="row">
                <div className="col-12 text-center py-2">
                  <button
                    className="btn btn-grey mx-1"
                    type="button"
                    onClick={(e) => cancelChange(e)}
                  >
                    <i className="fa fa-times"></i> Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/hotel")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {hotelId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((hotelId && hasFeatureEdit) || (!hotelId && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={
                  phoneNumber
                    ? isValidPhoneNumber(phoneNumber)
                      ? undefined
                      : "disabled"
                    : "disabled"
                }
              >
                <i
                  className={
                    "fa " + (hotelId ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {hotelId ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditHotel;
