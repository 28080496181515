import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import CheckFeature from "../../../components/CheckFeature";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const MarketingTaskList = (props) => {
  const [taskList, setTaskList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredTask, setFilteredTask] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/marketing-tasks`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/marketing-tasks/delete/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Marketing Task",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Marketing Task",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Marketing Task",
    feature: props.feature,
  });

  //get task
  const getTaskList = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.due_date.localeCompare(b.due_date)
          );
          setTaskList(sortedData);
          setFilteredTask(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getTaskList();
  }, []);

  //search campaign
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = taskList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const dueDate = moment(data.due_date)
        .format("D MMMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const name = data?.name?.toLowerCase().match(searchRegex);
      const hotel = data.hotel?.name?.toLowerCase().match(searchRegex);
      const employee = data.employee?.toLowerCase().match(searchRegex);
      const project_name = data.project_name?.toLowerCase().match(searchRegex);
      const desc = data.description?.toLowerCase().match(searchRegex);
      const status = data.status?.toLowerCase().match(searchRegex);

      return (
        dueDate || hotel || name || employee || project_name || desc || status
      );
    });

    setFilteredTask(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "40px",
    },
    {
      name: "Due Date",
      selector: (row) =>
        row.due_date ? moment(row.due_date).format("DD/MM/YYYY") : "-",
      sortable: true,
      sortFunction: (a, b) => {
        const dateA = moment(a.due_date).format("YYYY MM DD");
        const dateB = moment(b.due_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "120px",
    },
    {
      name: "Hotel",
      selector: (row) => (row?.hotel?.name ? row.hotel.name : "-"),
    },
    {
      name: "Project Name",
      selector: (row) => row.project_name,
      sortable: true,
    },
    {
      name: "Task Name",
      selector: (row) => (row?.name ? row.name : "-"),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "110px",
    },
    {
      name: "Updated at",
      selector: (row) =>
        row.status_updated_at
          ? moment(row.status_updated_at).format("DD/MM/YYYY")
          : "-",
      sortable: true,
      sortFunction: (a, b) => {
        const dateA = moment(a.status_updated_at).format("YYYY MM DD");
        const dateB = moment(b.status_updated_at).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "120px",
    },
    // {
    //   name: "Detail",
    //   allowOverflow: true,
    //   button: true,
    //   cell: (row) =>
    //     row.members?.length > 0 || row.products?.length > 0 ? (
    //       <button
    //         className="btn btn-aksi btn-reset"
    //         onClick={() => handleRowExpansion(row)}
    //       >
    //         {expandedRowUuid === row.uuid ? "Hide ▼" : "Show ▶"}
    //       </button>
    //     ) : (
    //       "-"
    //     ),
    //   ignoreRowClick: true,
    //   width: "90px",
    // },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">
              {hasFeatureEdit && row.status !== "Done" ? "Edit" : "Detail"}
            </button>
          </Link>
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.project_name)}
            >
              Delete
            </button>
          )}
        </>
      ),
      id: "action",
      width: "180px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredTask.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete
  const alertDelete = (uuid, project_name) => () => {
    Swal.fire({
      title: "Delete Marketing Task?",
      html: `Are you sure want to delete marketing task for project named <b class="text-uppercase">${project_name}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTask(uuid, project_name);
      }
    });
  };

  //function delete
  const deleteTask = (uuid, project_name) => {
    Swal.fire({
      title: "Sending Message ...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .delete(endpointDelete + uuid, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        Swal.fire(
          "Succes!",
          `Successfully deleted marketing task for project named <b class="text-uppercase">${project_name}</b>`,
          "success"
        );
        getTaskList();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire(
          "Failed!",
          `Failed to delete marketing task for project named <b class="text-uppercase">${project_name}</b> <br> ${error.response.data.message}`,
          "error"
        );
      });
  };

  return (
    <>
      <h1 className="page-title mb-3">Marketing Task List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-7 col-sm-12 col-12 col-btn-export">
          {hasFeatureAdd && (
            <Link to="add">
              <button className="btn btn-primary btn-grey mr-2 button-export">
                <i className="fa fa-plus-square mr-2"></i>
                Add Task
              </button>
            </Link>
          )}
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredTask,
                "Marketing Task List",
                "Marketing Task List"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredTask, "Marketing Task List")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-12 col-12 col-btn-export">
          <input
            type="text"
            className="form-control input-search mt-0"
            placeholder="Search.."
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredTask.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default MarketingTaskList;
