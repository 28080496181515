import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import Select from "react-select";

const AddBroadcast = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [varNum, setVarNum] = useState(0);
  const [exampleMessage, setExampleMessage] = useState([]);
  const [exampleHeader, setExampleHeader] = useState("");
  const [headerType, setHeaderType] = useState("");

  const [hotelList, setHotelList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [invalidDate, setInvalidDate] = useState(false);
  const [targetList, setTargetList] = useState([]);
  const [filteredTarget, setFilteredTarget] = useState([]);
  const [search, setSearch] = useState("");
  const [targetType, setTargetType] = useState("Karyawan");
  const [finalMessage, setFinalMessage] = useState("");

  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  const [template, setTemplate] = useState("");
  const [sendAt, setSendAt] = useState(null);
  const [messageParams, setMessageParams] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [headerFile, setHeaderFile] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [hotelTarget, setHotelTarget] = useState(null);
  const [description, setDescription] = useState("");
  const [targetEmployee, setTargetEmployee] = useState([]);
  const [targetCustomer, setTargetCustomer] = useState([]);

  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [segmentList, setSegmentList] = useState([]);
  const [segment, setSegment] = useState(null);

  const [selectedDateTime, setSelectedDateTime] = useState("");

  useEffect(() => {
    if (selectedDateTime) {
      const interval = setInterval(() => {
        const now = new Date();
        if (selectedDateTime < now) {
          setInvalidDate(true);
        } else {
          setInvalidDate(false);
        }
      }, 1000); // Check every second

      return () => clearInterval(interval);
    }
  }, [selectedDateTime]);

  const handleDateChange = (e) => {
    const newSelectedDateTime = new Date(e.target.value);
    setSelectedDateTime(newSelectedDateTime);
    const formattedDate = moment(newSelectedDateTime).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    setSendAt(formattedDate);
  };

  //endpoint
  const endpointTemplate = `${process.env.REACT_APP_BASE_URL}/message-templates/`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointEmployee = `${process.env.REACT_APP_BASE_URL}/employees`;
  const endpointCust = `${process.env.REACT_APP_BASE_URL}/customers`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/broadcasts/add`;
  const endpointSegment = `${process.env.REACT_APP_BASE_URL}/customer-segmentations`;

  const changeTarget = (e) => {
    setFilteredTarget([]);
    setTargetList([]);
    setTargetType(e.target.value);
    getTarget(e.target.value);
    setSearch("");
    setSegment(null);
  };

  const getTarget = async (target) => {
    try {
      await axios
        .get(target === "Customer" ? endpointCust : endpointEmployee, {
          headers: {
            Authorization: token,
          },
          params: {
            hasPhoneNumber: "true"
          }
        })
        .then((response) => {
          //console.log(response.data.content)
          const data = response.data.content.map(function (data) {
            const obj = {
              uuid: data.uuid,
              name: data.name,
              phone_number: data.phone_number,
            };

            if (target === "Karyawan") {
              obj.hotel = data.hotel;
              obj.department = data.department;
            } else {
              obj.segmentation = data.segmentation;
              obj.hotel = data.hotel;
            }

            return obj;
          });
          setTargetList(data);
          setFilteredTarget(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getTemplate = async () => {
    try {
      await axios
        .get(endpointTemplate, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data
            .filter((tmp) => tmp.meta_status === "APPROVED")
            .map(function (tmp) {
              return {
                value: tmp.uuid,
                label: tmp.name.replace(/_/g, " "),
                message:
                  typeof tmp.message === "object"
                    ? tmp.message
                    : JSON.parse(tmp.message),
                header:
                  typeof tmp.header === "object"
                    ? tmp.header
                    : JSON.parse(tmp.header),
              };
            });
          setTemplateList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changeTemplate = (selectedOption) => {
    if (selectedOption) {
      setTemplate(selectedOption.value);
      setVarNum(
        selectedOption.message.variable_num
          ? selectedOption.message.variable_num
          : 0
      );
      setFinalMessage(
        selectedOption.message.text ? selectedOption.message.text : ""
      );
      setExampleMessage(
        selectedOption.message.example ? selectedOption.message.example : ""
      );
      if (selectedOption.header !== null) {
        setHeaderType(selectedOption.header.type);
        setExampleHeader(selectedOption.header.example);
      } else {
        setHeaderType("");
        setExampleHeader("");
      }
      setSendAt("");
      setMessageParams([]);
      setHeaderText("");
      setHeaderFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setHotel(null);
      setDescription("");
      setTargetEmployee([]);
      setTargetCustomer([]);
    } else {
      setTemplate("");
      setVarNum(0);
      setExampleMessage([]);
      setHeaderType("");
      setExampleHeader("");
      setHeaderType("");
      setExampleHeader("");
      setSendAt("");
      setMessageParams([]);
      setHeaderText("");
      setHeaderFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setHotel(null);
      setDescription("");
      setTargetEmployee([]);
      setTargetCustomer([]);
    }
  };

  const updateExample = (event, index) => {
    const newInputs = [...messageParams];
    newInputs[index] = event.target.value;
    setMessageParams(newInputs);
    console.log(messageParams.length);
  };

  //create template
  const createTemplate = useCallback(
    (inputValue) => {
      window.open("/whatsapp-broadcast-template/create", "_blank").focus();
    },
    [templateList]
  );

  const loadFile = async (event, headerType) => {
    if (event.target.files[0] != null) {
      const fileName = event.target.files[0].name.toLowerCase();
      var fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);

      if (
        (headerType === "IMAGE" && (fileExt === "png" || fileExt === "jpeg")) ||
        (headerType === "DOCUMENT" &&
          (fileExt === "pdf" || fileExt === "xlsx" || fileExt === "docx")) ||
        (headerType === "VIDEO" && (fileExt === "mp4" || fileExt === "3gp"))
      ) {
        if (event.target.files[0].size > 25000000) {
          Swal.fire(
            "Failed to upload header file!",
            "The file size must not exceed 25MB",
            "error"
          );
        } else {
          setHeaderFile(event.target.files[0]);
        }
      } else {
        Swal.fire("Failed,", "File extension is not allowed", "error");
        setHeaderFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = targetList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      if (targetType === "Customer") {
        return (
          (!segment || data.segmentation === segment) &&
          (!hotelTarget || data.hotel === hotelTarget) &&
          (!search || data.name.toLowerCase().match(searchRegex))
        );
      }
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredTarget(result);
    setCurrentPage(1);
  }, [search, segment, targetType, hotelTarget]);

  useEffect(() => {
    getHotel();
    getTemplate();
    getTarget("Karyawan");
    getSegment();
  }, []);

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Please wait..",
        html: `Broadcast creation is currently in progress <br/>`,
        showCancelButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didRender: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading]);

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  const changeHotelTarget = (selectedOption) => {
    if (selectedOption) {
      setHotelTarget(selectedOption.label);
    } else {
      setHotelTarget(null);
    }
  };

  //create hotel
  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [hotelList]
  );

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = filteredTarget.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  // Handle checkbox change
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      if (targetType === "Karyawan") {
        setTargetEmployee([...targetEmployee, id]);
      } else if (targetType === "Customer") {
        setTargetCustomer([...targetCustomer, id]);
      }
    } else {
      if (targetType === "Karyawan") {
        setTargetEmployee(
          targetEmployee.filter((selectedId) => selectedId !== id)
        );
      } else if (targetType === "Customer") {
        setTargetCustomer(
          targetCustomer.filter((selectedId) => selectedId !== id)
        );
      }
    }
  };

  // Handle "Check All" checkbox change
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const allIds = filteredTarget.map((row) => row.uuid);
      if (targetType === "Customer") {
        setTargetCustomer(allIds);
      } else {
        setTargetEmployee(allIds);
      }
    } else {
      if (targetType === "Customer") {
        setTargetCustomer([]);
      } else {
        setTargetEmployee([]);
      }
    }
  };

  const addBroadcast = async (e) => {
    e.preventDefault();
    const data = {
      uuid_template: template,
      send_at: sendAt,
      ...(hotel !== "" ? { uuid_hotel: hotel } : {}),
      ...(description !== "" ? { description: description } : {}),
      ...(messageParams.length > 0
        ? { message_parameters: messageParams }
        : {}),
      ...(headerText !== "" ? { header_text: headerText } : {}),
      ...(targetEmployee.length > 0 ? { employee_list: targetEmployee } : {}),
      ...(targetCustomer.length > 0 ? { customer_list: targetCustomer } : {}),
    };

    const formData = new FormData();
    formData.append("uuid_template", template);
    formData.append("send_at", sendAt);
    formData.append("file", headerFile);

    if (hotel !== "") {
      formData.append("uuid_hotel", hotel);
    }
    if (description !== "") {
      formData.append("description", description);
    }

    if (messageParams.length > 0) {
      messageParams.map((data, i) => {
        formData.append("message_parameters[" + i + "]", data);
      });
    }

    if (headerText !== "") {
      formData.append("header_text", headerText);
    }

    if (targetEmployee.length > 0) {
      targetEmployee.map((data, i) => {
        formData.append("employee_list[" + i + "]", data);
      });
    }
    if (targetCustomer.length > 0) {
      targetCustomer.map((data, i) => {
        formData.append("customer_list[" + i + "]", data);
      });
    }

    // if (headerType && headerType !== "TEXT" && headerFile) {
    //   for (let [key, value] of formData) {
    //     console.log(`${key}: ${value}`);
    //   }
    // } else {
    //   console.log(data);
    // }

    try {
      setLoading(true);
      await axios
        .post(
          endpointAdd,
          headerType && headerType !== "TEXT" && headerFile ? formData : data,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          Swal.fire({
            title: "Success!",
            html: `Successfully scheduled a broadcast on <b class="text-uppercase">${moment(
              sendAt
            ).format("D MMMM YYYY HH:mm:ss")}</b>`,
            icon: "success",
          });
          navigate("/whatsapp-broadcast/all");
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get segment list
  const getSegment = async () => {
    try {
      await axios
        .get(endpointSegment, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (segment) {
            return { value: segment.name, label: segment.name };
          });
          setSegmentList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected segment
  const changeSegment = (selectedOption) => {
    if (selectedOption) {
      setSegment(selectedOption.value);
    } else {
      setSegment(null);
    }
  };

  const handleRowClick = (id) => {
    // Toggle the row selection when the row is clicked
    if (targetType === "Karyawan") {
      if (targetEmployee.includes(id)) {
        setTargetEmployee(
          targetEmployee.filter((selectedId) => selectedId !== id)
        );
      } else {
        setTargetEmployee([...targetEmployee, id]);
      }
    } else {
      if (targetCustomer.includes(id)) {
        setTargetCustomer(
          targetCustomer.filter((selectedId) => selectedId !== id)
        );
      } else {
        setTargetCustomer([...targetCustomer, id]);
      }
    }
  };

  const replaceVariable = (message, messageParams) => {
    for (let i = 0; i < messageParams.length; i++) {
      const varMessage = `{{${i + 1}}}`;
      const replacement = messageParams[i];
      message = message.replace(varMessage, replacement);
    }

    return message;
  };

  return (
    <>
      <h1 className="page-title">Create WhatsApp Broadcast</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/whatsapp-broadcast/all">WhatsApp Broadcast List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Create WhatsApp Broadcast
        </li>
      </ol>
      <form
        onSubmit={addBroadcast}
        method="post"
        className="mt-4 px-5 form form-employee"
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputTemplate"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Template <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select template"
                isClearable={true}
                options={templateList}
                name="uuid_template"
                value={templateList.find((obj) => obj.value === template)}
                onChange={changeTemplate}
                className="select2 select-group"
                id="inputTemplate"
                onCreateOption={createTemplate}
                required
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getTemplate()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="send_at"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Sent At <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <input
              type="datetime-local"
              name="sendAt"
              className="form-control"
              id="send_at"
              value={sendAt || ""}
              onChange={handleDateChange}
              step={1}
              required
            />

            {invalidDate && (
              <small className="red mt-2">Please do not set back dates.</small>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select hotel"
                isClearable={true}
                options={hotelList}
                name="uuid_hotel"
                value={hotelList.find((obj) => obj.value === hotel)}
                onChange={changeHotel}
                id="inputHotel"
                onCreateOption={createHotel}
                className="select2 select-group"
                required
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getHotel()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDeskripsi"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Description
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <textarea
              name="deskripsi"
              id="inputDeskripsi"
              rows="3"
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </div>
        {varNum > 0 && (
          <div className="text-with-line col-xl-11 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
            MESSAGE
          </div>
        )}
        {parseInt(varNum, 10) > 0 &&
          Array.from(Array(parseInt(varNum, 10)), (e, i) => {
            return (
              <div className="row mb-3" key={i}>
                <label
                  htmlFor={"inputVar" + i}
                  className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
                >
                  Contents of Variable {i + 1} <i className="red">*</i>
                </label>
                <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                  <input
                    type="text"
                    id={"inputVar" + i}
                    className="form-control"
                    value={messageParams[i] || ""}
                    onChange={(e) => updateExample(e, i)}
                    required
                  />
                  <small>Example: {exampleMessage[i]}</small>
                </div>
              </div>
            );
          })}
        <div className="mb-3 row">
          <label
            htmlFor="finalMessage"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Final Message
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <textarea
              name="finalMessage"
              id="finalMessage"
              rows="6"
              className="form-control"
              value={
                messageParams.length === 0
                  ? finalMessage
                  : replaceVariable(finalMessage, messageParams)
              }
              disabled
            ></textarea>
          </div>
        </div>

        {exampleHeader && (
          <>
            <div className="text-with-line col-xl-11 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
              HEADER
            </div>
            <div className="row">
              <label
                htmlFor="headerText"
                className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
              >
                Header {headerType.toLowerCase()} <i className="red">*</i>
              </label>
              {headerType === "TEXT" && (
                <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                  <input
                    type="text"
                    id="headerText"
                    className="form-control"
                    value={headerText}
                    onChange={(e) => setHeaderText(e.target.value)}
                    required
                  />
                  <small>Example: {exampleHeader}</small>
                </div>
              )}
              {(headerType === "IMAGE" ||
                headerType === "DOCUMENT" ||
                headerType === "VIDEO") && (
                  <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                    <input
                      type="file"
                      id="headerFile"
                      className="form-control input-file-custom"
                      accept={
                        headerType === "IMAGE"
                          ? ".png, .jpg, .jpeg"
                          : headerType === "DOCUMENT"
                            ? ".pdf"
                            : ".mp4"
                      }
                      onChange={(event) => loadFile(event, headerType)}
                      required
                      ref={fileInputRef}
                    />
                  </div>
                )}
            </div>
          </>
        )}

        <div className="text-with-line col-xl-11 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          TARGET
        </div>
        <div className="row mb-3 filter-target">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <select
              name="search"
              id="selectTarget"
              className="form-control"
              onChange={(e) => changeTarget(e)}
            >
              <option value="Karyawan">Employee</option>
              <option value="Customer">Customer</option>
            </select>
          </div>
          {targetType === "Customer" && (
            <>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <Select
                  placeholder="Filter by segmentation"
                  isClearable={true}
                  options={segmentList}
                  name="segmentFilter"
                  value={segmentList.find((obj) => obj.value === segment)}
                  onChange={changeSegment}
                  className="select2 input-search"
                  id="filterSegment"
                  classNamePrefix="react-select"
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <Select
                  placeholder="Select hotel"
                  isClearable={true}
                  options={hotelList}
                  name="hotelFilter"
                  value={hotelList.find((obj) => obj.value === hotelTarget)}
                  onChange={changeHotelTarget}
                  className="select2 input-search"
                  id="filterHotel"
                  classNamePrefix="react-select"
                />
              </div>
            </>

          )}
          <div
            className={
              targetType === "Customer"
                ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 pt-2"
                : "col-xl-7 col-lg-8 col-md-8 col-sm-12"
            }
          >
            <input
              type="text"
              className="form-control input-search"
              placeholder="Search By Name"
              aria-label="Recipient's username"
              aria-describedby="btn-search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
        <div className="datatable col-xl-11 col-12 p-0">
          Employee target :{" "}
          {targetEmployee.length > 0 ? targetEmployee.length : 0}
          <br />
          Customer Target :{" "}
          {targetCustomer.length > 0 ? targetCustomer.length : 0}
          <div className="table table-responsive mb-0 mt-3">
            <table className="table table-hover table-sm custom-table">
              <thead>
                <tr>
                  <th scope="col" className="text-center th-checkbox">
                    <input
                      type="checkbox"
                      onChange={handleCheckAllChange}
                      checked={
                        (search !== "" || segment) && currentData.length > 0
                          ? filteredTarget.every((row) =>
                            targetType === "Customer"
                              ? targetCustomer.includes(row.uuid)
                              : targetEmployee.includes(row.uuid)
                          )
                          : targetType === "Customer"
                            ? targetCustomer.length === targetList.length
                            : targetEmployee.length === targetList.length
                      }
                      disabled={currentData.length <= 0}
                    />
                  </th>
                  <th scope="col">Name</th>
                  {targetType === "Karyawan" && (
                    <>
                      <th scope="col">Hotel</th>
                      <th scope="col">Department</th>
                      <th scope="col">Phone Number</th>
                    </>
                  )}
                  {targetType === "Customer" && (
                    <>
                      <th scope="col">Hotel</th>
                      <th scope="col">Segmentation</th>
                      <th scope="col">Phone Number</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, i) => (
                  <tr
                    key={row.uuid}
                    className={
                      targetType === "Customer"
                        ? targetCustomer.includes(row.uuid)
                          ? "selected"
                          : ""
                        : targetEmployee.includes(row.uuid)
                          ? "selected"
                          : ""
                    }
                    onClick={() => handleRowClick(row.uuid)}
                  >
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={
                          targetType === "Customer"
                            ? targetCustomer.includes(row.uuid)
                            : targetEmployee.includes(row.uuid)
                        }
                        onChange={(event) =>
                          handleCheckboxChange(event, row.uuid)
                        }
                      />
                    </td>
                    <td>{row.name}</td>
                    {targetType === "Karyawan" && (
                      <>
                        <td>{row.hotel}</td>
                        <td>{row.department}</td>
                        <td>{row.phone_number}</td>
                      </>
                    )}
                    {targetType === "Customer" && (
                      <>
                        <td>{row.hotel}</td>
                        <td>{row.segmentation}</td>
                        <td>{row.phone_number}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between pagination-table">
            <div>
              Showing {firstIndex + 1} to{" "}
              {Math.min(lastIndex, filteredTarget.length)} of{" "}
              {filteredTarget.length} entries
            </div>
            <div>
              <select
                className="form-control form-control-sm"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pagination pagination-custom justify-content-center">
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              &lt;&lt;
            </button>
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <button type="button" className="page-link active">{currentPage}</button>
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(filteredTarget.length / pageSize)
              }
            >
              &gt;
            </button>
            <button
              type="button"
              className="page-link"
              onClick={() =>
                handlePageChange(Math.ceil(filteredTarget.length / pageSize))
              }
              disabled={
                currentPage === Math.ceil(filteredTarget.length / pageSize)
              }
            >
              &gt;&gt;
            </button>
          </div>
        </div>
        {targetEmployee.length + targetCustomer.length === 0 && (
          <div className="red my-2">Broadcast target is required</div>
        )}
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/whatsapp-broadcast/all")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Cancel
            </button>
            <button
              className="btn btn-primary btn-blue"
              type="submit"
              disabled={
                invalidDate ||
                  targetEmployee.length + targetCustomer.length === 0
                  ? "disabled"
                  : ""
              }
            >
              <i className="fa fa-plus-square mr-2"></i>
              Create Broadcast
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddBroadcast;
