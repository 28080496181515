import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";

const TableList = (props) => {
  const [responseMessageList, setResponseMessageList] = useState([]);

  const token = localStorage.getItem("token");

  const endpointGetResponseMessageList = `${process.env.REACT_APP_BASE_URL}/response-messages`;
  const endpointDeleteResponseMessage = `${process.env.REACT_APP_BASE_URL}/response-messages/delete/`;
  const endpointActivateResponseMessage = `${process.env.REACT_APP_BASE_URL}/response-messages/activate/`;
  const endpointGetResponseMessageDetail = `${process.env.REACT_APP_BASE_URL}/response-messages/`;
  const endpointEditResponseMessage = `${process.env.REACT_APP_BASE_URL}/response-messages/edit/`;

  const navigate = useNavigate();

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Response Message",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Response Message",
    feature: props.feature,
  });
  const hasFeatureActivate = CheckFeature({
    name: "Activating Response Message",
    feature: props.feature,
  });

  useEffect(() => {
    getResponseMessage();
  }, []);

  useEffect(() => {
    getResponseMessage();
  }, [props.refresh]);

  const alertResponseMessageActivate = (uuid, type, message) => {
    Swal.fire({
      title: "Activate the response message?",
      html: `Are you sure want to select this message "${message}" to be the response message of the <b class="text-uppercase">${type}</b> type?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Activate",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .post(
              endpointActivateResponseMessage + uuid,
              {},
              {
                headers: { Authorization: token },
              }
            )
            .then(() => {
              Swal.fire(
                "Success!",
                `Successfully changed the active <b class="text-uppercase">${type}</b> type response message to "${message}"`,
                "success"
              );
              getResponseMessage();
            })
            .catch((error) => {
              Swal.fire("Failed!", error.response.data.message, "error");

              getResponseMessage();
            });
        } catch (error) {
          Swal.fire(
            "Failed!",
            `Failed to change the active <b class="text-uppercase">${type}</b> type response message to "${message}"`,
            "error"
          );
        }
      }
    });
  };

  const alertEditResponseMessage = (uuid) => {
    axios
      .get(endpointGetResponseMessageDetail + uuid, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        Swal.fire({
          title: "Edit Response Message",
          input: "textarea",
          inputLabel: "Message",
          inputAttributes: {
            maxlength: 4000,
          },
          showCancelButton: true,
          inputValue: response.data.content.message,
          confirmButtonColor: "#1c5ddc",
          cancelButtonColor: "#fc351c",
          cancelButtonText: "Cancel",
          confirmButtonText: "Edit",
          inputValidator: (value) => {
            if (!value) {
              return "Message cannot be empty!";
            } else if (value.length > 4000) {
              return "Message cannot be more than 4000 characters!";
            } else if (
              value.toLowerCase() ===
              response.data.content.message.toLowerCase()
            ) {
              return "The message cannot be the same as before!";
            } else {
              const data = {
                message: value,
                type: response.data.content.type,
              };
              try {
                axios
                  .put(endpointEditResponseMessage + uuid, data, {
                    headers: {
                      Authorization: token,
                    },
                  })
                  .then(() => {
                    Swal.fire(
                      "Success!",
                      `Successfully changed response message to "${value}"`,
                      "success"
                    );
                    getResponseMessage();
                  })
                  .catch((error) => {
                    Swal.fire(
                      "Failed!",
                      `${error.response.data.message}`,
                      "error"
                    );
                  });
              } catch (error) {
                Swal.fire("Failed!", `${error.response.data.message}`, "error");
              }
            }
          },
        });
      });
  };

  const alertDeleteResponseMessage = (uuid, type, message) => {
    Swal.fire({
      title: "Delete Response Message?",
      html: `
      Are you sure want to delete <b class="text-uppercase">${type}</b> type response messages with message "${message}"?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(endpointDeleteResponseMessage + uuid, {
              headers: { Authorization: token },
            })
            .then(() => {
              Swal.fire(
                "Success!",
                `Successfully deleted <b class="text-uppercase">${type}</b> type response messages with message "${message}"`,
                "success"
              );
              getResponseMessage();
            });
        } catch (error) {
          Swal.fire(
            "Failed!",
            `Failed to delete <b class="text-uppercase">${type}</b> type response messages with message "${message}"`,
            "error"
          );
        }
      }
    });
  };

  const getResponseMessage = async () => {
    try {
      await axios
        .get(endpointGetResponseMessageList, {
          headers: { Authorization: token },
        })
        .then((response) => {
          const data = response.data.content;
          const result = data.filter((data) => {
            return data.type === props.type;
          });
          setResponseMessageList(result);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "60px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
      id: "type",
      width: "150px",
    },
    {
      name: "Message",
      selector: (row) => row.message,
      id: "capitalizeUnset",
      width: "400px",
    },
    {
      name: "Status",
      selector: (row) => (row.is_active ? "Active" : "Not Active"),
      id: "status",
    },
    ...(hasFeatureActivate || hasFeatureEdit || hasFeatureDelete
      ? [
          {
            name: "Action",
            cell: (row) => (
              <>
                {!row.is_active && (
                  <>
                    {hasFeatureActivate && (
                      <button
                        className="btn btn-aksi btn-reset mr-1"
                        onClick={() =>
                          alertResponseMessageActivate(
                            row.uuid,
                            row.type,
                            row.message
                          )
                        }
                      >
                        Activate
                      </button>
                    )}
                    {hasFeatureEdit && (
                      <button
                        className="btn btn-aksi btn-edit mr-1"
                        onClick={() => alertEditResponseMessage(row.uuid)}
                      >
                        Edit
                      </button>
                    )}
                    {hasFeatureDelete && (
                      <button
                        className="btn btn-aksi btn-delete"
                        onClick={() =>
                          alertDeleteResponseMessage(
                            row.uuid,
                            row.type,
                            row.message
                          )
                        }
                      >
                        Delete
                      </button>
                    )}
                  </>
                )}
              </>
            ),
            id: "aksi",
            width: "270px",
          },
        ]
      : []),
  ];

  return (
    <>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={responseMessageList}
          highlightOnHover
          pagination
          responsive
        />
      </div>
    </>
  );
};

export default TableList;
