import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import "../../pages/custom-pages-css.scss";
import { formatMoney } from "../FormatMoneyToRupiah";
import DataTable from "react-data-table-component";
import ExpandedRow from "./ExpandedRowMarketingProgress";
import moment from "moment";

const MarketingProgress = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [expandedRowUuid, setExpandedRowUuid] = useState(null);

  const endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/marketing-progresses`;

  const getMarketingProgress = async () => {
    try {
      setLoading(true);
      await axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setLoading(false);
          const data = response.data.content;
          setData(data);
          setFilteredData(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((dataItem) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const budget = dataItem.budget?.toLowerCase().match(searchRegex);
      const startDate = moment(dataItem.start_date)
        .format("DD MMMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const endDate = moment(dataItem.end_date)
        .format("DD MMMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const desc = dataItem.description?.toLowerCase().match(searchRegex);
      const status = dataItem.status?.toLowerCase().match(searchRegex);

      return budget || startDate || endDate || desc || status;
    });

    setFilteredData(result);
  }, [search, data]);

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("DD MMMM YYYY"),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => moment(row.end_date).format("DD MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Budget",
      selector: (row) => formatMoney(row.budget),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.budget) - parseInt(b.budget);
      },
    },

    {
      name: "Description",
      selector: (row) => (row.description ? row.description : "-"),
      sortable: true,
    },
    {
      name: "Task List",
      allowOverflow: true,
      button: true,
      cell: (row) =>
        row.marketing_tasks.length > 0 && (
          <button
            className="btn btn-aksi btn-edit"
            onClick={() => handleRowExpansion(row)}
          >
            {expandedRowUuid === row.uuid ? "Hide ▼" : "Show ▶"}
          </button>
        ),
      ignoreRowClick: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const handleRowExpansion = (row) => {
    // If the row is already expanded, collapse it
    if (expandedRowUuid === row.uuid) {
      setExpandedRowUuid(null);
    } else {
      // If the row is not expanded, expand it
      setExpandedRowUuid(row.uuid);
    }
  };

  const expandableRowsComponent = ({ data }) => (
    <ExpandedRow task={data.marketing_tasks} />
  );

  useEffect(() => {
    if (show) {
      getMarketingProgress();
    }
  }, [show]);

  return (
    <>
      <div className="col-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse11"
            role="button"
            aria-expanded="false"
            aria-controls="collapse11"
            onClick={() => setShow(true)}
          >
            Marketing Project Progress
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse11">
            <div className="row filter-search p-3">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <input
                  type="text"
                  className="form-control input-search"
                  placeholder="Search.."
                  aria-label="Recipient's username"
                  aria-describedby="btn-search"
                  id="search"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="px-3 pb-3 w-100 d-flex align-items-center justify-content-center">
              {loading ? (
                "Loading..."
              ) : filteredData.length > 0 ? (
                <div className="w-100 datatable">
                  <DataTable
                    dense
                    columns={columns}
                    data={currentData}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={filteredData.length}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    responsive
                    expandableRows
                    expandableRowsComponent={(row) =>
                      expandableRowsComponent(row)
                    }
                    expandableRowExpanded={(row) =>
                      expandedRowUuid === row.uuid
                    }
                    onRowExpandToggled={(row) => handleRowExpansion(row)}
                    expandableRowsHideExpander
                  />
                </div>
              ) : (
                "There is no data to display"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingProgress;
