import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";

const PartnershipList = (props) => {
  const [partnership, setPartnership] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredPartner, setFilteredPartner] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/partnerships`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/partnerships/delete/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Partnership",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Partnership",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Partnership",
    feature: props.feature,
  });

  //get partnership
  const getPartnership = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
          setPartnership(sortedData);
          setFilteredPartner(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getPartnership();
  }, []);

  //search partnership
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = partnership.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredPartner(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      id: "email",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">
              {hasFeatureEdit ? "Edit" : "Detail"}
            </button>
          </Link>
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.name)}
            >
              Delete
            </button>
          )}
        </>
      ),
      id: "aksi",
      width: "180px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredPartner.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete partnership
  const alertDelete = (uuid, name) => () => {
    Swal.fire({
      title: "Delete Partnership?",
      html: `Are you sure want to delete <b class="text-uppercase">${name}</b> from the partnership list?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#f0f0f0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePartnership(uuid, name);
      }
    });
  };

  //function delete partnership
  const deletePartnership = (uuid, name) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted <b class="text-uppercase">${name}</b> from the partnership list`,
            "success"
          );
          getPartnership();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete <b class="text-uppercase">${name}</b> from the partnership list`,
        "error"
      );
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Partnership List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-6 col-sm-5 col-12">
          {hasFeatureAdd && (
            <Link to="add">
              <button className="btn btn-primary btn-grey mr-2">
                <i className="fa fa-plus-square mr-2"></i>
                Add Partnership
              </button>
            </Link>
          )}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-7 col-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredPartner.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default PartnershipList;
