import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import "../../pages/custom-pages-css.scss";

const CustomerReviewRating = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  const [hotelList, setHotelList] = useState([]);
  const [hotel, setHotel] = useState("");

  const [platformList, setPlatformList] = useState([]);
  const [platform, setPlatform] = useState("");

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointPlatform = `${process.env.REACT_APP_BASE_URL}/platforms`;

  const getHotelList = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const filterByHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel("");
    }
  };

  const getPlatformList = async () => {
    try {
      await axios
        .get(endpointPlatform, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setPlatformList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const filterByPlatform = (selectedOption) => {
    if (selectedOption) {
      setPlatform(selectedOption.value);
    } else {
      setPlatform("");
    }
  };

  const getCustomerReviewRating = async (hotel, platform) => {
    try {
      setLoading(true);
      let endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/customer-rating`;

      if (hotel && platform) {
        endpoint += `?hotel_uuid=${hotel}&platform_uuid=${platform}`;
      } else if (hotel) {
        endpoint += `?hotel_uuid=${hotel}`;
      } else if (platform) {
        endpoint += `?platform_uuid=${platform}`;
      }

      await axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setTimeout(() => {
            setLoading(false);
            const data = response.data.content;
            setData(data);
          }, 500);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = data.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  useEffect(() => {
    if (show) {
      getHotelList();
      getPlatformList();
      getCustomerReviewRating(hotel, platform);
    }
  }, [show, hotel, platform]);

  return (
    <>
      <div className="col-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse12"
            role="button"
            aria-expanded="false"
            aria-controls="collapse12"
            onClick={() => setShow(true)}
          >
            Customer Review Rating
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse12">
            <div className="row filter-search p-3">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={hotelList}
                  value={hotelList.find((obj) => obj.value === hotel)}
                  id="filterByHotel"
                  className="select2"
                  name="filterByHotel"
                  isClearable={true}
                  placeholder="Filter by hotel"
                  onChange={filterByHotel}
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={platformList}
                  value={platformList.find((obj) => obj.value === platform)}
                  id="filterBuPlatform"
                  className="select2 input-search"
                  name="filterBuPlatform"
                  isClearable={true}
                  placeholder="Filter by platform"
                  onChange={filterByPlatform}
                  classNamePrefix="overflow-select"
                />
              </div>
            </div>
            <div className="px-3 pb-3 text-center">
              {loading ? (
                "Loading..."
              ) : data.length > 0 ? (
                <>
                  <div className="table table-responsive text-left mb-0">
                    <table className="table table-sm table-bordered custom-table">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            #
                          </th>
                          <th scope="col">Hotel</th>
                          <th scope="col">Platform</th>
                          <th scope="col">Average Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((row, i) => (
                          <React.Fragment key={row.hotel}>
                            {row.reviews.length > 0 &&
                              row.reviews.map((item, j) => (
                                <tr key={`${row.hotel}-${j}`}>
                                  {j === 0 ? (
                                    <>
                                      <td
                                        className="text-center"
                                        rowSpan={row.reviews.length}
                                      >
                                        {(currentPage - 1) * pageSize + i + 1}
                                      </td>
                                      <td rowSpan={row.reviews.length}>
                                        {row.hotel}
                                      </td>
                                    </>
                                  ) : null}
                                  <td>{item.platform}</td>
                                  <td>{item.average_rating}</td>
                                </tr>
                              ))}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between pagination-table">
                    <div>
                      Showing {firstIndex + 1} to{" "}
                      {Math.min(lastIndex, data.length)} of {data.length}{" "}
                      entries
                    </div>
                    <div>
                      <select
                        className="form-control form-control-sm"
                        value={pageSize}
                        onChange={handlePageSizeChange}
                      >
                        {pageSizeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="pagination pagination-custom justify-content-center">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      &lt;&lt;
                    </button>
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      &lt;
                    </button>
                    <button className="page-link active">{currentPage}</button>
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        data.length === 0 ||
                        currentPage === Math.ceil(data.length / pageSize)
                      }
                    >
                      &gt;
                    </button>
                    <button
                      className="page-link"
                      onClick={() =>
                        handlePageChange(Math.ceil(data.length / pageSize))
                      }
                      disabled={
                        data.length === 0 ||
                        currentPage === Math.ceil(data.length / pageSize)
                      }
                    >
                      &gt;&gt;
                    </button>
                  </div>
                </>
              ) : (
                "There is no data to display"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerReviewRating;
