import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import CheckDataZero from "./CheckDataZero";
import { generateYearOptions } from "../GenerateYearOption";
import { generateMonthOptions } from "../GenerateMonthOptions";
import { formatMoney } from "../FormatMoneyToRupiah";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const TopCompany = () => {
  const [data, setData] = useState([]);
  const [filterBy, setFilterBy] = useState("deals");

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(null); // New state for response

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions();

  const fetchData = async (selectedYear, selectedMonth) => {
    try {
      if (token) {
        let endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/top-product`;

        if (selectedYear && selectedMonth) {
          endpoint += `?yearMonth=${selectedYear}-${selectedMonth}`;
        } else if (selectedYear) {
          endpoint += `?year=${selectedYear}`;
        }

        const topProductPromise = axios.get(endpoint, {
          headers: {
            Authorization: token,
          },
        });

        const [response] = await Promise.all([topProductPromise]);

        setResponse(response); // Store the response in state

        const chartData = {
          labels: response.data.content.map((item) => item.product),
          datasets: [
            {
              data: response.data.content.map((item) =>
                filterBy === "deals" ? item.deals : item.revenue
              ),
              label:
                filterBy === "deals"
                  ? "Number of Deals"
                  : "Total Revenue in Rupiah",
              backgroundColor: "#1c5ddc",
              barPercentage: 0.9,
              categoryPercentage: 0.5,
            },
          ],
        };

        setData(chartData);
      }
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchData(selectedYear, selectedMonth);
    }
  }, [show, filterBy, selectedYear, selectedMonth]);

  const filterProduct = (event) => {
    setFilterBy(event.target.value);
  };

  const filterByYear = (selectedOption) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
    } else {
      setSelectedYear("");
    }
  };

  const filterByMonth = (selectedOption) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
    } else {
      setSelectedMonth("");
    }
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          precision: 0,
          callback: (value) =>
            filterBy === "deals" ? value : formatMoney(value),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        onClick: () => {},
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (response) {
              // Check if response is defined
              const label = context.label;
              const dataIndex = context.dataIndex;
              const dataset = context.dataset;
              const hotel = response.data.content[dataIndex].hotel;
              return `${label} - ${hotel}: ${
                filterBy === "deals"
                  ? dataset.data[dataIndex]
                  : formatMoney(dataset.data[dataIndex])
              }`;
            }
            return "";
          },
        },
      },
    },
  };

  return (
    <>
      <div className="col-md-6 col-sm-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse5"
            role="button"
            aria-expanded="false"
            aria-controls="collapse5"
            onClick={() => setShow(true)}
          >
            Top Product
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse5">
            <div className="chart scroll-chart d-flex flex-column rounded-0">
              <select
                value={filterBy}
                className="form-control mb-3"
                onChange={filterProduct}
              >
                <option value="deals">Based on deals</option>
                <option value="revenue">Based on revenue</option>
              </select>
              <b className="mb-1">Filter by:</b>
              <div className="row mb-2">
                <label
                  htmlFor="filterYear"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Year
                </label>
                <div className="col">
                  <Select
                    options={yearOptions}
                    value={yearOptions.find(
                      (obj) => obj.value === selectedYear
                    )}
                    id="filterYear"
                    className="select2"
                    name="filterYear"
                    isClearable={true}
                    placeholder="Select year"
                    onChange={filterByYear}
                    classNamePrefix="overflow-select"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label
                  htmlFor="filterMonth"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Month
                </label>
                <div className="col">
                  <Select
                    options={monthOptions}
                    value={monthOptions.find(
                      (obj) => obj.value === selectedMonth
                    )}
                    id="filterMonth"
                    className="select2"
                    name="filterMonth"
                    isClearable={true}
                    placeholder="Select Month"
                    onChange={filterByMonth}
                    classNamePrefix="overflow-select"
                  />
                </div>
              </div>
              <div className="w-100 responsive-chart mt-3 d-flex align-items-center justify-content-center align-self-center flex-grow-1">
                {!CheckDataZero(data) && data.labels && !isLoading && (
                  <Bar data={data} options={options} />
                )}
                {CheckDataZero(data) &&
                  !isLoading &&
                  "There is no data to display"}
                {isLoading && "Loading..."}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopCompany;
