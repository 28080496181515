import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import CheckDataZero from "./CheckDataZero";
import { generateYearOptions } from "../GenerateYearOption";
import { generateMonthOptions } from "../GenerateMonthOptions";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const FailedDealReason = () => {
  const [failedDeal, setFailedDeal] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(null); // New state for response

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions();

  const fetchData = async (selectedYear, selectedMonth) => {
    try {
      if (token) {
        let endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/failed-deal-reason`;

        if (selectedYear && selectedMonth) {
          endpoint += `?yearMonth=${selectedYear}-${selectedMonth}`;
        } else if (selectedYear) {
          endpoint += `?year=${selectedYear}`;
        }

        const failedDealPromise = axios.get(endpoint, {
          headers: {
            Authorization: token,
          },
        });

        const [failedDealResponse] = await Promise.all([failedDealPromise]);
        setResponse(failedDealResponse); // Store the response in state

        const failedDealData = {
          labels: failedDealResponse.data.content.map((item) => item.reason),
          datasets: [
            {
              data: failedDealResponse.data.content.map(
                (item) => item.percentage
              ),
              backgroundColor: ["#fc351c", "#1c5ddc", "#feb52b", "#19ae5d"],
            },
          ],
        };

        setFailedDeal(failedDealData);
      }
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchData(selectedYear, selectedMonth);
    }
  }, [show, selectedYear, selectedMonth]);

  const options = {
    plugins: {
      responsive: true,
      legend: {
        display: true,
        position: "bottom",
        onClick: () => {},
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            if (response) {
              // Check if response is defined
              const label = context.label;
              const dataIndex = context.dataIndex;
              const count = response.data.content[dataIndex].count;
              return `${label}: ${count}`;
            }
            return "";
          },
        },
      },
    },
  };

  const filterByYear = (selectedOption) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
    } else {
      setSelectedYear("");
    }
  };

  const filterByMonth = (selectedOption) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
    } else {
      setSelectedMonth("");
    }
  };

  const areAllDataZero = (data) => {
    return data.every((value) => parseFloat(value) === 0);
  };

  return (
    <>
      <div className="col-md-6 col-sm-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse3"
            role="button"
            aria-expanded="false"
            aria-controls="collapse3"
            onClick={() => setShow(true)}
          >
            Failed Deal Reason
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse3">
            <div className="chart scroll-pie-chart d-flex flex-column rounded-0">
              <b className="mb-1">Filter by:</b>
              <div className="row mb-2">
                <label
                  htmlFor="filterYear"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Year
                </label>
                <div className="col">
                  <Select
                    options={yearOptions}
                    value={yearOptions.find(
                      (obj) => obj.value === selectedYear
                    )}
                    id="filterYear"
                    className="select2"
                    name="filterYear"
                    isClearable={true}
                    placeholder="Select year"
                    onChange={filterByYear}
                    classNamePrefix="overflow-select"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label
                  htmlFor="filterMonth"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Month
                </label>
                <div className="col">
                  <Select
                    options={monthOptions}
                    value={monthOptions.find(
                      (obj) => obj.value === selectedMonth
                    )}
                    id="filterMonth"
                    className="select2"
                    name="filterMonth"
                    isClearable={true}
                    placeholder="Select month"
                    onChange={filterByMonth}
                    classNamePrefix="overflow-select"
                  />
                </div>
              </div>
              <div className="mt-3 responsive-pie-chart doughnut-chart d-flex align-items-center justify-content-center align-self-center flex-grow-1">
                {!CheckDataZero(failedDeal) &&
                  failedDeal.labels &&
                  !isLoading &&
                  (areAllDataZero(failedDeal.datasets[0].data) ? (
                    "There is no data to display"
                  ) : (
                    <Doughnut data={failedDeal} options={options} />
                  ))}
                {CheckDataZero(failedDeal) &&
                  !isLoading &&
                  "There is no data to display"}
                {isLoading && "Loading..."}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FailedDealReason;
