import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ExpandedRow from "./ExpandedRow";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DetailMeetingRoom = () => {
  const params = useParams();
  const campaignId = params.id;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [meetingRoomName, setmMetingRoomName] = useState("");
  const [description, setDescription] = useState("");
  const [hotel, setHotel] = useState("");
  const [hotelAddress, setHotelAddress] = useState("");
  const [hotelEmail, setHotelEmail] = useState("");
  const [hotelPhoneNumber, setHotelPhoneNumber] = useState("");
  const [hotelSortSequence, setHotelSortSequence] = useState("");
  const [hotelMeetingRoomStatus, setHotelMeetingRoomStatus] = useState("");


  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/functions/meeting-rooms/${campaignId}`;

  //get campaign
  const getMeetingRoomDetail = async () => {
    await axios
      .get(endpointList, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setmMetingRoomName(response.data.content.name);
        setDescription(response.data.content.description);
        setHotel(response.data.content.hotel.name);
        setHotelSortSequence(response.data.content.sort_seq);
        setHotelMeetingRoomStatus(response.data.content.is_closed == true ? "Closed" : "Open");
        setHotelAddress(response.data.content.hotel.address);
        setHotelEmail(response.data.content.hotel.email);
        setHotelPhoneNumber(response.data.content.hotel.phone_number);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/function-block/meeting-room", { replace: true });
        }
      });
  };

  useEffect(() => {
    getMeetingRoomDetail();
  }, []);

  return (
    <>
      <h1 className="page-title mb-3">Meeting Room Detail</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/function-block/meeting-room">Meeting Room List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Detail Meeting Room
        </li>
      </ol>
      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        General Information
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Name
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={meetingRoomName}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotel}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Sort Sequence
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotelSortSequence}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Status Meeting Room
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotelMeetingRoomStatus}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDescription"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Description
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <textarea
            name="description"
            id="inputDescription"
            rows="3"
            className="form-control"
            value={description || ""}
            disabled
          ></textarea>
        </div>
      </div>
      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Meeting Room Hotel Information
      </div>

      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotel}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel Email
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotelEmail}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel Phone Number
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotelPhoneNumber}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDescription"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel Address
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <textarea
            name="description"
            id="inputDescription"
            rows="3"
            className="form-control"
            value={hotelAddress || ""}
            disabled
          ></textarea>
        </div>
      </div>
      <div className="mb-5 mt-4">
        <div className="col-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate("/function-block/meeting-room")}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailMeetingRoom;
