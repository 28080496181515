import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../../components/CheckFeature";
const AddSalesActivityManage = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [potentialRevenue, setPotentialRevenue] = useState(0);
  const [actualRevenue, setActualRevenue] = useState(0);
  const [potentialSuccess, setPotentialSuccess] = useState(0);
  const [salesActivityTypeList, setSalesActivityTypeList] = useState([]);
  const [salesActivityStatusList, setSalesActivityStatusList] = useState([]);
  const [salesActivityEventList, setSalesActivityEventList] = useState([]);
  const [salesActivityType, setSalesActivityType] = useState("");
  const [salesActivityStatus, setSalesActivityStatus] = useState("");
  const [salesActivityEvent, setSalesActivityEvent] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPIC, setSelectedPIC] = useState("");
  const [picList, setPicList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const salesActivityId = params.id;

  const [formFiles, setFormFiles] = useState([])
  const [files, setFiles] = useState([])
  const [deletefiles, setDeleteFiles] = useState([])

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/sales-activity/${salesActivityId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/sales-activity/`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/sales-activity/${salesActivityId}`;
  const endpointSalesActivityType = `${process.env.REACT_APP_BASE_URL}/sales-activity-type`;
  const endpointSalesActivityStatus = `${process.env.REACT_APP_BASE_URL}/sales-activity-status`;
  const endpointSalesActivityEvent = `${process.env.REACT_APP_BASE_URL}/sales-activity-event`;
  const endpointPIC = `${process.env.REACT_APP_BASE_URL}/pics`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Activity",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Activity",
    feature: props.feature,
  });


  useEffect(() => {
    if (salesActivityId) {
      getSalesActivityDetail();
    }
    getSalesActivityType();
    getSalesActivityEvent();
    getSalesActivityStatus();
    getPIC();
    getHotel();
    console.log(params);
  }, []);

  useEffect(() => {
    console.log(formFiles);
  }, [formFiles]);
  //get campaign
  const getSalesActivityDetail = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setName(response.data.content.name);
        setDescription(response.data.content.description);
        setPotentialRevenue(response.data.content.potential_revenue);
        setActualRevenue(response.data.content.actual_revenue);
        setPotentialSuccess(response.data.content.potential_success_percentage);
        setLocation(response.data.content.location);
        setSelectedPIC(response.data.content.uuid_pic);
        setSalesActivityEvent(response.data.content.uuid_sales_activity_event);
        setSalesActivityType(response.data.content.uuid_sales_activity_type);
        setSalesActivityStatus(response.data.content.uuid_sales_activity_status);
        setPotentialRevenue(response.data.content.potential_revenue);
        setFiles(response.data.content.files);
        const options = response.data.content.hotels?.map(function (hotel) {
          return { value: hotel.uuid_hotel, label: hotel.hotel };
        });
        setSelectedHotel(options);
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-activity", { replace: true });
        }
      });
  };
  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };
  //get Sales Activity Type
  const getSalesActivityType = async () => {
    try {
      await axios
        .get(endpointSalesActivityType, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data?.map(function (item) {
            return { value: item.uuid, label: item.name };
          });
          setSalesActivityTypeList(options);
        });
    } catch (error) {
      console.log(error);
      navigate("/404", { replace: true });
    }
  };
  const getSalesActivityStatus = async () => {
    try {
      await axios
        .get(endpointSalesActivityStatus, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data?.map(function (item) {
            return { value: item.uuid, label: item.name };
          });
          setSalesActivityStatusList(options);
        });
    } catch (error) {
      console.log(error);
      navigate("/404", { replace: true });
    }
  };
  const getSalesActivityEvent = async () => {
    try {
      await axios
        .get(endpointSalesActivityEvent, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data?.map(function (item) {
            return { value: item.uuid, label: item.name };
          });
          setSalesActivityEventList(options);
        });
    } catch (error) {
      console.log(error);
      navigate("/404", { replace: true });
    }
  };
  const getPIC = async () => {
    try {
      await axios
        .get(endpointPIC, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (pic) {
            return { value: pic.uuid, label: pic.name };
          });
          setPicList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };
  //selected sales activity type
  const changeSalesActivityType = (selectedOption) => {
    if (selectedOption) {
      setSalesActivityType(selectedOption.value);
    } else {
      setSalesActivityType("");
    }
  };
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setSelectedHotel(selectedOption);
    } else {
      setSelectedHotel(null);
    }
  };
  //selected sales activity status
  const changeSalesActivityStatus = (selectedOption) => {
    if (selectedOption) {
      setSalesActivityStatus(selectedOption.value);
    } else {
      setSalesActivityStatus("");
    }
  };
  //selected sales activity event
  const changeSalesActivityEvent = (selectedOption) => {
    if (selectedOption) {
      setSalesActivityEvent(selectedOption.value);
    } else {
      setSalesActivityEvent("");
    }
  };
  const changePIC = (selectedOption) => {
    if (selectedOption) {
      setSelectedPIC(selectedOption.value);
    } else {
      setSelectedPIC(null);
    }
  };
  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    let hotelExtract = selectedHotel?.map(function (item) {
      return item.value
    });
    console.log(hotelExtract);
    const formData = new FormData();
    formData.append('description', description);
    formData.append('name', name);
    formData.append('type', salesActivityType);
    selectedHotel.map((result) => {
      formData.append('uuid_hotel[]', result.value);
    })
    formData.append('potential_success_percentage', potentialSuccess);
    formData.append('potential_revenue', potentialRevenue);
    formData.append('actual_revenue', actualRevenue);
    formData.append('location', location);
    formData.append('uuid_sales_activity_event', salesActivityEvent);
    formData.append('uuid_sales_activity_type', salesActivityType);
    formData.append('uuid_sales_activity_status', salesActivityStatus);
    formData.append('uuid_pic', selectedPIC);
    if (formFiles.length > 0) {
      formFiles.map((result) => {
        formData.append('attachments', result.attachments);
      })
    }
    if (deletefiles.length > 0) {
      deletefiles.map((result) => {
        formData.append('uuid_delete_file[]', result.attachments);
      })
    }

    console.log(formData);
    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    try {
      if (!salesActivityId) {
        await axios
          .post(endpointAdd, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully created a Sales Activity with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/sales-activity/manage");
          });
      } else {
        await axios
          .put(endpointEdit, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a Sales Activity with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/sales-activity/manage");
          });
      }
    } catch (error) {
      console.log(error.response.data.content);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.content}`, "error");
      }
    }
  };
  const handleChange = (i, e) => {
    let newFormValues = [...formFiles];
    newFormValues[i][e.target.name] = e.target.files[0];
    setFormFiles(newFormValues);
  }
  const addFormFile = () => {
    setFormFiles([...formFiles, { attachments: "" }])
  }
  const removeFormFile = (i) => {
    let newFormValues = [...formFiles];
    newFormValues.splice(i, 1);
    setFormFiles(newFormValues)
  }

  const removeFile = (i, uuid) => {
    console.log('yaampun' + i);
    let newFormValues = [...files];
    newFormValues.splice(i, 1);
    setDeleteFiles([...formFiles, { attachments: uuid }])
    setFiles(newFormValues)
    console.log(newFormValues);
  }
  return (
    <>
      <h1 className="page-title">
        {salesActivityId
          ? hasFeatureEdit
            ? "Edit Sales Activity"
            : "Sales Activity Details"
          : "Create Sales Activity"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-activity/manage">Sales Activity List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {salesActivityId
            ? hasFeatureEdit
              ? "Edit Sales Activity"
              : "Sales Activity Details"
            : "Create Sales Activity"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (salesActivityId && !hasFeatureEdit && " form-detail")
        }
      >

        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              placeholder="Name"
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPotentialRevenue"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Potential Revenue <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              placeholder="Potential Revenue"
              type="number"
              name="inputPotentialRevenue"
              className="form-control"
              id="inputPotentialRevenue"
              value={potentialRevenue}
              onChange={(e) => setPotentialRevenue(e.target.value)}
              required
              disabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputActualRevenue"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Actual Revenue <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              placeholder="Actual Revenue"
              type="number"
              name="inputActualRevenue"
              className="form-control"
              id="inputActualRevenue"
              value={actualRevenue}
              onChange={(e) => setActualRevenue(e.target.value)}
              required
              disabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPotentialSuccess"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Potential Success Precentage<i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              placeholder="Potential Success Precentage"
              type="number"
              name="inputPotentialSuccess"
              className="form-control"
              id="inputPotentialSuccess"
              value={potentialSuccess}
              onChange={(e) => setPotentialSuccess(e.target.value)}
              required
              disabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Location
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              placeholder="Location"
              type="text"
              name="inputLocation"
              className="form-control"
              id="inputLocation"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              disabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPIC"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            PIC <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select PIC"
              isClearable={true}
              options={picList}
              value={picList.filter((item) => item.value === selectedPIC)}
              name="uuid_pic"
              onChange={changePIC}
              className="select2"
              id="inputPIC"
              isDisabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Status <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
            <Select
              placeholder="Status"
              isClearable={true}
              options={salesActivityStatusList}
              value={salesActivityStatusList.filter((item) => item.value === salesActivityStatus)}
              name="sales_activity_status"
              onChange={changeSalesActivityStatus}
              className="select2"
              id="inputSalesActivityStatus"
              isDisabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Type <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
            <Select
              placeholder="Type"
              isClearable={true}
              options={salesActivityTypeList}
              value={salesActivityTypeList.filter((item) => item.value === salesActivityType)}
              name="sales_activity_type"
              onChange={changeSalesActivityType}
              className="select2"
              id="inputSalesActivityType"
              isDisabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Event <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
            <Select
              placeholder="Event"
              isClearable={true}
              options={salesActivityEventList}
              value={salesActivityEventList.filter((item) => item.value === salesActivityEvent)}
              name="sales_activity_event"
              onChange={changeSalesActivityEvent}
              className="select2"
              id="inputSalesActivityEvent"
              isDisabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>

          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
            <Select
              placeholder="Select hotel"
              isMulti
              isClearable={true}
              value={selectedHotel}
              options={hotelList}
              name="uuid_hotel"
              onChange={changeHotel}
              className="basic-multi-select"
              id="inputHotel"
              isDisabled={salesActivityId && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputDescription"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Description
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="description"
              id="inputDescription"
              rows="3"
              className="form-control"
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
              disabled={salesActivityId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>



        <div className="mb-3 row">
          <label
            htmlFor="inputDescription"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Attachments
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            {salesActivityId && (
              <div className="row">
                {files?.map((element, index) => (
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2" key={index}>
                    <a href={'https://crm-api.jxboard.id/' + element.file} target="_blank" className="mr-4" style={{ color: "black", fontWeight: "bold" }}>
                      {'File '}
                    </a>
                    <button
                      type="button"
                      onClick={() => removeFile(index, element.uuid)}
                      className="btn btn-danger btn-red btn-sm"
                      style={{ minWidth: "0px", float: "right" }}
                    >
                      <i
                        className="fa fa-minus-circle"
                      ></i>
                    </button>
                  </div>
                ))}
              </div>
            )}
            {formFiles.map((element, index) => (
              <div className="row mb-2" key={index}>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                  <input
                    type="FILE"
                    placeholder="attachments"
                    name="attachments"
                    className="form-control mb-2"
                    id="attachments"
                    onChange={e => handleChange(index, e)}
                    disabled={salesActivityId && !hasFeatureEdit}
                  />
                </div>

                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                  <button
                    type="button"
                    onClick={() => removeFormFile(index)}
                    className="btn btn-danger btn-red mb-2"
                    style={{ minWidth: "0px" }}
                  >
                    <i
                      className="fa fa-minus-circle"
                    ></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <button
                  className="btn btn-primary btn-green mb-2"
                  type="button"
                  onClick={() => addFormFile()}
                >
                  <i
                    className="fa fa-plus-circle"
                  ></i> Add Attachments
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-activity/manage")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {salesActivityId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((salesActivityId && hasFeatureEdit) ||
              (!salesActivityId && hasFeatureAdd)) && (
                <button
                  className="btn btn-primary btn-blue"
                  type="submit"

                >
                  <i
                    className={
                      "fa " +
                      (salesActivityId ? "fa-edit" : "fa-plus-square") +
                      " mr-2"
                    }
                  ></i>
                  {salesActivityId ? "Edit" : "Create"}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSalesActivityManage;
