import React from "react";
import "../custom-pages-css.scss";
import "./employee.scss";
import AddEditEmployee from "./AddEditEmployee";
import ImportEmployee from "./ImportEmployee";

const AddEmployee = (props) => {
  return (
    <>
      <h1 className="page-title">Add Employee</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/employee">Employee List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Add Employee
        </li>
      </ol>
      <ul className="nav nav-tabs tab-custom" id="myTab">
        <li className="nav-item">
          <button
            className="nav-link active"
            id="manualInput-tab"
            data-toggle="tab"
            data-target="#manualInput"
            type="button"
            role="tab"
            aria-controls="manualInput"
            aria-selected="true"
          >
            Manual Input
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link"
            id="importData-tab"
            data-toggle="tab"
            data-target="#importData"
            type="button"
            role="tab"
            aria-controls="importData"
            aria-selected="false"
          >
            Import from Excel/ CSV
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="manualInput"
          role="tabpanel"
          aria-labelledby="manualInput-tab"
        >
          <AddEditEmployee feature={props.feature} />
        </div>
        <div
          className="tab-pane fade"
          id="importData"
          role="tabpanel"
          aria-labelledby="importData-tab"
        >
          <ImportEmployee />
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
