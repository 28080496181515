import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import DataLanguage from "../../../data/LanguageCode.json";
import FormatTextWa from "../../../components/FormatTextWa";

const AddBroadcastMessageTemplate = () => {
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [language, setLanguange] = useState("");

  // message
  const [text, setText] = useState("");
  const [variableNum, setVariableNum] = useState("");
  const [exampleMessage, setExampleMessage] = useState([""]);

  //header
  const [type, setType] = useState();
  const [headerText, setHeaderText] = useState("");
  const [exampleHeader, setExampleHeader] = useState([]);
  const [exampleHeaderFile, setExampleHeaderFile] = useState("");

  const [footer, setFooter] = useState("");

  const [metaCategory, setMetaCategory] = useState();

  //show format text wa
  const [messageDesc, setMessageDesc] = useState([false, false, false]);
  const [loading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("");

  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/message-templates/add`;

  const showMessageDesc = (i) => {
    let toggleShow = [...messageDesc];
    let oldShow = toggleShow[i];
    toggleShow[i] = !oldShow;
    setMessageDesc(toggleShow);
  };

  const changeLanguage = (selectedOption) => {
    if (selectedOption) {
      setLanguange(selectedOption.code);
    } else {
      setLanguange(null);
    }
  };

  const changeVariableNum = (value) => {
    if (value < 0) {
      value = 0;
      setAlertText("At least 0 variables");
    } else if (value > 10) {
      value = 10;
      setAlertText("Maximum 10 variables");
    }

    setVariableNum(value);
    setExampleMessage("");
  };

  const updateExample = (event, index) => {
    const newInputs = [...exampleMessage];
    newInputs[index] = event.target.value;
    setExampleMessage(newInputs);
  };

  const headerTypeOptions = [
    { value: "TEXT", label: "Text" },
    { value: "IMAGE", label: "Image" },
    { value: "DOCUMENT", label: "Document" },
    { value: "VIDEO", label: "Video" },
  ];

  const metaCategoryOptions = [
    { value: "MARKETING", label: "Marketing" },
    { value: "UTILITY", label: "Utility" },
  ];

  const changeType = (selectedOption) => {
    if (selectedOption) {
      setType(selectedOption.value);
      setExampleHeaderFile(null);
      const fileInput = document.getElementById("myFileInput");
      if (fileInput) {
        fileInput.value = null;
      }
    } else {
      setType(null);
      setExampleHeaderFile(null);
      const fileInput = document.getElementById("myFileInput");
      if (fileInput) {
        fileInput.value = null;
      }
    }
  };

  const changeMetaCategory = (selectedOption) => {
    if (selectedOption) {
      setMetaCategory(selectedOption.value);
    } else {
      setMetaCategory(null);
    }
  };

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Please wait..",
        html: `Create broadcast template is in progress <br/>`,
        showCancelButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didRender: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading]);

  const addTemplate = async (e) => {
    e.preventDefault();

    const message = {
      text: text,
      variable_num: parseInt(variableNum, 10),
      example: exampleMessage,
    };

    const message2 = {
      text: text,
    };

    let header = {};

    if (type != null) {
      if (type === "TEXT") {
        if (exampleHeader && exampleHeader.length > 0) {
          header = {
            type: type,
            text: headerText,
            example: exampleHeader,
          };
        } else {
          header = {
            type: type,
            text: headerText,
          };
        }
      } else {
        if (exampleHeaderFile) {
          header = {
            type: type,
          };
        }
      }
    }

    const data = {
      category: category,
      name: name,
      language: language,
      message: variableNum > 0 ? message : message2,
      ...(Object.keys(header).length !== 0 ? { header: header } : {}),
      ...(footer !== "" ? { footer: footer } : {}),
      meta_category: metaCategory,
    };

    const formData = new FormData();
    if (type && type !== "TEXT" && exampleHeaderFile) {
      formData.append("category", category);
      formData.append("name", name);
      formData.append("language", language);
      Object.entries(variableNum > 0 ? message : message2).map(
        ([key, value]) => {
          if (key === "example") {
            Object.entries((variableNum > 0 ? message : message2).example).map(
              ([i, value]) => {
                formData.append("message[example][" + i + "]", value);
              }
            );
          } else {
            formData.append("message[" + key + "]", value);
          }
        }
      );
      if (Object.keys(header).length !== 0) {
        Object.entries(header).map(([key, value]) => {
          formData.append("header[" + key + "]", value);
        });
      }
      formData.append("file", exampleHeaderFile);
      if (footer !== "") {
        formData.append("footer", footer);
      }
      formData.append("meta_category", metaCategory);
    }

    if (
      type &&
      type !== "TEXT" &&
      exampleHeaderFile &&
      exampleHeaderFile.size > 25000000
    ) {
      Swal.fire(
        "Failed to Upload Header File!",
        "File size cannot be more than 25MB",
        "error"
      );
    } else {
      try {
        setLoading(true);
        await axios
          .post(endpointAdd, exampleHeaderFile ? formData : data, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            setLoading(false);
            Swal.fire({
              title: "Success!",
              html: `Successfully created a broadcast message template with the name <b class="text-uppercase">${name}</b>`,
              icon: "success",
            });
            navigate("/whatsapp-broadcast-template");
          })
          .catch((error) => {
            Swal.fire("Failed!", `${error.response.data.message}`, "error");
          });
      } catch (error) {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 500) {
          Swal.fire("Failed!", "Please complete the data", "error");
        } else if (error.response.data.status === 400) {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        }
      }
    }
  };

  return (
    <>
      <h1 className="page-title">Create WhatsApp Broadcast Template</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/whatsapp-broadcast-template">
            WhatsApp Broadcast Template List
          </a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Create WhatsApp Broadcast Template
        </li>
      </ol>
      <form
        onSubmit={addTemplate}
        method="post"
        className="mt-4 px-5 form form-employee"
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputKategori"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Category <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="text"
              className="form-control"
              id="inputKategori"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputNama"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="text"
              className="form-control"
              id="inputNama"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-4 row">
          <label
            htmlFor="inputBahasa"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Language <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <Select
              placeholder="Select language"
              isClearable={true}
              options={DataLanguage}
              value={DataLanguage.find((obj) => obj.code === language)}
              onChange={changeLanguage}
              className="select2 select-group"
              id="inputBahasa"
              getOptionLabel={(option) => option.language}
              getOptionValue={(option) => option.code}
            />
          </div>
        </div>
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4 p-0">
          Message
        </div>
        <div className="mb-3 row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <textarea
              className="form-control"
              id="inputPesan"
              rows="4"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
            ></textarea>
            <small>
              <button
                type="button"
                className="my-2 btn btn-primary btn-grey"
                onClick={() => showMessageDesc(0)}
              >
                View message writing guidelines
              </button>
              {messageDesc[0] && <FormatTextWa type="message" />}
            </small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputVariableNum"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Number of Variables <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="number"
              className="form-control"
              id="inputVariableNum"
              min={0}
              max={10}
              value={variableNum}
              onChange={(e) => changeVariableNum(e.target.value)}
              required
            />
            {alertText && <small className="red">{alertText}</small>}
          </div>
        </div>
        {parseInt(variableNum, 10) > 0 &&
          Array.from(Array(parseInt(variableNum, 10)), (e, i) => {
            return (
              <div className="row" key={i}>
                <label
                  htmlFor="inputVariableNum"
                  className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
                >
                  Example content of variable {i + 1} <i className="red">*</i>
                </label>
                <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
                  <input
                    type="text"
                    className="form-control mb-3"
                    value={exampleMessage[i] || ""}
                    onChange={(e) => updateExample(e, i)}
                    required
                  />
                </div>
              </div>
            );
          })}

        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          HEADER
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHeaderType"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Type
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <Select
              placeholder="Select type"
              isClearable={true}
              options={headerTypeOptions}
              value={headerTypeOptions.find((obj) => obj.value === type) || ""}
              onChange={changeType}
              className="select2 select-group"
              id="inputHeaderType"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>
        {type === "TEXT" && (
          <div className="mb-3 row">
            <label
              htmlFor="inputText"
              className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
            >
              Text <i className="red">*</i>
            </label>
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
              <input
                type="text"
                className="form-control"
                id="inputText"
                value={headerText}
                onChange={(e) => setHeaderText(e.target.value)}
                required
              />
              <small>
                <button
                  type="button"
                  className="my-2 btn btn-primary btn-grey"
                  onClick={() => showMessageDesc(1)}
                >
                  View message writing guidelines
                </button>
                {messageDesc[1] && (
                  <>
                    <br />
                    **Note: <br />
                    To create variables in the message, use the code{" "}
                    <code>&#123;&#123;variable number&#125;&#125;</code> <br />
                    Example: <code>&#123;&#123;1&#125;&#125;</code>
                    <br />
                    Header Maximum 1 variable
                  </>
                )}
              </small>
            </div>
          </div>
        )}
        {type && (
          <div className="mb-3 row">
            <label
              htmlFor={type === "TEXT" ? "inputText" : "myFileInput"}
              className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
            >
              Example variable content{" "}
              {type !== "TEXT" && <i className="red">*</i>}
            </label>
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
              {type === "TEXT" && (
                <input
                  type="text"
                  className="form-control"
                  id="inputText"
                  value={exampleHeader}
                  onChange={(e) => setExampleHeader(e.target.value)}
                  required={type !== "TEXT"}
                />
              )}
              {(type === "IMAGE" ||
                type === "DOCUMENT" ||
                type === "VIDEO") && (
                <input
                  type="file"
                  className="form-control input-file-custom"
                  id="myFileInput"
                  onChange={(e) => setExampleHeaderFile(e.target.files[0])}
                  accept={
                    type === "IMAGE"
                      ? ".jpeg, .png"
                      : type === "VIDEO"
                      ? ".mp4, .3gp"
                      : ".pdf"
                  }
                  required
                />
              )}
            </div>
          </div>
        )}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          FOOTER
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          <textarea
            className="form-control"
            id="inputPesan"
            rows="4"
            value={footer}
            onChange={(e) => setFooter(e.target.value)}
          ></textarea>
          <small>
            <button
              type="button"
              className="my-2 btn btn-primary btn-grey"
              onClick={() => showMessageDesc(2)}
            >
              View message writing guidelines
            </button>
            {messageDesc[2] && <FormatTextWa type="footer" />}
          </small>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputMetaCategory"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Meta Category <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <Select
              placeholder="Select meta category"
              isClearable={true}
              options={metaCategoryOptions}
              value={metaCategoryOptions.find(
                (obj) => obj.value === metaCategory
              )}
              onChange={changeMetaCategory}
              className="select2 select-group"
              id="inputMetaCategory"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              required
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/whatsapp-broadcast-template")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Cancel
            </button>
            <button className="btn btn-primary btn-blue" type="submit">
              <i className="fa fa-plus-square mr-2"></i>
              Create
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddBroadcastMessageTemplate;
