import React from "react";
import CheckFeature from "../../components/CheckFeature";
import CustomerSource from "../../components/charts/CustomerSource";
import CustomerGrowth from "../../components/charts/CustomerGrowth";
import SalesRepPerformance from "../../components/charts/SalesRepPerformance";
import MarketingProgress from "../../components/charts/MarketingProgress";
import FailedDeal from "../../components/charts/FailedDealReason";
import TopPartnership from "../../components/charts/TopPartnership";
import TopProduct from "../../components/charts/TopProduct";
import SuccessfulSalesTask from "../../components/charts/SuccessfulSalesTask";
import CustomerReviewRating from "../../components/charts/CustomerReviewRating";
import SalesHotelTarget from "../../components/charts/SalesHotelTarget";
import SalesRepTarget from "../../components/charts/SalesRepTarget";
import ChartRevenue from "../../components/charts/ChartRevenue";
import MonthlyRevenue from "../../components/charts/MonthlyRevenue";
import TodaysOccupancy from "../../components/charts/TodaysOccupancy";
import OtaRevenue from "../../components/charts/OtaRevenue";

const Dashboard = (props) => {
  const hasFeatureCustomerSource = CheckFeature({
    name: "View Customer Source Chart",
    feature: props.feature,
  });
  const hasFeatureCustomerGrowth = CheckFeature({
    name: "View Customer Growth Chart",
    feature: props.feature,
  });
  const hasFeatureSalesRepPerformance = CheckFeature({
    name: "View Sales Representative Performance",
    feature: props.feature,
  });
  const hasFeatureMarketingProgress = CheckFeature({
    name: "View Marketing Progress",
    feature: props.feature,
  });
  const hasFeatureFailedDeal = CheckFeature({
    name: "View Reason Failed Deals Chart",
    feature: props.feature,
  });
  const hasFeatureTopPartnership = CheckFeature({
    name: "View Top Partnership",
    feature: props.feature,
  });
  const hasFeatureTopProduct = CheckFeature({
    name: "View Top Product",
    feature: props.feature,
  });
  const hasFeatureSuccessfulSalesTask = CheckFeature({
    name: "View Percentage of Succesfull Tasks",
    feature: props.feature,
  });
  const hasFeatureCustomerReviewRating = CheckFeature({
    name: "View Customer Review Rating Chart",
    feature: props.feature,
  });
  const hasFeatureSalesHotelTarget = CheckFeature({
    name: "View Hotel Sales Target Chart",
    feature: props.feature,
  });
  const hasFeatureSalesRepTarget = CheckFeature({
    name: "View Sales Representative Target Chart",
    feature: props.feature,
  });
  const hasFeatureChartRevenue = CheckFeature({
    name: "View Monthly Revenue Chart",
    feature: props.feature,
  });
  const hasFeatureMonthlyRevenue = CheckFeature({
    name: "View Total Monthly Revenue",
    feature: props.feature,
  });
  const hasFeatureTodaysOccupancy = CheckFeature({
    name: "View Todays Occupancy Percentage",
    feature: props.feature,
  });
  const hasFeatureOtaRevenue = CheckFeature({
    name: "View OTA Revenue",
    feature: props.feature,
  });

  return (
    <>
      <h1 className="page-title">Dashboard</h1>
      <div className="container-fluid dashboard p-0">
        <div className="row m-0">
          {hasFeatureCustomerSource && <CustomerSource />}
          {hasFeatureCustomerGrowth && <CustomerGrowth />}
          {hasFeatureFailedDeal && <FailedDeal />}
          {hasFeatureTopPartnership && <TopPartnership />}
          {hasFeatureTopProduct && <TopProduct />}
          {hasFeatureSuccessfulSalesTask && <SuccessfulSalesTask />}
          {hasFeatureChartRevenue && <ChartRevenue />}
          {hasFeatureMonthlyRevenue && <MonthlyRevenue />}
          {hasFeatureTodaysOccupancy && <TodaysOccupancy />}
          {hasFeatureSalesRepPerformance && <SalesRepPerformance />}
          {hasFeatureMarketingProgress && <MarketingProgress />}
          {hasFeatureCustomerReviewRating && <CustomerReviewRating />}
          {hasFeatureSalesHotelTarget && <SalesHotelTarget />}
          {hasFeatureSalesRepTarget && <SalesRepTarget />}
          {hasFeatureOtaRevenue && <OtaRevenue />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
