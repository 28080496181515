import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { formatMoney } from "../../../components/FormatMoneyToRupiah";

const SalesDealWonList = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/sales-deals`;

  //get data
  const getData = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.createdAt.localeCompare(b.createdAt)
          );
          setData(sortedData);
          setFilteredData(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //search
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const product = data.product?.toLowerCase().match(searchRegex);
      const partnership = data.partnership?.toLowerCase().match(searchRegex);

      return product || partnership;
    });

    setFilteredData(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Created At",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      sortable: true,
      sortFunction: (a, b) => {
        const dateA = moment(a.createdAt).format("YYYY MM DD");
        const dateB = moment(b.createdAt).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: "Partnership",
      selector: (row) => row.partnership,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Offering Price",
      selector: (row) => formatMoney(row.offering_price),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.offering_price) - parseInt(b.offering_price);
      },
    },
    {
      name: "Deal Price",
      selector: (row) => formatMoney(row.accepted_price),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.accepted_price) - parseInt(b.accepted_price);
      },
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  return (
    <>
      <h1 className="page-title mb-3">Sales Deal Won List</h1>
      <div className="row mb-3">
        <div className="offset-lg-8 offset-md-6 offset-sm-5 col-lg-4 col-md-6 col-sm-7 col-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by product / partnership"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredData.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default SalesDealWonList;
