import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import ExpandedRows from "./ExpandedRows";

const DetailSalesRep = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  const [dataSalesRep, setDataSalesRep] = useState([]);
  const [dataSalesRepTask, setDataSalesRepTask] = useState([]);
  const [dataSalesRepActivity, setDataSalesRepActivity] = useState([]);
  const [filteredSalesRepTask, setFilteredSalesRepTask] = useState([]);

  const [searchTask, setSearchTask] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [expandedRows, setExpandedRows] = useState({});

  //endpoint
  const endpointSalesRep = `${process.env.REACT_APP_BASE_URL}/sales-reps`;
  const endpointSalesTask = `${process.env.REACT_APP_BASE_URL}/sales-reps/${uuid}/tasks`;
  const endpointGetSalesTaskDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/`;

  useEffect(() => {
    if (uuid) {
      getSalesRep();
      getSalesTask();
    }
  }, []);

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = dataSalesRepTask.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(searchTask), "i");

      const startDate = moment(data.start_date)
        .format("D MMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const dueDate = moment(data.due_date)
        .format("D MMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const statusDeals = data.status_deals
        ?.toString()
        .toLowerCase()
        .match(searchRegex);

      return !searchTask || startDate || dueDate || statusDeals || value;
    });

    setFilteredSalesRepTask(result);
  }, [searchTask]);

  //get sales rep
  const getSalesRep = async () => {
    try {
      const response = await axios.get(endpointSalesRep, {
        headers: {
          Authorization: token,
        },
      });

      const data = response.data.content;
      const getByUuid = data.find((data) => data.uuid === uuid);
      setDataSalesRep(getByUuid);
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //get task
  const getSalesTask = async () => {
    try {
      await axios
        .get(endpointSalesTask, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.due_date.localeCompare(b.due_date)
          );
          setDataSalesRepTask(sortedData);
          setFilteredSalesRepTask(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getActivity = async (uuid_sales_task) => {
    // const endpointSalesActivity = `${process.env.REACT_APP_BASE_URL}/sales-rep-activities?uuid_sales_task=${uuid_sales_task}&uuid_user=${uuid}`;
    try {
      const response = await axios.get(
        endpointGetSalesTaskDetail + uuid_sales_task,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(response.data);
      const data = response.data.content.sales_rep_activities;
      const filteredData = data.filter((item) => item.uuid_user === uuid);
      const sortedData = filteredData.sort((a, b) =>
        a.start_time.localeCompare(b.start_time)
      );
      return sortedData;
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
      throw error; // Re-throw the error to be caught at a higher level if needed.
    }
  };

  useEffect(() => {
    const fetchActivities = async () => {
      const activitiesPromises = filteredSalesRepTask.map((row) =>
        getActivity(row.uuid_sales_task)
      );

      try {
        const activitiesData = await Promise.all(activitiesPromises);

        const activitiesObj = filteredSalesRepTask.reduce((acc, row, index) => {
          acc[row.uuid_sales_task] = activitiesData[index];
          return acc;
        }, {});

        setDataSalesRepActivity(activitiesObj);
      } catch (error) {
        console.log(error);
      }
    };

    fetchActivities();
  }, [filteredSalesRepTask]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("D MMM YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.start_date).format("YYYY MM DD");
        const dateB = moment(b.start_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: "Due Date",
      selector: (row) => moment(row.due_date).format("D MMM YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.due_date).format("YYYY MM DD");
        const dateB = moment(b.due_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: "Task Name",
      selector: (row) => row.name,
      sortable: true,
      width: "180px",
    },
    {
      name: "Company",
      selector: (row) => row.partnership,
      sortable: true,
    },
    {
      name: "Deal Status",
      selector: (row) => row.status_deals,
      sortable: true,
    },
    {
      name: "Activity Detail",
      allowOverflow: true,
      button: true,
      cell: (row) => {
        const activity = dataSalesRepActivity[row.uuid_sales_task] || [];
        return (
          activity.length > 0 && (
            <button
              className="btn btn-aksi btn-edit"
              onClick={() => handleRowExpansion(row, activity)}
              type="button"
            >
              {expandedRows[row.uuid_sales_task] ? "Hide ▼" : "Show ▶"}
            </button>
          )
        );
      },
      ignoreRowClick: true,
      width: "150px",
    },
  ];

  const handleRowExpansion = (row, content) => {
    if (expandedRows[row.uuid_sales_task]) {
      setExpandedRows((prevState) => ({
        ...prevState,
        [row.uuid_sales_task]: null,
      }));
    } else {
      setExpandedRows((prevState) => ({
        ...prevState,
        [row.uuid_sales_task]: { content },
      }));
    }
  };
  const expandableRowsComponent = ({ data }) => {
    if (expandedRows[data.uuid_sales_task]) {
      const { content } = expandedRows[data.uuid_sales_task];
      return <ExpandedRows data={content} />;
    }
    return null;
  };

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredSalesRepTask.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  return (
    <>
      <h1 className="page-title">Sales Representative Detail</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-representative-staff">Sales Representative List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Sales Representative Detail
        </li>
      </ol>
      <form className="mt-4 px-5 form form-employee form-detail">
        <div className="mb-3 row">
          <label
            htmlFor="name"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Name
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              id="name"
              className="form-control"
              value={dataSalesRep.name || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="username"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Username
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="username"
              id="username"
              className="form-control"
              value={dataSalesRep.username || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="email"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Email
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="email"
              id="email"
              className="form-control"
              value={dataSalesRep.email || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="phone_number"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Phone Number
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="phone_number"
              id="phone_number"
              className="form-control"
              value={dataSalesRep.phone_number || ""}
              disabled
            />
          </div>
        </div>

        {/* task */}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          Task List
        </div>
        <div className="row mb-3 filter-target">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <input
              type="text"
              className="form-control input-search"
              placeholder="Search.."
              aria-label="Recipient's username"
              aria-describedby="btn-search"
              onChange={(e) => setSearchTask(e.target.value)}
              value={searchTask}
            />
          </div>
        </div>
        <div className="datatable">
          <DataTable
            dense
            columns={columns}
            data={currentData}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={filteredSalesRepTask.length}
            paginationDefaultPage={currentPage}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={setRowsPerPage}
            responsive
            expandableRows
            expandableRowsComponent={expandableRowsComponent}
            expandableRowExpanded={(row) => expandedRows[row.uuid_sales_task]}
            onRowExpandToggled={(row) => handleRowExpansion(row, null)}
            expandableRowsHideExpander
          />
        </div>

        <div className="mb-5 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-representative-staff")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Go Back
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default DetailSalesRep;
