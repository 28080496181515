import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../../components/CheckFeature";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Users = (props) => {
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/users`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/users/delete/`;

  const navigate = useNavigate();

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update User",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete User",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create User",
    feature: props.feature,
  });
  const hasFeatureResetPassword = CheckFeature({
    name: "Reset Password User",
    feature: props.feature,
  });

  //get list user
  const getUser = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.employee.name.localeCompare(b.employee.name)
          );
          setUser(sortedData);
          setFilteredUser(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  //search user
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = user.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.employee.name.toLowerCase().match(searchRegex);
    });

    setFilteredUser(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.employee.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.employee.email,
      id: "email",
    },
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
      id: "username",
    },
    {
      name: "Role",
      selector: (row) => row.role.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">
              {hasFeatureEdit ? "Edit" : "Detail"}
            </button>
          </Link>
          {hasFeatureResetPassword && (
            <Link to={`reset-password/${row.uuid}`}>
              <button className="btn btn-aksi btn-reset mr-1">
                Reset Password
              </button>
            </Link>
          )}
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDeleteUser(row.uuid, row.username)}
            >
              Delete
            </button>
          )}
        </>
      ),
      width: "315px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredUser.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete user
  const alertDeleteUser = (uuid, name) => (event) => {
    Swal.fire({
      title: "Delete User Account?",
      html: `Are you sure want to delete user account with the username <b class="text-uppercase">${name}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(uuid, name);
      }
    });
  };

  //function delete user
  const deleteUser = (uuid, name) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted user account with username <b class="text-uppercase">${name}</b>`,
            "success"
          );
          getUser();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete user account with username <b class="text-uppercase">${name}</b>`,
        "error"
      );
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">User List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-7 col-sm-12 col-12 col-btn-export">
          {hasFeatureAdd && (
            <Link to="create">
              <button className="btn btn-primary btn-grey mr-2 button-export">
                <i className="fa fa-plus-square mr-2"></i>
                Create User
              </button>
            </Link>
          )}
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(columns, filteredUser, "User List", "User List")
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() => generateExcel(columns, filteredUser, "User List")}
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-12 col-12 col-btn-export">
          <input
            type="text"
            className="form-control input-search mt-0"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredUser.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default Users;
