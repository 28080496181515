import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const AccountDetail = () => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showForm, setShowForm] = useState(false);
  const [passwordShown, setPasswordShown] = useState([false, false, false]);
  const [eyeClass, setEyeClass] = useState([false, false, false]);

  const navigate = useNavigate();

  const showChangePasswordForm = () => {
    setShowForm((current) => !current);
  };

  const togglePassword = (i) => {
    let toggleShow = [...passwordShown];
    let oldShow = toggleShow[i];
    toggleShow[i] = !oldShow;
    setPasswordShown(toggleShow);

    let toggleIcon = [...passwordShown];
    let oldIcon = toggleIcon[i];
    toggleIcon[i] = !oldIcon;
    setEyeClass(toggleIcon);
  };

  const endpointView = `${process.env.REACT_APP_BASE_URL}/users/`;
  const endpointChangePassword = `${process.env.REACT_APP_BASE_URL}/users/change_password/`;

  const getUserDetail = async () => {
    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid = stringifyUserData["uuid"];
      try {
        await axios
          .get(endpointView + uuid, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            setUsername(response.data.content.username);
            setRole(response.data.content.role.name);
          });
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid = stringifyUserData["uuid"];
      const data = {
        oldPassword: password,
        newPassword: confirmPassword,
      };
      try {
        await axios
          .post(endpointChangePassword + uuid, data, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire("Success!", `Successfully changed password`, "success");
            setPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setShowForm(false);
            getUserDetail();
          });
      } catch (error) {
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        }
      }
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <>
      <form className="mt-4 px-5 form form-profile">
        <div className="mb-3 row">
          <label
            htmlFor="inputUsername"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Username
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputUsername"
              value={username}
              readOnly
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputRole"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Role
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputRole"
              value={role}
              readOnly
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="offset-xl-2 col-xl-8 offset-lg-3 col-lg-8 offset-md-3 col-md-9 col-sm-12">
            <button
              className="btn btn-blue"
              type="button"
              onClick={showChangePasswordForm}
            >
              <i className="fa fa-key mr-2"></i>
              Change Password
            </button>
          </div>
        </div>
      </form>
      {showForm && (
        <div className="card my-4">
          <div className="card-header">Change Password</div>
          <div className="card-body px-4">
            <form
              method="post"
              onSubmit={changePassword}
              className="form form-profile"
            >
              <div className="mb-3 row">
                <label
                  htmlFor="oldPassword"
                  className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
                >
                  Current Password <i className="red">*</i>
                </label>
                <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
                  <div className="input-group">
                    <input
                      type={passwordShown[0] ? "text" : "password"}
                      placeholder="Current password"
                      name="password"
                      id="password"
                      className="form-control border-right-0"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <button
                      className="btn btn-primary btn-eye"
                      type="button"
                      onClick={() => togglePassword(0)}
                    >
                      <span
                        className={
                          eyeClass[0] ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="newPassword"
                  className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
                >
                  New Password <i className="red">*</i>
                </label>
                <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
                  <div className="input-group">
                    <input
                      type={passwordShown[1] ? "text" : "password"}
                      placeholder="New password"
                      name="password"
                      id="newPassword"
                      className="form-control border-right-0"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <button
                      className="btn btn-primary btn-eye"
                      type="button"
                      onClick={() => togglePassword(1)}
                    >
                      <span
                        className={
                          eyeClass[1] ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <label
                  htmlFor="confirmNewPassword"
                  className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
                >
                  Confirm New Password <i className="red">*</i>
                </label>
                <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
                  <div className="input-group">
                    <input
                      type={passwordShown[2] ? "text" : "password"}
                      placeholder="Confirm new password"
                      name="password"
                      id="confirmNewPassword"
                      className="form-control border-right-0"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <button
                      className="btn btn-primary btn-eye"
                      type="button"
                      onClick={() => togglePassword(2)}
                    >
                      <span
                        className={
                          eyeClass[2] ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                      ></span>
                    </button>
                  </div>
                  <small htmlFor="confirmNewPassword" className="mt-2 red">
                    {confirmPassword !== "" && newPassword !== confirmPassword
                      ? "Passwords do not match"
                      : ""}
                  </small>
                </div>
              </div>
              <div className="mb-3">
                <i className="red">*</i> Required
              </div>
              <div className="mb-3 row">
                <div className="col-12 text-right">
                  <button
                    className="btn btn-primary btn-blue"
                    type="submit"
                    disabled={newPassword !== confirmPassword ? "disabled" : ""}
                  >
                    <i className="fa fa-save mr-2"></i>
                    Change Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDetail;
