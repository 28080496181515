import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ManagementFeature = () => {
  const navigate = useNavigate();

  const [showDiv, setShowDiv] = useState(false);

  const [featureList, setFeatureList] = useState([]);
  const [filteredFeature, setFilteredFeature] = useState([]);
  const [search, setSearch] = useState("");

  const [role, setRole] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [selectedRoleAccess, setSelectedRoleAccess] = useState();
  const token = localStorage.getItem("token");

  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  //endpoint
  const endpointFeatureList = `${process.env.REACT_APP_BASE_URL}/features`;
  const endpointFeatureRoleList = `${process.env.REACT_APP_BASE_URL}/roles/`;
  const endpointRoleList = `${process.env.REACT_APP_BASE_URL}/roles`;
  const endpointAddFeature = `${process.env.REACT_APP_BASE_URL}/roles/edit/`;

  //get role list
  const getRole = async () => {
    try {
      await axios
        .get(endpointRoleList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (role) {
            return {
              uuid: role.uuid,
              name: role.name,
              is_data_restricted: role.is_data_restricted,
            };
          });
          setRole(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getFeature = async () => {
    try {
      await axios
        .get(endpointFeatureList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) => {
            const modulComparison = a.modul.localeCompare(b.modul);
            if (modulComparison !== 0) {
              return modulComparison;
            }

            const subModulComparison = a.sub_modul.localeCompare(b.sub_modul);
            if (subModulComparison !== 0) {
              return subModulComparison;
            }

            return a.name.localeCompare(b.name);
          });
          setFeatureList(sortedData);
          setFilteredFeature(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getFeatureRole = (uuid) => {
    try {
      axios
        .get(endpointFeatureRoleList + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content.features;
          const options = data.map(function (role) {
            return role.uuid;
          });
          setSelectedFeature(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getRole();
  }, []);

  //search feature
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = featureList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const modulMatch = data.modul?.toLowerCase().match(searchRegex);
      const subModulMatch = data.sub_modul?.toLowerCase().match(searchRegex);
      const featureMatch = data.name?.toLowerCase().match(searchRegex);

      return modulMatch || subModulMatch || featureMatch;
    });

    setFilteredFeature(result);
    setCurrentPage(1);
  }, [search]);

  const changeRole = (selectedOption) => {
    setShowDiv(true);
    if (selectedOption) {
      setSelectedRole(selectedOption.uuid);
      setSelectedRoleName(selectedOption.name);
      setSelectedRoleAccess(selectedOption.is_data_restricted);
      getFeature();
      getFeatureRole(selectedOption.uuid);
      setSearch("");
    }
  };

  const addEdit = (e) => {
    e.preventDefault();
    const uuid_features = selectedFeature.map((obj) => ({
      uuid: obj,
    }));
    const dataFeatureRole = {
      name: selectedRoleName,
      is_data_restricted: !selectedRoleAccess,
      features: uuid_features,
    };
    try {
      axios
        .put(endpointAddFeature + selectedRole, dataFeatureRole, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully changed the <b class="text-uppercase">${selectedRoleName}</b> role feature`,
            "success"
          );
          getRole();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = filteredFeature.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  // Handle checkbox change
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedFeature([...selectedFeature, id]);
    } else {
      setSelectedFeature(
        selectedFeature.filter((selectedId) => selectedId !== id)
      );
    }
  };

  // Handle "Check All" checkbox change
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const allIds = filteredFeature.map((row) => row.uuid);
      if (search !== "") {
        const filteredIds = allIds.filter(
          (id) => !selectedFeature.includes(id)
        );
        setSelectedFeature((prevSelectedFeature) => [
          ...prevSelectedFeature,
          ...filteredIds,
        ]);
      } else {
        setSelectedFeature(allIds);
      }
    } else {
      if (search !== "") {
        const uncheckedIds = filteredFeature.map((row) => row.uuid);
        setSelectedFeature((prevSelectedFeature) =>
          prevSelectedFeature.filter((id) => !uncheckedIds.includes(id))
        );
      } else {
        setSelectedFeature([]);
      }
    }
  };

  const handleRowClick = (id) => {
    // Toggle the row selection when the row is clicked
    if (selectedFeature.includes(id)) {
      setSelectedFeature(
        selectedFeature.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedFeature([...selectedFeature, id]);
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Feature Management</h1>
      <div className="card my-4">
        <div className="card-body py-5">
          <form className="px-5 form form-employee">
            <div className="row">
              <label
                htmlFor="inputName"
                className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
              >
                Role <i className="red">*</i>
              </label>
              <div className="col-xl-10 col-lg-8 col-md-9 col-sm-12">
                <Select
                  placeholder="Select role"
                  options={role}
                  name="uuid_role"
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.uuid}
                  autoFocus={true}
                  onChange={changeRole}
                  className="select2"
                  id="searchRole"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {showDiv && (
        <div>
          <div className="row mb-3">
            <div className="col-lg-8 col-md-12">
              <button
                className="btn btn-primary btn-grey"
                onClick={(e) => addEdit(e)}
              >
                <i className="fa fa-plus-square mr-2"></i>
                Save Features
              </button>
            </div>
            <div className="col-lg-4 col-md-12">
              <input
                type="text"
                className="form-control input-search"
                placeholder="Search.."
                aria-label="Recipient's username"
                aria-describedby="btn-search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
          </div>
          <div className="table table-responsive mb-0">
            <table className="table table-hover table-bordered table-sm custom-table">
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    <input
                      type="checkbox"
                      onChange={handleCheckAllChange}
                      checked={
                        search !== "" && currentData.length > 0
                          ? filteredFeature.every((row) =>
                              selectedFeature.includes(row.uuid)
                            )
                          : selectedFeature.length === featureList.length
                      }
                      disabled={currentData.length <= 0}
                    />
                  </th>
                  <th scope="col">Module</th>
                  <th scope="col">Sub Module</th>
                  <th scope="col">Feature</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, i) => (
                  <tr
                    key={row.uuid}
                    className={
                      selectedFeature.includes(row.uuid) ? "selected" : ""
                    }
                    onClick={() => handleRowClick(row.uuid)}
                  >
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={selectedFeature.includes(row.uuid)}
                        onChange={(event) =>
                          handleCheckboxChange(event, row.uuid)
                        }
                      />
                    </td>
                    <td>{row.modul}</td>
                    <td>{row.sub_modul}</td>
                    <td>{row.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between pagination-table">
            <div>
              Showing {firstIndex + 1} to{" "}
              {Math.min(lastIndex, filteredFeature.length)} of{" "}
              {filteredFeature.length} entries
            </div>
            <div>
              <select
                className="form-control form-control-sm"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="pagination pagination-custom justify-content-center">
            <button
              className="page-link"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              &lt;&lt;
            </button>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <button className="page-link active">{currentPage}</button>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                filteredFeature.length === 0 ||
                currentPage === Math.ceil(filteredFeature.length / pageSize)
              }
            >
              &gt;
            </button>
            <button
              className="page-link"
              onClick={() =>
                handlePageChange(Math.ceil(filteredFeature.length / pageSize))
              }
              disabled={
                filteredFeature.length === 0 ||
                currentPage === Math.ceil(filteredFeature.length / pageSize)
              }
            >
              &gt;&gt;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ManagementFeature;
