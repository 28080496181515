import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

const CheckFeature = (props) => {

  if (props.feature.length > 0) {
    const result = props.feature.filter((data) => {
      return data.name.toLowerCase() === props.name.toLowerCase();
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  }
};

export default CheckFeature;
