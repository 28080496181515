import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfilePic.scss";
import axios from "axios";
import Swal from "sweetalert2";

const ProfilePic = (props) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const [file, setFile] = useState();
  const [showBtn, setShowBtn] = useState(false);

  const endpointUpdate = `${process.env.REACT_APP_BASE_URL}/users/edit/`;

  const navigate = useNavigate();

  const loadFile = async (event) => {
    if (event.target.files[0] != null) {
      var image = document.getElementById("output");
      image.src = URL.createObjectURL(event.target.files[0]);
      setFile(event.target.files[0]);
      setShowBtn(true);
      event.target.value = null;
    }
  };

  const cancelChange = async (event) => {
    var image = document.getElementById("output");
    image.src = props.oldImage;
    setShowBtn(false);
  };

  const changePhoto = async (e) => {
    e.preventDefault();
    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid = stringifyUserData["uuid"];
      const uuid_role = stringifyUserData["uuid_role"];

      const fileName = file.name.toLowerCase();
      var fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);

      if (fileExt === "png" || fileExt === "jpg" || fileExt === "jpeg") {
        if (file.size > 2048000) {
          Swal.fire(
            "Failed to Change Profile Photo!",
            "File size cannot be more than 2MB",
            "error"
          );
        } else {
          const formData = new FormData();
          formData.append("userImage", file);
          formData.append("uuid_role", uuid_role);
          formData.append("name", props.employee.name);
          formData.append("address", props.employee.address);
          formData.append("phone_number", props.employee.phone_number);
          formData.append("email", props.employee.email);
          formData.append("position", props.employee.position);
          formData.append("uuid_hotel", props.employee.hotel.uuid);
          formData.append("uuid_department", props.employee.department.uuid);
          props.socialMedia.map((data, i) => {
            formData.append("social_media[" + i + "][uuid]", data.uuid);
            formData.append("social_media[" + i + "][name]", data.name);
          });
          try {
            axios
              .patch(endpointUpdate + uuid, formData, {
                headers: {
                  Authorization: token,
                },
              })
              .then(() => {
                Swal.fire(
                  "Success!",
                  "Successfully changed profile photo",
                  "success"
                ).then(function () {
                  window.location.reload();
                });
              });
          } catch (error) {
            console.log(error.response.data.message);
            if (
              error.response.data.status === 401 ||
              error.response.data.status === 403
            ) {
              navigate("/404", { replace: true });
            } else if (error.response.data.status === 400) {
              Swal.fire("Failed!", `${error.response.data.message}`, "error");
            }
          }
          // for (const value of formData.values()) {
          //   console.log(value);
          // }
        }
      } else {
        Swal.fire(
          "File Extension Not Allowed!",
          "Only jpg, png and jpeg files are allowed",
          "error"
        );
      }
    }
  };

  return (
    <form
      className="form-profile-pic col-xl-10 col-lg-11 col-md-12 px-5 my-4"
      onSubmit={changePhoto}
      method="post"
    >
      <div className="profile-pic">
        <label className="-label mb-0" htmlFor="file">
          <span className="glyphicon glyphicon-camera"></span>
          <span>Change Photo</span>
        </label>
        <input
          id="file"
          type="file"
          accept=".png, .jpg, .jpeg"
          name="file"
          onChange={(event) => loadFile(event)}
        />
        <img src={props.oldImage} id="output" />
      </div>
      {showBtn && (
        <div className="row">
          <div className="col-12 text-center py-2">
            <button className="btn btn-blue mx-1" type="submit">
              <i className="fa fa-check"></i> Save
            </button>
            <button
              className="btn btn-grey mx-1"
              type="button"
              onClick={(e) => cancelChange(e)}
            >
              <i className="fa fa-times"></i> Cancel
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

export default ProfilePic;
