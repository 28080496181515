import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../../components/CheckFeature";

const AddEditCampaign = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState("0");
  const [description, setDescription] = useState("");
  const [hotel, setHotel] = useState("");
  const [product, setProduct] = useState([]);

  const [invalidDate, setInvalidDate] = useState(false);

  const token = localStorage.getItem("token");
  const params = useParams();
  const campaignId = params.id;

  const [productList, setProductList] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [productType, setProductType] = useState("");

  //endpoint
  const endpointProduct = `${process.env.REACT_APP_BASE_URL}/products`;
  const endpointView = `${process.env.REACT_APP_BASE_URL}/marketing-projects/${campaignId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/marketing-projects/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/marketing-projects/edit/${campaignId}`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointProductType = `${process.env.REACT_APP_BASE_URL}/product-types`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Marketing Project",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Marketing Project",
    feature: props.feature,
  });

  useEffect(() => {
    if (campaignId) {
      getCampaign();
    }
    getHotel();
    getProduct();
    getProductType();
  }, []);

  useEffect(() => {
    let options = [];
    if (hotel) {
      options = productList
        .filter(
          (product) =>
            product.uuid_hotel === hotel &&
            (!productType || product.uuid_product_type === productType)
        )
        .map((product) => ({ value: product.uuid, label: product.name }));
    }
    setFilteredProduct(options);
  }, [hotel, productType, productList]);

  const changeBudget = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/\D/g, "");
    setBudget(numericValue);
  };

  const changeToRupiah = (value) => {
    const formattedValue = Number(value).toLocaleString("id-ID");
    return `Rp ${formattedValue}`;
  };

  useEffect(() => {
    const start = new Date(startDate);

    if (start && endDate) {
      const selectedDateTime = new Date(endDate);

      if (selectedDateTime < start) {
        setInvalidDate(true);
      } else {
        setInvalidDate(false);
      }
    }
  }, [startDate, endDate]);

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
      setFilteredProduct([]);
      setProduct([]);
    } else {
      setHotel("");
      setFilteredProduct([]);
      setProduct([]);
    }
  };

  //get product list
  const getProduct = () => {
    try {
      axios
        .get(endpointProduct, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setProductList(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected product
  const changeProduct = (selectedOptions) => {
    if (selectedOptions) {
      const selectedProductIds = selectedOptions.map((option) => option.value);
      setProduct(selectedProductIds);
    } else {
      setProduct([]);
    }
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const campaignData = {
      name: name,
      start_date: startDate,
      end_date: endDate,
      budget: parseInt(budget),
      description: description,
      productList: product,
      uuid_hotel: hotel,
    };
    try {
      if (!campaignId) {
        await axios
          .post(endpointAdd, campaignData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully created a marketing project with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/marketing-project");
          });
      } else {
        await axios
          .put(endpointEdit, campaignData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a marketing project with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/marketing-project");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get campaign
  const getCampaign = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setName(response.data.content.name);
        setStartDate(response.data.content.start_date);
        setEndDate(response.data.content.end_date);
        setBudget(response.data.content.budget);
        setDescription(response.data.content.description);
        setHotel(response.data.content.uuid_hotel);

        const productOptions = response.data.content.products.map(
          (product) => product.uuid
        );

        setProduct(productOptions);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/marketing-project", { replace: true });
        }
      });
  };

  //get product type list
  const getProductType = async () => {
    try {
      await axios
        .get(endpointProductType, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (dpt) {
            return { value: dpt.uuid, label: dpt.name };
          });
          setProductTypeList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };
  //selected productType
  const changeProductType = (selectedOption) => {
    if (selectedOption) {
      setProductType(selectedOption.value);
      setFilteredProduct([]);
      setProduct([]);
    } else {
      setProductType(null);
      setFilteredProduct([]);
      setProduct([]);
    }
  };

  return (
    <>
      <h1 className="page-title">
        {campaignId
          ? hasFeatureEdit
            ? "Edit Marketing Project"
            : "Marketing Project Details"
          : "Create Marketing Project"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/marketing-project">Marketing Project List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {campaignId
            ? hasFeatureEdit
              ? "Edit Marketing Project"
              : "Marketing Project Details"
            : "Create Marketing Project"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (campaignId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={campaignId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputStartDate"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Start Date <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="startDate"
              className="form-control"
              id="inputStartDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              disabled={campaignId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEndDate"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            End Date <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="endDate"
              className="form-control"
              id="inputEndDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={campaignId && !hasFeatureEdit}
              required
            />

            {invalidDate && (
              <small className="red mt-2">
                End date cannot be less than start date.
              </small>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputBudget"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Budget <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="budget"
              className="form-control"
              id="inputBudget"
              value={changeToRupiah(budget)}
              onChange={changeBudget}
              required
              disabled={campaignId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDescription"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Description <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="description"
              id="inputDescription"
              rows="3"
              className="form-control"
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
              required
              disabled={campaignId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>
        {(!campaignId || (campaignId && hasFeatureEdit)) && (
          <div className="mb-3 row">
            <label
              htmlFor="inputHotel"
              className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
            >
              Hotel
            </label>
            <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
              <Select
                placeholder="Select hotel"
                isClearable={true}
                options={hotelList}
                value={hotelList.filter((item) => item.value === hotel)}
                name="uuid_hotel"
                onChange={changeHotel}
                className="select2"
                id="inputHotel"
                isDisabled={campaignId && !hasFeatureEdit}
              />
              <small className="red">Select hotel to filter products</small>
            </div>
          </div>
        )}
        {(!campaignId || (campaignId && hasFeatureEdit)) && (
          <div className="mb-3 row">
            <label
              htmlFor="inputKategoriProduk"
              className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
            >
              Product Type
            </label>
            <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
              <Select
                placeholder="Select product type"
                isClearable={true}
                options={productTypeList}
                name="inputKategoriProduk"
                onChange={changeProductType}
                className="select2"
                id="inputKategoriProduk"
                isDisabled={campaignId && !hasFeatureEdit}
              />
              <small className="red">
                Select product type to filter products
              </small>
            </div>
          </div>
        )}
        <div className="mb-3 row">
          <label
            htmlFor="inputProduct"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Product <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select products"
              isClearable
              options={filteredProduct}
              name="uuid_product"
              onChange={changeProduct}
              value={product.map((productId) =>
                filteredProduct.find((option) => option.value === productId)
              )}
              className="select-product"
              id="inputProduct"
              required
              isMulti
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/marketing-project")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {campaignId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((campaignId && hasFeatureEdit) ||
              (!campaignId && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={invalidDate}
              >
                <i
                  className={
                    "fa " +
                    (campaignId ? "fa-edit" : "fa-plus-square") +
                    " mr-2"
                  }
                ></i>
                {campaignId ? "Edit" : "Create"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditCampaign;
