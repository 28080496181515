import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CheckFeature from "../../../components/CheckFeature";

const AddEditPartnership = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const partnerId = params.id;

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/partnerships/${partnerId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/partnerships/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/partnerships/edit/${partnerId}`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Partnership",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Partnership",
    feature: props.feature,
  });

  useEffect(() => {
    if (partnerId) {
      getPartnership();
    }
  }, []);

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const partnershipData = {
      name: name,
      address: address,
      phone_number: phoneNumber,
      email: email,
    };
    try {
      if (!partnerId) {
        await axios
          .post(endpointAdd, partnershipData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added a partnership named <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/partnership");
          });
      } else {
        await axios
          .put(endpointEdit, partnershipData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited partnership data with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/partnership");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get partnership
  const getPartnership = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setName(response.data.content.name);
        setAddress(response.data.content.address);
        setPhoneNumber(response.data.content.phone_number);
        setEmail(response.data.content.email);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/partnership", { replace: true });
        }
      });
  };

  return (
    <>
      <h1 className="page-title">
        {partnerId
          ? hasFeatureEdit
            ? "Edit Partnership Data"
            : "Partnership Details"
          : "Add Partnership"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/partnership">Partnership List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {partnerId
            ? hasFeatureEdit
              ? "Edit Partnership Data"
              : "Partnership Details"
            : "Add Partnership"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (partnerId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={partnerId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputAddress"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Address <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="inputAddress"
              id="inputAddress"
              rows="3"
              className="form-control"
              value={address || ""}
              onChange={(e) => setAddress(e.target.value)}
              required
              disabled={partnerId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPhoneNumber"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Phone Number <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <PhoneInput
              international
              defaultCountry="ID"
              value={phoneNumber}
              onChange={setPhoneNumber}
              id="inputPhoneNumber"
              disabled={partnerId && !hasFeatureEdit}
            />
            <small className="form-text text-danger">
              {phoneNumber
                ? isValidPhoneNumber(phoneNumber)
                  ? undefined
                  : "Invalid phone number"
                : "The phone number is required"}
            </small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEmail"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Email <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="email"
              name="inputEmail"
              className="form-control"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={partnerId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/partnership")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {partnerId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((partnerId && hasFeatureEdit) ||
              (!partnerId && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={
                  phoneNumber
                    ? isValidPhoneNumber(phoneNumber)
                      ? undefined
                      : "disabled"
                    : "disabled"
                }
              >
                <i
                  className={
                    "fa " + (partnerId ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {partnerId ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditPartnership;
