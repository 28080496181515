import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../components/CheckFeature";

const HotelAccount = (props) => {
  const [accountList, setAccountList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/hotel-accounts`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/hotel-accounts/delete/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Hotel Account",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Hotel Account",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Hotel Account",
    feature: props.feature,
  });

  //get account
  const getAccount = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setAccountList(data);
          setFilteredAccount(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  //search akun
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = accountList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.id.toLowerCase().match(searchRegex);
    });

    setFilteredAccount(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Hotel",
      cell: (row) => row.hotel,
    },
    {
      name: "Platform",
      selector: (row) => row.platform,
      id: "capitalizeUnset",
    },
    {
      name: "Username",
      selector: (row) => row.id,
      sortable: true,
      width: "200px",
      id: "capitalizeUnset2",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">
              {hasFeatureEdit ? "Edit" : "Detail"}
            </button>
          </Link>
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.hotel, row.platform, row.id)}
            >
              Delete
            </button>
          )}
        </>
      ),
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredAccount.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete account
  const alertDelete = (uuid, hotel, platform, username) => () => {
    Swal.fire({
      title: "Delete Hotel?",
      html: `Are you sure want to delete ${hotel} account on the <b class="text-uppercase">${platform}</b> platform with username <b class="text-uppercase">${username}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAccount(uuid, hotel, platform, username);
      }
    });
  };

  //function delete hotel
  const deleteAccount = (uuid, hotel, platform, username) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted ${hotel} account on the <b class="text-uppercase">${platform}</b> platform with username <b class="text-uppercase">${username}</b>`,
            "success"
          );
          getAccount();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete ${hotel} account on the <b class="text-uppercase">${platform}</b> platform with username <b class="text-uppercase">${username}</b>`,
        "error"
      );
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Hotel Account</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-12">
          {hasFeatureAdd && (
            <Link to="add">
              <button className="btn btn-primary btn-grey mr-2">
                <i className="fa fa-plus-square mr-2"></i>
                Add Hotel Account
              </button>
            </Link>
          )}
        </div>
        <div className="col-lg-4 col-md-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredAccount.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default HotelAccount;
