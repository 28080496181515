import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DataLanguage from "../../../data/LanguageCode.json";

const DetailBroadcastMessageTemplate = () => {
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [language, setLanguange] = useState("");

  // message
  const [text, setText] = useState("");
  const [variableNum, setVariableNum] = useState("");
  const [exampleMessage, setExampleMessage] = useState([""]);

  //header
  const [type, setType] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [exampleHeader, setExampleHeader] = useState([]);

  const [footer, setFooter] = useState("");

  const [metaCategory, setMetaCategory] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const templateId = params.id;

  const navigate = useNavigate();

  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/message-templates/${templateId}`;

  const getDetail = async () => {
    try {
      await axios
        .get(endpointDetail, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setCategory(response.data.content.category);
          setName(response.data.content.name.replace(/_/g, " "));
          const result = DataLanguage.filter(
            (data) => data.code === response.data.content.language
          ).map((filteredLanguage) => {
            return {
              language: filteredLanguage.language,
            };
          });
          setLanguange(result[0].language);
          let message;
          if (typeof response.data.content.message === "string") {
            message = JSON.parse(response.data.content.message);
          } else {
            message = response.data.content.message;
          }
          setText(message.text);
          setVariableNum(message.variable_num ? message.variable_num : 0);
          setExampleMessage(message.example ? message.example : "");
          let header;
          if (typeof response.data.content.header === "string") {
            header = JSON.parse(response.data.content.header);
          } else {
            header = response.data.content.header;
          }
          if (header !== null) {
            setType(header.type ? header.type : "");
            setHeaderText(header.text);
            setExampleHeader(header.example);
          }
          setFooter(
            response.data.content.footer ? response.data.content.footer : ""
          );
          setMetaCategory(response.data.content.meta_category);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    if (templateId) {
      getDetail();
    }
  }, []);

  return (
    <>
      <h1 className="page-title">WhatsApp Broadcast Template Details</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/whatsapp-broadcast-template">
            WhatsApp Broadcast Template List
          </a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          WhatsApp Broadcast Template Details
        </li>
      </ol>
      <form method="post" className="mt-4 px-5 form form-employee form-detail">
        <div className="mb-3 row">
          <label
            htmlFor="inputKategori"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Category
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="text"
              className="form-control"
              id="inputKategori"
              value={category}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputNama"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Name
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="text"
              className="form-control"
              id="inputNama"
              value={name}
              disabled
            />
          </div>
        </div>
        <div className="mb-4 row">
          <label
            htmlFor="inputBahasa"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Language
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="text"
              className="form-control"
              id="inputBahasa"
              value={language}
              disabled
            />
          </div>
        </div>
        <div className="text-with-line col-xl-11 col-lg-12 col-md-12 col-sm-12 mb-4 p-0">
          Message
        </div>
        <div className="mb-3 row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <textarea
              className="form-control"
              id="inputPesan"
              rows="4"
              value={text}
              disabled
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputVariableNum"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Number of Variables
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="number"
              className="form-control"
              id="inputVariableNum"
              value={variableNum}
              disabled
            />
          </div>
        </div>
        {parseInt(variableNum, 10) > 0 &&
          Array.from(Array(parseInt(variableNum, 10)), (e, i) => {
            return (
              <div className="row" key={i}>
                <label
                  htmlFor="inputVariableNum"
                  className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
                >
                  Example content of variable {i + 1}
                </label>
                <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
                  <input
                    type="text"
                    className="form-control mb-3"
                    value={exampleMessage[i] || ""}
                    disabled
                  />
                </div>
              </div>
            );
          })}

        {type && (
          <>
            <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
              HEADER
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="inputHeaderType"
                className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
              >
                Type
              </label>
              <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="inputHeaderType"
                  value={type}
                  disabled
                />
              </div>
            </div>
          </>
        )}
        {type === "TEXT" && (
          <div className="mb-3 row">
            <label
              htmlFor="inputText"
              className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
            >
              Text
            </label>
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
              <input
                type="text"
                className="form-control"
                id="inputText"
                value={headerText}
                disabled
              />
            </div>
          </div>
        )}
        {type && (
          <div className="mb-3 row">
            <label
              htmlFor="inputText"
              className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
            >
              Example Header
            </label>
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
              <input
                type="text"
                className="form-control"
                id="inputText"
                value={exampleHeader}
                disabled
              />
            </div>
          </div>
        )}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          FOOTER
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          <textarea
            className="form-control"
            id="inputPesan"
            rows="4"
            value={footer}
            disabled
          ></textarea>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputMetaCategory"
            className="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-form-label"
          >
            Meta Category
          </label>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <input
              type="text"
              className="form-control"
              value={metaCategory}
              id="inputMetaCategory"
              disabled
            />
          </div>
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/whatsapp-broadcast-template")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Back
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default DetailBroadcastMessageTemplate;
