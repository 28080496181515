import React, { useEffect, useState } from "react";
import "./faqList.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FaqRecursive from "./FaqRecursive";
import Swal from "sweetalert2";
import { ModalAddEditFaq } from "./ModalAddEditFaq";
import CheckFeature from "../../../components/CheckFeature";

const FaqList = (props) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const endpointGetAllFaq = `${process.env.REACT_APP_BASE_URL}/faqs`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/faqs/delete/`;
  const endpointList = `${process.env.REACT_APP_BASE_URL}/hotels/non-jxb`;

  const [showModal, setShowModal] = useState(false);

  const [faqList, setFaqList] = useState([]);
  const [uuid, setUuid] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedParent, setSelectedParent] = useState("");

  const getHotels = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setHotelList(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getAllFaq = async () => {
    try {
      await axios
        .get(endpointGetAllFaq, {
          headers: { Authorization: token },
        })
        .then((response) => {
          const data = response.data.content;
          setFaqList(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getAllFaq();
    getHotels();
  }, []);

  const openModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setShowModal(false);
    setSelectedType("");
    setSelectedParent("");
    setSelectedHotel("");
    setUuid("");
    setIsEdit(false);
  };

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update FAQ",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete FAQ",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create FAQ",
    feature: props.feature,
  });

  const deleteFaq = (uuid, keyword) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted FAQ with the keyword <b class="text-uppercase">${keyword}</b>`,
            "success"
          );
          getAllFaq();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete FAQ with the keyword <b class="text-uppercase">${keyword}</b>`,
        "error"
      );
    }
  };

  const alertDeleteFaq = (uuid, keyword) => {
    Swal.fire({
      title: "Delete FAQ?",
      html: `Are you sure want to delete <b class="text-uppercase">${keyword}</b> FAQ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFaq(uuid, keyword);
        // console.log("deleted " + uuid + " " + keyword);
      }
    });
  };

  return (
    <>
      <h1 className="page-title mb-3">FAQ LIST</h1>
      <p className="notes-red mb-2">*Maximum 10 FAQ items</p>
      {/* looping parent FAQ */}
      {faqList.length > 0 && (
        <div className="accordion" id="faqlist">
          {faqList
            .filter(
              (data) =>
                data.is_main === true && data.type !== "Customer Service"
            )
            .map((data, index) => (
              <div className="card" key={"main" + index}>
                <div className="card-header" id={"headingMain" + index}>
                  <div className="mb-0 row parent-faq-row">
                    <div
                      className={
                        hasFeatureEdit
                          ? "col-xl-10 col-lg-9 col-md-8 col-sm-12"
                          : "col-12"
                      }
                    >
                      <button
                        className="btn btn-link btn-block text-left collapsed btn-collapse"
                        type="button"
                        data-toggle="collapse"
                        data-target={"#collapseMain" + index}
                        aria-expanded="true"
                        aria-controls={"collapseMain" + index}
                      >
                        {data.keyword}
                      </button>
                    </div>
                    {hasFeatureEdit && (
                      <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 div-btn text-right align-self-center">
                        <button
                          className="btn btn-aksi btn-edit mr-1"
                          onClick={(e) => {
                            openModal(e);
                            setUuid(data.uuid);
                            setIsEdit(true);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  id={"collapseMain" + index}
                  className="collapse"
                  aria-labelledby={"headingMain" + index}
                  data-parent="#faqlist"
                >
                  <div className="card-body">
                    {/* Loop Hotel FAQ */}
                    {data.type === "Hotel" ? (
                      <div className="accordion" id="faqhotel">
                        {/* first loop */}
                        {hotelList.map((dataHotel) => (
                          <div
                            className="card"
                            key={"cardHotel" + dataHotel.code}
                          >
                            <div
                              className="card-header"
                              id={"headingHotel" + dataHotel.code}
                            >
                              <div className="mb-0">
                                <button
                                  className="btn btn-link btn-block text-left collapsed btn-collapse"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={
                                    "#collapseHotel" + dataHotel.code
                                  }
                                  aria-expanded="true"
                                  aria-controls={
                                    "collapseHotel" + dataHotel.code
                                  }
                                >
                                  {dataHotel.name}
                                </button>
                              </div>
                            </div>
                            <div
                              id={"collapseHotel" + dataHotel.code}
                              className="collapse"
                              aria-labelledby={"headingHotel" + dataHotel.code}
                              data-parent="#faqhotel"
                            >
                              <div className="card-body">
                                <div
                                  className="accordion"
                                  id={"faqhotel" + dataHotel.code}
                                >
                                  {/* second loop */}
                                  {faqList
                                    .filter(
                                      (hotelChild) =>
                                        hotelChild.uuid_hotel ===
                                        dataHotel.uuid &&
                                        hotelChild.is_main === false &&
                                        hotelChild.parent_faq === null
                                    )
                                    .map((dataHotelChild) => (
                                      <div
                                        className="card"
                                        key={
                                          "cardChildHotel" +
                                          dataHotelChild.uuid.split("-")[2]
                                        }
                                      >
                                        <div
                                          className="card-header"
                                          id={
                                            "headingChildHotel" +
                                            dataHotelChild.uuid.split("-")[2]
                                          }
                                        >
                                          <div className="mb-0 row">
                                            <div
                                              className={
                                                hasFeatureEdit ||
                                                  hasFeatureDelete
                                                  ? "col-xl-9 col-lg-8 col-md-7 col-sm-12 set-850-col12"
                                                  : "col-12"
                                              }
                                            >
                                              <button
                                                className="btn btn-link btn-block text-left btn-collapse"
                                                data-toggle="collapse"
                                                data-target={
                                                  "#collapseChildHotel" +
                                                  dataHotelChild.uuid.split(
                                                    "-"
                                                  )[2]
                                                }
                                                aria-expanded="true"
                                                aria-controls={
                                                  "collapseChildHotel" +
                                                  dataHotelChild.uuid.split(
                                                    "-"
                                                  )[2]
                                                }
                                              >
                                                {dataHotelChild.keyword}
                                              </button>
                                            </div>
                                            {/* action to faq */}
                                            {(hasFeatureEdit ||
                                              hasFeatureDelete) && (
                                                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 text-right div-btn align-self-center set-850-col12">
                                                  {hasFeatureEdit && (
                                                    <button
                                                      className="btn btn-aksi btn-edit mr-1"
                                                      onClick={(e) => {
                                                        openModal(e);
                                                        setUuid(
                                                          dataHotelChild.uuid
                                                        );
                                                        setIsEdit(true);
                                                      }}
                                                    >
                                                      Edit
                                                    </button>
                                                  )}
                                                  {hasFeatureDelete && (
                                                    <button
                                                      className="btn btn-aksi btn-delete mr-1"
                                                      onClick={() =>
                                                        alertDeleteFaq(
                                                          dataHotelChild.uuid,
                                                          dataHotelChild.keyword
                                                        )
                                                      }
                                                    >
                                                      Delete
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                        </div>

                                        <div
                                          id={
                                            "collapseChildHotel" +
                                            dataHotelChild.uuid.split("-")[2]
                                          }
                                          className="collapse"
                                          aria-labelledby={
                                            "headingChildHotel" +
                                            dataHotelChild.uuid.split("-")[2]
                                          }
                                          data-parent={
                                            "#faqhotel" + dataHotel.code
                                          }
                                        >
                                          <div className="card-body">
                                            {/*third loop */}
                                            {!dataHotelChild.reply ? (
                                              <FaqRecursive
                                                feature={props.feature}
                                                parentData={dataHotelChild}
                                                childData={faqList}
                                                uniqueId={
                                                  dataHotelChild.uuid.split(
                                                    "-"
                                                  )[2]
                                                }
                                                openModal={openModal}
                                                setUuid={setUuid}
                                                setIsEdit={setIsEdit}
                                                alertDeleteFaq={alertDeleteFaq}
                                                setSelectedType={
                                                  setSelectedType
                                                }
                                                setSelectedParent={
                                                  setSelectedParent
                                                }
                                              />
                                            ) : (
                                              <div>{dataHotelChild.reply}</div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                {/* add faq hotel */}
                                {hasFeatureAdd &&
                                  faqList.filter(
                                    (hotelChild) =>
                                      hotelChild.uuid_hotel ===
                                      dataHotel.uuid &&
                                      hotelChild.is_main === false &&
                                      hotelChild.parent_faq === null
                                  ).length < 10 && (
                                    <button
                                      className="btn btn-aksi btn-reset btn-lg btn-block mt-3"
                                      onClick={(e) => {
                                        openModal(e);
                                        setSelectedHotel(dataHotel.uuid);
                                        setSelectedType(data.type);
                                      }}
                                    >
                                      <i className="fa fa-plus-square"></i> Add{" "}
                                      {dataHotel.name} Child FAQ
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      // loop faq other
                      <>
                        <div className="accordion" id={"faqlistchild" + index}>
                          {faqList
                            .filter(
                              (childData) =>
                                data.type === childData.type &&
                                childData.is_main === false &&
                                childData.parent_faq === null
                            )
                            .map((data1) => (
                              <div className="card" key={data1.uuid}>
                                <div
                                  className="card-header"
                                  id={
                                    "heading" +
                                    data1.uuid.split("-")[1] +
                                    data1.uuid.split("-")[2]
                                  }
                                  key={
                                    "heading" +
                                    data1.uuid.split("-")[1] +
                                    data1.uuid.split("-")[2]
                                  }
                                >
                                  <div className="mb-0 row">
                                    <div
                                      className={
                                        hasFeatureEdit || hasFeatureDelete
                                          ? "col-xl-9 col-md-8 col-sm-12"
                                          : "col-12"
                                      }
                                    >
                                      <button
                                        className="btn btn-link btn-block text-left collapsed btn-collapse"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target={
                                          "#collapse" +
                                          data1.uuid.split("-")[1] +
                                          data1.uuid.split("-")[2]
                                        }
                                        aria-expanded="true"
                                        aria-controls={
                                          "collapse" +
                                          data1.uuid.split("-")[1] +
                                          data1.uuid.split("-")[2]
                                        }
                                      >
                                        {data1.keyword}
                                      </button>
                                    </div>
                                    {(hasFeatureEdit || hasFeatureDelete) && (
                                      <div className="col-xl-3 col-md-4 col-sm-12 text-right align-self-center">
                                        {hasFeatureEdit && (
                                          <button
                                            className="btn btn-aksi btn-edit mr-1"
                                            onClick={(e) => {
                                              openModal(e);
                                              setUuid(data1.uuid);
                                              setIsEdit(true);
                                            }}
                                          >
                                            Edit
                                          </button>
                                        )}
                                        {hasFeatureDelete && (
                                          <button
                                            className="btn btn-aksi btn-delete mr-1"
                                            onClick={() =>
                                              alertDeleteFaq(
                                                data1.uuid,
                                                data1.keyword
                                              )
                                            }
                                          >
                                            Delete
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div
                                  id={
                                    "collapse" +
                                    data1.uuid.split("-")[1] +
                                    data1.uuid.split("-")[2]
                                  }
                                  className="collapse"
                                  aria-labelledby={
                                    "heading" +
                                    data1.uuid.split("-")[1] +
                                    data1.uuid.split("-")[2]
                                  }
                                  data-parent={"#faqlistchild" + index}
                                >
                                  <div className="card-body">
                                    {/* conditioning loop faq */}
                                    {data1.reply ? (
                                      data1.reply
                                    ) : (
                                      <FaqRecursive
                                        feature={props.feature}
                                        parentData={data1}
                                        childData={faqList}
                                        uniqueId={data1.uuid.split("-")[2]}
                                        openModal={openModal}
                                        setUuid={setUuid}
                                        setIsEdit={setIsEdit}
                                        alertDeleteFaq={alertDeleteFaq}
                                        setSelectedType={setSelectedType}
                                        setSelectedParent={setSelectedParent}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        {hasFeatureAdd &&
                          faqList.filter(
                            (childData) =>
                              data.type === childData.type &&
                              childData.is_main === false &&
                              childData.parent_faq === null
                          ).length < 10 && (
                            <button
                              className="btn btn-aksi btn-reset btn-lg btn-block mt-3"
                              onClick={(e) => {
                                openModal(e);
                                setSelectedType(data.type);
                              }}
                            >
                              <i className="fa fa-plus-square"></i> Add{" "}
                              {data.keyword} Child FAQ
                            </button>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}

      <ModalAddEditFaq
        show={showModal}
        onClose={closeModal}
        parentType={selectedType}
        hotel={selectedHotel}
        parent={selectedParent}
        isEdit={isEdit}
        uuid={uuid}
        alertDeleteFaq={alertDeleteFaq}
        getAllFaq={getAllFaq}
      />
    </>
  );
};

export default FaqList;
