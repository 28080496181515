import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import "../../pages/custom-pages-css.scss";
import { formatMoney } from "../FormatMoneyToRupiah";
import DataTable from "react-data-table-component";
import { generateYearOptions } from "../GenerateYearOption";
import { generateMonthOptions } from "../GenerateMonthOptions";
import ExpandedRowSalesRepTarget from "./ExpandedRowSalesRepTarget";

const SalesRepTarget = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [hotelList, setHotelList] = useState([]);
  const [hotel, setHotel] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const currentYear = new Date().getFullYear().toString();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const yearOptions = generateYearOptions();
  const [selectedMonth, setSelectedMonth] = useState("");
  const monthOptions = generateMonthOptions();

  const [expandedRowUuid, setExpandedRowUuid] = useState(null);

  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  const getHotelList = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const filterByHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel("");
    }
  };

  const getSalesHotelTarget = async (selectedYear, selectedMonth, hotel) => {
    try {
      setLoading(true);
      let endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/sales-rep-target`;
      if (selectedYear && selectedMonth) {
        endpoint += `?yearMonth=${selectedYear}-${selectedMonth}`;
      } else if (selectedYear) {
        endpoint += `?year=${selectedYear}`;
      }

      if (hotel) {
        endpoint += `&uuid_hotel=${hotel}`;
      }

      await axios
        .get(endpoint, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setTimeout(() => {
            setLoading(false);
            const data = response.data.content;
            setData(data);
            setFilteredData(data);
          }, 500);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Revenue Target",
      selector: (row) =>
        formatMoney(
          typeof row.revenue_target === "number"
            ? row.revenue_target.toString()
            : row.revenue_target !== null && row.revenue_target !== undefined
            ? row.revenue_target.toString()
            : "0"
        ),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.revenue_target) - parseInt(b.revenue_target);
      },
    },
    {
      name: "Revenue Generated",
      selector: (row) =>
        formatMoney(
          typeof row.revenue_generated === "number"
            ? row.revenue_generated.toString()
            : row.revenue_generated !== null &&
              row.revenue_generated !== undefined
            ? row.revenue_generated.toString()
            : "0"
        ),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(b.revenue_generated) - parseInt(a.revenue_generated);
      },
      width: "150px",
    },
    {
      name: "Deals Target",
      selector: (row) =>
        row.num_of_deals_target ? row.num_of_deals_target : 0,
      sortable: true,
    },
    {
      name: "Actual Deals",
      selector: (row) =>
        row.num_of_deals_actual ? row.num_of_deals_actual : 0,
      sortable: true,
    },
    {
      name: "Monthly Revenue",
      allowOverflow: true,
      button: true,
      selector: (row) =>
        row.monthRevenues &&
        row.monthRevenues.length > 0 && (
          <button
            className="btn btn-aksi btn-edit"
            onClick={() => handleRowExpansion(row)}
          >
            {console.log(row.monthRevenues)}
            {expandedRowUuid === row.uuid ? "Hide ▼" : "Show ▶"}
          </button>
        ),
      ignoreRowClick: true,
    },
  ];

  const handleRowExpansion = (row) => {
    // If the row is already expanded, collapse it
    if (expandedRowUuid === row.uuid) {
      setExpandedRowUuid(null);
    } else {
      // If the row is not expanded, expand it
      setExpandedRowUuid(row.uuid);
    }
  };

  const expandableRowsComponent = ({ data }) => (
    <ExpandedRowSalesRepTarget data={data.monthRevenues} />
  );

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredData(result);
  }, [search, hotel]);

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const filterByYear = (selectedOption) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
    } else {
      setSelectedYear("");
    }
  };

  const filterByMonth = (selectedOption) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
    } else {
      setSelectedMonth("");
    }
  };

  useEffect(() => {
    if (show) {
      getHotelList();
      getSalesHotelTarget(selectedYear, selectedMonth, hotel);
    }
  }, [show, selectedYear, selectedMonth, hotel]);

  return (
    <>
      <div className="col-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse14"
            role="button"
            aria-expanded="false"
            aria-controls="collapse14"
            onClick={() => setShow(true)}
          >
            Sales Representative Target
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse14">
            <div className="row filter-search p-3">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={yearOptions}
                  value={yearOptions.find((obj) => obj.value === selectedYear)}
                  id="filterYear"
                  className="select2"
                  name="filterYear"
                  placeholder="Filter by year"
                  onChange={filterByYear}
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={monthOptions}
                  value={monthOptions.find(
                    (obj) => obj.value === selectedMonth
                  )}
                  id="filterYear"
                  className="select2 input-search"
                  name="filterYear"
                  placeholder="Filter by month"
                  onChange={filterByMonth}
                  isClearable={true}
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  placeholder="Filter by hotel"
                  isClearable={true}
                  options={hotelList}
                  name="filterByHotel"
                  value={hotelList.find((obj) => obj.label === hotel)}
                  onChange={filterByHotel}
                  className="select2 input-search"
                  id="filterByHotel"
                  classNamePrefix="react-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <input
                  type="text"
                  className="form-control input-search"
                  placeholder="Search by name"
                  aria-label="Recipient's username"
                  aria-describedby="btn-search"
                  id="search"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="px-3 pb-3 w-100 d-flex align-items-center justify-content-center">
              {loading ? (
                "Loading..."
              ) : filteredData.length > 0 ? (
                <div className="w-100 datatable">
                  <DataTable
                    dense
                    columns={columns}
                    data={currentData}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={filteredData.length}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    responsive
                    expandableRows
                    expandableRowsComponent={(row) =>
                      expandableRowsComponent(row)
                    }
                    expandableRowExpanded={(row) =>
                      expandedRowUuid === row.uuid
                    }
                    onRowExpandToggled={(row) => handleRowExpansion(row)}
                    expandableRowsHideExpander
                  />
                </div>
              ) : (
                "There is no data to display"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesRepTarget;
