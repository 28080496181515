import React, { useState } from "react";
import "../../custom-pages-css.scss";
import moment from "moment";
import ModalShowMap from "../../../components/ModalShowMap";
import ShowAttachment from "../../../components/ShowAttachment";

const ExpandedRows = ({ data }) => {
  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = data.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <div className="expanded-rows">
      <div className="title">Activity Detail</div>
      <div className="table table-responsive mb-0 mt-3">
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                #
              </th>

              <th scope="col">Start Time</th>
              <th scope="col">End Time</th>
              <th scope="col">Name</th>
              <th scope="col">Status</th>
              <th scope="col">PIC</th>
              <th scope="col">Location</th>
              <th scope="col">Attachment</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((row, i) => (
              <tr key={row.uuid}>
                <td className="text-center">
                  {(currentPage - 1) * pageSize + i + 1}
                </td>
                <td>{moment(row.start_time).format("DD/MM/YYYY")}</td>
                <td>{moment(row.end_time).format("DD/MM/YYYY")}</td>
                <td>{row.name}</td>
                <td>{row.status}</td>
                <td>
                  {row.pics?.length > 0 ? (
                    <ul className="pl-3 pb-0 mb-0">
                      {row.pics.map((data) => (
                        <li>{data.name}</li>
                      ))}
                    </ul>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  <ModalShowMap
                    latitude={row.latitude}
                    longitude={row.longitude}
                  />
                </td>
                <td>{row.file ? <ShowAttachment file={row.file} /> : "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between pagination-table">
        <div>
          Showing {firstIndex + 1} to {Math.min(lastIndex, data.length)} of{" "}
          {data.length} entries
        </div>
        <div>
          <select
            className="form-control form-control-sm"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            {pageSizeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="pagination pagination-custom justify-content-center">
        <button
          className="page-link"
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        >
          &lt;&lt;
        </button>
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        <button className="page-link active">{currentPage}</button>
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={
            data.length === 0 ||
            currentPage === Math.ceil(data.length / pageSize)
          }
        >
          &gt;
        </button>
        <button
          className="page-link"
          onClick={() => handlePageChange(Math.ceil(data.length / pageSize))}
          disabled={
            data.length === 0 ||
            currentPage === Math.ceil(data.length / pageSize)
          }
        >
          &gt;&gt;
        </button>
      </div>
    </div>
  );
};

export default ExpandedRows;
