import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import moment from "moment";
import CheckFeature from "../../../components/CheckFeature";
import ExpandedRowMarketingStaff from "./ExpandedRowMarketingStaff";
import Swal from "sweetalert2";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const MyMarketingTask = (props) => {
  const [taskList, setTaskList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredTask, setFilteredTask] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");

  const [expandedRowUuid, setExpandedRowUuid] = useState(null);

  const navigate = useNavigate();

  const userData = localStorage.getItem("user");
  const stringifyUserData = JSON.parse(userData);
  const uuid_user = stringifyUserData.uuid;

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/marketing-staffs/${uuid_user}/tasks/`;
  const endpointGetTaskList = `${process.env.REACT_APP_BASE_URL}/marketing-tasks/member/${uuid_user}`;
  const endpointProgress = `${process.env.REACT_APP_BASE_URL}/marketing-tasks/progress/`;

  //check features
  const hasFeatureProgress = CheckFeature({
    name: "Progress Marketing Task",
    feature: props.feature,
  });

  //get task
  const getTaskList = async () => {
    try {
      await axios
        .get(endpointGetTaskList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.due_date.localeCompare(b.due_date)
          );
          setTaskList(sortedData);
          setFilteredTask(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getTaskList();
  }, []);

  //search campaign
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = taskList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.project_name.toLowerCase().match(searchRegex);
    });

    setFilteredTask(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Deadline",
      selector: (row) => moment(row.due_date).format("D MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Project Name",
      selector: (row) => row.project_name,
      sortable: true,
    },
    {
      name: "Task Name",
      selector: (row) => row.task_name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row.description ? row.description : "-"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "120px",
    },
    {
      name: "Task Member",
      allowOverflow: true,
      button: true,
      cell: (row) =>
        row.members.length > 0 ? (
          <button
            className="btn btn-aksi btn-reset"
            onClick={() => handleRowExpansion(row)}
          >
            {expandedRowUuid === row.uuid ? "Hide ▼" : "Show ▶"}
          </button>
        ) : (
          "-"
        ),
      ignoreRowClick: true,
      width: "150px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {/* {hasFeatureProgress && ( */}
          <button
            className="btn btn-aksi btn-edit"
            onClick={() => alertProgress(row.uuid, row.status)}
          >
            Update Progress
          </button>
          {/* )} */}
        </>
      ),
      id: "aksi",
      width: "150px",
    },
  ];

  //alert progress
  const alertProgress = (uuid, status) => {
    const inputOptions = [
      { value: "Not Started", text: "Not Started" },
      { value: "In Progress", text: "In Progress" },
      { value: "Done", text: "Done" },
    ];

    const selectedOption = inputOptions.find(
      (option) => option.text === status
    );
    const inputValue = selectedOption ? selectedOption.value : null;

    Swal.fire({
      title: "Update Progress Marketing Task",
      input: "select",
      inputOptions: inputOptions.reduce((obj, option) => {
        obj[option.value] = option.text;
        return obj;
      }, {}),
      inputValue: inputValue,
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Next",
      inputValidator: (value) => {
        if (!value) {
          return "Please choose task status!";
        } else {
          Swal.fire({
            title: `Add Notes Update Status to ${value}`,
            input: "textarea",
            inputLabel: "Notes",
            inputPlaceholder: "Notes..",
            showCancelButton: true,
            confirmButtonColor: "#1c5ddc",
            cancelButtonColor: "#fc351c",
            cancelButtonText: "Cancel",
            confirmButtonText: "Save Progresss",
            inputValidator: (notes) => {
              if (!notes) {
                return "Notes cannot be empty!";
              } else {
                changeStatus(uuid, value, notes);
              }
            },
          });
          //
        }
      },
    });
  };

  const changeStatus = (uuid, value, notes) => {
    try {
      axios
        .patch(
          endpointProgress + uuid,
          { status: value, notes: notes },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully updated marketing progress to <b class="text-uppercase">${value}</b>`,
            "success"
          );
          getTaskList();
        });
    } catch (error) {
      Swal.fire("Failed!", `Failed to change marketing progress`, "error");
    }
  };

  const handleRowExpansion = (row) => {
    // If the row is already expanded, collapse it
    if (expandedRowUuid === row.uuid) {
      setExpandedRowUuid(null);
    } else {
      // If the row is not expanded, expand it
      setExpandedRowUuid(row.uuid);
    }
  };

  const expandableRowsComponent = ({ data }) => (
    <ExpandedRowMarketingStaff
      members={data.members}
      products={data.products}
    />
  );

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredTask.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  return (
    <>
      <h1 className="page-title mb-3">My Marketing Task List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-7 col-sm-12 col-12 col-btn-export">
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredTask,
                "My Marketing Task",
                "My Marketing Task"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredTask, "My Marketing Task")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-12 col-12 col-btn-export">
          <input
            type="text"
            className="form-control input-search mt-0"
            placeholder="Search"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredTask.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
          expandableRows
          expandableRowsComponent={(row) => expandableRowsComponent(row)}
          expandableRowExpanded={(row) => expandedRowUuid === row.uuid}
          onRowExpandToggled={(row) => handleRowExpansion(row)}
          expandableRowsHideExpander
        />
      </div>
    </>
  );
};

export default MyMarketingTask;
