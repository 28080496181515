import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../components/CheckFeature";
import { formatMoney } from "../../components/FormatMoneyToRupiah";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ProductList = (props) => {
  const [productList, setProductList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [productType, setProductType] = useState("");
  const [productTypeList, setProductTypeList] = useState([]);

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/products`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/products/delete/`;
  const endpointproductType = `${process.env.REACT_APP_BASE_URL}/product-types/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Product",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Product",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Product",
    feature: props.feature,
  });

  //get product
  const getProduct = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setProductList(data);
          setFilteredProduct(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getProduct();
    getProductType();
  }, []);

  //get product type list
  const getProductType = async () => {
    try {
      await axios
        .get(endpointproductType, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setProductTypeList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected product type
  const filterProductType = (selectedOption) => {
    if (selectedOption) {
      setProductType(selectedOption.value);
    } else {
      setProductType(null);
    }
  };

  //search
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = productList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");

      return (
        (!productType || data.uuid_product_type === productType) &&
        (!search || data.name.toLowerCase().match(searchRegex))
      );
    });

    setFilteredProduct(result);
  }, [search, productType]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => formatMoney(row.cost),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.cost) - parseInt(b.cost);
      },
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Platform",
      selector: (row) => row.platform,
      sortable: true,
      id: "email",
    },
    {
      name: "Qty",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">
              {hasFeatureEdit ? "Edit" : "Detail"}
            </button>
          </Link>
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.name, row.hotel, row.platform)}
            >
              Delete
            </button>
          )}
        </>
      ),
      width: "170px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredProduct.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete product
  const alertDelete = (uuid, name, hotel, platform) => () => {
    Swal.fire({
      title: "Delete Product?",
      html: `Are you sure want to delete the product named <b class="text-uppercase">${name}</b> from hotel ${hotel} on platform ${platform}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#f0f0f0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(uuid, name, hotel, platform);
      }
    });
  };

  //function delete produk
  const deleteProduct = (uuid, name, hotel, platform) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted product named <b class="text-uppercase">${name}</b> from hotel ${hotel} on platform ${platform}`,
            "success"
          );
          getProduct();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete product named <b class="text-uppercase">${name}</b> from hotel ${hotel} on platform ${platform}`,
        "error"
      );
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Product List</h1>
      <div className="row">
        <div className="col-12 col-customer-export">
          {hasFeatureAdd && (
            <Link to="add">
              <button className="btn btn-primary btn-grey mr-2 button-export">
                <i className="fa fa-plus-square mr-2"></i>
                Add Product
              </button>
            </Link>
          )}
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredProduct,
                "Product List",
                "Product List"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredProduct, "Product List")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
      </div>
      <div className="row filter-search mb-3">
        <div className="col-lg-4 col-md-5 col-sm-6">
          <Select
            placeholder="Filter by product type"
            isClearable={true}
            options={productTypeList}
            name="uuid_product_type"
            value={productTypeList.find((obj) => obj.value === productType)}
            onChange={filterProductType}
            className="select2 input-search mt-2"
            id="filter-product-type"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-4 col-md-5 col-sm-6">
          <input
            type="text"
            className="form-control input-search mt-2"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredProduct.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default ProductList;
