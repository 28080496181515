import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import TaskDetail from "./TaskDetail";
import moment from "moment";
import ModalShowMap from "../../../components/ModalShowMap";
import ShowAttachment from "../../../components/ShowAttachment";

const DetailMySalesTask = () => {
  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;
  const navigate = useNavigate();

  const endpointGetSalesTaskDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/`;

  const [dataActivity, setDataActivity] = useState([]);
  const [dataReportDeals, setDataReportDeals] = useState([]);
  const [dataTask, setDataTask] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (uuid) {
      getSalesTask();
    }
  }, [uuid]);

  const getSalesTask = async () => {
    try {
      await axios
        .get(endpointGetSalesTaskDetail + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setDataTask(data);
          setDataReportDeals(data.deals);
          setDataActivity(data.sales_rep_activities);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const columnsReportDeals = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "60px",
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
    },
    {
      name: "Offering Price",
      selector: (row) => changeToRupiah(row.offering_price),
    },
    {
      name: "Accepted Price",
      selector: (row) => changeToRupiah(row.accepted_price),
    },
  ];

  const columnsReportActivity = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Start Time",
      selector: (row) => moment(row.start_time).format("DD-MM-YYYY, HH:mm"),
      sortable: true,
    },
    {
      name: "End Time",
      selector: (row) => moment(row.end_time).format("DD-MM-YYYY, HH:mm"),
      sortable: true,
    },
    {
      name: "Activity Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "PIC",
      selector: (row) =>
        row.pics?.length > 0 ? (
          <ul className="pl-3 pb-0 mb-0">
            {row.pics.map((data) => (
              <li key={data.name}>{data.name}</li>
            ))}
          </ul>
        ) : (
          "-"
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Location",
      cell: (row) => (
        <ModalShowMap latitude={row.latitude} longitude={row.longitude} />
      ),
    },
    {
      name: "Attachment",
      cell: (row) => (row.file ? <ShowAttachment file={row.file} /> : "-"),
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = dataActivity.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const changeToRupiah = (value) => {
    const formattedValue = Number(value).toLocaleString("id-ID");
    return `Rp ${formattedValue}`;
  };

  return (
    <>
      <h1 className="page-title mb-3">My Sales Task Detail</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/my-task/sales-task">My Sales Task</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Detail Task
        </li>
      </ol>

      <TaskDetail dataTask={dataTask} />

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Report Deals
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columnsReportDeals}
          data={dataReportDeals}
          highlightOnHover
          pagination
          responsive
        />
      </div>

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Report Activity
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columnsReportActivity}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={dataActivity.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>

      <div className="mb-5 mt-4">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate(-1)}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailMySalesTask;
