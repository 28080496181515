import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CreatableSelect from "react-select/creatable";
import CheckFeature from "../../components/CheckFeature";

const AddEditEmployee = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [hotel, setHotel] = useState(null);
  const [department, setDepartment] = useState(null);
  const [socialMedia, setSocialMedia] = useState([]);
  const token = localStorage.getItem("token");
  const params = useParams();
  const userId = params.id;

  const [hotelList, setHotelList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  //endpoint
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointDepartment = `${process.env.REACT_APP_BASE_URL}/departments`;
  const endpointView = `${process.env.REACT_APP_BASE_URL}/employees/${userId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/employees/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/employees/edit/${userId}`;
  const endpointAddDepartment = `${process.env.REACT_APP_BASE_URL}/departments/add`;

  useEffect(() => {
    if (userId) {
      getUserById();
    }
    getHotel();
    getDepartment();
  }, []);

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Employee",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Employee",
    feature: props.feature,
  });

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //get department list
  const getDepartment = async () => {
    try {
      await axios
        .get(endpointDepartment, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (dpt) {
            return { value: dpt.uuid, label: dpt.name };
          });
          setDepartmentList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  //create hotel
  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [hotelList]
  );

  //selected department
  const changeDepartment = (selectedOption) => {
    if (selectedOption) {
      setDepartment(selectedOption.value);
    } else {
      setDepartment(null);
    }
  };

  //create department
  const createDepartment = useCallback(
    (inputValue) => {
      alertAddDepartment(inputValue);
    },
    [departmentList]
  );

  //alert add department
  const alertAddDepartment = (inputValue) => {
    Swal.fire({
      title: "Add Department",
      input: "text",
      inputLabel: "Department Name",
      showCancelButton: true,
      inputValue: inputValue,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value) {
          return "Department name cannot be empty!";
        } else {
          const check = departmentList.filter((data) => {
            return value.toLowerCase() === data.label.toLowerCase();
          });
          if (check.length > 0) {
            return "Department already exists!";
          } else {
            addDepartment(value);
          }
        }
      },
    });
  };

  //function add department
  const addDepartment = (value) => {
    const dataDepartment = {
      name: value,
    };
    try {
      axios
        .post(endpointAddDepartment, dataDepartment, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully added <b class="text-uppercase">${value}</b> department`,
            "success"
          );
          getDepartment();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const employeeData = {
      name: name,
      address: address,
      phone_number: phoneNumber,
      email: email,
      position: position,
      uuid_hotel: hotel,
      uuid_department: department,
      is_active: true,
      social_media: socialMedia,
    };
    try {
      if (!userId) {
        await axios
          .post(endpointAdd, employeeData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added an employee named <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/employee");
          });
      } else {
        await axios
          .put(endpointEdit, employeeData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Data for employee named <b class="text-uppercase">${name}</b> has been successfully edited`,
              "success"
            );
            navigate("/employee");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get detail user
  const getUserById = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setName(response.data.content.name);
        setAddress(response.data.content.address);
        setPhoneNumber(response.data.content.phone_number);
        setEmail(response.data.content.email);
        setPosition(response.data.content.position);
        setHotel(response.data.content.uuid_hotel);
        setDepartment(response.data.content.uuid_department);
        setSocialMedia(response.data.content.social_media);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/employee", { replace: true });
        }
      });
  };

  return (
    <>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (userId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={userId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputAddress"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Address <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="address"
              id="inputAddress"
              rows="3"
              className="form-control"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              disabled={userId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPhoneNumber"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Phone Number <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <PhoneInput
              international
              defaultCountry="ID"
              value={phoneNumber}
              onChange={setPhoneNumber}
              id="inputPhoneNumber"
              disabled={userId && !hasFeatureEdit}
            />
            <small className="form-text text-danger">
              {phoneNumber
                ? isValidPhoneNumber(phoneNumber)
                  ? undefined
                  : "Invalid phone number"
                : "The phone number is required"}
            </small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEmail"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Email <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="email"
              name="email"
              className="form-control"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={userId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPosition"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Position <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="position"
              className="form-control"
              id="inputPosition"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              required
              disabled={userId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select hotel"
                isClearable={true}
                options={hotelList}
                name="uuid_hotel"
                value={hotelList.find((obj) => obj.value === hotel)}
                onChange={changeHotel}
                className="select2 select-group"
                id="inputHotel"
                onCreateOption={createHotel}
                isDisabled={userId && !hasFeatureEdit}
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getHotel()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDepartment"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Department
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <CreatableSelect
              placeholder="Select department"
              isClearable
              value={departmentList.find((obj) => obj.value === department)}
              options={departmentList}
              onChange={changeDepartment}
              onCreateOption={createDepartment}
              className="select2"
              id="inputDepartment"
              name="uuid_department"
              isDisabled={userId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/employee")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {userId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((userId && hasFeatureEdit) || (!userId && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={
                  phoneNumber
                    ? isValidPhoneNumber(phoneNumber)
                      ? undefined
                      : "disabled"
                    : "disabled"
                }
              >
                <i
                  className={
                    "fa " +
                    (userId != null ? "fa-edit" : "fa-plus-square") +
                    " mr-2"
                  }
                ></i>
                {userId != null ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditEmployee;
