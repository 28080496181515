import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import moment from "moment";
import CheckFeature from "../../../components/CheckFeature";

const SalesTaskList = (props) => {
  const [taskList, setTaskList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredTask, setFilteredTask] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const userData = localStorage.getItem("user");
  const stringifyUserData = JSON.parse(userData);
  const uuid_user = stringifyUserData.uuid;

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/sales-reps/${uuid_user}/tasks`;

  //check features
  const hasFeatureDealWon = CheckFeature({
    name: "Create Sales Deal Won",
    feature: props.feature,
  });
  const hasFeatureActivity = CheckFeature({
    name: "Create Sales Representative Activity",
    feature: props.feature,
  });

  //get task
  const getTaskList = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.due_date.localeCompare(b.due_date)
          );
          setTaskList(sortedData);
          setFilteredTask(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getTaskList();
  }, []);

  //search
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = taskList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");

      const startDate = moment(data.start_date)
        .format("D MMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const dueDate = moment(data.due_date)
        .format("D MMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const partnership = data.partnership?.toLowerCase().match(searchRegex);
      const taskName = data.name?.toLowerCase().match(searchRegex);
      const statusDeals = data.status_deals
        ?.toString()
        .toLowerCase()
        .match(searchRegex);

      return (
        !search ||
        startDate ||
        dueDate ||
        partnership ||
        taskName ||
        statusDeals
      );
    });

    setFilteredTask(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("D MMMM YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.start_date).format("YYYY MM DD");
        const dateB = moment(b.start_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: "Deadline",
      selector: (row) => moment(row.due_date).format("D MMMM YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.due_date).format("YYYY MM DD");
        const dateB = moment(b.due_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: "Task Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Partnership",
      selector: (row) => row.partnership,
      sortable: true,
    },
    {
      name: "Deal Status",
      selector: (row) => row.status_deals,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {hasFeatureDealWon && row.status_deals === "Waiting" && (
            <Link to={`report-deals/${row.uuid_sales_task}`}>
              <button
                className="btn btn-aksi btn-edit mr-1"
                title={"Report Deals"}
              >
                <i className="fas fa-handshake"></i>
              </button>
            </Link>
          )}
          {hasFeatureActivity && row.status_deals === "Waiting" && (
            <Link to={`report-activities/${row.uuid_sales_task}`}>
              <button
                className="btn btn-aksi btn-edit mr-1"
                title={"Report Activities"}
              >
                <i className="fa fa-tasks"></i>
              </button>
            </Link>
          )}
          {row.status_deals !== "Waiting" && (
            <Link to={`${row.uuid_sales_task}`}>
              <button className="btn btn-aksi btn-edit mr-1">Detail</button>
            </Link>
          )}
        </>
      ),
      id: "aksi",
      width: "160px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredTask.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  return (
    <>
      <h1 className="page-title mb-3">My Sales Task List</h1>
      <div className="row mb-3 filter-search">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search.."
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredTask.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default SalesTaskList;
