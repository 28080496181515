import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../../components/CheckFeature";
import moment from "moment";

const AddEditBookingRoom = (props) => {
  const navigate = useNavigate();

  const [bookingName, setBookingName] = useState("");
  const [totalPax, setTotalPax] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState("");

  const [invalidDate, setInvalidDate] = useState(false);

  const token = localStorage.getItem("token");
  const params = useParams();
  const bookingId = params.id;

  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState("");
  const [selectedPIC, setSelectedPIC] = useState("");
  const [selectedLayout, setSelectedLayout] = useState("");
  const [selectedBookingType, setSelectedBookingType] = useState("");
  const [selectedBookingStatus, setSelectedBookingStatus] = useState("");

  const [hotelList, setHotelList] = useState([]);
  const [meetingRoomList, setMeetingRoomList] = useState([]);
  const [picList, setPicList] = useState([]);
  const [layoutList, setLayoutList] = useState([]);
  const [bookingTypeList, setBookingTypeList] = useState([]);
  const [bookingStatusList, setBookingStatusList] = useState([]);

  //endpoint
  const endpointPIC = `${process.env.REACT_APP_BASE_URL}/pics`;
  const endpointMeetingRoomList = `${process.env.REACT_APP_BASE_URL}/functions/meeting-rooms`;
  const endpointList = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/${bookingId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/${bookingId}`;
  const endpointBookingStatus = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/statuses`;
  const endpointBookingType = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/types`;
  const endpointBookingLayouts = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/layouts`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Booking Room",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Booking Room",
    feature: props.feature,
  });

  useEffect(() => {
    getHotel();
    getPIC();
    getLayout();
    getBookingtype();
    getBookingStatus();
    if (bookingId) {
      getBookingDetail();
    }
  }, []);


  //get Bookim Detail
  const getBookingDetail = async () => {
    await axios
      .get(endpointList, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setBookingName(response.data.content.booking_name);
        setSelectedBookingType(response.data.content.booking_type);
        setSelectedLayout(response.data.content.layout_type);
        setSelectedBookingStatus(response.data.content.status);
        setTotalPax(response.data.content.total_pax);
        setStartTime(moment(response.data.content.start_time).format('yyyy-MM-DDTH:mm:ss'));
        setEndTime(moment(response.data.content.end_time).format('yyyy-MM-DDTH:mm:ss'));
        setNote(response.data.content.note);
        setSelectedPIC(response.data.content.pic.uuid)
        setSelectedMeetingRoom(response.data.content.meeting_room.uuid)
        setSelectedHotel(response.data.content.meeting_room.hotel.uuid)
        //console.log(moment(response.data.content.start_time).format('yyyy-MM-DDThh:mm:ss'))
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/function-block/booking-room", { replace: true });
        }
      });
  };

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };

  //get PIC list
  const getPIC = async () => {
    try {
      await axios
        .get(endpointPIC, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (pic) {
            return { value: pic.uuid, label: pic.name };
          });
          setPicList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };

  const getLayout = async () => {
    try {
      await axios
        .get(endpointBookingLayouts, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (layout) {
            return { value: layout, label: layout };
          });
          setLayoutList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };

  const getBookingtype = async () => {
    try {
      await axios
        .get(endpointBookingType, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (status) {
            return { value: status, label: status };
          });
          setBookingTypeList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };

  const getBookingStatus = async () => {
    try {
      await axios
        .get(endpointBookingStatus, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (status) {
            return { value: status, label: status };
          });
          setBookingStatusList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const bookingRoomData = {
      uuid_meeting_room: selectedMeetingRoom,
      uuid_pic: selectedPIC,
      booking_name: bookingName,
      layout_type: selectedLayout,
      booking_type: selectedBookingType,
      total_pax: totalPax,
      start_time: moment(startTime).format("YYYY-MM-DD H:mm:ss"),
      end_time: moment(endTime).format("YYYY-MM-DD H:mm:ss"),
      note: note,
      status: selectedBookingStatus,
      attachment: attachment,
    };
    console.log(bookingRoomData);
    try {
      if (!bookingId) {
        await axios
          .post(endpointAdd, bookingRoomData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully created a Booking Room with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/function-block/booking-room");
          });
      } else {
        await axios
          .put(endpointEdit, bookingRoomData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a Booking Room with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/function-block/booking-room");
          });
      }
    } catch (error) {
      console.log(error.response.data.content);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.content}`, "error");
      }
    }
  };

  //get MeetingRoom
  const getMeetingRoom = async () => {
    try {
      await axios
        .get(endpointMeetingRoomList, {
          headers: {
            Authorization: token,
          },
          params: {
            uuid_hotel: selectedHotel
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setMeetingRoomList(options);
        });
    } catch (error) {
      console.log(error);
      //navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setSelectedHotel(selectedOption.value);
    } else {
      setSelectedHotel(null);
    }
  };
  //selected meeting room
  const changeMeetingRoom = (selectedOption) => {
    if (selectedOption) {
      setSelectedMeetingRoom(selectedOption.value);
    } else {
      setSelectedMeetingRoom(null);
    }
  };
  //selected PIC
  const changePIC = (selectedOption) => {
    if (selectedOption) {
      setSelectedPIC(selectedOption.value);
    } else {
      setSelectedPIC(null);
    }
  };
  //selected Layout Type
  const changeLayoutType = (selectedOption) => {
    if (selectedOption) {
      setSelectedLayout(selectedOption.value);
    } else {
      setSelectedLayout(null);
    }
  };
  //selected Type
  const changeBookingType = (selectedOption) => {
    if (selectedOption) {
      setSelectedBookingType(selectedOption.value);
    } else {
      setSelectedBookingType(null);
    }
  };
  //selected Status 
  const changeBookingStatus = (selectedOption) => {
    if (selectedOption) {
      setSelectedBookingStatus(selectedOption.value);
    } else {
      setSelectedBookingStatus(null);
    }
  };

  useEffect(() => {
    getMeetingRoom();
  }, [selectedHotel]);

  return (
    <>
      <h1 className="page-title">
        {bookingId
          ? hasFeatureEdit
            ? "Edit Booking Room"
            : "Booking Room Details"
          : "Create Booking Room"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/function-block/booking-room">Booking Room List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {bookingId
            ? hasFeatureEdit
              ? "Edit Booking Room"
              : "Booking Room Details"
            : "Create Booking Room"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (bookingId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Booking Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={bookingName}
              onChange={(e) => setBookingName(e.target.value)}
              required
              disabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select hotel"
              isClearable={true}
              options={hotelList}
              value={hotelList.filter((item) => item.value === selectedHotel)}
              name="uuid_hotel"
              onChange={changeHotel}
              className="select2"
              id="inputHotel"
              isDisabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        {selectedHotel != null && (
          <div className="mb-3 row">
            <label
              htmlFor="inputMeetingRoom"
              className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
            >
              Meeting Room <i className="red">*</i>
            </label>
            <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
              <Select
                placeholder="Select Meeting Room"
                isClearable={true}
                options={meetingRoomList}
                value={meetingRoomList.filter((item) => item.value === selectedMeetingRoom)}
                name="uuid_meeting_room"
                onChange={changeMeetingRoom}
                className="select2"
                id="inputMeetingRoom"
                isDisabled={bookingId && !hasFeatureEdit}
              />
            </div>
          </div>
        )}
        <div className="mb-3 row">
          <label
            htmlFor="inputPIC"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            PIC <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select PIC"
              isClearable={true}
              options={picList}
              value={picList.filter((item) => item.value === selectedPIC)}
              name="uuid_pic"
              onChange={changePIC}
              className="select2"
              id="inputPIC"
              isDisabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputLayout"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Layout Type
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select Meeting Room Layout"
              isClearable={true}
              options={layoutList}
              value={layoutList.filter((item) => item.value === selectedLayout)}
              name="layout_type"
              onChange={changeLayoutType}
              className="select2"
              id="inputLayout"
              isDisabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputBookingType"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Booking Type <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select Booking Type"
              isClearable={true}
              options={bookingTypeList}
              value={bookingTypeList.filter((item) => item.value === selectedBookingType)}
              name="booking_type"
              onChange={changeBookingType}
              className="select2"
              id="inputBookingType"
              isDisabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputBookingStatus"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Booking Status <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select Booking Type"
              isClearable={true}
              options={bookingStatusList}
              value={bookingStatusList.filter((item) => item.value === selectedBookingStatus)}
              name="status"
              onChange={changeBookingStatus}
              className="select2"
              id="inputBookingStatus"
              isDisabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputTotalPax"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Total Pax <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="number"
              name="inputTotalPax"
              className="form-control"
              id="inputTotalPax"
              value={totalPax}
              onChange={(e) => setTotalPax(e.target.value)}
              required
              disabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputStartTime"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Start Time <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="datetime-local"
              name="inputStartTime"
              className="form-control"
              id="inputStartTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required
              disabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputEndTime"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            End Time <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="datetime-local"
              name="inputEndTime"
              className="form-control"
              id="inputEndTime"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
              disabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputNote"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Note <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="note"
              id="inputNote"
              rows="3"
              className="form-control"
              value={note || ""}
              onChange={(e) => setNote(e.target.value)}
              required
              disabled={bookingId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputAttachment"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Attachment
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="file"
              name="inputAttachment"
              className="form-control"
              id="inputAttachment"
              onChange={(e) => setAttachment(e.target.files[0])}
              disabled={bookingId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/function-block/booking-room")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {bookingId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((bookingId && hasFeatureEdit) ||
              (!bookingId && hasFeatureAdd)) && (
                <button
                  className="btn btn-primary btn-blue"
                  type="submit"
                  disabled={invalidDate}
                >
                  <i
                    className={
                      "fa " +
                      (bookingId ? "fa-edit" : "fa-plus-square") +
                      " mr-2"
                    }
                  ></i>
                  {bookingId ? "Edit" : "Create"}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditBookingRoom;
