import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../../components/CheckFeature";
import moment from "moment";

const AddEditMarketingTask = (props) => {
  const navigate = useNavigate();

  const [taskName, setTaskName] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [status, setStatus] = useState("");
  const [mode, setMode] = useState("");
  const [desc, setDesc] = useState("");
  const [taskMember, setTaskMember] = useState([]);
  const [campaign, setCampaign] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [products, setProducts] = useState([]);
  const [hotel, setHotel] = useState("");

  const [invalidDate, setInvalidDate] = useState(false);

  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  const [marketingStaffList, setMarketingStaffList] = useState([]);
  const [filteredMarketingStaff, setFilteredMarketingStaff] = useState([]);

  const [campaignList, setCampaignList] = useState([]);

  const [search, setSearch] = useState("");
  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  //endpoint
  const endpointMarketingStaff = `${process.env.REACT_APP_BASE_URL}/marketing-staffs`;
  const endpointCampaign = `${process.env.REACT_APP_BASE_URL}/marketing-projects`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/marketing-tasks/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/marketing-tasks/edit/${uuid}`;
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/marketing-tasks/${uuid}`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Marketing Task",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Marketing Task",
    feature: props.feature,
  });

  useEffect(() => {
    if (uuid) {
      getDetail();
      getCampaign({ filter: false });
    } else {
      getCampaign({ filter: true });
    }
  }, []);

  useEffect(() => {
    if (!uuid && dueDate) {
      const dateNow = moment().format("YYYY-MM-DD");
      if (dueDate < dateNow) {
        setInvalidDate(true);
      } else {
        setInvalidDate(false);
      }
    }
  }, [dueDate]);

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = marketingStaffList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredMarketingStaff(result);
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    setMode(uuid ? "edit" : "create");
  }, [uuid]);

  //get marketing task detail
  const getDetail = async () => {
    await axios
      .get(endpointDetail, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setTaskName(response.data.content.name);
        setDueDate(response.data.content.due_date);
        setStatus(response.data.content.current_status);
        setDesc(response.data.content.description);
        getMarketingStaff(response.data.content.hotel.name);
        setTaskMember(
          response.data.content.member_list.length > 0
            ? response.data.content.member_list.map((data) => ({
                uuid: data.uuid,
                position: data.position,
              }))
            : []
        );
        setCampaign(response.data.content.uuid_campaign);
        setProjectName(response.data.content.project_name);
        setProjectDesc(response.data.content.project_description);
        setProducts(
          response.data.content.products.length > 0
            ? response.data.content.products
            : []
        );
        setHotel(response.data.content.hotel.name);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/marketing-task", { replace: true });
        }
      });
  };
  console.log(taskMember);
  //get campaign
  const getCampaign = ({ filter }) => {
    try {
      axios
        .get(endpointCampaign, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          let options = [];
          if (filter) {
            options = data
              .sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
              .filter((data) => data.end_date >= moment().format("YYYY-MM-DD"))
              .map((data) => ({
                value: data.uuid,
                label:
                  moment(data.start_date).format("D MMMM YYYY") +
                  " - " +
                  moment(data.end_date).format("D MMMM YYYY") +
                  " | " +
                  data.name,
                name: data.name,
                desc: data.description,
                products: data.products,
                hotel: data.hotel,
              }));
          } else {
            options = data
              .sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
              .map((data) => ({
                value: data.uuid,
                label:
                  moment(data.start_date).format("D MMMM YYYY") +
                  " - " +
                  moment(data.end_date).format("D MMMM YYYY") +
                  " | " +
                  data.name,
                name: data.name,
                desc: data.description,
                products: data.products,
                hotel: data.hotel,
                endDate: data.end_date,
              }));
          }

          setCampaignList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected campaign
  const changeCampaign = (selectedOption) => {
    if (selectedOption) {
      setCampaign(selectedOption.value);
      setProjectName(selectedOption.name);
      setProjectDesc(selectedOption.desc);
      setProducts(selectedOption.products);
      setHotel(selectedOption.hotel);
      getMarketingStaff(selectedOption.hotel);
    } else {
      setCampaign(null);
      setProjectName(null);
      setProjectDesc(null);
      setProducts([]);
      setHotel(null);
      setMarketingStaffList([]);
      setFilteredMarketingStaff([]);
    }
  };

  //get marketing staff
  const getMarketingStaff = (hotelName) => {
    try {
      axios
        .get(endpointMarketingStaff, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const mappedData = data
            .filter((data) => data.hotel === hotelName)
            .map((item) => {
              const countTask =
                item.marketing_tasks && Array.isArray(item.marketing_tasks)
                  ? item.marketing_tasks.reduce(
                      (count, task) =>
                        count +
                        (task.status === "Not Started" ||
                        task.status === "In Progress"
                          ? 1
                          : 0),
                      0
                    )
                  : 0;

              return {
                uuid: item.uuid_user,
                name: item.name,
                username: item.username,
                email: item.email,
                phone_number: item.phone_number,
                hotel: item.hotel,
                count_task: countTask,
              };
            });

          // Sort the mappedData array by name in ascending order
          const sortedData = mappedData.sort((a, b) =>
            a.name.localeCompare(b.name)
          );

          setMarketingStaffList(sortedData);
          setFilteredMarketingStaff(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const DATA = {
      name: taskName,
      due_date: dueDate,
      description: desc,
      uuid_campaign: campaign,
      ...(uuid ? { status: status } : {}),
      member_list: taskMember,
    };
    try {
      if (!uuid) {
        await axios
          .post(endpointAdd, DATA, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added a marketing task for a project named <b class="text-uppercase">${projectName}</b>`,
              "success"
            );
            navigate("/marketing-task");
          });
      } else {
        await axios
          .put(endpointEdit, DATA, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully changed data marketing task for project named <b class="text-uppercase">${projectName}</b>`,
              "success"
            );
            navigate("/marketing-task");
          });
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data.", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  const statusList = [
    { value: "Not Started", label: "Not Started" },
    { value: "In Progress", label: "In Progress" },
    { value: "Done", label: "Done" },
  ];

  //selected status
  const changeStatus = (selectedOption) => {
    if (selectedOption) {
      setStatus(selectedOption.value);
    } else {
      setStatus(null);
    }
  };

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = filteredMarketingStaff.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  const handleRowClick = (id) => {
    // Toggle the row selection when the row is clicked
    if (taskMember.filter((e) => e.uuid === id).length > 0) {
      setTaskMember(taskMember.filter((data) => data.uuid !== id));
    } else {
      setTaskMember([...taskMember, { uuid: id, position: "" }]);
    }
  };

  // Handle "Check All" checkbox change
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const allIds = filteredMarketingStaff.map((row) => row.uuid);
      const transformedData = allIds.map((id) => ({ uuid: id, position: "" }));
      setTaskMember(transformedData);
    } else {
      setTaskMember([]);
    }
  };

  const addPosition = (uuid, name) => {
    Swal.fire({
      title: `Position for ${name}`,
      input: "textarea",
      inputValue: taskMember.find((data) => data.uuid === uuid)?.position || "",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Save",
      inputValidator: (value) => {
        if (!value) {
          return "Please fill the position!";
        } else {
          handleChangePosition(uuid, name, value);
        }
      },
    });
  };

  const handleChangePosition = (uuid, name, value) => {
    const updatedTaskMember = taskMember.map((e) =>
      e.uuid === uuid ? { ...e, position: value } : e
    );

    setTaskMember(updatedTaskMember);
  };

  return (
    <>
      <h1 className="page-title">
        {uuid
          ? hasFeatureEdit && status !== "Done"
            ? "Edit"
            : "Detail"
          : "Create"}{" "}
        Marketing Task
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/marketing-task">Marketing Task List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {uuid
            ? hasFeatureEdit && status !== "Done"
              ? "Edit"
              : "Detail"
            : "Create"}{" "}
          Marketing Task
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (((uuid && !hasFeatureEdit) ||
            (uuid && hasFeatureEdit && status === "Done")) &&
            " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="campaign"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Marketing Project <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select marketing project"
              isClearable={true}
              options={campaignList}
              name="campaign"
              onChange={changeCampaign}
              className="select2 select-group"
              value={campaignList.find((obj) => obj.value === campaign)}
              id="campaign"
              isDisabled={
                mode === "edit" ||
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && status === "Done")
              }
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="taskName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Task Name
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              className="form-control"
              id="taskName"
              name="taskName"
              placeholder="Task Name"
              value={taskName || ""}
              onChange={(e) => setTaskName(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && status === "Done")
              }
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="dueDate"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Deadline <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="dueDate"
              className="form-control"
              id="dueDate"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && status === "Done")
              }
            />
            {invalidDate && (
              <small className="red mt-2">
                Deadline cannot be less than date now.
              </small>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="desc"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Description <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <textarea
              placeholder="Task Description"
              name="desc"
              id="desc"
              rows="3"
              className="form-control"
              value={desc || ""}
              onChange={(e) => setDesc(e.target.value)}
              required
              disabled={
                (uuid && !hasFeatureEdit) ||
                (uuid && hasFeatureEdit && status === "Done")
              }
            ></textarea>
          </div>
        </div>
        {campaign && (
          <div className="mb-3 row">
            <label
              htmlFor="projectDesc"
              className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
            >
              Project Description
            </label>
            <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
              <textarea
                name="projectDesc"
                id="projectDesc"
                rows="3"
                className="form-control"
                value={projectDesc ? projectDesc : "-"}
                disabled
              ></textarea>
            </div>
          </div>
        )}

        {uuid && (
          <div className="mb-3 row">
            <label
              htmlFor="status"
              className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
            >
              Status
            </label>
            <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
              <input
                type="text"
                className="form-control"
                id="Status"
                name="Status"
                value={status}
                required
                disabled
              />
            </div>
          </div>
        )}

        {campaign && (
          <div className="mb-3 row">
            <label
              htmlFor="hotel"
              className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
            >
              Hotel
            </label>
            <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
              <input
                type="text"
                className="form-control"
                id="hotel"
                name="hotel"
                value={hotel}
                disabled
              />
            </div>
          </div>
        )}

        {/* task member */}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          Task Member
          {uuid &&
            hasFeatureEdit &&
            status !== "Not Started" &&
            !taskMember.length && <span className="red ml-2">*</span>}
        </div>
        <div className="row mb-3 filter-target">
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <input
              type="text"
              className="form-control input-search"
              placeholder="Search by name"
              aria-label="Recipient's username"
              aria-describedby="btn-search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
        <div className="datatable col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
          Task Member : {taskMember.length > 0 ? taskMember.length : 0}
          <div className="table table-responsive mb-0 mt-3">
            <table className="table table-hover table-sm custom-table td-vertical-align-middle">
              <thead>
                <tr className="th-vertical-align-middle">
                  <th scope="col" className="text-center th-checkbox">
                    <input
                      type="checkbox"
                      onChange={handleCheckAllChange}
                      checked={
                        currentData.length > 0
                          ? search !== ""
                            ? filteredMarketingStaff.every((row) =>
                                taskMember.includes(row.uuid)
                              )
                            : taskMember.length === marketingStaffList.length
                          : false
                      }
                      disabled={
                        currentData.length <= 0 ||
                        (uuid && !hasFeatureEdit) ||
                        (uuid && hasFeatureEdit && status === "Done")
                      }
                    />
                  </th>
                  <th scope="col">Nama</th>
                  <th scope="col">Username</th>
                  <th scope="col">No. Telepon</th>
                  <th scope="col">Email</th>
                  <th scope="col">Current Task</th>
                  <th scope="col">Position</th>
                </tr>
              </thead>
              <tbody>
                {console.log(currentData)}
                {currentData.map((row, i) => (
                  <tr
                    key={row.uuid}
                    className={`row-item ${
                      taskMember.some((e) => e.uuid === row.uuid)
                        ? "selected"
                        : ""
                    } ${
                      ((uuid && !hasFeatureEdit) ||
                        (uuid && hasFeatureEdit && status === "Done")) &&
                      "disabled"
                    }`}
                    onClick={() => handleRowClick(row.uuid)}
                    disabled={
                      (uuid && !hasFeatureEdit) ||
                      (uuid && hasFeatureEdit && status === "Done")
                    }
                  >
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={taskMember.some((e) => e.uuid === row.uuid)}
                        onChange={() => handleRowClick(row.uuid)}
                        disabled={
                          (uuid && !hasFeatureEdit) ||
                          (uuid && hasFeatureEdit && status === "Done")
                        }
                      />
                    </td>
                    <td>{row.name}</td>
                    <td>{row.username}</td>
                    <td>{row.phone_number}</td>
                    <td>{row.email}</td>
                    <td>{row.count_task}</td>
                    <td>
                      {uuid && (!hasFeatureEdit || status === "Done") ? (
                        taskMember.find((data) => data.uuid === row.uuid)
                          ?.position || ""
                      ) : (
                        <button
                          className="btn btn-aksi btn-edit"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            addPosition(row.uuid, row.name);
                          }}
                          disabled={
                            !taskMember.some((e) => e.uuid === row.uuid)
                          }
                        >
                          {uuid && hasFeatureEdit
                            ? "Change Position"
                            : "Add Position"}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between pagination-table">
            <div>
              Showing {filteredMarketingStaff.length > 0 ? firstIndex + 1 : 0}{" "}
              to {Math.min(lastIndex, filteredMarketingStaff.length)} of{" "}
              {filteredMarketingStaff.length} entries
            </div>
            <div>
              <select
                className="form-control form-control-sm"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pagination pagination-custom justify-content-center">
            <button
              className="page-link"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              &lt;&lt;
            </button>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <button className="page-link active">{currentPage}</button>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                filteredMarketingStaff.length === 0 ||
                currentPage ===
                  Math.ceil(filteredMarketingStaff.length / pageSize)
              }
            >
              &gt;
            </button>
            <button
              className="page-link"
              onClick={() =>
                handlePageChange(
                  Math.ceil(filteredMarketingStaff.length / pageSize)
                )
              }
              disabled={
                filteredMarketingStaff.length === 0 ||
                currentPage ===
                  Math.ceil(filteredMarketingStaff.length / pageSize)
              }
            >
              &gt;&gt;
            </button>
          </div>
        </div>

        {uuid &&
          hasFeatureEdit &&
          status !== "Not Started" &&
          !taskMember.length && (
            <span className="red">Task member is required</span>
          )}

        {/* product list */}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-2 p-0">
          Product List
        </div>
        <div className="mb-3">
          {products.length > 0 ? (
            <ul>
              {products.map((data) => (
                <li key={data.uuid}>{data.name}</li>
              ))}
            </ul>
          ) : (
            "No product found."
          )}
        </div>

        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/marketing-task")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {uuid
                ? hasFeatureEdit && status !== "Done"
                  ? "Cancel"
                  : "Go Back"
                : "Cancel"}
            </button>
            {((uuid && hasFeatureEdit && status !== "Done") ||
              (!uuid && hasFeatureAdd)) && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={
                  invalidDate ||
                  (uuid &&
                    hasFeatureEdit &&
                    status !== "Not Started" &&
                    !taskMember.length)
                }
              >
                <i
                  className={
                    "fa " +
                    (uuid && hasFeatureEdit && status !== "Done"
                      ? "fa-edit"
                      : "fa-plus-square") +
                    " mr-2"
                  }
                ></i>
                {uuid && hasFeatureEdit && status !== "Done" ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditMarketingTask;
