// src/LeafletMap.js
import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";

const LeafletMap = ({ longitude, latitude, setLatitude, setLongitude }) => {
  const mapRef = useRef(null);

  const customMarker = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
  });

  useEffect(() => {
    // Initialize the map
    const map = L.map(mapRef.current).setView([latitude, longitude], 17);

    L.marker([latitude, longitude], { icon: customMarker })
      .addTo(map)
      .bindPopup("You clicked here!")
      .openPopup();
    // Add a tile layer
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // Function to handle map click
    function onMapClick(e) {
      // Remove any existing markers
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });

      map.flyTo(e.latlng, 17, {
        animate: true,
        duration: 1.5, // in seconds
      });
      // Add a marker at the clicked location
      L.marker(e.latlng, { icon: customMarker })
        .addTo(map)
        .bindPopup("You clicked here!")
        .openPopup();
      setLatitude(e?.latlng?.lat);
      setLongitude(e?.latlng?.lng);
    }

    // Attach the click event to the map
    map.on("click", onMapClick);

    L.easyButton("fa-crosshairs", function () {
      //map.locate({ setView: true, maxZoom: 16 });
      map.locate().on("locationfound", function (e) {
        map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            map.removeLayer(layer);
          }
        });
        map.flyTo(e.latlng, 17, {
          animate: true,
          duration: 1.5, // in seconds
        });
        L.marker(e.latlng, { icon: customMarker })
          .addTo(map)
          .bindPopup("You clicked here!")
          .openPopup();
        setLatitude(e?.latlng?.lat);
        setLongitude(e?.latlng?.lng);
      });
    }).addTo(map);

    // Clean up resources on component unmount
    return () => {
      map.off("click", onMapClick);
      map.remove();
    };
  }, []); // Run this effect only once on component mount

  return <div ref={mapRef} style={{ height: "500px", width: "100%" }} />;
};

export default LeafletMap;
