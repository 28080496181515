import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "../custom-pages-css.scss";
import axios from "axios";
import moment from "moment";

const AddEditReviewReply = () => {
  const navigate = useNavigate();

  const [date, setDate] = useState("");
  const [rating, setRating] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [platform, setPlatform] = useState("");
  const [hotel, setHotel] = useState("");
  const [customer, setCustomer] = useState("");

  const token = localStorage.getItem("token");

  const params = useParams();
  const uuid = params.id;

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/reviews/${uuid}`;

  useEffect(() => {
    if (uuid) {
      getDetail();
    }
  }, []);

  const getDetail = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setDate(
          moment(response.data.content.review_date).format("D MMMM YYYY")
        );
        setRating(response.data.content.rating);
        setMessage(response.data.content.message);
        setType(response.data.content.type);
        setPlatform(response.data.content.platform);
        setHotel(response.data.content.hotel);
        setCustomer(response.data.content.customer);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/customer-review", { replace: true });
        }
      });
  };

  return (
    <>
      <h1 className="page-title">Review Details</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/customer-review">Customer Reviews</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Review Details
        </li>
      </ol>

      <form className="mt-4 px-5 form form-employee form-detail">
        <div className="table-responsive-lg table-overflow">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td className="col-lg-2 col-3">Date</td>
                <td>:</td>
                <td>{date}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>:</td>
                <td>{customer}</td>
              </tr>
              <tr>
                <td>Hotel</td>
                <td>:</td>
                <td>{hotel}</td>
              </tr>
              <tr>
                <td>Review Type</td>
                <td>:</td>
                <td>{type}</td>
              </tr>
              <tr>
                <td className="align-middle">Rating</td>
                <td className="align-middle">:</td>
                <td className="d-flex">{rating}</td>
              </tr>
              <tr>
                <td>Platform</td>
                <td>:</td>
                <td>{platform}</td>
              </tr>
              <tr>
                <td>Review</td>
                <td>:</td>
                <td>{message}</td>
              </tr>
              <tr>
                <td colSpan="3" className="text-right">
                  <button
                    className="btn btn-primary btn-grey mr-2"
                    onClick={() => navigate("/customer-review")}
                    type="button"
                  >
                    <i className="fa fa-arrow-left mr-2"></i>
                    Back
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
};

export default AddEditReviewReply;
