import React from "react";
import "./faqList.scss";
import CheckFeature from "../../../components/CheckFeature";

const FaqRecursive = (props) => {
  const {
    parentData,
    childData,
    uniqueId,
    openModal,
    setUuid,
    setIsEdit,
    alertDeleteFaq,
    setSelectedType,
    setSelectedParent,
  } = props;

  const filteredData = childData.filter(
    (cData) => parentData.uuid === cData.parent_faq
  );

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update FAQ",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete FAQ",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create FAQ",
    feature: props.feature,
  });

  return (
    <>
      <div className="accordion" id={"faqRec" + parentData.type + uniqueId}>
        {filteredData.map((data) => (
          <div className="card" key={data.uuid}>
            <div
              className="card-header"
              id={"heading" + data.uuid.split("-")[1] + data.uuid.split("-")[2]}
            >
              <div className="mb-0 row">
                <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12 set-900-col12">
                  <button
                    className="btn btn-link btn-block text-left btn-collapse collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target={
                      "#collapseFaqRec" +
                      data.uuid.split("-")[1] +
                      data.uuid.split("-")[2]
                    }
                    aria-expanded="true"
                    aria-controls={
                      "collapseFaqRec" +
                      data.uuid.split("-")[1] +
                      data.uuid.split("-")[2]
                    }
                  >
                    {data.keyword}
                  </button>
                </div>
                <div
                  className={
                    hasFeatureEdit || hasFeatureDelete
                      ? "col-xl-3 col-lg-4 col-md-5 col-sm-12 text-right align-self-center set-900-col12 div-btn"
                      : "col-12"
                  }
                >
                  {hasFeatureEdit && (
                    <button
                      className="btn btn-aksi btn-edit mr-1"
                      onClick={(e) => {
                        openModal(e);
                        setUuid(data.uuid);
                        setIsEdit(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                  {hasFeatureDelete && (
                    <button
                      className="btn btn-aksi btn-delete mr-1"
                      onClick={() => {
                        alertDeleteFaq(data.uuid, data.keyword);
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              id={
                "collapseFaqRec" +
                data.uuid.split("-")[1] +
                data.uuid.split("-")[2]
              }
              className="collapse"
              aria-labelledby={
                "headingFaqRec" +
                data.uuid.split("-")[1] +
                data.uuid.split("-")[2]
              }
              data-parent={
                "#faqRec" + parentData.type + parentData.uuid.split("-")[2]
              }
            >
              <div className="card-body">
                {data.reply === null ? (
                  <FaqRecursive
                    feature={props.feature}
                    parentData={data}
                    childData={childData}
                    uniqueId={data.uuid.split("-")[2]}
                    openModal={openModal}
                    setUuid={setUuid}
                    setIsEdit={setIsEdit}
                    alertDeleteFaq={alertDeleteFaq}
                    setSelectedType={setSelectedType}
                    setSelectedParent={setSelectedParent}
                  />
                ) : (
                  <div>{data.reply}</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {filteredData.length < 10 && hasFeatureAdd && (
        <button
          className="btn btn-aksi btn-reset btn-lg btn-block mt-3"
          onClick={(e) => {
            openModal(e);
            setSelectedType(parentData.type);
            setSelectedParent(parentData.uuid);
          }}
        >
          <i className="fa fa-plus-square"></i> Add {parentData.keyword} Child
        </button>
      )}
    </>
  );
};

export default FaqRecursive;
