import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import moment from "moment";
import ModalShowMap from "../../../components/ModalShowMap";
import ShowAttachment from "../../../components/ShowAttachment";

const DetailSalesTask = () => {
  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  const navigate = useNavigate();
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [desc, setDesc] = useState("");
  const [hotel, setHotel] = useState("");
  const [company, setCompany] = useState("");
  const [taskMember, setTaskMember] = useState([]);
  const [taskDeals, setTaskDeals] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [activitiesList, setActivitiesList] = useState([]);
  const [filteredActivity, setFilteredActivity] = useState([]);
  const [completionDate, setCompletionDate] = useState("");
  const [statusDeal, setStatusDeal] = useState(null);
  const [lossReason, setLossReason] = useState(null);

  const [searchActivity, setSearchActivity] = useState("");
  const [currentPageActivity, setCurrentPageActivty] = useState(1);
  const [rowsPerPageActivity, setRowsPerPageActivity] = useState(10);

  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/${uuid}`;

  useEffect(() => {
    if (uuid) {
      getDetail();
    }
  }, []);

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const resultActivity = activitiesList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(searchActivity), "i");
      const name = data.name?.toLowerCase().match(searchRegex);
      const salesName = data.sales_name?.toLowerCase().match(searchRegex);

      return name || salesName;
    });

    setFilteredActivity(resultActivity);
  }, [searchActivity]);

  const getDetail = async () => {
    await axios
      .get(endpointDetail, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setTaskName(response.data.content.name);
        setStartDate(response.data.content.start_date);
        setDueDate(response.data.content.due_date);
        setDesc(response.data.content.description);
        setHotel(response.data.content.uuid_hotel);
        setCompany(response.data.content.uuid_company);
        setTaskMember(response.data.content.members);
        setActivitiesList(response.data.content.sales_rep_activities);
        setFilteredActivity(response.data.content.sales_rep_activities);
        setCompanyName(response.data.content.partnership);
        setCompletionDate(response.data.content.completion_date);
        setStatusDeal(response.data.content.status_deals);
        setLossReason(response.data.content.loss_reason);
        setTaskDeals(response.data.content.deals);
      })
      .catch((error) => {
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-task", { replace: true });
        }
      });
  };

  const lastRowIndexActivity = currentPageActivity * rowsPerPageActivity;
  const currentDataActivity = filteredActivity.slice(
    (currentPageActivity - 1) * rowsPerPageActivity,
    lastRowIndexActivity
  );

  const handlePageChangeActivity = (pageNumber) => {
    setCurrentPageActivty(pageNumber);
  };

  const columnsActivity = [
    {
      name: "#",
      cell: (row, rowIndex) =>
        (currentPageActivity - 1) * rowsPerPageActivity + rowIndex + 1,
      width: "40px",
    },
    {
      name: "Start Time",
      selector: (row) => moment(row.start_time).format("DD/MM/YYYY, HH:mm"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.start_time).format("YYYY MM DD");
        const dateB = moment(b.start_time).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "110px",
    },
    {
      name: "End Time",
      selector: (row) => moment(row.end_time).format("DD/MM/YYYY, HH:mm"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.end_time).format("YYYY MM DD");
        const dateB = moment(b.end_time).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "110px",
    },
    {
      name: "Activity Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Sales Name",
      selector: (row) => row.sales_name,
      sortable: true,
    },
    {
      name: "Location",
      cell: (row) => (
        <ModalShowMap latitude={row.latitude} longitude={row.longitude} />
      ),
    },
    {
      name: "Attachment",
      cell: (row) => (row.file ? <ShowAttachment file={row.file} /> : "-"),
    },
    {
      name: "Pic",
      cell: (row) =>
        row.pics?.length > 0 ? (
          <ul className="pl-3 pb-0 mb-0">
            {row.pics.map((data) => (
              <li key={data.name}>{data.name}</li>
            ))}
          </ul>
        ) : (
          "-"
        ),
    },
  ];

  const columnsMembers = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "40px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
  ];

  const columnsDeals = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "40px",
    },
    {
      name: "Date Deals",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
    },
    {
      name: "Offering Price",
      selector: (row) => row.offering_price,
      sortable: true,
    },
    {
      name: "Accepted Price",
      selector: (row) => row.accepted_price,
      sortable: true,
    },
  ];

  console.log(taskDeals);
  return (
    <>
      {console.log(process.env.REACT_APP_BASE_URL)}
      <h1 className="page-title">Detail Sales Task</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-task">Sales Task List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Detail Sales Task
        </li>
      </ol>
      <form method="post" className={"mt-4 px-5 form form-employeeform-detail"}>
        <div className="mb-3 row">
          <label
            htmlFor="taskName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Task Name
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="taskName"
              className="form-control"
              id="taskName"
              value={taskName || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="startDate"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Start Date
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="startDate"
              className="form-control"
              id="startDate"
              value={startDate}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="dueDate"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Due Date
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="date"
              name="dueDate"
              className="form-control"
              id="dueDate"
              value={dueDate}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="desc"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Description
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="desc"
              id="desc"
              rows="3"
              className="form-control"
              value={desc || ""}
              disabled
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="hotel"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Hotel
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="hotel"
              className="form-control"
              id="hotel"
              value={hotel}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="partnership"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Partnership
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="partnership"
              className="form-control"
              id="partnership"
              value={companyName}
              disabled
            />
          </div>
        </div>
        {statusDeal !== "Waiting" && (
          <div className="mb-3 row">
            <label
              htmlFor="completionDate"
              className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
            >
              Completion Date
            </label>
            <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
              <input
                type="date"
                name="completionDate"
                className="form-control"
                id="completionDate"
                value={completionDate}
                disabled
              />
            </div>
          </div>
        )}
        <div className="mb-3 row">
          <label
            htmlFor="statusDeals"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Status Deal
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="statusDeals"
              className="form-control"
              id="statusDeals"
              value={statusDeal}
              disabled
            />
          </div>
        </div>

        {statusDeal === "Lose" && (
          <div className="mb-3 row">
            <label
              htmlFor="lossReason"
              className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
            >
              Loss Reason
            </label>
            <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
              <input
                type="text"
                name="lossReason"
                className="form-control"
                id="lossReason"
                value={lossReason}
                disabled
              />
            </div>
          </div>
        )}

        {/* task member */}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-0 p-0">
          Task Member
        </div>
        <div className="datatable mt-4">
          <DataTable
            dense
            columns={columnsMembers}
            data={taskMember}
            highlightOnHover
            responsive
          />
        </div>

        {/* task Deals */}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-0 p-0">
          Task Deals
        </div>
        <div className="datatable mt-4">
          <DataTable
            dense
            columns={columnsDeals}
            data={taskDeals}
            highlightOnHover
            responsive
          />
        </div>

        {/* Task Activity */}
        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-0 p-0">
          Task Activity
        </div>
        <div className="row mb-3">
          <div className="offset-lg-8 offset-md-6 offset-sm-5 col-lg-4 col-md-6 col-sm-7 col-12">
            <input
              type="text"
              className="form-control input-search"
              placeholder="Search.."
              aria-label=""
              aria-describedby="btn-search"
              onChange={(e) => setSearchActivity(e.target.value)}
            />
          </div>
        </div>
        <div className="datatable">
          <DataTable
            dense
            columns={columnsActivity}
            data={currentDataActivity}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={filteredActivity.length}
            paginationDefaultPage={currentPageActivity}
            paginationPerPage={rowsPerPageActivity}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChangeActivity}
            onChangeRowsPerPage={setRowsPerPageActivity}
            responsive
          />
        </div>

        <div className="mb-3 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-task")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Go Back
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default DetailSalesTask;
