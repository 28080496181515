import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ExpandedRow from "./ExpandedRow";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const MarketingStaffList = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");

  const [expandedRowUuid, setExpandedRowUuid] = useState(null);

  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/marketing-staffs`;

  //get marketing staff
  const getData = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
          setData(sortedData);
          setFilteredData(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //search
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const name = data.name?.toLowerCase().match(searchRegex);
      const username = data.username?.toLowerCase().match(searchRegex);

      return name || username;
    });

    setFilteredData(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      id: "email",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Detail Task",
      allowOverflow: true,
      button: true,
      cell: (row) =>
        row.marketing_tasks.length > 0 && (
          <button
            className="btn btn-aksi btn-edit"
            onClick={() => handleRowExpansion(row)}
          >
            {expandedRowUuid === row.uuid_user ? "Hide ▼" : "Show ▶"}
          </button>
        ),
      ignoreRowClick: true,
      width: "150px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const handleRowExpansion = (row) => {
    // If the row is already expanded, collapse it
    if (expandedRowUuid === row.uuid_user) {
      setExpandedRowUuid(null);
    } else {
      // If the row is not expanded, expand it
      setExpandedRowUuid(row.uuid_user);
    }
  };

  const expandableRowsComponent = ({ data }) => (
    <ExpandedRow task={data.marketing_tasks} name={data.name} />
  );

  return (
    <>
      <h1 className="page-title mb-3">Marketing Staff List</h1>
      <div className="row mb-3">
        <div className="align-self-center col-sm-6 col-12">
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredData,
                "Marketing Staff",
                "Marketing Staff",
                "landscape"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredData, "Marketing Staff")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
        <div className="col-sm-6 col-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search by name / username"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredData.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
          expandableRows
          expandableRowsComponent={(row) => expandableRowsComponent(row)}
          expandableRowExpanded={(row) => expandedRowUuid === row.uuid_user}
          onRowExpandToggled={(row) => handleRowExpansion(row)}
          expandableRowsHideExpander
        />
      </div>
    </>
  );
};

export default MarketingStaffList;
