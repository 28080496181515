import React, { useState, useEffect, useCallback } from "react";
import "../../custom-pages-css.scss";
import "./users.scss";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CreatableSelect from "react-select/creatable";

const AddUser = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  //add user
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [employee, setEmployee] = useState(null);
  const [role, setRole] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [department, setDepartment] = useState(null);

  //list
  const [listRole, setListRole] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [listHotel, setListHotel] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);

  const [passwordShown, setPasswordShown] = useState([false, false]);
  const [eyeClass, setEyeClass] = useState([false, false]);

  const togglePassword = (i) => {
    let toggleShow = [...passwordShown];
    let oldShow = toggleShow[i];
    toggleShow[i] = !oldShow;
    setPasswordShown(toggleShow);

    let toggleIcon = [...passwordShown];
    let oldIcon = toggleIcon[i];
    toggleIcon[i] = !oldIcon;
    setEyeClass(toggleIcon);
  };

  const endpointAddUser = `${process.env.REACT_APP_BASE_URL}/users/add`;
  const endpointGetRole = `${process.env.REACT_APP_BASE_URL}/roles`;
  const endpointAddRole = `${process.env.REACT_APP_BASE_URL}/roles/add`;
  const endpointEmployeeNoAcc = `${process.env.REACT_APP_BASE_URL}/employees/no_account`;
  const endpointGetHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointGetDepartment = `${process.env.REACT_APP_BASE_URL}/departments`;

  //role
  const getRole = async () => {
    try {
      await axios
        .get(endpointGetRole, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (role) {
            return { value: role.uuid, label: role.name };
          });
          setListRole(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      }
    }
  };

  const changeRole = (selectedOption) => {
    if (selectedOption) {
      setRole(selectedOption.value);
    } else {
      setRole(null);
    }
  };

  const createRole = useCallback(
    (inputValue) => {
      alertAddRole(inputValue);
    },
    [listRole]
  );

  const alertAddRole = (inputValue) => {
    Swal.fire({
      title: "Create Role",
      html:
        '<div class="row mr-0 ml-0">' +
        '    <input type="text" id="roleName" class="swal2-input col align-self-center" placeholder="Role Name" value="' +
        inputValue +
        '">' +
        "</div>" +
        '<div class="row mr-0 ml-0">' +
        '    <input type="checkbox" id="someCheckbox" class="swal2-checkbox mt-3 ml-5 mr-3 mb-2"> ' +
        '    <label class="mt-3" for="someCheckbox">Can Access All Hotel Data</label></label>' +
        "</div>",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Create",
      preConfirm: () => {
        const roleName = document.getElementById("roleName").value;
        const checkboxValue = document.getElementById("someCheckbox").checked;

        if (!roleName) {
          Swal.showValidationMessage("Role name cannot be empty!");
        } else {
          const check = listRole.filter((data) => {
            return roleName.toLowerCase() === data.label.toLowerCase();
          });
          if (check.length > 0) {
            Swal.showValidationMessage("Role already exist!");
          } else {
            addRole(roleName, checkboxValue);
          }
        }
      },
    });
  };

  const addRole = (value, access) => {
    const roleData = {
      name: value,
      is_data_restricted: access,
      features: [],
    };
    try {
      axios
        .post(endpointAddRole, roleData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully create <b class="text-uppercase">${value}</b> role ${access}`,
            "success"
          );
          getRole();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //employee
  const changeEmployee = (selectedOption) => {
    if (selectedOption) {
      setEmployee(selectedOption.uuid);
    } else {
      setEmployee(null);
    }
  };

  const getEmployee = async () => {
    try {
      await axios
        .get(endpointEmployeeNoAcc, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (employee) {
            return {
              uuid: employee.uuid,
              name: employee.name,
              uuid_hotel: employee.uuid_hotel,
              uuid_department: employee.uuid_department,
            };
          });
          setListEmployee(options);
          setFilteredEmployee(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      }
    }
  };

  //hotel
  const getHotel = async () => {
    try {
      await axios
        .get(endpointGetHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { uuid: hotel.uuid, name: hotel.name };
          });
          setListHotel(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      }
    }
  };

  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.uuid);
    } else {
      setHotel(null);
    }
  };

  useEffect(() => {
    if (listEmployee.length > 0) {
      if (hotel && department) {
        const filter = listEmployee.filter(
          (obj) =>
            obj.uuid_hotel === hotel && obj.uuid_department === department
        );
        setFilteredEmployee(filter);
      } else if (hotel) {
        const filter = listEmployee.filter((obj) => obj.uuid_hotel === hotel);
        setFilteredEmployee(filter);
      } else if (department) {
        const filter = listEmployee.filter(
          (obj) => obj.uuid_department === department
        );
        setFilteredEmployee(filter);
      } else {
        setFilteredEmployee(listEmployee);
      }
    }
  }, [hotel, department]);

  //department
  const getDepartment = async () => {
    try {
      await axios
        .get(endpointGetDepartment, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (dpt) {
            return { uuid: dpt.uuid, name: dpt.name };
          });
          setListDepartment(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      }
    }
  };

  const changeDepartment = (selectedOption) => {
    if (selectedOption) {
      setDepartment(selectedOption.uuid);
    } else {
      setDepartment(null);
    }
  };

  //add user
  const addUser = async (e) => {
    e.preventDefault();
    const userData = {
      username: username,
      password: password,
      uuid_employee: employee,
      uuid_role: role,
    };
    try {
      await axios
        .post(endpointAddUser, userData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully created user with the username <b class="text-uppercase">${username}</b>`,
            "success"
          );
          navigate("/user");
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  useEffect(() => {
    getRole();
    getEmployee();
    getHotel();
    getDepartment();
  }, []);

  return (
    <>
      <h1 className="page-title">Create User</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/user">User List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Create User
        </li>
      </ol>

      <form
        onSubmit={addUser}
        method="post"
        className="mt-4 px-5 form form-users"
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Hotel
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <Select
              placeholder="Select hotel"
              isClearable={true}
              options={listHotel}
              name="uuid_hotel"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.uuid}
              autoFocus={true}
              onChange={changeHotel}
              className="select2"
              id="inputHotel"
            />
            <small>Select hotel to filter employee</small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDepartment"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Department
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <Select
              placeholder="Select department"
              isClearable={true}
              options={listDepartment}
              name="uuid_department"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.uuid}
              autoFocus={true}
              onChange={changeDepartment}
              className="select2"
              id="inputDepartment"
            />
            <small>Select department to filter employee</small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEmployee"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Employee Name <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <Select
              placeholder="Select employee"
              isClearable={true}
              options={filteredEmployee}
              name="uuid_employee"
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.uuid}
              autoFocus={true}
              onChange={changeEmployee}
              className="select2"
              id="inputEmployee"
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputUsername"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Username <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <input
              type="text"
              name="username"
              className="form-control"
              id="inputUsername"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="password"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Password <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <div className="input-group">
              <input
                type={passwordShown[1] ? "text" : "password"}
                name="password"
                id="password"
                className="form-control border-right-0"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                className="btn btn-primary btn-eye"
                type="button"
                onClick={() => togglePassword(1)}
              >
                <span
                  className={eyeClass[1] ? "fas fa-eye-slash" : "fas fa-eye"}
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="confirmPassword"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Confirm Password <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <div className="input-group">
              <input
                type={passwordShown[2] ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                className="form-control border-right-0"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button
                className="btn btn-primary btn-eye"
                type="button"
                onClick={() => togglePassword(2)}
              >
                <span
                  className={eyeClass[2] ? "fas fa-eye-slash" : "fas fa-eye"}
                ></span>
              </button>
            </div>
            {confirmPassword !== "" && password !== confirmPassword && (
              <label htmlFor="confirmPassword" className="mt-2 red form-label">
                Passwords do not match
              </label>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputRole"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Role <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <CreatableSelect
              placeholder="Select role"
              isClearable={true}
              value={listRole.find((obj) => obj.value === role)}
              options={listRole}
              onChange={changeRole}
              onCreateOption={createRole}
              className="select2"
              id="inputRole"
              autoFocus={true}
              name="uuid_role"
              required
            />
          </div>
        </div>

        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/user")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Cancel
            </button>
            <button
              className="btn btn-primary btn-blue"
              type="submit"
              disabled={password !== confirmPassword ? "disabled" : ""}
            >
              <i className="fa fa-plus-square mr-2"></i>
              Create
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddUser;
