import React from "react";

export function generateMonthOptions() {
  const monthOptions = [];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  for (let month = 1; month <= 12; month++) {
    const monthValue = month.toString().padStart(2, "0"); // Pad month value with leading zero if needed
    monthOptions.push({
      value: monthValue,
      label: months[month - 1],
    });
  }

  return monthOptions;
}
