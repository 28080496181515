import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import "../../custom-pages-css.scss";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const SocialMedia = (props) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const [platformList, setPlatformList] = useState([]);

  const [accountName, setAccountName] = useState("");
  const [accountPlatform, setAccountPlatform] = useState();

  const endpointUpdate = `${process.env.REACT_APP_BASE_URL}/employees/edit/`;
  const endpointPlatform = `${process.env.REACT_APP_BASE_URL}/platforms/`;

  const navigate = useNavigate();

  const getPlatform = () => {
    try {
      axios
        .get(endpointPlatform, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const option = data
            .filter((platform) => platform.type === "Social Media")
            .map((filteredPlatform) => {
              return {
                value: filteredPlatform.uuid,
                label: filteredPlatform.name,
              };
            });
          setPlatformList(option);
        });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  //selected platform
  const changeAccountPlatform = (selectedOption) => {
    if (selectedOption) {
      setAccountPlatform(selectedOption.value);
    } else {
      setAccountPlatform(null);
    }
  };

  //add social media
  const addSocialMedia = (e) => {
    e.preventDefault();

    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid_employee = stringifyUserData["uuid_employee"];
      const arrayPlatform = platformList.find(
        (obj) => obj.value === accountPlatform
      );
      const check = props.socialMedia.filter(
        (obj) => obj.uuid === accountPlatform
      );
      if (check.length > 0) {
        Swal.fire(
          "Failed to Add Social Media!",
          `${arrayPlatform.label} account already exists`,
          "error"
        );
      } else {
        props.socialMedia.push({
          uuid: accountPlatform,
          name: accountName,
        });
        const employeeData = {
          name: props.employee.name,
          address: props.employee.address,
          phone_number: props.employee.phone_number,
          email: props.employee.email,
          position: props.employee.position,
          uuid_hotel: props.employee.hotel.uuid,
          uuid_department: props.employee.department.uuid,
          is_active: props.employee.is_active,
          social_media: props.socialMedia,
        };
        try {
          axios
            .put(endpointUpdate + uuid_employee, employeeData, {
              headers: {
                Authorization: token,
              },
            })
            .then(() => {
              Swal.fire(
                "Success!",
                `Successfully added <b class="text-uppercase">${arrayPlatform.label}</b> account with the username <b class="text-uppercase">${accountName}</b>`,
                "success"
              );
              setAccountName("");
              props.getUserDetail();
            });
        } catch (error) {
          console.log(error.response.data.message);
          if (
            error.response.data.status === 401 ||
            error.response.data.status === 403
          ) {
            navigate("/404", { replace: true });
          } else if (error.response.data.status === 400) {
            Swal.fire("Failed!", `${error.response.data.message}`, "error");
          }
        }
      }
    }
  };

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "60px",
    },
    {
      name: "Social Media",
      selector: (row) =>
        platformList.map((platform) => {
          if (platform.value === row.uuid) {
            return platform.label;
          }
        }),
    },
    {
      name: "Username",
      selector: (row) => row.name,
      id: "email",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            className="btn btn-aksi btn-delete"
            onClick={alertDeleteSosmed(row.uuid, row.name)}
          >
            Delete
          </button>
        </div>
      ),
      id: "aksi",
      width: "130px",
    },
  ];

  //alert delete sosmed
  const alertDeleteSosmed = (uuid, name) => () => {
    const arrayPlatform = platformList.find((obj) => obj.value === uuid);
    Swal.fire({
      title: "Delete Social Media Data?",
      html: `Are you sure want to delete <b class="text-uppercase">${arrayPlatform.label}</b> account with the username <b class="text-uppercase"> ${name} </b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        const newData = props.socialMedia.filter((a) => a.uuid !== uuid);
        deleteSosmed(newData, arrayPlatform.label, name);
      }
    });
  };

  //function delete sosmed
  const deleteSosmed = (newData, platform, name) => {
    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid_employee = stringifyUserData["uuid_employee"];
      const employeeData = {
        name: props.employee.name,
        address: props.employee.address,
        phone_number: props.employee.phone_number,
        email: props.employee.email,
        position: props.employee.position,
        uuid_hotel: props.employee.hotel.uuid,
        uuid_department: props.employee.department.uuid,
        is_active: props.employee.is_active,
        social_media: newData,
      };
      try {
        axios
          .put(endpointUpdate + uuid_employee, employeeData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully deleted <b class="text-uppercase">${platform}</b> account with the username <b class="text-uppercase">${name}</b>`,
              "success"
            );
            props.getUserDetail();
          });
      } catch (error) {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 400) {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        }
      }
    }
  };

  useEffect(() => {
    getPlatform();
  }, []);

  return (
    <>
      <div className="card my-4">
        <div className="card-header">Add Social Media</div>
        <div className="card-body">
          <form
            method="post"
            onSubmit={addSocialMedia}
            className="mt-4 px-5 form form-profile"
          >
            <div className="mb-3 row">
              <label
                htmlFor="inputSosmed"
                className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
              >
                Social Media
              </label>
              <div className="col-xl-10 col-lg-9 col-md-9 col-sm-12">
                <div className="row">
                  <div className="col-lg-6">
                    <Select
                      placeholder="Select platform"
                      options={platformList}
                      onChange={changeAccountPlatform}
                      value={platformList.find(
                        (obj) => obj.value === accountPlatform
                      )}
                      className="select2"
                      id="inputSosmed"
                      autoFocus={true}
                      name="uuid_sosmed"
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      placeholder="Username"
                      type="text"
                      className="form-control"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="col-12 text-right">
                <button className="btn btn-primary btn-blue" type="submit">
                  <i className="fa fa-plus-square mr-2"></i>
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {props.socialMedia.length > 0 && (
        <div className="datatable">
          <DataTable
            dense
            columns={columns}
            data={props.socialMedia}
            highlightOnHover
            pagination
            responsive
          />
        </div>
      )}
    </>
  );
};

export default SocialMedia;
