import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import "../../pages/custom-pages-css.scss";
import { formatMoney } from "../FormatMoneyToRupiah";
import DataTable from "react-data-table-component";
import { generateYearOptions } from "../GenerateYearOption";
import { generateMonthOptions } from "../GenerateMonthOptions";

const SalesRepPerformance = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [hotelList, setHotelList] = useState([]);
  const [hotel, setHotel] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions();

  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  const getHotelList = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const filterByHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.label);
    } else {
      setHotel("");
    }
  };

  const getSalesRepPerformance = async (selectedYear, selectedMonth) => {
    try {
      setLoading(true);
      let endpointSalesRep = `${process.env.REACT_APP_BASE_URL}/dashboards/sales-rep-performance`;

      if (selectedYear && selectedMonth) {
        endpointSalesRep += `?yearMonth=${selectedYear}-${selectedMonth}`;
      } else if (selectedYear) {
        endpointSalesRep += `?year=${selectedYear}`;
      }

      await axios
        .get(endpointSalesRep, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setTimeout(() => {
            setLoading(false);
            const data = response.data.content;
            const sortedData = data.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
            setData(sortedData);
            setFilteredData(sortedData);
          }, 500);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");

      return (
        (!hotel || data.hotel === hotel) &&
        (!search || data.name.toLowerCase().match(searchRegex))
      );
    });

    setFilteredData(result);
  }, [search, hotel]);

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "180px",
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
      width: "180px",
    },
    {
      name: "Activity",
      selector: (row) => row.num_of_activities,
      sortable: true,
      width: "120px",
    },
    {
      name: "Task",
      selector: (row) => row.num_of_tasks,
      sortable: true,
    },
    {
      name: "Score",
      selector: (row) => row.score,
      sortable: true,
    },
    {
      name: "Deal",
      selector: (row) => row.num_of_deals,
      sortable: true,
    },
    {
      name: "Revenue",
      selector: (row) => formatMoney(row.revenue_generated),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.revenue_generated) - parseInt(b.revenue_generated);
      },
      width: "180px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const filterByYear = (selectedOption) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
    } else {
      setSelectedYear("");
    }
  };

  const filterByMonth = (selectedOption) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
    } else {
      setSelectedMonth("");
    }
  };

  useEffect(() => {
    if (show) {
      getHotelList();
      getSalesRepPerformance(selectedYear, selectedMonth);
    }
  }, [show, selectedYear, selectedMonth]);

  return (
    <>
      <div className="col-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse10"
            role="button"
            aria-expanded="false"
            aria-controls="collapse10"
            onClick={() => setShow(true)}
          >
            Sales Representative Performance
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse10">
            <div className="row filter-search p-3">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={yearOptions}
                  value={yearOptions.find((obj) => obj.value === selectedYear)}
                  id="filterYear"
                  className="select2"
                  name="filterYear"
                  isClearable={true}
                  placeholder="Filter by year"
                  onChange={filterByYear}
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={monthOptions}
                  value={monthOptions.find(
                    (obj) => obj.value === selectedMonth
                  )}
                  id="filterMonth"
                  className="select2 input-search"
                  name="filterMonth"
                  isClearable={true}
                  placeholder="Filter by month"
                  onChange={filterByMonth}
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  placeholder="Filter by hotel"
                  isClearable={true}
                  options={hotelList}
                  name="filterByHotel"
                  value={hotelList.find((obj) => obj.label === hotel)}
                  onChange={filterByHotel}
                  className="select2 input-search"
                  id="filterByHotel"
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <input
                  type="text"
                  className="form-control input-search"
                  placeholder="Search by name"
                  aria-label="Recipient's username"
                  aria-describedby="btn-search"
                  id="search"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="px-3 pb-3 w-100 d-flex align-items-center justify-content-center">
              {loading ? (
                "Loading..."
              ) : filteredData.length > 0 ? (
                <div className="w-100 datatable">
                  <DataTable
                    dense
                    columns={columns}
                    data={currentData}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={filteredData.length}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    responsive
                  />
                </div>
              ) : (
                "There is no data to display"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesRepPerformance;
