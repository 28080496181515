import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../pages/dashboard/dashboard.scss";
import "../../pages/custom-pages-css.scss";
import { formatMoney } from "../FormatMoneyToRupiah";
import { generateYearOptions } from "../GenerateYearOption";
import { generateMonthOptions } from "../GenerateMonthOptions";

const OtaRevenue = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const pageSizeOptions = [10, 20, 30, 40, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

  const [hotelList, setHotelList] = useState([]);
  const [hotel, setHotel] = useState("");

  const currentYear = new Date().getFullYear().toString();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState("");
  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions();

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  const getHotelList = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const filterByHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel("");
    }
  };

  const getOtaRevenue = async (hotel) => {
    try {
      let endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/ota-revenue`;

      if (selectedYear && selectedMonth) {
        endpoint += `?yearMonth=${selectedYear}-${selectedMonth}`;
      } else if (selectedYear) {
        endpoint += `?year=${selectedYear}`;
      }

      if (hotel) {
        endpoint += `&uuid_hotel=${hotel}`;
      }

      const response = await axios.get(endpoint, {
        headers: { Authorization: token },
      });

      setTimeout(() => {
        setLoading(false);
        const data = response.data.content;
        setData(data);
        setFilteredData(data);
      }, 500);
    } catch (error) {
      if (error.response.data.status === 500) {
        setData([]);
      } else {
        console.log(error.response.data.message);
        navigate("/404", { replace: true });
      }
    }
  };

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = filteredData.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  const filterByYear = (selectedOption) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
    } else {
      setSelectedYear("");
    }
  };

  const filterByMonth = (selectedOption) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
    } else {
      setSelectedMonth("");
    }
  };

  useEffect(() => {
    if (show) {
      getHotelList();
      getOtaRevenue(hotel);
    }
  }, [show, hotel, selectedYear, selectedMonth]);

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.reduce((filtered, row) => {
      const filteredOTA = row.ota.filter((item) => {
        const searchRegex = new RegExp(escapeRegExp(search), "i");
        return searchRegex.test(item.OTAName);
      });

      if (filteredOTA.length > 0) {
        filtered.push({ ...row, ota: filteredOTA });
      }

      return filtered;
    }, []);

    setFilteredData(result);
  }, [search, data]);

  return (
    <>
      <div className="col-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse15"
            role="button"
            aria-expanded="false"
            aria-controls="collapse15"
            onClick={() => setShow(true)}
          >
            OTA Revenue
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse15">
            <div className="row filter-search p-3">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={yearOptions}
                  value={yearOptions.find((obj) => obj.value === selectedYear)}
                  id="filterYear"
                  className="select2"
                  name="filterYear"
                  placeholder="Filter by year"
                  onChange={filterByYear}
                  isClearable={false}
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  options={monthOptions}
                  value={monthOptions.find(
                    (obj) => obj.value === selectedMonth
                  )}
                  id="filterMonth"
                  className="select2 input-search"
                  name="filterMonth"
                  isClearable={true}
                  placeholder="Filter by month"
                  onChange={filterByMonth}
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <Select
                  placeholder="Filter by hotel"
                  isClearable={true}
                  options={hotelList}
                  name="filterByHotel"
                  value={hotelList.find((obj) => obj.label === hotel)}
                  onChange={filterByHotel}
                  className="select2 input-search"
                  id="filterByHotel"
                  classNamePrefix="overflow-select"
                />
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <input
                  type="text"
                  className="form-control input-search"
                  placeholder="Search by name"
                  aria-label="Recipient's username"
                  aria-describedby="btn-search"
                  id="search"
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="px-3 pb-3 text-center">
              {loading ? (
                "Loading..."
              ) : filteredData.length > 0 ? (
                <>
                  <div className="table table-responsive text-left mb-0">
                    <table className="table table-sm table-bordered custom-table">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center" width="50px">
                            #
                          </th>
                          <th scope="col">Hotel</th>
                          <th scope="col">OTA Name</th>
                          <th scope="col">Total Room Nights</th>
                          <th scope="col">Total Room Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((row, i) => (
                          <React.Fragment key={row.name}>
                            {row.ota.length > 0 &&
                              row.ota.map((item, j) => (
                                <tr key={`${row.name}-${j}`}>
                                  {j === 0 ? (
                                    <>
                                      <td
                                        className="text-center"
                                        rowSpan={row.ota.length}
                                      >
                                        {(currentPage - 1) * pageSize + i + 1}
                                      </td>
                                      <td rowSpan={row.ota.length}>
                                        {row.name}
                                      </td>
                                    </>
                                  ) : null}
                                  <td>{item.OTAName}</td>
                                  <td>{item.TotalRoomNights}</td>
                                  <td>{formatMoney(item.TotalRoomRev)}</td>
                                </tr>
                              ))}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between pagination-table">
                    <div>
                      Showing {firstIndex + 1} to{" "}
                      {Math.min(lastIndex, data.length)} of {data.length}{" "}
                      entries
                    </div>
                    <div>
                      <select
                        className="form-control form-control-sm"
                        value={pageSize}
                        onChange={handlePageSizeChange}
                      >
                        {pageSizeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="pagination pagination-custom justify-content-center">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      &lt;&lt;
                    </button>
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      &lt;
                    </button>
                    <button className="page-link active">{currentPage}</button>
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        data.length === 0 ||
                        currentPage === Math.ceil(data.length / pageSize)
                      }
                    >
                      &gt;
                    </button>
                    <button
                      className="page-link"
                      onClick={() =>
                        handlePageChange(Math.ceil(data.length / pageSize))
                      }
                      disabled={
                        data.length === 0 ||
                        currentPage === Math.ceil(data.length / pageSize)
                      }
                    >
                      &gt;&gt;
                    </button>
                  </div>
                </>
              ) : (
                "There is no data to display"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtaRevenue;
