import React, { useEffect, useRef, useState } from "react";
import "./chat-request/css-chat.scss";
import moment from "moment";
import axios from "axios";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";

const HistoryChat = () => {
  const navigate = useNavigate();

  const chatContainerRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState({});
  const [date, setDate] = useState("");

  const pathSegments = window.location.pathname.split("/");
  const uuidChatRequest = pathSegments[pathSegments.length - 1];

  const token = localStorage.getItem("token");
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/chat-requests/conversation/${uuidChatRequest}`;
  const endpointExport = `${process.env.REACT_APP_BASE_URL}/live-conversations/${uuidChatRequest}/export`;

  useEffect(() => {
    adjustChatContainerHeight();

    window.addEventListener("resize", adjustChatContainerHeight);
    return () => {
      window.removeEventListener("resize", adjustChatContainerHeight);
    };
  });

  useEffect(() => {
    getChatRequestDetail();
  }, []);

  const getChatRequestDetail = async () => {
    try {
      await axios
        .get(endpointDetail, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setData(response.data.content);
          setMessages(response.data.content.live_conversations);
          setUserName(response.data.content.name);
          setUserPhoneNumber(response.data.content.phone_number);
          setDate(response.data.content.handle_at);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const exportChat = async () => {
    try {
      await axios
        .get(endpointExport, {
          responseType: "arraybuffer",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          handleExport(response.data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const handleExport = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const a = document.createElement("a");
    const dateChat = moment(date).format("DD-MMM-YYYY");

    a.href = url;
    a.download = `Chat-${dateChat}-${userName}.xlsx`; // Set the desired file name

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  };

  const adjustChatContainerHeight = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer.scrollHeight > chatContainer.clientHeight) {
      chatContainer.classList.add("scrollable");
    } else {
      chatContainer.classList.remove("scrollable");
    }
  };

  function formattedTime(ISODateString) {
    const date = new Date(ISODateString);
    const localDate = new Date(date.getTime() + 7 * 60000); // Adjust for the local timezone offset
    return localDate.toLocaleString(); // Convert to local time format
  }

  function getNameFile(name) {
    const keyword = "media/documents/conversations/";
    const fileName = name.substring(keyword.length);
    return fileName;
  }

  return (
    <>
      <div className="full-screen-container">
        <div className="user-info">
          <div className="no-tlp">{userPhoneNumber}</div>
          <div className="nama">{userName}</div>
        </div>
        <div className="chat-container" ref={chatContainerRef}>
          {messages
            .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
            .map((message, index) => (
              <div className="row">
                <div
                  key={index}
                  className={`col-xl-5 col-md-5 col-sm-7 col-10 
            offset-xl-${message.sender === "Admin" ? "7" : "0"}
            offset-md-${message.sender === "Admin" ? "7" : "0"} offset-sm-${
                    message.sender === "Admin" ? "5" : "0"
                  } offset-${message.sender === "Admin" ? "2" : "0"}`}
                >
                  <div
                    className={`chat-message p-3 ${
                      message.sender === "Customer"
                        ? "receiver-message"
                        : "sender-message"
                    }`}
                  >
                    {message.type === "text" ? (
                      <p className="message-text mb-0">{message.message}</p>
                    ) : (
                      <a
                        href={
                          process.env.REACT_APP_BASE_URL + "/" + message.message
                        }
                      >
                        {getNameFile(message.message)}
                      </a>
                    )}

                    <div className="message-time text-right">
                      {formattedTime(message.timestamp)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="div-input-chat">
          <div className="row w-100 m-0 p-3 div-input-chat">
            <div className="col-sm-2 col-md-12 p-0 col-btn-end">
              <button
                className="btn btn-primary btn-edit w-100"
                onClick={exportChat}
              >
                Export <span className="button-name">Chat</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryChat;
