import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../components/CheckFeature";

const InstagramAccountList = (props) => {
  const [accountList, setAccountList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/instagram-posts/accounts`;
  const endpointSync = `${process.env.REACT_APP_BASE_URL}/instagram-posts/accounts/sync`;

  //check features
  // const hasFeatureSync = CheckFeature({
  //   name: "Sync Instagram Account",
  // });

  //get account
  const getAccount = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setAccountList(data);
          setFilteredAccount(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  //search akun
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = accountList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const nameMatch = data.name?.toLowerCase().match(searchRegex);
      const usernameMatch = data.username?.toLowerCase().match(searchRegex);

      return nameMatch || usernameMatch;
    });

    setFilteredAccount(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
    },
    {
      name: "Username",
      selector: (row) => row.username,
      id: "capitalizeUnset",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredAccount.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const accountSync = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .post(
          endpointSync,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          Swal.fire({
            title: "Success!",
            html: `successfully sync instagram account`,
            icon: "success",
          }).then((result) => {
            getAccount();
          });
        })
        .catch((error) => {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Please wait..",
        html: `Instagram account sync in progress`,
        showCancelButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didRender: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading]);

  return (
    <>
      <h1 className="page-title mb-3">Instagram Account</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-12">
          {/* {hasFeatureSync && ( */}
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={(e) => accountSync(e)}
          >
            <i className="fa fa-sync mr-2"></i>
            Instagram Account Sync
          </button>
          {/* )} */}
        </div>
        <div className="col-lg-4 col-md-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search.."
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredAccount.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default InstagramAccountList;
