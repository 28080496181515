import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BackToTop from "../../components/BackToTop";
import axios from "axios";
import { isExpired, decodeToken } from "react-jwt";
import Swal from "sweetalert2";
import $ from "jquery";

const Layout = (props) => {
  const [styleToggled, setToggled] = useState(
    "navbar-nav bg-white sidebar sidebar-white accordion"
  );

  const changeToggled = () => {
    styleToggled === "navbar-nav bg-white sidebar sidebar-white accordion"
      ? setToggled(styleToggled + " toggled")
      : setToggled("navbar-nav bg-white sidebar sidebar-white accordion");
  };

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("user");

  const myDecodedToken = decodeToken(token);
  const isMyTokenExpired = isExpired(token);

  const [featureChatRequest, setFeatureChatRequest] = useState(false);

  //check if token expired
  useEffect(() => {
    if (token === null) {
      // localStorage.clear();
      navigate("/", { replace: true });
    } else if (isMyTokenExpired) {
      Swal.fire(
        "Token Expired!",
        "The token has expired. Please log in again.",
        "error"
      );
      localStorage.clear();
      navigate("/", { replace: true });
    }
  }, [token, isMyTokenExpired]);

  const logout = () => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/auth/logout`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          localStorage.clear();
          props.setIsLogout(true);
          Swal.fire("Logout successful!", "", "success");
          navigate("/", { replace: true });
        });
    } catch (error) {
      Swal.fire("Logout successful!", error.response.data.message, "error");
    }
  };

  const alertLogout = () => {
    Swal.fire({
      title: "Are you sure want to exit the system?",
      text: "You need to log in again to access the system.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  const getUserData = () => {
    if (userData !== null) {
      var stringifyUserData = JSON.parse(userData);
      props.setUserData(stringifyUserData);
    }
  };

  useEffect(() => {
    if (!token) {
      getUserData();
      props.setToken(token);
    }
  }, []);

  useEffect(() => {
    if (featureChatRequest) {
      props.setHasFeatureChatRequest(true);
    }
  }, [featureChatRequest]);

  return (
    <div id="page-top">
      <div id="wrapper">
        <Sidebar
          styleToggled={styleToggled}
          changeToggled={changeToggled}
          feature={props.feature}
          setFeatureChatRequest={setFeatureChatRequest}
        />
        <div
          id="content-wrapper"
          className="d-flex flex-column bg-custom-white"
          onClick={() => {
            const elements = document.getElementsByClassName(
              "collapse-sidebar collapse show"
            );

            const navLink = document.getElementsByClassName(
              "nav-link-sidebar nav-link"
            );

            for (const element of elements) {
              element.classList.add("hide");
              element.classList.remove("show");
            }

            for (const link of navLink) {
              link.classList.add("collapsed");
            }
          }}
        >
          <div id="content">
            <Navbar
              changeToggled={changeToggled}
              alertLogout={alertLogout}
              newChatRequest={props.newChatRequest}
              newReviews={props.newReviews}
              newMessage={props.newMessage}
            />

            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <BackToTop />
    </div>
  );
};

export default Layout;
