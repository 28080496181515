import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const FormTambah = ({ onRefresh }) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const [responseMessageType, setResponseMessageType] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [invalidMessage, setInvalidMessage] = useState(false);

  const endpointAddResponseMessage = `${process.env.REACT_APP_BASE_URL}/response-messages/add`;

  const addResponseMessage = async (e) => {
    e.preventDefault();
    const data = {
      message: responseMessage,
      type: responseMessageType,
    };

    try {
      await axios
        .post(endpointAddResponseMessage, data, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          setResponseMessage("");
          setResponseMessageType("");
          Swal.fire(
            "Success!",
            `Successfully added a response message of the <b class="text-uppercase">${responseMessageType}</b> type`,
            "success"
          ).then(function () {
            onRefresh();
          });
        })
        .catch((error) => {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  const responseMessageTypeList = [
    { value: "Welcome", label: "Welcome" },
    { value: "Invalid", label: "Invalid" },
    { value: "Waiting Agent", label: "Waiting Agent" },
    { value: "End Session", label: "End Session" },
    { value: "Try", label: "Try" },
  ];

  const changeResponseMessageType = (selectedOption) => {
    if (selectedOption) {
      setResponseMessageType(selectedOption.value);
    } else {
      setResponseMessageType(null);
    }
  };

  const handleResponseChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 4000) {
      setInvalidMessage(false);
    } else {
      setInvalidMessage(true);
    }
    setResponseMessage(inputValue);
  };

  return (
    <>
      <div className="card my-4">
        <div className="card-header">Add Response Message</div>
        <div className="card-body">
          <form
            method="post"
            onSubmit={addResponseMessage}
            className="mt-4 px-5 form form-profile"
          >
            <div className="mb-3 row">
              <label
                htmlFor="inputResponseMessage"
                className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-form-label"
              >
                Response Message Type <i className="red">*</i>
              </label>
              <div className="col">
                <Select
                  placeholder="Select type"
                  options={responseMessageTypeList}
                  onChange={changeResponseMessageType}
                  value={
                    responseMessageType
                      ? responseMessageTypeList.find(
                          (obj) => obj.value === responseMessageType
                        )
                      : null
                  }
                  className="select2"
                  id="inputResponseMessageType"
                  isClearable={true}
                  name="responseMessageType"
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="inputResponseMessage"
                className="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-form-label"
              >
                Message <i className="red">*</i>
              </label>
              <div className="col">
                <textarea
                  placeholder="Message"
                  className="form-control"
                  rows={3}
                  value={responseMessage || ""}
                  onChange={handleResponseChange}
                  required
                ></textarea>
                {invalidMessage && (
                  <small className="red">
                    Message cannot exceed 4000 characters
                  </small>
                )}
              </div>
            </div>
            <div className="mb-3">
              <i className="red">*</i> Required
            </div>
            <div className="mb-3 row">
              <div className="col-12 text-right">
                <button
                  className="btn btn-primary btn-blue"
                  type="submit"
                  disabled={invalidMessage}
                >
                  <i className="fa fa-plus-square mr-2"></i>
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormTambah;
