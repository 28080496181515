import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";

const Sidebar = ({
  styleToggled,
  changeToggled,
  feature,
  setFeatureChatRequest,
}) => {
  const checkFeature = (name) => {
    const result = feature.filter((data) => {
      return data.name.toLowerCase() === name.toLowerCase();
    });
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (checkFeature("View Chat Request") === true) {
      setFeatureChatRequest(true);
    }
    //console.log(feature);

  });

  return (
    <>
      <ul className={styleToggled} id="accordionSidebar">
        <a
          className="sidebar-brand d-flex align-items-end justify-content-center mb-4"
          href="/dashboard"
        >
          <img
            className="img-profile"
            src="./img/jxb-black.png"
            width={"50px"}
            alt="logo"
          />
          <div className="sidebar-brand-text mx-3 ">CRM App</div>
        </a>

        <NavLink className="nav-item my-1" to="dashboard">
          <div className="nav-link">
            <i className="fas fa-th-large mr-2"></i>
            <span>Dashboard</span>
          </div>
        </NavLink>

        {checkFeature("view employee") && (
          <NavLink className="nav-item my-1" to="employee">
            <div className="nav-link">
              <i className="fas fa-user-tie mr-2"></i>
              <span>Employee Management</span>
            </div>
          </NavLink>
        )}

        {(checkFeature("view department") ||
          checkFeature("view hotel") ||
          checkFeature("view product")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseHotel"
                aria-expanded="true"
                aria-controls="collapseHotel"
              >
                <i className="fas fa-hotel mr-2"></i>
                <span>Hotel Management</span>
              </a>
              <div
                id="collapseHotel"
                className="collapse-sidebar collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("view department") && (
                    <NavLink className="collapse-item" to="department">
                      Departments
                    </NavLink>
                  )}
                  {checkFeature("view hotel") && (
                    <NavLink className="collapse-item" to="hotel">
                      Hotels
                    </NavLink>
                  )}
                  {checkFeature("view product") && (
                    <NavLink className="collapse-item" to="product">
                      Products
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {(checkFeature("view person in charge") ||
          checkFeature("view partnership")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseAsset"
                aria-expanded="true"
                aria-controls="collapseAsset"
              >
                <i className="fas fa-handshake mr-2"></i>
                <span>Partnership Management</span>
              </a>
              <div
                id="collapseAsset"
                className="collapse-sidebar collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("view partnership") && (
                    <NavLink className="collapse-item" to="partnership">
                      Partnerships
                    </NavLink>
                  )}
                  {checkFeature("view person in charge") && (
                    <NavLink className="collapse-item" to="pic">
                      Person In Charge
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {checkFeature("view customer") && (
          <NavLink className="nav-item my-1" to="customer">
            <div className="nav-link">
              <i className="fas fa-users mr-2"></i>
              <span>Customer Management</span>
            </div>
          </NavLink>
        )}

        {checkFeature("view review") && (
          <NavLink className="nav-item my-1" to="customer-review">
            <div className="nav-link">
              <i className="fas fa-comments mr-2"></i>
              <span>Customer Reviews</span>
            </div>
          </NavLink>
        )}

        {(checkFeature("view message template") ||
          checkFeature("view broadcast") ||
          checkFeature("View Scheduled Broadcast") ||
          checkFeature("View Waiting Approval Broadcast") ||
          checkFeature("View Sent Broadcast") ||
          checkFeature("View Rejected Broadcast")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseBroadcast"
                aria-expanded="true"
                aria-controls="collapseBroadcast"
              >
                <i className="fas fa-bullhorn mr-2"></i>
                <span>WhatsApp Broadcast</span>
              </a>
              <div
                id="collapseBroadcast"
                className="collapse-sidebar collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("view message template") && (
                    <NavLink
                      className="collapse-item"
                      to="whatsapp-broadcast-template"
                    >
                      Broadcast Template
                    </NavLink>
                  )}
                  {checkFeature("view broadcast") && (
                    <NavLink
                      className="collapse-item"
                      to="whatsapp-broadcast/all"
                    >
                      All Broadcasts
                    </NavLink>
                  )}
                  {checkFeature("View Scheduled Broadcast") && (
                    <NavLink
                      className="collapse-item"
                      to="whatsapp-broadcast/scheduled"
                    >
                      Scheduled Broadcast
                    </NavLink>
                  )}
                  {checkFeature("View Waiting Approval Broadcast") && (
                    <NavLink
                      className="collapse-item"
                      to="whatsapp-broadcast/waiting-approval"
                    >
                      Waiting Approval Broadcast
                    </NavLink>
                  )}
                  {checkFeature("View Sent Broadcast") && (
                    <NavLink
                      className="collapse-item"
                      to="whatsapp-broadcast/sent"
                    >
                      Sent Broadcast
                    </NavLink>
                  )}
                  {checkFeature("View Rejected Broadcast") && (
                    <NavLink
                      className="collapse-item"
                      to="whatsapp-broadcast/rejected"
                    >
                      Rejected Broadcast
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {/* {(checkFeature("View Instagram Post") ||
          checkFeature("View Scheduled Instagram Post") ||
          checkFeature("View Waiting Approval Instagram Post") ||
          checkFeature("View Sent Instagram Post") ||
          checkFeature("View Rejected Instagram Post")) && (
          <li className="nav-item my-1">
            <a
              className="nav-link collapsed"
              href="/#"
              data-toggle="collapse"
              data-target="#collapseInstagram"
              aria-expanded="true"
              aria-controls="collapseInstagram"
            >
              <i className="fab fa-instagram mr-2"></i>
              <span>Instagram Post</span>
            </a>
            <div
              id="collapseInstagram"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {checkFeature("View Instagram Post") && (
                  <NavLink className="collapse-item" to="instagram-post/all">
                    All Instagram Posts
                  </NavLink>
                )}
                {checkFeature("View Scheduled Instagram Post") && (
                  <NavLink
                    className="collapse-item"
                    to="instagram-post/scheduled"
                  >
                    Scheduled Instagram Post
                  </NavLink>
                )}
                {checkFeature("View Waiting Approval Instagram Post") && (
                  <NavLink
                    className="collapse-item"
                    to="instagram-post/waiting-approval"
                  >
                    Waiting Approval Instagram Post
                  </NavLink>
                )}
                {checkFeature("View Sent Instagram Post") && (
                  <NavLink className="collapse-item" to="instagram-post/sent">
                    Sent Instagram Post
                  </NavLink>
                )}
                {checkFeature("View Rejected Instagram Post") && (
                  <NavLink
                    className="collapse-item"
                    to="instagram-post/rejected"
                  >
                    Rejected Instagram Post
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )} */}

        {checkFeature("View Chat Request") && (
          <li className="nav-item my-1">
            <a
              className="nav-link-sidebar nav-link collapsed"
              href="/#"
              data-toggle="collapse"
              data-target="#collapseCs"
              aria-expanded="true"
              aria-controls="collapseCs"
            >
              <i className="fas fa-headset mr-2"></i>
              <span>Customer Service</span>
            </a>
            <div
              id="collapseCs"
              className="collapse-sidebar collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {checkFeature("View Chat Request") && (
                  <NavLink className="collapse-item" to="whatsapp-chat-request">
                    Chat Request
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )}

        {(checkFeature("view marketing project") ||
          checkFeature("view marketing task") ||
          checkFeature("view marketing staff")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseMarketing"
                aria-expanded="true"
                aria-controls="collapseMarketing"
              >
                <i className="fas fa-dollar-sign mr-2"></i>
                <span>Marketing</span>
              </a>
              <div
                id="collapseMarketing"
                className="collapse-sidebar collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("view marketing project") && (
                    <NavLink className="collapse-item" to="marketing-project">
                      Marketing Project
                    </NavLink>
                  )}
                  {checkFeature("view marketing task") && (
                    <NavLink className="collapse-item" to="marketing-task">
                      Marketing Task
                    </NavLink>
                  )}
                  {checkFeature("view marketing staff") && (
                    <NavLink className="collapse-item" to="marketing-staff">
                      Marketing Staff
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {checkFeature("View Product Knowledge") && (
          <NavLink className="nav-item my-1" to="product-knowledge">
            <div className="nav-link">
              <i className="fas fa-book mr-2"></i>
              <span>Product Knowledge</span>
            </div>
          </NavLink>
        )}

        {checkFeature("View Room Charting") && (
          <NavLink className="nav-item my-1" to="room-charting">
            <div className="nav-link">
              <i className="fas fa-inbox mr-2"></i>
              <span>Room Charting</span>
            </div>
          </NavLink>
        )}

        {(checkFeature("View Meeting Room") ||
          checkFeature("View Booking Room")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseMyFuntionBlocking"
                aria-expanded="true"
                aria-controls="collapseMyFuntionBlocking"
              >
                <i class="fas fa-people-arrows mr-2"></i>
                <span>Function Blocking</span>
              </a>
              <div
                id="collapseMyFuntionBlocking"
                className="collapse-sidebar collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("View Meeting Room") && (
                    <NavLink
                      className="collapse-item"
                      to="function-block/meeting-room"
                    >
                      Meeting Room
                    </NavLink>
                  )}

                  {checkFeature("View Booking Room") && (
                    <NavLink className="collapse-item" to="function-block/booking-room">
                      Booking Room
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {(checkFeature("View Sales Activity") ||
          checkFeature("View Sales Activity Status") ||
          checkFeature("View Sales Activity Type") ||
          checkFeature("View Sales Activity Event")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseMySalesActivity"
                aria-expanded="true"
                aria-controls="collapseMySalesActivity"
              >
                <i class="fas fa-shopping-bag mr-2"></i>
                <span>Sales Activity</span>
              </a>
              <div
                id="collapseMySalesActivity"
                className="collapse-sidebar collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("View Sales Activity") && (
                    <NavLink
                      className="collapse-item"
                      to="sales-activity/manage"
                    >
                      Manage Sales Activity
                    </NavLink>
                  )}

                  {checkFeature("View Sales Activity Type") && (
                    <NavLink className="collapse-item" to="sales-activity/type">
                      Sales Activity Type
                    </NavLink>
                  )}
                  {checkFeature("View Sales Activity Status") && (
                    <NavLink className="collapse-item" to="sales-activity/status">
                      Sales Activity Status
                    </NavLink>
                  )}
                  {checkFeature("View Sales Activity Event") && (
                    <NavLink className="collapse-item" to="sales-activity/event">
                      Sales Activity Event
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {(checkFeature("view my marketing task") ||
          checkFeature("view my sales task")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseMyTask"
                aria-expanded="true"
                aria-controls="collapseMyTask"
              >
                <i className="fas fa-list mr-2"></i>
                <span>My Task</span>
              </a>
              <div
                id="collapseMyTask"
                className="collapse-sidebar collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("view my marketing task") && (
                    <NavLink
                      className="collapse-item"
                      to="my-task/marketing-task"
                    >
                      My Marketing Task
                    </NavLink>
                  )}

                  {checkFeature("view my sales task") && (
                    <NavLink className="collapse-item" to="my-task/sales-task">
                      My Sales Task
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}


        {(checkFeature("View Sales Target") ||
          checkFeature("View Sales Task") ||
          checkFeature("View Sales Representative Target") ||
          checkFeature("View Sales Deal Won") ||
          checkFeature("View Sales Representative") ||
          checkFeature("View Sales Representative Activity")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseSales"
                aria-expanded="true"
                aria-controls="collapseSales"
              >
                <i className="fas fa-tag mr-2"></i>
                <span>Sales Forecast</span>
              </a>
              <div
                id="collapseSales"
                className="collapse-sidebar collapse"
                aria-labelledby="headingFive"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("View Sales Target") && (
                    <NavLink className="collapse-item" to="sales-hotel-target">
                      Sales Hotel Target
                    </NavLink>
                  )}
                  {checkFeature("View Sales Representative Target") && (
                    <NavLink
                      className="collapse-item"
                      to="sales-representative-target"
                    >
                      Sales Representative Target
                    </NavLink>
                  )}
                  {checkFeature("View Sales Representative") && (
                    <NavLink
                      className="collapse-item"
                      to="sales-representative-staff"
                    >
                      Sales Representative Staff
                    </NavLink>
                  )}
                  {checkFeature("View Sales Task") && (
                    <NavLink className="collapse-item" to="sales-task">
                      Sales Task
                    </NavLink>
                  )}
                  {checkFeature("View Sales Representative Activity") && (
                    <NavLink
                      className="collapse-item"
                      to="sales-representative-activity"
                    >
                      Sales Representative Activity
                    </NavLink>
                  )}
                  {checkFeature("View Sales Deal Won") && (
                    <NavLink className="collapse-item" to="sales-deal-won">
                      Sales Deal Won
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {(checkFeature("view user") ||
          checkFeature("view role") ||
          checkFeature("update role")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseUser"
                aria-expanded="true"
                aria-controls="collapseUser"
              >
                <i className="fas fa-key mr-2"></i>
                <span>Access Management</span>
              </a>
              <div
                id="collapseUser"
                className="collapse-sidebar collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("view user") && (
                    <NavLink className="collapse-item" to="user">
                      Users
                    </NavLink>
                  )}
                  {checkFeature("view role") && (
                    <NavLink className="collapse-item" to="role">
                      Roles
                    </NavLink>
                  )}
                  {checkFeature("update role") && (
                    <NavLink className="collapse-item" to="feature-management">
                      Features
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {(checkFeature("view backup") || checkFeature("Restore Backup")) && (
          <li className="nav-item my-1">
            <a
              className="nav-link-sidebar nav-link collapsed"
              href="/#"
              data-toggle="collapse"
              data-target="#collapseBackup"
              aria-expanded="true"
              aria-controls="collapseBackup"
            >
              <i className="fas fa-database mr-2"></i>
              <span>Backup and Restore</span>
            </a>
            <div
              id="collapseBackup"
              className="collapse-sidebar collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {checkFeature("view backup") && (
                  <NavLink className="collapse-item" to="backup-database">
                    Manual Backup
                  </NavLink>
                )}
                {checkFeature("restore backup") && (
                  <NavLink
                    className="collapse-item"
                    to="restore-database-via-file"
                  >
                    Restore
                  </NavLink>
                )}
              </div>
            </div>
          </li>
        )}

        {(checkFeature("view platform") ||
          checkFeature("view hotel account") ||
          checkFeature("View Product Type") ||
          checkFeature("view customer segmentation") ||
          checkFeature("View Response Message") ||
          checkFeature("View FAQ") ||
          checkFeature("Configure Database Backup")) && (
            <li className="nav-item my-1">
              <a
                className="nav-link-sidebar nav-link collapsed"
                href="/#"
                data-toggle="collapse"
                data-target="#collapseConfig"
                aria-expanded="true"
                aria-controls="collapseConfig"
              >
                <i className="fas fa-cog mr-2"></i>
                <span>System Configuration</span>
              </a>
              <div
                id="collapseConfig"
                className="collapse-sidebar collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {checkFeature("view platform") && (
                    <NavLink className="collapse-item" to="platform">
                      Platforms
                    </NavLink>
                  )}
                  {checkFeature("view hotel account") && (
                    <NavLink className="collapse-item" to="hotel-account">
                      Hotel Account
                    </NavLink>
                  )}
                  {checkFeature("View Instagram Account") && (
                    <NavLink className="collapse-item" to="instagram-account">
                      Instagram Account
                    </NavLink>
                  )}
                  {checkFeature("View Product Type") && (
                    <NavLink className="collapse-item" to="product-type">
                      Product Type
                    </NavLink>
                  )}
                  {checkFeature("view customer segmentation") && (
                    <NavLink className="collapse-item" to="customer-segmentation">
                      Customer Segmentation
                    </NavLink>
                  )}
                  {checkFeature("View Response Message") && (
                    <NavLink className="collapse-item" to="response-message">
                      Response Message
                    </NavLink>
                  )}
                  {checkFeature("View FAQ") && (
                    <NavLink className="collapse-item" to="faq">
                      FAQ
                    </NavLink>
                  )}
                  {checkFeature("Configure Database Backup") && (
                    <NavLink className="collapse-item" to="backup-time-setup">
                      Backup Time Set Up
                    </NavLink>
                  )}
                  {checkFeature("Configure Converstation Hour Limit") && (
                    <NavLink className="collapse-item" to="whatsapp-time-setup">
                      WhatsApp Chat Expiration Time Set Up
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        <div className="text-center d-none d-md-inline mt-3">
          <button
            className="border-0 rounded"
            id="sidebarToggle"
            onClick={() => changeToggled()}
          ></button>
        </div>
      </ul>
    </>
  );
};

export default Sidebar;
