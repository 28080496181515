import React, { useState } from "react";
import DataTable from "react-data-table-component";

const TaskDetail = ({ dataTask }) => {
  function changeFormatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = dataTask?.members?.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  return (
    <>
      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Task Detail
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="taskName"
          className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
        >
          Task Name
        </label>
        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="taskName"
            className="form-control"
            id="taskName"
            value={dataTask.name || ""}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="startDate"
          className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
        >
          Start Date
        </label>
        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="startDate"
            className="form-control"
            id="startDate"
            value={changeFormatDate(dataTask.start_date) || ""}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="dueDate"
          className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
        >
          Deadline
        </label>
        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="dueDate"
            className="form-control"
            id="dueDate"
            value={changeFormatDate(dataTask.due_date) || ""}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="desc"
          className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
        >
          Description
        </label>
        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
          <textarea
            name="desc"
            id="desc"
            rows="3"
            className="form-control"
            value={dataTask.description || "-"}
            disabled
          ></textarea>
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
        >
          Hotel
        </label>
        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={dataTask.hotel || ""}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="partnership"
          className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
        >
          Partnership
        </label>
        <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="partnership"
            className="form-control"
            id="partnership"
            value={dataTask.partnership || ""}
            disabled
          />
        </div>
      </div>

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Members
      </div>
      <div className="row">
        <div className="col-12">
          <div className="datatable">
            <DataTable
              dense
              columns={columns}
              data={currentData}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={dataTask?.members?.length}
              paginationDefaultPage={currentPage}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={setRowsPerPage}
              responsive
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskDetail;
