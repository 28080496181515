import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
import { formatMoney } from "../../../components/FormatMoneyToRupiah";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DetailSalesActivityManage = () => {
  const params = useParams();
  const productID = params.id;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [childs, setChilds] = useState("");
  const [event, setEvent] = useState("");
  const [potentialRevenue, setPotentialRevenue] = useState("");
  const [potentialSuccess, setPotentialSuccess] = useState("");
  const [actualRevenue, setActualRevenue] = useState("");
  const [sales, setSales] = useState("");
  const [pic, setPic] = useState("");
  const [location, setLocation] = useState("");
  const [partnership, setPartnership] = useState("");
  const [files, setFiles] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/sales-activity/${productID}`;
  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentDataChilds = childs?.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );
  //get campaign
  const getSalesActivityDetail = async () => {
    await axios
      .get(endpointList, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setName(response.data.content.name);
        setDescription(response.data.content.description);
        setType(response.data.content.type);
        setStatus(response.data.content.status);
        setEvent(response.data.content.event);
        setFiles(response.data.content.files);
        setChilds(response.data.content.childs);
        setPotentialRevenue(response.data.content.potential_revenue);
        setActualRevenue(response.data.content.actual_revenue);
        setPotentialSuccess(response.data.content.potential_success_percentage);
        setSales(response.data.content.sales);
        setLocation(response.data.content.location);
        setPartnership(response.data.content.partnership);
        setPic(response.data.content.pic);
        setHotels(response.data.content.hotels);
        console.log(response.data.content.product_knowledge_files);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-activity/manage", { replace: true });
        }
      });
  };

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Actual Revenue",
      selector: (row) => row.actual_revenue ? formatMoney(row.actual_revenue) : "",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <a href={`/sales-activity/manage/${row.uuid}`} style={{ margin: "auto" }}>
            <button className="btn btn-aksi btn-edit mr-1">Detail</button>
          </a>
        </>
      ),

    },

  ];

  useEffect(() => {
    getSalesActivityDetail();
  }, []);

  return (
    <>
      <h1 className="page-title mb-3">Sales Activity Detail</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-activity/manage">Sales Activity List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Detail Sales Activity
        </li>
      </ol>
      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Sales Activity Information
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Name
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={name}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Potential Success
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={potentialSuccess + ' %'}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Potential Revenue
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={potentialRevenue ? formatMoney(potentialRevenue) : ""}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Actual Revenue
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={actualRevenue ? formatMoney(actualRevenue) : ""}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Type/Event/Status
        </label>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
          <button type="button" class="btn" style={{ width: "100%", backgroundColor: "blue", color: "white" }} disabled>{type}</button>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-2">
          <button type="button" class="btn" style={{ width: "100%", backgroundColor: "blue", color: "white" }} disabled>{event}</button>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2">
          <button type="button" class="btn" style={{ width: "100%", backgroundColor: "blue", color: "white" }} disabled>{status}</button>
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Created By:
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={sales}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          PIC
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={pic}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Partnership
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={partnership}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Location
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={location}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDescription"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Description
        </label>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <textarea
            name="description"
            id="inputDescription"
            rows="3"
            className="form-control"
            value={description || ""}
            disabled
          ></textarea>
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel Information
        </label>

        {hotels?.map((element, index) => (
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2" key={index}>
            <button type="button" class="btn" style={{ width: "100%", backgroundColor: "green", color: "white" }} disabled>{element.hotel}</button>
          </div>
        ))}
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDescription"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Attachments
        </label>
        {files?.map((element, index) => (
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-2" key={index}>
            <a href={'https://crm-api.jxboard.id/' + element.file} target="_blank"><button type="button" class="btn" style={{ width: "100%", backgroundColor: "orange", color: "white" }}>{'File ' + (index + 1)}</button></a>
          </div>
        ))}
      </div>
      {childs && (
        <>
          <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
            Renew Sales Activity
          </div>
          <DataTable
            dense
            columns={columns}
            data={currentDataChilds}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={childs?.length}
            paginationDefaultPage={currentPage}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={setRowsPerPage}
            responsive
          />
        </>
      )}
      <div className="mb-5 mt-4">
        <div className="col-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate("/sales-activity/manage")}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailSalesActivityManage;
