import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import TaskDetail from "../TaskDetail";
import "./report-deals.scss";

const ReportDeals = () => {
  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;
  const navigate = useNavigate();

  const sectionRef = useRef(null);
  const [dataReportDeals, setDataReportDeals] = useState([]);
  const [dataTask, setDataTask] = useState([]);
  const [offeringPrice, setOfferingPrice] = useState("0");
  const [acceptedPrice, setAcceptedPrice] = useState("0");
  const [hotel, setHotel] = useState("");
  const [product, setProduct] = useState("");
  const [productName, setProductName] = useState("");
  const [date, setDate] = useState("");
  const [isAdd, setIsAdd] = useState(true);
  const [productList, setProductList] = useState([]);
  const [uuidDeals, setUuidDeals] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const endpointGetSalesTaskDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/`;
  const endpointGetProductByHotel = `${process.env.REACT_APP_BASE_URL}/products?hotel=`;
  const endpointAddReportDeals = `${process.env.REACT_APP_BASE_URL}/sales-deals/add`;
  const endpointDeleteDeals = `${process.env.REACT_APP_BASE_URL}/sales-deals/delete/`;
  const endpointEditDeals = `${process.env.REACT_APP_BASE_URL}/sales-deals/edit/`;

  useEffect(() => {
    if (uuid) {
      getReportDeals();
      if (hotel) {
        getProduct();
      }
    }
  }, [hotel]);

  const getProduct = () => {
    try {
      axios
        .get(endpointGetProductByHotel + hotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const option = data.map((product) => {
            return {
              value: product.uuid,
              label: product.name,
            };
          });
          setProductList(option);
        });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const getReportDeals = async () => {
    try {
      await axios
        .get(endpointGetSalesTaskDetail + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          setDataTask(data);
          setDataReportDeals(data.deals);
          setHotel(data.uuid_hotel);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changeOfferingPrice = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/\D/g, "");
    setOfferingPrice(numericValue);
  };

  const changeAcceptedPrice = (e) => {
    const rawValue = e.target.value;
    const numericValue = rawValue.replace(/\D/g, "");
    setAcceptedPrice(numericValue);
  };

  const changeToRupiah = (value) => {
    const formattedValue = Number(value).toLocaleString("id-ID");
    return `Rp ${formattedValue}`;
  };

  const changeProduct = (selectedOption) => {
    if (selectedOption) {
      setProduct(selectedOption.value);
      setSelectedOption(selectedOption);
      setProductName(selectedOption.label);
      const check = dataReportDeals.filter(
        (obj) => obj.uuid_product === selectedOption.value
      );
      if (check.length > 0) {
        setUuidDeals(check[0].uuid);
        setDate(check[0].date);
        setOfferingPrice(check[0].offering_price);
        setAcceptedPrice(check[0].accepted_price);
        setIsAdd(false);
      } else {
        setUuidDeals("");
        setDate("");
        setOfferingPrice("0");
        setAcceptedPrice("0");
        setIsAdd(true);
      }
    } else {
      setSelectedOption(null);
      setProduct(null);
      setProductName(null);
      setDate("");
      setOfferingPrice("0");
      setAcceptedPrice("0");
      setUuidDeals("");
      setIsAdd(true);
    }
  };

  const addDeal = (e) => {
    e.preventDefault();
    const check = dataReportDeals.filter((obj) => obj.uuid_product === product);

    if (isAdd) {
      const dealsData = {
        uuid_sales_task: uuid,
        uuid_product: product,
        offering_price: offeringPrice,
        accepted_price: acceptedPrice,
        date: date,
      };

      axios
        .post(endpointAddReportDeals, dealsData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully added deals with product <b class="text-uppercase">${productName}</b> `,
            "success"
          );
          getReportDeals();
          setDate("");
          setProduct(null);
          setProductName(null);
          setSelectedOption(null);
          setOfferingPrice("");
          setAcceptedPrice("");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            navigate("/404", { replace: true });
          } else if (error.response.status === 500) {
            Swal.fire("Failed!", "Please complete the data", "error");
          } else if (error.response.status === 400 || 401 || 403) {
            Swal.fire("Failed!", `${error.response.data.message}`, "error");
          }
        });
    } else {
      const dealsData = {
        uuid: uuidDeals,
        uuid_sales_task: uuid,
        uuid_product: product,
        offering_price: offeringPrice,
        accepted_price: acceptedPrice,
        date: date,
      };

      axios
        .put(endpointEditDeals + uuidDeals, dealsData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully edit deals with product <b class="text-uppercase">${productName}</b> `,
            "success"
          );
          getReportDeals();
          setDate("");
          setProduct(null);
          setProductName(null);
          setSelectedOption(null);
          setOfferingPrice("0");
          setAcceptedPrice("0");
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status === 404) {
            navigate("/404", { replace: true });
          } else if (error.response.status === 500) {
            Swal.fire("Failed!", "Please complete the data", "error");
          } else if (error.response.status === 400 || 401 || 403) {
            Swal.fire("Failed!", `${error.response.data.message}`, "error");
          }
        });
      // Swal.fire(
      //   "Failed to Add Deals!",
      //   `Report Deal with product <b class="text-uppercase">${arrayProduct.label}</b> already exists`,
      //   "error"
      // );
    }
  };

  const alertDeleteDeal = (uuid, product) => () => {
    Swal.fire({
      title: "Delete Deals?",
      html: `Are you sure want to delete report deal account with product <b class="text-uppercase"> ${product} </b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDeal(uuid, product);
      }
    });
  };

  const deleteDeal = (uuid, product) => {
    try {
      axios
        .delete(endpointDeleteDeals + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted report deal with product <b class="text-uppercase">${product}</b>`,
            "success"
          );
          getReportDeals();
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "60px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
    },
    {
      name: "Offering Price",
      selector: (row) => changeToRupiah(row.offering_price),
    },
    {
      name: "Accepted Price",
      selector: (row) => changeToRupiah(row.accepted_price),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <a
            className="scroll-to-top rounded add-deals btn btn-aksi btn-edit mr-1"
            href="#report-deals"
            onClick={(e) =>
              editDeals(
                e,
                row.uuid,
                row.uuid_product,
                row.product,
                row.date,
                row.offering_price,
                row.accepted_price
              )
            }
          >
            Edit
          </a>
          <button
            className="btn btn-aksi btn-delete"
            onClick={alertDeleteDeal(row.uuid, row.product)}
          >
            Delete
          </button>
        </div>
      ),
      id: "aksi",
      width: "160px",
    },
  ];

  const editDeals = (
    e,
    uuid,
    uuid_product,
    product,
    date,
    offering_price,
    accepted_price
  ) => {
    e.preventDefault();
    setSelectedOption({ value: uuid_product, label: product });
    setProduct(uuid_product);
    setProductName(product);
    setUuidDeals(uuid);
    setDate(date);
    setOfferingPrice(offering_price);
    setAcceptedPrice(accepted_price);
    setIsAdd(false);
  };

  return (
    <>
      <h1 className="page-title mb-3">Report Deals</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/my-task/sales-task">My Sales Task</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Report Deals
        </li>
      </ol>
      <TaskDetail dataTask={dataTask} />
      <div
        id="report-deals"
        ref={sectionRef}
        className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0"
      >
        Add Report
      </div>
      <div className="card my-4">
        <div className="card-header">Add Deal</div>
        <div className="card-body">
          <form
            method="post"
            onSubmit={addDeal}
            className="mt-4 px-5 form form-report-deals"
          >
            <div className="mb-3 row">
              <label
                htmlFor="product"
                className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
              >
                Product
              </label>
              <div className="col-xl-10 col-lg-9 col-md-9 col-sm-12">
                <Select
                  placeholder="Select Product"
                  isClearable={true}
                  options={productList}
                  onChange={changeProduct}
                  // value={productList.find((obj) => obj.value === product)}
                  value={selectedOption}
                  className="select2"
                  id="inputProduct"
                  autoFocus={true}
                  name="uuid_product"
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="completionDate"
                className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
              >
                Date <i className="red">*</i>
              </label>
              <div className="col-xl-10 col-lg-9 col-md-9 col-sm-12">
                <input
                  type="date"
                  name="Date"
                  className="form-control"
                  id="Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label
                htmlFor="offeringPrice"
                className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
              >
                Offering Price
              </label>
              <div className="col-xl-10 col-lg-9 col-md-9 col-sm-12">
                <input
                  id="offeringPrice"
                  placeholder="Rp. 15.000.000"
                  type="text"
                  className="form-control"
                  value={changeToRupiah(offeringPrice)}
                  onChange={changeOfferingPrice}
                  required
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="acceptedPrice"
                className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
              >
                Accepted Price
              </label>
              <div className="col-xl-10 col-lg-9 col-md-9 col-sm-12">
                <input
                  id="acceptedPrice"
                  placeholder="Rp. 15.000.000"
                  type="text"
                  className="form-control"
                  value={changeToRupiah(acceptedPrice)}
                  onChange={changeAcceptedPrice}
                  required
                />
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col-12 text-right">
                <button
                  className="btn btn-primary btn-blue"
                  type="submit"
                  disabled={!selectedOption}
                >
                  {isAdd ? (
                    <>
                      <i className="fa fa-plus-square mr-2"></i>
                      Add
                    </>
                  ) : (
                    <>
                      <i className="fa fa-edit mr-2"></i>
                      Edit
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Report Deal List
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={dataReportDeals}
          highlightOnHover
          pagination
          responsive
        />
      </div>
      <div className="mb-5 mt-4">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate("/my-task/sales-task")}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default ReportDeals;
