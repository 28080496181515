import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { formatMoney } from "../../../components/FormatMoneyToRupiah";
import CheckFeature from "../../../components/CheckFeature";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SalesHotelTargetList = (props) => {
  const currentYear = new Date().getFullYear();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/sales-hotel-targets`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/sales-hotel-targets/delete/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Target",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Sales Target",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Target",
    feature: props.feature,
  });

  //get data
  const getData = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort(
            (a, b) => parseInt(b.year) - parseInt(a.year)
          );
          setData(sortedData);
          setFilteredData(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //search data
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = data.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return data.hotel.toLowerCase().match(searchRegex);
    });

    setFilteredData(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
      width: "90px",
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Revenue Targets",
      selector: (row) => formatMoney(row.revenue_target),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.revenue_target) - parseInt(b.revenue_target);
      },
    },
    {
      name: "Deal Target",
      selector: (row) =>
        row.num_of_deals > 0 ? row.num_of_deals : <i className="text-lg">∞</i>,
      sortable: true,
      width: "110px",
    },
    {
      name: "Actual Revenue",
      selector: (row) => formatMoney(row.actual_revenues),
      sortable: true,
    },
    {
      name: "Actual Deals",
      selector: (row) => row.actual_deals,
      sortable: true,
      width: "110px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">
              {hasFeatureEdit && row.year >= currentYear ? "Edit" : "Detail"}
            </button>
          </Link>
          {hasFeatureDelete && row.year > currentYear && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.year, row.hotel)}
            >
              Delete
            </button>
          )}
        </>
      ),
      id: "aksi",
      width: "150px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete
  const alertDelete = (uuid, year, hotel) => () => {
    Swal.fire({
      title: "Delete Hotel Revenue Target?",
      html: `Are you sure want to delete revenue target ${hotel} htoel for ${year}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Batal",
      confirmButtonText: "Hapus",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTarget(uuid, year, hotel);
      }
    });
  };

  //function delete
  const deleteTarget = (uuid, year, hotel) => {
    axios
      .delete(endpointDelete + uuid, {
        headers: {
          Authorization: token,
        },
      })
      .then(() => {
        Swal.fire(
          "Succes!",
          `Successfully removed the ${hotel} hotel revenue target for ${year}`,
          "success"
        );
        getData();
      })
      .catch((error) => {
        console.error(error);
        Swal.fire(
          "Failed!",
          `Failed to delete ${hotel} hotel revenue target for ${year}`,
          "error"
        );
      });
  };

  return (
    <>
      <h1 className="page-title mb-3">Hotel Revenue Target List</h1>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-7 col-sm-12 col-12 col-btn-export">
          {hasFeatureAdd && (
            <Link to="add">
              <button className="btn btn-primary btn-grey mr-2 button-export">
                <i className="fa fa-plus-square mr-2"></i>
                Add
              </button>
            </Link>
          )}
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredData,
                "Hotel Revenue Target",
                "Hotel Revenue Target"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredData, "Hotel Revenue Target")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-12 col-12 col-btn-export">
          <input
            type="text"
            className="form-control input-search mt-0"
            placeholder="Search by hotel"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredData.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default SalesHotelTargetList;
