import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { error } from "jquery";

const Navbar = ({
  changeToggled,
  alertLogout,
  newChatRequest,
  newReviews,
  newMessage,
}) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const [profile, setProfile] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [img, setImg] = useState();
  const navigate = useNavigate();

  const endpointProfile = `${process.env.REACT_APP_BASE_URL}/users/`;
  const endpointPic = `${process.env.REACT_APP_BASE_URL}/pics/search`;
  const endpointCustomer = `${process.env.REACT_APP_BASE_URL}/customers/search`;

  const getProfile = () => {
    if (user !== null) {
      var stringifyUserData = JSON.parse(user);
      const userId = stringifyUserData["uuid"];
      try {
        axios
          .get(endpointProfile + userId, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            setProfile(response.data.content);
            setImg(
              `${process.env.REACT_APP_BASE_URL}/${response.data.content.image}`
            );
          });
      } catch (error) {
        alert(error.response.data.message);
        if (
          error.response.data.status == 401 ||
          error.response.data.status == 403
        ) {
          navigate("/dashboard", { replace: true });
        }
      }
    }
  };

  useEffect(() => {
    getProfile();
    // fetchData();
  }, []);

  const fetchData = async () => {
    if (user !== null && token !== null) {
      try {
        const responsePic = await axios.get(endpointPic, {
          headers: {
            Authorization: token,
          },
        });
        const responseCustomer = await axios.get(endpointCustomer, {
          headers: {
            Authorization: token,
          },
        });

        const dataPic = responsePic.data.content;
        const optionsPic = dataPic.map((data) => ({
          value: data.uuid,
          label: `${data.name} - ${data.partnership}`,
          type: "pic",
        }));

        const dataCustomer = responseCustomer.data.content;
        const optionsCustomer = dataCustomer.map((data) => ({
          value: data.uuid,
          label: `${data.name} - ${data.hotel}`,
          type: "customer",
        }));

        const groupedOptions = [
          {
            label: "pic",
            options: optionsPic,
          },
          {
            label: "customer",
            options: optionsCustomer,
          },
        ];

        setOptions(groupedOptions);
      } catch (error) {
        console.log(error.response.data.message);
        navigate("/404", { replace: true });
      }
    }
  };

  const formatGroupLabel = (data) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span>{data.label}</span>
      <span
        style={{
          backgroundColor: "#EBECF0",
          borderRadius: "2em",
          color: "#172B4D",
          display: "inline-block",
          fontSize: 12,
          fontWeight: "normal",
          lineHeight: "1",
          minWidth: 1,
          padding: "0.16666666666667em 0.5em",
          textAlign: "center",
        }}
      >
        {data.options.length}
      </span>
    </div>
  );

  const searchPicCustomer = (selected) => {
    if (selected) {
      setSelectedOption(selected.value);
      if (selected.type === "pic") {
        navigate(`/pic/${selected.value}`);
      } else if (selected.type === "customer") {
        navigate(`/customer/${selected.value}`);
      }
    } else {
      setSelectedOption("");
    }
  };

  const getData = async (inputSearch) => {
    if (user !== null && token !== null) {
      const responsePic = await axios
        .get(endpointPic + "?search=" + inputSearch + "&page=1&limit=10", {
          headers: {
            Authorization: token,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      const responseCustomer = await axios
        .get(endpointCustomer + "?search=" + inputSearch + "&page=1&limit=10", {
          headers: {
            Authorization: token,
          },
        })
        .catch((error) => {
          console.log(error);
        });

      const dataPic = responsePic.data.content.pics;
      const optionsPic = dataPic.map((data) => ({
        value: data.uuid,
        label: `${data.name} - ${data.partnership}`,
        type: "pic",
      }));

      const dataCustomer = responseCustomer.data.content.customers;
      const optionsCustomer = dataCustomer.map((data) => ({
        value: data.uuid,
        label: `${data.name} - ${data.hotel}`,
        type: "customer",
      }));

      const groupedOptions = [
        {
          label: "pic",
          options: optionsPic,
        },
        {
          label: "customer",
          options: optionsCustomer,
        },
      ];

      setOptions(groupedOptions);
      // } catch (error) {
      //   console.log(error.response.data.message);
      //   navigate("/404", { replace: true });
      // }
    }
  };

  let delay;
  const searchData = (inputValue) => {
    // clearTimeout(searchTimer);

    if (inputValue.length >= 5) {
      delay = setTimeout(() => {
        getData(inputValue);
      }, 900);
    }
    // return () => {
    //   clearTimeout(searchTimer);
    // };
  };

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-white topbar static-top z-1 navbar-mb navbar-content">
        {/* <!-- Sidebar Toggle (Topbar) --> */}

        <div className="row p-0 m-0 w-100 px-0 py-2 row-reverse">
          <div className="p-0 d-md-none col-sm-2 col-2 set-align-right">
            <button
              id="sidebarToggleTop"
              className="btn btn-link d-md-none rounded-circle mr-3"
              onClick={() => changeToggled()}
            >
              <i className="fa fa-bars"></i>
            </button>
          </div>
          <div className="col-xl-4 col-lg-5 col-md-5 col-sm-10 col-10 p-0 m-0 align-self-center">
            <Select
              options={options}
              formatGroupLabel={formatGroupLabel}
              value={
                options
                  ? options.find((obj) => obj.value === selectedOption)
                  : ""
              }
              id="searchPicCustomer"
              className="select2"
              name="searchPicCustomer"
              isClearable={true}
              placeholder="Search PIC / Customer"
              classNamePrefix="navbar-select"
              onChange={searchPicCustomer}
              onInputChange={searchData}
            />
          </div>
          <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12 col-12 p-0 m-0">
            {/* dropdown new reviews */}
            <div className="row p-0 m-0 w-100 justify-content-end">
              <div className="ml-auto" id="divReviewsDropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="reviewsDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-bell size-icon icon-bell"></i>
                  {/* <!-- Counter - Messages --> */}
                  <span className="badge badge-danger badge-counter">
                    {newReviews ? newReviews.length : 0}
                  </span>
                </a>
                <div
                  className="w-100 dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in popup-notif"
                  aria-labelledby="reviewsDropdown"
                >
                  <h6 className="dropdown-header">New Customer Reviews</h6>
                  {newReviews.length > 0 ? (
                    <>
                      <div className="div-scroll-notification">
                        {newReviews.map((data, i) => (
                          <div
                            className="dropdown-item d-flex align-items-center"
                            href={"/customer-review/" + data.uuid}
                            key={i}
                          >
                            <div className="w-100">
                              <div className="text-secondary">
                                Hotel: {data.hotel}
                              </div>
                              <div className="text-secondary">
                                Platform: {data.platform}
                              </div>
                              <div className="text-secondary">
                                Customer Name: {data.customer}
                              </div>
                              <div className="text-secondary">
                                Rating: {data.rating}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <a
                        className="dropdown-item text-center text-secondary"
                        href="/customer-review"
                      >
                        View all customer reviews
                      </a>
                    </>
                  ) : (
                    <div className="text-center text-secondary py-3 px-4">
                      There are no new reviews
                    </div>
                  )}
                </div>
              </div>

              {/* dropdown new message */}
              <div id="divMessagesDropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="messagesDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-envelope size-icon icon-chat"></i>
                  {/* <!-- Counter - Messages --> */}
                  <span className="badge badge-danger badge-counter">
                    {newMessage ? newMessage.length : 0}
                  </span>
                </a>
                <div
                  className="w-100 dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in popup-notif"
                  aria-labelledby="messagesDropdown"
                >
                  <h6 className="dropdown-header">New WhatsApp Message</h6>
                  {newMessage.length > 0 ? (
                    <div className="div-scroll-notification">
                      {newMessage.map((data, i) => (
                        <div
                          className="dropdown-item d-flex align-items-center"
                          href="/#"
                          key={i}
                        >
                          <div className="w-100">
                            <div className="d-flex justify-content-between set-display-block">
                              <div className="text-secondary font-weight-bold">
                                {data.sender}
                              </div>
                              <div className="text-secondary add-m3">
                                {data.phoneNumber}
                              </div>
                            </div>
                            <div className="text-secondary overflow-message">
                              {data.message}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center text-secondary py-3 px-4">
                      There are no new messages
                    </div>
                  )}
                </div>
              </div>

              {/* dropdown chat request */}
              <div id="divChatRequestDropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="chatRequestDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fab fa-whatsapp size-icon icon-wa"></i>
                  {/* <!-- Counter - Messages --> */}
                  <span className="badge badge-danger badge-counter">
                    {newChatRequest ? newChatRequest.length : 0}
                  </span>
                </a>
                {/* <!-- Dropdown - Messages --> */}
                <div
                  className="w-100 dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in popup-notif"
                  aria-labelledby="chatRequestDropdown"
                >
                  <h6 className="dropdown-header">
                    Live Chat WhatsApp Request
                  </h6>
                  {newChatRequest.length > 0 ? (
                    <>
                      <div className="div-scroll-notification">
                        {newChatRequest.map((data, i) => (
                          <div
                            className="dropdown-item d-flex align-items-center"
                            href="/#"
                            key={i}
                          >
                            <div className="w-100">
                              <div className="d-flex justify-content-between set-display-block">
                                <div className="text-secondary font-weight-bold">
                                  {data.customer}
                                </div>
                                <div className="text-secondary add-m3">
                                  {moment(data.time).format("HH:mm:ss")} WIB
                                </div>
                              </div>
                              <div className="text-secondary">
                                {data.phoneNumber}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <a
                        className="dropdown-item text-center text-secondary"
                        href="/whatsapp-chat-request"
                      >
                        View all chat requests
                      </a>
                    </>
                  ) : (
                    <div className="text-center text-secondary py-3 px-4">
                      There are no new chat requests
                    </div>
                  )}
                </div>
              </div>

              <div className="topbar-divider d-none d-sm-block mr-0"></div>

              {/* profile */}
              <div>
                <a
                  className="nav-link dropdown-toggle"
                  href="/#"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={img}
                    alt="profile"
                    className="rounded-circle photo-profile mr-2"
                    width="25px"
                    height="25px"
                  />
                  <span className="mr-2 d-none d-lg-inline custom-black-color text-capitalize">
                    {profile.username}
                  </span>
                  <i className="fa fa-chevron-down fa-xs"></i>
                </a>
                {/* <!-- Dropdown - User Information --> */}
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  <a className="dropdown-item" href="profile">
                    <i className="fas fa-user fa-fw mr-2 custom-black-color"></i>
                    Profile
                  </a>
                  <button className="dropdown-item" onClick={alertLogout}>
                    <i className="fas fa-sign-out-alt fa-fw mr-2 custom-black-color"></i>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
