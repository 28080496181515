import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import "./users.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const params = useParams();
  const userId = params.id;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [passwordShown, setPasswordShown] = useState([false, false]);
  const [eyeClass, setEyeClass] = useState([false, false]);

  const togglePassword = (i) => {
    let toggleShow = [...passwordShown];
    let oldShow = toggleShow[i];
    toggleShow[i] = !oldShow;
    setPasswordShown(toggleShow);

    let toggleIcon = [...passwordShown];
    let oldIcon = toggleIcon[i];
    toggleIcon[i] = !oldIcon;
    setEyeClass(toggleIcon);
  };

  const endpointDetailUser = `${process.env.REACT_APP_BASE_URL}/users/${userId}`;
  const endpointResetPasswordUser = `${process.env.REACT_APP_BASE_URL}/users/password_reset/${userId}`;

  const getUserById = async () => {
    await axios
      .get(endpointDetailUser, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setUsername(response.data.content.username);
      })
      .catch((error) => {
        alert(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403 ||
          error.response.data.status === 404
        ) {
          navigate("/404", { replace: true });
        }
      });
  };

  const resetPasswordUser = async (e) => {
    e.preventDefault();
    const userData = {
      password: passwordConfirm,
    };
    try {
      await axios
        .patch(endpointResetPasswordUser, userData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully reset user password with the username <b class="text-uppercase">${username}</b>`,
            "success"
          );
          navigate("/user");
        });
    } catch (error) {
      alert(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (userId) {
      getUserById();
    }
  }, []);

  return (
    <>
      <h1 className="page-title">Reset Password</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/user">User List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Reset Password
        </li>
      </ol>
      <form
        onSubmit={resetPasswordUser}
        method="post"
        className="mt-4 px-5 form form-users"
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputUsername"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Username <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <input
              type="text"
              name="username"
              className="form-control"
              id="inputUsername"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="password"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Password <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <div className="input-group">
              <input
                type={passwordShown[1] ? "text" : "password"}
                name="password"
                id="password"
                className="form-control border-right-0"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                className="btn btn-primary btn-eye"
                type="button"
                onClick={() => togglePassword(1)}
              >
                <span
                  className={eyeClass[1] ? "fas fa-eye-slash" : "fas fa-eye"}
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="confirmPassword"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Confirm Password <i className="red">*</i>
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
            <div className="input-group">
              <input
                type={passwordShown[2] ? "text" : "password"}
                name="password"
                id="confirmPassword"
                className="form-control border-right-0"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                required
              />
              <button
                className="btn btn-primary btn-eye"
                type="button"
                onClick={() => togglePassword(2)}
              >
                <span
                  className={eyeClass[2] ? "fas fa-eye-slash" : "fas fa-eye"}
                ></span>
              </button>
            </div>
            {passwordConfirm !== "" && password !== passwordConfirm && (
              <small htmlFor="confirmPassword" className="mt-2 red form-label">
                Passwords do not match
              </small>
            )}
          </div>
        </div>

        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/user")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Cancel
            </button>
            <button className="btn btn-primary btn-blue" type="submit">
              <i className="fa fa-key mr-2"></i>
              Reset Password
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
