import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Select from "react-select";
import { Link } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SalesRepActivity = () => {
  const [dataSalesRepActivity, setDataSalesRepActivity] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [dueDateFilter, setDueDateFilter] = useState("");
  const statusList = [
    { value: "New", label: "New" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
    { value: "Canceled", label: "Canceled" },
  ];

  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/sales-rep-activities`;

  //get data
  const getData = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          // const sortedData = data.sort((a, b) =>
          //   b.createdAt.localeCompare(a.createdAt)
          // );
          setDataSalesRepActivity(data);
          setFilteredData(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //search
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = dataSalesRepActivity.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const name = data.name?.toLowerCase().match(searchRegex);
      const salesName = data.sales_name?.toLowerCase().match(searchRegex);
      const taskName = data.task_name?.toLowerCase().match(searchRegex);
      const partnership = data.partnership?.toLowerCase().match(searchRegex);

      return (
        (!statusFilter || data.status === statusFilter) &&
        (!startDateFilter ||
          moment(data.start_time).format("YYYY-MM-DD") >=
            moment(startDateFilter).format("YYYY-MM-DD")) &&
        (!dueDateFilter ||
          moment(data.end_time).format("YYYY-MM-DD") <=
            moment(dueDateFilter).format("YYYY-MM-DD")) &&
        (!search || name || salesName || taskName || partnership)
      );
    });

    setFilteredData(result);
  }, [search, statusFilter, startDateFilter, dueDateFilter]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Start Date Time",
      selector: (row) => moment(row.start_time).format("DD/MM/YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.start_time).format("YYYY MM DD");
        const dateB = moment(b.start_time).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: "End Date Time",
      selector: (row) => moment(row.end_time).format("DD/MM/YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        // Custom sorting function for date in "DD MM YYYY" format
        const dateA = moment(a.end_time).format("YYYY MM DD");
        const dateB = moment(b.end_time).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
    },
    {
      name: "Sales Name",
      selector: (row) => row.sales_name,
      sortable: true,
    },
    {
      name: "Task Name",
      selector: (row) => row.task_name,
      sortable: true,
    },
    {
      name: "Partnership",
      selector: (row) => row.partnership,
      sortable: true,
    },
    {
      name: "Activity Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "110px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">Detail</button>
          </Link>
        </>
      ),
      id: "aksi",
      width: "110px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const filterStatus = (selectedOption) => {
    if (selectedOption) {
      setStatusFilter(selectedOption.value);
    } else {
      setStatusFilter(null);
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Sales Representative Activity List</h1>

      <div className="row mb-3">
        <div className="col-lg-8 col-md-7 col-sm-12 col-12 col-btn-export">
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredData,
                "Sales Representative Activity List",
                "Sales Representative Activity List"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(
                columns,
                filteredData,
                "Sales Representative Activity List"
              )
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
      </div>
      <div className="row mb-3 filter-search">
        <div className="col-lg-3 col-md-6 col-sm-12 mb-md-2">
          <input
            type="date"
            name="startDateFilter"
            className="form-control"
            id="startDateFIlter"
            title="Filter data by Start Date"
            value={startDateFilter}
            onChange={(e) => setStartDateFilter(e.target.value)}
            required
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <input
            type="date"
            name="dueDateFilter"
            className="form-control"
            id="dueDateFIlter"
            title="Filter data by Due Date"
            value={dueDateFilter}
            onChange={(e) => setDueDateFilter(e.target.value)}
            required
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <Select
            placeholder="Filter by Status"
            isClearable={true}
            options={statusList}
            name="hotel"
            value={statusList.find((obj) => obj.value === statusList)}
            onChange={filterStatus}
            className="select2 input-search"
            id="hotel"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search.."
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredData.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default SalesRepActivity;
