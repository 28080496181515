import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import CheckFeature from "../../../components/CheckFeature";
import ExpandedRow from "./ExpandedRow";
import Select from "react-select";
import { formatMoney } from "../../../components/FormatMoneyToRupiah";

const SalesTaskList = (props) => {
  const [taskList, setTaskList] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredTask, setFilteredTask] = useState([]);
  const [hotel, setHotel] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [dueDateFilter, setDueDateFilter] = useState("");

  const [hotelList, setHotelList] = useState([]);
  const statusList = [
    { value: "Waiting", label: "Waiting" },
    { value: "Won", label: "Won" },
    { value: "Lose", label: "Lose" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");

  const [expandedRowUuid, setExpandedRowUuid] = useState(null);

  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/sales-tasks`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/sales-tasks/delete/`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Task",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Sales Task",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Task",
    feature: props.feature,
  });
  const hasFeatureProgress = CheckFeature({
    name: "Progress Sales Task",
    feature: props.feature,
  });

  //get task
  const getTaskList = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            b.start_date.localeCompare(a.start_date)
          );
          setTaskList(sortedData);
          setFilteredTask(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getTaskList();
    getHotel();
  }, []);

  //search
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = taskList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");

      const startDate = moment(data.start_date)
        .format("D MMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const dueDate = moment(data.due_date)
        .format("D MMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const statusDeals = data.status_deals
        ?.toString()
        .toLowerCase()
        .match(searchRegex);
      const value = data.value?.toString().toLowerCase().match(searchRegex);

      return (
        (!hotel || data.uuid_hotel === hotel) &&
        (!statusFilter || data.status_deals === statusFilter) &&
        (!startDateFilter ||
          moment(data.start_date).format("YYYY-MM-DD") >=
            moment(startDateFilter).format("YYYY-MM-DD")) &&
        (!dueDateFilter ||
          moment(data.due_date).format("YYYY-MM-DD") <=
            moment(dueDateFilter).format("YYYY-MM-DD")) &&
        (!search || startDate || dueDate || statusDeals || value)
      );
    });
    setFilteredTask(result);
  }, [search, hotel, statusFilter, startDateFilter, dueDateFilter]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "40px",
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("D/MM/YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        const dateA = moment(a.start_date).format("YYYY MM DD");
        const dateB = moment(b.start_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "105px",
    },
    {
      name: "Due Date",
      selector: (row) => moment(row.due_date).format("D/MM/YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        const dateA = moment(a.due_date).format("YYYY MM DD");
        const dateB = moment(b.due_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "105px",
    },
    {
      name: "Task Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Partnership",
      selector: (row) => row.partnership,
      sortable: true,
    },
    {
      name: "Status Deal",
      selector: (row) => row.status_deals,
      sortable: true,
      width: "105px",
    },
    {
      name: "Value",
      cell: (row) => formatMoney(row.value),
      sortable: true,
      sortFunction: (a, b) => {
        // If you need custom sorting behavior, you can provide a sortFunction
        return parseInt(a.value) - parseInt(b.value);
      },
    },
    {
      name: "Progress",
      cell: (row) => (
        <div style={{ width: "100%" }}>
          {row.progress.toFixed(0) + "%"}
          <div class="progress" style={{ width: "100%" }}>
            <div
              class="progress-bar progress-bar-striped"
              role="progressbar"
              style={{ width: row.progress + "%" }}
              aria-valuenow={row.progress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      ),
      sortable: true,
    },
    // {
    //   name: "Task Member",
    //   allowOverflow: true,
    //   button: true,
    //   cell: (row) =>
    //     row.members.length > 0 ? (
    //       <button
    //         className="btn btn-aksi btn-reset"
    //         onClick={() => handleRowExpansion(row)}
    //       >
    //         {expandedRowUuid === row.uuid ? "Hide ▼" : "Show ▶"}
    //       </button>
    //     ) : (
    //       "-"
    //     ),
    //   ignoreRowClick: true,
    //   width: "105px",
    // },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1" title="detail">
              <i className="fa fa-eye"></i>
            </button>
          </Link>

          {hasFeatureEdit && row.status_deals === "Waiting" && (
            <Link to={`edit/${row.uuid}`}>
              <button className="btn btn-aksi btn-edit mr-1" title="Edit">
                <i className="fa fa-edit"></i>
              </button>
            </Link>
          )}
          {hasFeatureProgress && (
            <Link to={`add-progress/${row.uuid}`}>
              <button
                className="btn btn-aksi btn-edit mr-1"
                title={"Edit Progres"}
              >
                <i className="fa fa-tasks"></i>
              </button>
            </Link>
          )}
          {hasFeatureDelete && row.status_deals === "Waiting" && (
            <button
              className="btn btn-aksi btn-delete"
              title="Hapus"
              onClick={alertDelete(row.uuid, row.partnership)}
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
        </>
      ),
      id: "aksi",
      width: "190px",
    },
  ];

  const handleRowExpansion = (row) => {
    // If the row is already expanded, collapse it
    if (expandedRowUuid === row.uuid) {
      setExpandedRowUuid(null);
    } else {
      // If the row is not expanded, expand it
      setExpandedRowUuid(row.uuid);
    }
  };

  const expandableRowsComponent = ({ data }) => (
    <ExpandedRow member={data.members} />
  );

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredTask.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete
  const alertDelete = (uuid, partnership) => () => {
    Swal.fire({
      title: "Delete Sales Task?",
      html: `Are you sure want to delete sales task to <b class="text-uppercase">${partnership}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTask(uuid, partnership);
      }
    });
  };

  //function delete
  const deleteTask = (uuid, partnership) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Hapus Sales Task Berhasil!",
            `Berhasil menghapus sales task untuk untuk kerja sama dengan perusahaan <b class="text-uppercase">${partnership}</b>`,
            "success"
          );
          getTaskList();
        });
    } catch (error) {
      Swal.fire(
        "Hapus Sales Task Gagal!",
        `Gagal menghapus sales task untuk kerja sama dengan perusahaan <b class="text-uppercase">${partnership}</b>`,
        "error"
      );
    }
  };

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const filterHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  const filterStatus = (selectedOption) => {
    if (selectedOption) {
      setStatusFilter(selectedOption.value);
    } else {
      setStatusFilter(null);
    }
  };

  return (
    <>
      <h1 className="page-title mb-3">Sales Task List</h1>
      <div className="row mb-3 filter-search">
        <div className="col-lg-4 col-md-4 col-sm-12">
          {hasFeatureAdd && (
            <Link to="add">
              <button className="btn btn-primary btn-grey mr-2">
                <i className="fa fa-plus-square mr-2"></i>
                Add
              </button>
            </Link>
          )}
        </div>
        <div className="offset-lg-4 offset-md-4 col-lg-4 col-md-4 col-sm-12">
          <input
            type="text"
            className="form-control input-search"
            placeholder="Search.."
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="row mb-3 filter-search">
        <div className="col-lg-3 col-md-6 col-sm-12 mb-md-2">
          <input
            type="date"
            name="startDateFilter"
            className="form-control"
            id="startDateFIlter"
            title="Filter data by Start Date"
            value={startDateFilter}
            onChange={(e) => setStartDateFilter(e.target.value)}
            required
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <input
            type="date"
            name="dueDateFilter"
            className="form-control"
            id="dueDateFIlter"
            title="Filter data by Due Date"
            value={dueDateFilter}
            onChange={(e) => setDueDateFilter(e.target.value)}
            required
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <Select
            placeholder="Filter by Status"
            isClearable={true}
            options={statusList}
            name="hotel"
            value={statusList.find((obj) => obj.value === statusList)}
            onChange={filterStatus}
            className="select2 input-search"
            id="hotel"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <Select
            placeholder="Filter by hotel"
            isClearable={true}
            options={hotelList}
            name="hotel"
            value={hotelList.find((obj) => obj.value === hotel)}
            onChange={filterHotel}
            className="select2 input-search"
            id="hotel"
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredTask.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
          expandableRows
          expandableRowsComponent={(row) => expandableRowsComponent(row)}
          expandableRowExpanded={(row) => expandedRowUuid === row.uuid}
          onRowExpandToggled={(row) => handleRowExpansion(row)}
          expandableRowsHideExpander
        />
      </div>
    </>
  );
};

export default SalesTaskList;
