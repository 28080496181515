import React, { useState, useEffect } from "react";
import "../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export const ModalShowRoomData = ({
  show,
  onClose,
  data,
  date,
  typeRoom,
  parentType,
  hotel,
  parent,
  isEdit,
  uuid,
  getAllFaq,
}) => {
  const [keyword, setKeyword] = useState("");
  const [isParent, setIsParent] = useState(true);
  const [reply, setReply] = useState("");
  const [isMain, setIsMain] = useState(false);
  const [type, setType] = useState("");
  const [invalidKeyword, setInvalidKeyword] = useState(false);
  const [invalidReply, setInvalidReply] = useState(false);

  const handleRadioChange = () => {
    setIsParent(!isParent);
  };

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/faqs/`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/faqs/edit/`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/faqs/add`;

  useEffect(() => {
    console.log(data)
  })

  useEffect(() => {
    setIsParent(true);
    setKeyword("");
    setReply("");
    setIsMain(false);
    setType("");
    setInvalidKeyword(false);
    setInvalidReply(false);
  }, [onClose]);

  const addChildFaq = async (e) => {
    e.preventDefault();
    const dataAdd = {
      keyword: keyword,
      is_main: false,
      type: parentType,
      reply: !isParent ? reply : null,
      uuid_hotel: hotel ? hotel : null,
      parent_faq: parent ? parent : null,
    };

    const dataEdit = {
      uuid: uuid,
      keyword: keyword,
      reply: reply ? reply : null,
      is_main: isMain,
      type: type,
    };

    try {
      if (isEdit) {
        await axios
          .put(endpointEdit + uuid, dataEdit, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a <b class="text-uppercase">${keyword}</b> FAQ`,
              "success"
            );

            getAllFaq();
          });
      } else {
        await axios
          .post(endpointAdd, dataAdd, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added a <b class="text-uppercase">${keyword}</b> FAQ`,
              "success"
            );
            getAllFaq();
          });
      }
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    } finally {
      onClose(e);
    }
  };


  return (
    <div
      className={`modal custom-modal-faq${show ? " show" : ""}`}
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-content p-0">
          <div className="modal-header p-0">
            <h5 className="modal-title">{date} - {typeRoom}</h5>
          </div>
          <form method="post" onSubmit={addChildFaq}>
            <div className="modal-body">
              <div className="form form-employee">
                <div className="mb-3">
                  {data.TotalAvailableRoom ? (
                    <div className="col-12">
                      <div style={{}}>
                        <table style={{ width: "80%", margin: "0 auto" }}>
                          <tr>
                            <td>
                              Total FIT
                            </td>
                            <td>
                              {data?.totalFIT}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Total Confirmed Group
                            </td>
                            <td>
                              {data?.TotalConfirmedGroup}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Total Available Room
                            </td>
                            <td>
                              {data?.TotalAvailableRoom}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Total OOS
                            </td>
                            <td>
                              {data?.TotalOOS}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12">
                      <div >
                        <table style={{ width: "80%", margin: "0 auto" }}>
                          <tr>
                            <td>
                              FIT
                            </td>
                            <td>
                              {data?.FIT}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Confirmed Groups
                            </td>
                            <td>
                              {data?.ConfirmedGroups}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Available Rooms
                            </td>
                            <td>
                              {data?.AvailableRooms}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              OOS
                            </td>
                            <td>
                              {data?.OOS}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  )}

                </div>

              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <div className="row p-0 m-0">
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="btn btn-aksi btn-delete"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
