import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ExpandedRow from "./ExpandedRow";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DetailCampaign = () => {
  const params = useParams();
  const campaignId = params.id;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [taskList, setTaskList] = useState([]);
  const [filteredTaskList, setFilteredTaskList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState("0");
  const [description, setDescription] = useState("");
  const [hotel, setHotel] = useState("");
  const [product, setProduct] = useState([]);
  const [expandedRowUuid, setExpandedRowUuid] = useState(null);

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/marketing-projects/${campaignId}`;

  //get campaign
  const getCampaign = async () => {
    await axios
      .get(endpointList, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setTaskList(response.data.content.marketing_tasks);
        setFilteredTaskList(response.data.content.marketing_tasks);
        setProjectName(response.data.content.name);
        setStartDate(response.data.content.start_date);
        setEndDate(response.data.content.end_date);
        setBudget(response.data.content.budget);
        setDescription(response.data.content.description);
        setHotel(response.data.content.hotel);
        setProduct(response.data.content.products);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/marketing-project", { replace: true });
        }
      });
  };

  useEffect(() => {
    getCampaign();
  }, []);

  //search campaign
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = taskList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      const dueDate = moment(data.due_date)
        .format("D MMMM YYYY")
        ?.toLowerCase()
        .match(searchRegex);
      const name = data.name?.toLowerCase().match(searchRegex);
      const desc = data.description?.toLowerCase().match(searchRegex);

      return dueDate || name || desc;
    });

    setFilteredTaskList(result);
  }, [search]);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "40px",
    },
    {
      name: "Due Date",
      selector: (row) =>
        row.due_date ? moment(row.due_date).format("DD MMMM YYYY") : "-",
      sortable: true,
      sortFunction: (a, b) => {
        const dateA = moment(a.due_date).format("YYYY MM DD");
        const dateB = moment(b.due_date).format("YYYY MM DD");
        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      },
      width: "150px",
    },
    {
      name: "Name",
      selector: (row) => (row.name ? row.name : "-"),
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (row?.description ? row.description : "-"),
    },
    {
      name: "Updated Status",
      allowOverflow: true,
      button: true,
      cell: (row) =>
        row.marketing_task_statuses?.length > 0 ? (
          <button
            className="btn btn-aksi btn-reset"
            onClick={() => handleRowExpansion(row)}
          >
            {expandedRowUuid === row.uuid ? "Hide ▼" : "Show ▶"}
          </button>
        ) : (
          "-"
        ),
      ignoreRowClick: true,
    },
  ];

  const handleRowExpansion = (row) => {
    // If the row is already expanded, collapse it
    if (expandedRowUuid === row.uuid) {
      setExpandedRowUuid(null);
    } else {
      // If the row is not expanded, expand it
      setExpandedRowUuid(row.uuid);
    }
  };

  const expandableRowsComponent = ({ data }) => (
    <ExpandedRow statuses={data.marketing_task_statuses} />
  );

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = filteredTaskList.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  const changeToRupiah = (value) => {
    const formattedValue = Number(value).toLocaleString("id-ID");
    return `Rp ${formattedValue}`;
  };
  console.log(currentData);
  return (
    <>
      <h1 className="page-title mb-3">Marketing Project Detail</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/marketing-project">Marketing Project List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Detail Marketing Project
        </li>
      </ol>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Name
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={projectName}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotel}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputStartDate"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Start Date
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="date"
            name="startDate"
            className="form-control"
            id="inputStartDate"
            value={startDate}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputEndDate"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          End Date
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="date"
            name="endDate"
            className="form-control"
            id="inputEndDate"
            value={endDate}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputBudget"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Budget
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="budget"
            className="form-control"
            id="inputBudget"
            value={changeToRupiah(budget)}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDescription"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Description
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <textarea
            name="description"
            id="inputDescription"
            rows="3"
            className="form-control"
            value={description || ""}
            disabled
          ></textarea>
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDescription"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Product
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <ul>
            {product.map((product) => (
              <li key={product.name}>
                <strong>{product.name}</strong>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        TASK LIST
      </div>
      <div className="row mb-3">
        <div className="col-lg-8 col-md-7 col-sm-12 col-12 col-btn-export">
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredTaskList,
                `${projectName} Task List`,
                `${projectName} Task List`
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(
                columns,
                filteredTaskList,
                `${projectName} Task List`
              )
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
        <div className="col-lg-4 col-md-5 col-sm-12 col-12 col-btn-export">
          <input
            type="text"
            className="form-control input-search mt-0"
            placeholder="Search.."
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredTaskList.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
          expandableRows
          expandableRowsComponent={(row) => expandableRowsComponent(row)}
          expandableRowExpanded={(row) => expandedRowUuid === row.uuid}
          onRowExpandToggled={(row) => handleRowExpansion(row)}
          expandableRowsHideExpander
        />
      </div>

      <div className="mb-5 mt-4">
        <div className="col-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate("/marketing-project")}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailCampaign;
