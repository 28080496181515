import React from "react";
import AddEditEmployee from "./AddEditEmployee";
import CheckFeature from "../../components/CheckFeature";

const EditEmployee = (props) => {
  const hasFeatureEdit = CheckFeature({
    name: "Update Employee",
    feature: props.feature,
  });
  return (
    <>
      <h1 className="page-title">
        {hasFeatureEdit ? "Edit Employee Data" : "Detail Employee Data"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/employee">Employee List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {hasFeatureEdit ? "Edit Employee Data" : "Detail Employee Data"}
        </li>
      </ol>
      <AddEditEmployee feature={props.feature} />
    </>
  );
};

export default EditEmployee;
