import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../components/CheckFeature";
import moment from "moment";

const BackupList = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([false, false]); //loading[0] for backup; loading[1] for restore

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/backups`;
  const endpointRestore = `${process.env.REACT_APP_BASE_URL}/backups/restore`;
  const endpointBackup = `${process.env.REACT_APP_BASE_URL}/backups/manual`;
  const endpointDownload = `${process.env.REACT_APP_BASE_URL}/backups/export/`;

  //check features
  const hasFeatureRestore = CheckFeature({
    name: "Restore Backup",
    feature: props.feature,
  });
  const hasFeatureCreate = CheckFeature({
    name: "Create Backup",
    feature: props.feature,
  });
  const hasFeatureDownload = CheckFeature({
    name: "Export Backup",
    feature: props.feature,
  });

  //get backup
  const getBackupList = async () => {
    try {
      await axios
        .get(endpointList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            b.createdAt.localeCompare(a.createdAt)
          );
          setData(sortedData);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    getBackupList();
  }, []);

  //data table column
  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("D MMMM YYYY HH:mm:ss"),
      sortable: true,
    },
    {
      name: "File",
      selector: (row) => row.file,
      id: "capitalizeUnset",
    },
    {
      name: "Size",
      selector: (row) => row.size / 1000 + " KB",
      id: "capitalizeUnset",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {hasFeatureRestore && (
            <button
              className="btn btn-aksi btn-edit mr-1"
              onClick={alertRestore(
                row.uuid,
                moment(row.createdAt).format("D MMMM YYYY HH:mm:ss")
              )}
            >
              Restore
            </button>
          )}
          {hasFeatureDownload && (
            <button
              className="btn btn-aksi btn-reset"
              onClick={(e) =>
                downloadFile(
                  e,
                  row.uuid,
                  moment(row.createdAt).format("D MMMM YYYY HH:mm:ss")
                )
              }
            >
              Download
            </button>
          )}
        </>
      ),
      id: "aksi",
      width: "220px",
    },
  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  const currentData = data.slice((currentPage - 1) * rowsPerPage, lastRowIndex);

  //alert restore
  const alertRestore = (uuid, createdAt) => () => {
    Swal.fire({
      title: "Restore Database?",
      html: `Are you sure want to restore the database with the backup file created at ${createdAt}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Restore",
    }).then((result) => {
      if (result.isConfirmed) {
        restore(uuid, createdAt);
      }
    });
  };

  //function restore
  const restore = async (uuid, createdAt) => {
    try {
      setLoading((prevArray) => [
        ...prevArray.slice(0, 1),
        true,
        ...prevArray.slice(2),
      ]);

      await axios
        .post(
          endpointRestore,
          { uuid: uuid },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          setLoading((prevArray) => [
            ...prevArray.slice(0, 1),
            false,
            ...prevArray.slice(2),
          ]);

          Swal.fire(
            "Success!",
            `Successfully restored the database with the backup file created at ${createdAt}`,
            "success"
          ).then(function () {
            window.location.reload();
          });
        })
        .catch((error) => {
          setLoading((prevArray) => [
            ...prevArray.slice(0, 1),
            false,
            ...prevArray.slice(2),
          ]);

          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      setLoading((prevArray) => [
        ...prevArray.slice(0, 1),
        false,
        ...prevArray.slice(2),
      ]);

      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //function download file
  const downloadFile = async (e, uuid, createdAt) => {
    e.preventDefault();
    try {
      const response = await axios.get(endpointDownload + uuid, {
        headers: {
          Authorization: token,
        },
        responseType: "blob",
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/sql" });

      // Create a downloadable URL from the Blob
      const url = URL.createObjectURL(blob);

      //get file name
      const filePath = data.find((item) => item.uuid === uuid)?.file;
      const fileName = filePath.split("/").pop();

      // Create a temporary link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set the desired filename
      document.body.appendChild(link);

      // Simulate a click on the link to trigger the download
      link.click();

      // Remove the link element from the DOM
      document.body.removeChild(link);

      Swal.fire(
        "Success!",
        `Successfully downloaded the backup file created at ${createdAt}`,
        "success"
      );
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  const backup = async (e) => {
    e.preventDefault();
    try {
      setLoading((prevArray) => [true, ...prevArray.slice(1)]);
      await axios
        .post(
          endpointBackup,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          setLoading((prevArray) => [false, ...prevArray.slice(1)]);

          Swal.fire({
            title: "Database Backup Successful!",
            icon: "success",
          }).then((result) => {
            getBackupList();
          });
        })
        .catch((error) => {
          setLoading((prevArray) => [false, ...prevArray.slice(1)]);

          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        });
    } catch (error) {
      setLoading((prevArray) => [false, ...prevArray.slice(1)]);

      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  useEffect(() => {
    let title_text = "";
    let html_text = "";
    if (loading[0]) {
      title_text = "Please wait..";
      html_text = "Currently in the process of backing up the database";
    } else if (loading[1]) {
      title_text = "Please wait..";
      html_text = "Currently in the process of restoring the database";
    }

    if (loading[0] || loading[1]) {
      Swal.fire({
        title: title_text,
        html: html_text,
        showCancelButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didRender: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading]);

  return (
    <>
      <h1 className="page-title mb-3">Backup List</h1>
      <div className="row mb-3">
        <div className="col-12">
          {hasFeatureCreate && (
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={(e) => backup(e)}
            >
              <i className="fa fa-plus-square mr-2"></i>
              Create Backup
            </button>
          )}
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={data.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default BackupList;
