import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CheckFeature from "../../components/CheckFeature";

const AddEditPlatform = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [slug, setSlug] = useState("");

  const [platformList, setPlatformList] = useState([]);

  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/platforms/`;
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/platforms/${uuid}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/platforms/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/platforms/edit/${uuid}`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Platform",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Platform",
    feature: props.feature,
  });

  useEffect(() => {
    if (uuid) {
      getDetailPlatform();
    }
    getAllPlatforms();
  }, []);

  const changeType = (selectedOption) => {
    if (selectedOption) {
      setType(selectedOption.value);
    } else {
      setType(null);
    }
  };

  const typeList = [
    { value: "OTA", label: "Online Travel Agent" },
    { value: "Social Media", label: "Social Media" },
  ];

  //get platform
  const getDetailPlatform = async () => {
    try {
      await axios
        .get(endpointDetail, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setName(response.data.content.name);
          setType(response.data.content.type);
          setSlug(response.data.content.slug);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 404) {
        Swal.fire("Sorry,", `${error.response.data.message}`, "error");
        navigate("/platform", { replace: true });
      }
    }
  };

  //get all platform
  const getAllPlatforms = async () => {
    try {
      await axios
        .get(endpointView, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setPlatformList(response.data.content);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 404) {
        Swal.fire("Sorry,", `${error.response.data.message}`, "error");
        navigate("/platform", { replace: true });
      }
    }
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const platformData = {
      name: name,
      type: type,
    };
    try {
      if (!uuid) {
        //check kalo ada platform dengan nama yang sama, error "platform sudah ada"
        const check = platformList.filter((data) => {
          return name.toLowerCase() === data.name.toLowerCase();
        });
        if (check.length > 0) {
          Swal.fire(
            "Failed!",
            `The <b class="text-uppercase">${name}</b> platform already exists`,
            "error"
          );
        } else {
          await axios
            .post(endpointAdd, platformData, {
              headers: {
                Authorization: token,
              },
            })
            .then(() => {
              Swal.fire(
                "Success!",
                `Successfully added the <b class="text-uppercase">${name}</b> platform`,
                "success"
              );
              navigate("/platform");
            });
        }
      } else {
        const check = platformList.filter((data) => {
          return (
            data.uuid !== uuid && name.toLowerCase() === data.name.toLowerCase()
          );
        });
        if (check.length > 0) {
          Swal.fire(
            "Failed!",
            `The <b class="text-uppercase">${name}</b> platform already exists`,
            "error"
          );
        } else {
          await axios
            .put(endpointEdit, platformData, {
              headers: {
                Authorization: token,
              },
            })
            .then(() => {
              Swal.fire(
                "Success!",
                `Successfully edited <b class="text-uppercase">${name}</b> platform data`,
                "success"
              );
              navigate("/platform");
            });
        }
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  return (
    <>
      <h1 className="page-title">
        {uuid
          ? hasFeatureEdit
            ? "Edit Platform"
            : "Platform Details"
          : "Add Platform"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/platform">Platform List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {uuid
            ? hasFeatureEdit
              ? "Edit Platform"
              : "Platform Details"
            : "Add Platform"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (uuid && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={uuid && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputType"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Type <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select type"
              isClearable
              value={typeList.find((obj) => obj.value === type)}
              options={typeList}
              onChange={changeType}
              className="select2"
              id="inputType"
              name="type"
              required
              isDisabled={uuid && !hasFeatureEdit}
            />
          </div>
        </div>
        {uuid && (
          <div className="mb-3 row">
            <label
              htmlFor="inputSlug"
              className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
            >
              Slug
            </label>

            <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
              <input
                type="text"
                name="slug"
                className="form-control"
                id="inputSlug"
                value={slug}
                disabled
              />
            </div>
          </div>
        )}
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/platform")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {uuid ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((uuid && hasFeatureEdit) || (!uuid && hasFeatureAdd)) && (
              <button className="btn btn-primary btn-blue" type="submit">
                <i
                  className={
                    "fa " + (uuid ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {uuid ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditPlatform;
