import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../../components/CheckFeature";

const AddEditMeetingRoom = (props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState("0");
  const [description, setDescription] = useState("");
  const [hotel, setHotel] = useState("");
  const [sortSeq, setSortSeq] = useState("");
  const [isClosed, setIsClosed] = useState("");
  const [product, setProduct] = useState([]);

  const [invalidDate, setInvalidDate] = useState(false);

  const token = localStorage.getItem("token");
  const params = useParams();
  const campaignId = params.id;

  const [productList, setProductList] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [productType, setProductType] = useState("");

  //endpoint
  const endpointProduct = `${process.env.REACT_APP_BASE_URL}/products`;
  const endpointView = `${process.env.REACT_APP_BASE_URL}/functions/meeting-rooms/${campaignId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/functions/meeting-rooms`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/functions/meeting-rooms/${campaignId}`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointProductType = `${process.env.REACT_APP_BASE_URL}/product-types`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Meeting Room",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Meeting Room",
    feature: props.feature,
  });

  useEffect(() => {
    if (campaignId) {
      getMeetingRoom();
    }
    getHotel();
  }, []);

  useEffect(() => {
    let options = [];
    if (hotel) {
      options = productList
        .filter(
          (product) =>
            product.uuid_hotel === hotel &&
            (!productType || product.uuid_product_type === productType)
        )
        .map((product) => ({ value: product.uuid, label: product.name }));
    }
    setFilteredProduct(options);
  }, [hotel, productType, productList]);


  //status meeting room
  const dataStatus = [
    {
      value: 1,
      name: "Closed"
    },
    {
      value: 0,
      name: "Open"
    }
  ]
  const optionsStatusMeetingRoom = dataStatus.map(function (data) {
    return { value: data.value, label: data.name };
  });


  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
      setFilteredProduct([]);
      setProduct([]);
    } else {
      setHotel("");
      setFilteredProduct([]);
      setProduct([]);
    }
  };

  //selected hotel
  const changeStatusMeetingRoom = (selectedOption) => {
    if (selectedOption) {
      setIsClosed(selectedOption.value);
    } else {
      setIsClosed("");
    }
  };


  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const meetingRoomData = {
      description: description,
      name: name,
      is_closed: isClosed,
      sort_seq: sortSeq,
      uuid_hotel: hotel,
    };
    try {
      if (!campaignId) {
        await axios
          .post(endpointAdd, meetingRoomData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully created a Meeting Room with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/function-block/meeting-room");
          });
      } else {
        await axios
          .put(endpointEdit, meetingRoomData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a Meeting Room with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/function-block/meeting-room");
          });
      }
    } catch (error) {
      console.log(error.response.data.content);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.content}`, "error");
      }
    }
  };

  //get campaign
  const getMeetingRoom = async () => {
    console.log(endpointView);
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setName(response.data.content.name);
        setSortSeq(response.data.content.sort_seq);
        setIsClosed(response.data.content.is_closed == true ? 1 : 0);
        setBudget(response.data.content.budget);
        setDescription(response.data.content.description);
        setHotel(response.data.content.hotel.uuid);
        console.log(response.data.content.is_closed);
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/function-block/meeting-room", { replace: true });
        }
      });
  };

  return (
    <>
      <h1 className="page-title">
        {campaignId
          ? hasFeatureEdit
            ? "Edit Meeting Room"
            : "Meeting Room Details"
          : "Create Meeting Room"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/function-block/meeting-room">Meeting Room List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {campaignId
            ? hasFeatureEdit
              ? "Edit Meeting Room"
              : "Meeting Room Details"
            : "Create Meeting Room"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (campaignId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Meeting Room Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={campaignId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select hotel"
              isClearable={true}
              options={hotelList}
              value={hotelList.filter((item) => item.value === hotel)}
              name="uuid_hotel"
              onChange={changeHotel}
              className="select2"
              id="inputHotel"
              isDisabled={campaignId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Status <sup>(optional)</sup>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <Select
              placeholder="Select Status"
              isClearable={true}
              options={optionsStatusMeetingRoom}
              value={optionsStatusMeetingRoom.filter((item) => item.value === isClosed)}
              name="isClosed"
              onChange={changeStatusMeetingRoom}
              className="select2"
              id="isClosed"
              isDisabled={campaignId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Sort Sequence <sup>(optional)</sup>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="number"
              name="inputSortSeq"
              className="form-control"
              id="inputSortSeq"
              value={sortSeq}
              onChange={(e) => setSortSeq(e.target.value)}
              disabled={campaignId && !hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDescription"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Description <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="description"
              id="inputDescription"
              rows="3"
              className="form-control"
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
              required
              disabled={campaignId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>

        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/function-block/meeting-room")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {campaignId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((campaignId && hasFeatureEdit) ||
              (!campaignId && hasFeatureAdd)) && (
                <button
                  className="btn btn-primary btn-blue"
                  type="submit"
                  disabled={invalidDate}
                >
                  <i
                    className={
                      "fa " +
                      (campaignId ? "fa-edit" : "fa-plus-square") +
                      " mr-2"
                    }
                  ></i>
                  {campaignId ? "Edit" : "Create"}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditMeetingRoom;
