import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ImportCustomer = () => {
  const [file, setFile] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  //endpoint
  const endpointImport = `${process.env.REACT_APP_BASE_URL}/customers/add/bulk`;

  function handleFile(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    const fileName = file.name.toLowerCase();
    var fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if (fileExt === "csv" || fileExt === "xlsx") {
      Swal.fire({
        title: "Sending Message ...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(endpointImport, formData, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.data.message.info != null) {
            Swal.fire("Success!", `${response.data.message.info}`, "success");
          } else {
            Swal.fire("Success!", "Successfully added customers", "success");
          }
          navigate("/customer");
        })
        .catch((error) => {
          if (error.response.data.status === 400) {
            Swal.fire(
              "Failed!",
              `${error.response.data.message.info}`,
              "error"
            );
          }
        });
    } else {
      Swal.fire(
        "File Extension Not Allowed!",
        "Only csv or xlsx files are allowed",
        "error"
      );
    }
  };

  return (
    <>
      <div className="card my-4">
        <div className="card-body text-center">
          Upload file XLSX, or CSV
          <div className="example-file mt-3 col-lg-6 col-md-8 col-sm-12 mx-auto text-left">
            <label>Example file formats</label>
            <div className="file mb-1">
              <a href="file/customer.csv" download>
                <i className="fa fa-file-csv"></i> customer.csv
              </a>
            </div>
            <div className="file mb-1">
              <a href="file/customer.xlsx" download>
                <i className="fa fa-file-excel"></i> customer.xlsx
              </a>
            </div>
          </div>
          <form onSubmit={handleUpload} className="form">
            <input
              type="file"
              accept=".xlsx, .csv"
              name="file"
              className="form-control input-file-custom col-lg-6 col-md-8 col-sm-12 mx-auto mt-3"
              onChange={handleFile}
              required
            />
            <button className="btn btn-primary btn-blue mt-3" type="submit">
              <i className="fa fa-upload mr-2"></i>Upload
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ImportCustomer;
