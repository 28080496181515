import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

const DetailBookingRoom = (props) => {
  const navigate = useNavigate();

  const [picName, setPicName] = useState("");
  const [picPhone, setPicPhone] = useState("");
  const [picCompany, setPicCompany] = useState("");
  const [meetingRoom, setMeetingRoom] = useState("");
  const [hotelMeetingRoom, setHotelMeetingRoom] = useState("");
  const [layoutType, setLayoutType] = useState("");
  const [bookingType, setBookingType] = useState("");
  const [statusBooking, setStatusBooking] = useState("");
  const [bookingName, setBookingName] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");
  const [totalPax, setTotalPax] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [note, setNote] = useState("");
  const [salesBooking, setSalesBooking] = useState("");
  const [attachment, setAttachment] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const bookingId = params.id;

  const endpointList = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/${bookingId}`;

  //get Bookim Detail
  const getBookingDetail = async () => {
    await axios
      .get(endpointList, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setBookingName(response.data.content.booking_name);
        setBookingType(response.data.content.booking_type);
        setBookingStatus(response.data.content.status);
        setLayoutType(response.data.content.layout_type);
        setStatusBooking(response.data.content.status);
        setTotalPax(response.data.content.total_pax);
        setStartTime(response.data.content.start_time);
        setEndTime(response.data.content.end_time);
        setMeetingRoom(response.data.content.meeting_room.name);
        setHotelMeetingRoom(response.data.content.meeting_room.hotel.name);
        setSalesBooking(response.data.content.sales.employee.name);
        setNote(response.data.content.note);
        setPicName(response.data.content.pic.name);
        setPicPhone(response.data.content.pic.phone_number);
        setPicCompany(response.data.content.pic.partnership.name);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/function-block/meeting-room", { replace: true });
        }
      });
  };

  useEffect(() => {
    getBookingDetail();
  }, []);

  return (
    <>
      <h1 className="page-title mb-3">Booking Room Detail</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/function-block/booking-room">Booking Room List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Detail Booking Room
        </li>
      </ol>

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Meeting Room Information
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Meeting Room Name
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={meetingRoom}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Hotel
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={hotelMeetingRoom}
            disabled
          />
        </div>
      </div>

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Booking Information
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Booking Title
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={bookingName}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Booking Type
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={bookingType}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Meeting Layout
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={layoutType}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Booking Status
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={bookingStatus}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Total Pax
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={totalPax}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Start Time
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={moment(startTime).format('MMMM Do YYYY, h:mm:ss a')}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          End Time
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={moment(endTime).format('MMMM Do YYYY, h:mm:ss a')}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputDescription"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Notes
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <textarea
            name="description"
            id="inputDescription"
            rows="3"
            className="form-control"
            value={note || ""}
            disabled
          ></textarea>
        </div>
      </div>

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        Sales Officer Information
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Officer Name
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={salesBooking}
            disabled
          />
        </div>
      </div>

      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        PIC Partnership Information
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Name
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={picName}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Phone Number
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={picPhone}
            disabled
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="hotel"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Company
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="hotel"
            className="form-control"
            id="hotel"
            value={picCompany}
            disabled
          />
        </div>
      </div>

      <div className="mb-5 mt-4">
        <div className="col-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate("/function-block/booking-room")}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailBookingRoom;
