import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import CheckFeature from "../../components/CheckFeature";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CustomerList = (props) => {
  const navigate = useNavigate();

  const [customer, setCustomer] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customerCount, setCustomerCount] = useState(0);

  const token = localStorage.getItem("token");

  const [hotel, setHotel] = useState(null);
  const [hotelList, setHotelList] = useState([]);
  const [segmentation, setSegmentation] = useState("");
  const [segmentationList, setSegmentationList] = useState([]);

  const endpointGetCustomerList = `${process.env.REACT_APP_BASE_URL}/customers`;
  const endpointDeleteCustomer = `${process.env.REACT_APP_BASE_URL}/customers/delete/`;
  const endpointSegmentation = `${process.env.REACT_APP_BASE_URL}/customer-segmentations/search`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Customer",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Customer",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Customer",
    feature: props.feature,
  });

  useEffect(() => {
    getCustomer();
    getHotel();
    getSegmentation();
  }, [rowsPerPage]);

  useEffect(() => {
    getCustomer();
  }, [hotel, segmentation, search, currentPage]);

  const getCustomer = async () => {
    // let endpointGet;
    // if (!segmentation && !hotel) {
    //   if (!search) {
    //     endpointGet = `${endpointGetCustomerList}?page=${currentPage}&limit=${rowsPerPage}`;
    //   } else {
    //     endpointGet = `${endpointGetCustomerList}?search=${search}&page=${currentPage}&limit=${rowsPerPage}`;
    //   }
    // } else {
    //   if (!segmentation) {
    //     if (!search) {
    //       endpointGet = `${endpointGetCustomerList}?page=${currentPage}&limit=${rowsPerPage}&hotel=${hotel}`;
    //     } else {
    //       endpointGet = `${endpointGetCustomerList}?search=${search}&page=${currentPage}&limit=${rowsPerPage}&hotel=${hotel}`;
    //     }
    //   } else if (!hotel) {
    //     if (!search) {
    //       endpointGet = `${endpointGetCustomerList}?page=${currentPage}&limit=${rowsPerPage}&segmentation=${segmentation}`;
    //     } else {
    //     }
    //   } else {
    //     endpointGet = `${endpointGetCustomerList}?page=${currentPage}&limit=${rowsPerPage}&hotel=${hotel}&segmentation=${segmentation}`;
    //   }
    // }
    const endpointGetCustomer = `${endpointGetCustomerList}?page=${currentPage}&limit=${rowsPerPage}${
      search ? `&search=${search}` : ""
    }${hotel ? `&hotel=${hotel}` : ""}${
      segmentation ? `&segmentation=${segmentation}` : ""
    }`;
    try {
      await axios
        .get(endpointGetCustomer, { headers: { Authorization: token } })
        .then((response) => {
          const data = response.data.content.customers;
          setCustomerCount(response.data.content.totalData);
          setCustomer(data);
          setFilteredCustomer(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const deleteCustomer = (uuid, name) => {
    try {
      axios
        .delete(endpointDeleteCustomer + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted customer with the name <b class="text-uppercase">${name}</b>`,
            "success"
          );
          getCustomer();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to delete customer with the name <b class="text-uppercase">${name}</b>`,
        "error"
      );
    }
  };

  const alertDeleteCustomer = (uuid, name) => () => {
    Swal.fire({
      title: "Delete Customer?",
      html: `Are you sure want to delete <b class="text-uppercase">${name}</b> from the customer list?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#f0f0f0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCustomer(uuid, name);
      }
    });
  };

  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //get segmentation list
  const getSegmentation = async () => {
    try {
      await axios
        .get(endpointSegmentation, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setSegmentationList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected segment
  const filterSegment = (selectedOption) => {
    if (selectedOption) {
      setSegmentation(selectedOption.value);
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      setSegmentation(null);
    }
  };

  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      setHotel(null);
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearch(e.target.value);
      setCurrentPage(1);
    }
  };

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;
  // const currentData = filteredCustomer.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   lastRowIndex
  // );
  const currentData = filteredCustomer;

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
    },
    {
      name: "Hotel",
      selector: (row) => row.hotel,
      sortable: true,
    },
    {
      name: "Segmentation",
      selector: (row) => row.segmentation,
      sortable: true,
    },
    {
      name: "Platform",
      selector: (row) => (row.platform ? row.platform : "-"),
      sortable: true,
      id: "capitalizeUnset",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">
              {hasFeatureEdit ? "Edit" : "Detail"}
            </button>
          </Link>
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDeleteCustomer(row.uuid, row.name)}
            >
              Delete
            </button>
          )}
        </>
      ),
      id: "aksi",
    },
  ];
  return (
    <>
      <h1 className="page-title mb-3">Customer List</h1>
      <div className="row">
        <div className="col-12 col-customer-export">
          {hasFeatureAdd && (
            <Link to="add">
              <button className="btn btn-primary btn-grey mr-2 button-export">
                <i className="fa fa-plus-square mr-2"></i>
                Add Customer
              </button>
            </Link>
          )}
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columns,
                filteredCustomer,
                "Customer List",
                "Customer List"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columns, filteredCustomer, "Customer List")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
      </div>
      <div className="row filter-search mb-3">
        <div className="col-lg-4 col-md-5 col-sm-6">
          <Select
            placeholder="Filter by segmentation"
            isClearable={true}
            options={segmentationList}
            name="uuid_segmentasi"
            value={segmentationList.find((obj) => obj.value === segmentation)}
            onChange={filterSegment}
            className="select2 input-search mt-2"
            id="filter-segment"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-4 col-md-5 col-sm-6">
          <Select
            placeholder="Filter by Hotel"
            isClearable={true}
            options={hotelList}
            name="uuid_hotel"
            value={hotelList.find((obj) => obj.value === hotel)}
            onChange={changeHotel}
            className="select2 input-search mt-2"
            id="filter-hotel"
            classNamePrefix="react-select"
          />
        </div>
        <div className="col-lg-4 col-md-5 col-sm-6">
          <input
            type="text"
            className="form-control input-search mt-2"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            // onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => handleSearch(e)}
            // onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
      <div className="datatable">
        <DataTable
          dense
          columns={columns}
          data={currentData}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={customerCount}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>
    </>
  );
};

export default CustomerList;
