import React from "react";
import "./timeline-css.css";
import moment from "moment/moment";

const ExpandedRow = ({ statuses }) => {
  return (
    <div className="expanded-rows">
      <section>
        {statuses.length > 0 && (
          <ul className="timeline m-0">
            {statuses.map((row, i) => (
              <li className="timeline-item my-1" key={i}>
                <h5 className="status">{row.status}</h5>
                <p className="text-muted mb-2">
                  {row.createdAt
                    ? moment(row.createdAt).format("DD MMMM YYYY")
                    : "-"}
                </p>
                <p className="text-muted mb-4">
                  <b>Notes:</b> <br />
                  {row.notes ? row.notes : "-"}
                </p>
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  );
};

export default ExpandedRow;
