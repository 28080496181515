import React, { useEffect, useRef, useState } from "react";
import "./css-chat.scss";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
// import { useContext } from "react";
// import { MessageContext } from "../../../App";

function LiveConversation() {
  // const newWhatsAppMessage = useContext(MessageContext);
  // console.log(newWhatsAppMessage);

  const chatContainerRef = useRef(null);
  const [typeFile, setTypeFile] = useState(false);
  const [file, setFile] = useState();

  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [data, setData] = useState({});
  const [newWhatsAppMessage, setNewWhatsAppMessage] = useState([]);
  const [time, setTime] = useState("fetching");

  const [messages, setMessages] = useState([]);
  const [replyMessage, setReplyMessage] = useState("");
  const pathSegments = window.location.pathname.split("/");
  const uuidChatRequest = pathSegments[pathSegments.length - 1];

  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  var stringifyUserData = JSON.parse(user);
  const uuid = stringifyUserData["uuid"];
  const navigate = useNavigate();

  const socket = io(process.env.REACT_APP_WEBSOCKET_URL);

  const inputRef = useRef(null);
  //endpoint
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/chat-requests/conversation/${uuidChatRequest}`;
  const endpointEndChat = `${process.env.REACT_APP_BASE_URL}/chat-requests/close/${uuidChatRequest}`;
  const endpointSendMessage = `${process.env.REACT_APP_BASE_URL}/live-conversations/add`;

  useEffect(() => {
    adjustChatContainerHeight();

    window.addEventListener("resize", adjustChatContainerHeight);
    return () => {
      window.removeEventListener("resize", adjustChatContainerHeight);
    };
  });

  useEffect(() => {
    if (messages) {
      getChatRequestDetail();
    }
  }, [newWhatsAppMessage]);

  useEffect(() => {
    socket.on("connect", () => {
      setTime("Connected");
    });
    socket.on("connect_error", () => {
      setTimeout(() => socket.connect(), 5000);
      setTime("Reconnecting...");
    });
    socket.emit("joinChatRoom", {
      uuid_user: uuid,
      uuid_chat_request: uuidChatRequest,
    });

    socket.on("newWhatsappMessage", (notif) => {
      setNewWhatsAppMessage((prevNotif) => [...prevNotif, notif]);
      console.log(notif);
    });
    socket.on("disconnect", () => console.log("Disconnected from the server"));

    console.log("Socket.io: ", time);
  }, [socket]);

  useEffect(() => {}, [newWhatsAppMessage]);
  const getChatRequestDetail = async () => {
    try {
      await axios
        .get(endpointDetail, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setData(response.data.content);
          setMessages(response.data.content.live_conversations);
          setUserName(response.data.content.name);
          setUserPhoneNumber(response.data.content.phone_number);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const adjustChatContainerHeight = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer.scrollHeight > chatContainer.clientHeight) {
      chatContainer.classList.add("scrollable");
    } else {
      chatContainer.classList.remove("scrollable");
    }
  };

  const changeInputType = () => {
    setTypeFile(!typeFile);
  };

  function handleFile(event) {
    setFile(event.target.files[0]);
  }

  const alertEndChat = (uuid, name) => () => {
    Swal.fire({
      title: "End Live Chat ?",
      html: `Are you sure want to end live chat with <b class="text-uppercase">${name}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        endChat(uuid, name);
      }
    });
  };

  const endChat = (uuid, name) => {
    try {
      axios
        .post(
          endpointEndChat,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully end live chat with <b class="text-uppercase">${name}</b>`,
            "success"
          );
          window.close();
        });
    } catch (error) {
      Swal.fire(
        "Failed!",
        `Failed to end live chat with <b class="text-uppercase">${name}</b>`,
        "error"
      );
    }
  };

  const alertSendMessage = (type, message, uuid) => {
    // send message document type
    if (type) {
      const fileName = file.name.toLowerCase();
      var fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      const allowedExtensions = [
        ".jpeg",
        ".jpg",
        ".png",
        ".mp4",
        ".3gp",
        ".docx",
        ".pdf",
        ".xlsx",
      ];
      if (allowedExtensions.includes("." + fileExt)) {
        if (fileExt === "jpeg" || fileExt === "png") {
          var filetype = "image";
        } else if (fileExt === "mp4" || fileExt === "3gp") {
          var filetype = "video";
        } else {
          var filetype = "document";
        }
        Swal.fire({
          title: "Sending Message ...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", filetype);
        formData.append("uuid_chat_request", uuidChatRequest);
        axios
          .post(endpointSendMessage, formData, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Success!",
              text: "Succesfully to send message",
              icon: "success",
              timer: 10000,
            });
            getChatRequestDetail();
          })
          .catch((error) => {
            // Handle errors and show an error message in SweetAlert
            Swal.fire({
              title: "Failed To Send Message",
              text: error.response.data.message,
              icon: "error",
              timer: 100000,
            });
          });
      } else {
        Swal.fire(
          "File Extension Not Allowed!",
          "Only CSV or XLSX files are allowed",
          "error"
        );
      }
    }
    // send message type text
    else {
      Swal.fire({
        title: "Sending Message ...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const dataMessage = {
        type: "text",
        message: message,
        uuid_chat_request: uuid,
      };
      axios
        .post(endpointSendMessage, dataMessage, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          // Handle the response as needed
          Swal.fire({
            title: "Success!",
            text: "Succesfully to send message",
            icon: "success",
            timer: 10000,
          });
          getChatRequestDetail();
          clearInputById("message");
          setReplyMessage(null);
        })
        .catch((error) => {
          // Handle errors and show an error message in SweetAlert
          Swal.fire({
            title: "Failed To Send Message",
            text: error.response.data.message,
            icon: "error",
            timer: 100000,
          });
        });
    }
  };

  const clearInputById = (id) => {
    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.value = ""; // Clear the input value
    }
  };
  // Swal.close();

  // const sendMessage = (type, message, uuid) => {
  //   try {
  //     const dataMessage = {
  //       type: "text",
  //       message: message,
  //       uuid_chat_request: uuid,
  //     };
  //     console.log(dataMessage);
  //     axios.post(endpointSendMessage, dataMessage, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  //     // .then(() => {
  //     //   // Swal.fire(
  //     //   //   "Success!",
  //     //   //   `Successfully end live chat with <b class="text-uppercase"></b>`,
  //     //   //   "success"
  //     //   // );
  //     // });
  //   } catch (error) {
  //     // Swal.fire(
  //     //   "Failed!",
  //     //   `Failed to end live chat with <b class="text-uppercase">${name}</b>`,
  //     //   "error"
  //     // );
  //   }
  // };

  function formattedTime(ISODateString) {
    const date = new Date(ISODateString);
    const localDate = new Date(date.getTime() + 7 * 60000); // Adjust for the local timezone offset
    return localDate.toLocaleString(); // Convert to local time format
  }

  function getNameFile(name) {
    const keyword = "media/documents/conversations/";
    const fileName = name.substring(keyword.length);
    return fileName;
  }

  return (
    <>
      <div className="full-screen-container">
        <div className="user-info">
          <div className="no-tlp">{userPhoneNumber}</div>
          <div className="nama">{userName}</div>
        </div>
        <div className="chat-container" ref={chatContainerRef}>
          {messages
            .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
            .map((message, index) => (
              <div className="row" key={index}>
                <div
                  className={`col-xl-5 col-md-5 col-sm-7 col-10 
                offset-xl-${message.sender === "Admin" ? "7" : "0"}
                offset-md-${message.sender === "Admin" ? "7" : "0"} offset-sm-${
                    message.sender === "Admin" ? "5" : "0"
                  } offset-${message.sender === "Admin" ? "2" : "0"}`}
                >
                  <div
                    className={`chat-message p-3 ${
                      message.sender === "Customer"
                        ? "receiver-message"
                        : "sender-message"
                    }`}
                  >
                    {message.type === "text" ? (
                      <p className="message-text mb-0">{message.message}</p>
                    ) : (
                      <a
                        href={
                          process.env.REACT_APP_BASE_URL + "/" + message.message
                        }
                      >
                        {getNameFile(message.message)}
                      </a>
                    )}

                    <div className="message-time text-right">
                      {formattedTime(message.timestamp)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="div-input-chat">
          <div className="row w-100 m-0 p-3 div-input-chat">
            <div className="col-sm-8  col-6 px-0">
              <div className="input-group">
                <input
                  type={typeFile ? "file" : "text"}
                  id="message"
                  accept={
                    typeFile
                      ? ".jpeg, .png, .mp4, .3gp, .docx, .pdf, .xlsx"
                      : ""
                  }
                  // value={typeFile ? file : replyMessage}
                  onChange={
                    typeFile
                      ? handleFile
                      : (e) => setReplyMessage(e.target.value)
                  }
                  ref={inputRef}
                  className="form-control"
                  placeholder="Type a message..."
                  autoFocus
                />
                <button
                  className="btn import-file"
                  onClick={() => changeInputType()}
                >
                  {typeFile ? (
                    <i className="fa fa-times"></i>
                  ) : (
                    <i className="fa fa-paperclip"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="col-sm-2 col-3 py-0 m-0 col-btn-send">
              <button
                className="btn btn-primary btn-send w-100"
                onClick={(e) =>
                  alertSendMessage(typeFile, replyMessage, uuidChatRequest)
                }
              >
                Send{" "}
                <span className="button-name">
                  <i className="fa fa-paper-plane ml-2"></i>
                </span>
              </button>
            </div>
            <div className="col-sm-2 col-3 p-0 m-0 col-btn-end">
              <button
                className="btn btn-primary btn-end w-100"
                onClick={alertEndChat(uuidChatRequest, userName)}
              >
                End <span className="button-name">Chat</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LiveConversation;
