import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "../../pages/dashboard/dashboard.scss";
import CheckDataZero from "./CheckDataZero";
import moment from "moment";
import Select from "react-select";
import { generateYearOptions } from "../GenerateYearOption";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CustomerGrowth = () => {
  const [customerGrowth, setCustomerGrowth] = useState([]);
  const currentYear = new Date().getFullYear().toString();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedN, setSelectedN] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const yearOptions = generateYearOptions();

  const fetchData = async (selectedN, selectedYear) => {
    try {
      if (token) {
        let endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/customer-growth?year=${selectedYear}`;

        if (selectedN) {
          endpoint = `${process.env.REACT_APP_BASE_URL}/dashboards/customer-growth?lastNMonths=${selectedN}`;
        }

        const customerGrowthPromise = axios.get(endpoint, {
          headers: {
            Authorization: token,
          },
        });

        const [customerGrowthResponse] = await Promise.all([
          customerGrowthPromise,
        ]);

        const customerGrowthData = {
          labels: customerGrowthResponse.data.content.map((item) =>
            moment(item.month).format("MM-YYYY")
          ),

          datasets: [
            {
              data: customerGrowthResponse.data.content.map(
                (item) => item.totalCustomers
              ),
              backgroundColor: "#1c1b20",
              borderColor: "#1c5ddc",
              label: "Total Customers",
              fill: false,
            },
          ],
        };

        setCustomerGrowth(customerGrowthData);
      }
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchData(selectedN, selectedYear);
    }
  }, [show, selectedYear, selectedN]);

  const optionsLineChart = {
    onClick: (e) => false,
    responsive: true,
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        onClick: () => {},
        position: "bottom",
      },
    },
  };

  const filterByYear = (selectedOption) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
      setSelectedN("");
    }
  };

  const filterByLastNMonth = (selectedOption) => {
    if (selectedOption) {
      setSelectedN(selectedOption.value);
      setSelectedYear("");
    } else {
      setSelectedN("");
      setSelectedYear(currentYear);
    }
  };

  const generateOptions = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ value: i, label: `${i} month${i > 1 ? "s" : ""}` });
    }
    return options;
  };

  const optionsNMonth = generateOptions();

  return (
    <>
      <div className="col-md-6 col-sm-12 px-1 py-3">
        <div className="card rounded-0 card-chart">
          <a
            className="card-header px-4 rounded-0 border-bottom-0"
            data-toggle="collapse"
            href="#collapse2"
            role="button"
            aria-expanded="false"
            aria-controls="collapse2"
            onClick={() => setShow(true)}
          >
            Customer Growth
          </a>
          <div className="collapse rounded-0 collapse-border" id="collapse2">
            <div className="chart scroll-chart d-flex flex-column rounded-0">
              <b className="mb-1">Filter by:</b>
              <div className="row mb-2">
                <label
                  htmlFor="filterYear"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Year
                </label>
                <div className="col">
                  <Select
                    options={yearOptions}
                    value={
                      selectedYear
                        ? {
                            value: selectedYear,
                            label: selectedYear.toString(),
                          }
                        : null
                    }
                    id="filterYear"
                    className="select2"
                    name="filterYear"
                    placeholder="Select year"
                    onChange={filterByYear}
                    classNamePrefix="overflow-select"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label
                  htmlFor="filterNMonth"
                  className="col-xl-4 col-lg-5 col-md-12 mb-0 align-self-center"
                >
                  Last N Months
                </label>
                <div className="col">
                  <Select
                    options={optionsNMonth}
                    value={
                      selectedN
                        ? {
                            value: selectedN,
                            label: `${selectedN} month${
                              selectedN > 1 ? "s" : ""
                            }`,
                          }
                        : null
                    }
                    id="filterNMonth"
                    className="select2"
                    name="filterNMonth"
                    isClearable={true}
                    placeholder="Select last N months"
                    onChange={filterByLastNMonth}
                    classNamePrefix="overflow-select"
                  />
                </div>
              </div>
              <div className="w-100 responsive-chart mt-3 d-flex align-items-center justify-content-center align-self-center flex-grow-1">
                {!CheckDataZero(customerGrowth) &&
                  customerGrowth.labels &&
                  !isLoading && (
                    <Line data={customerGrowth} options={optionsLineChart} />
                  )}
                {CheckDataZero(customerGrowth) &&
                  !isLoading &&
                  "There is no data to display"}
                {isLoading && "Loading..."}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerGrowth;
