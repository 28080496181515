import React from "react";

const FormatTextWa = ({ type }) => {
  return (
    <div>
      **Note: <br />
      {type === "message" && (
        <>
          To create variables in the message, use the code{" "}
          <code>&#123;&#123;variable number&#125;&#125;</code> <br />
          Example: <code>&#123;&#123;1&#125;&#125;</code>{" "}
          <code>&#123;&#123;2&#125;&#125;</code>{" "}
          <code>&#123;&#123;3&#125;&#125;</code> <br />
          <br />
        </>
      )}
      <b>The message format can follow the WhatsApp message format</b>
      <ol className="list-group">
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div>
            <div className="font-weight-bold">Italic</div>
            To write your message in <i>italic</i> format, place an underscore
            on both sides of the text
            <br />
            Example: &nbsp;
            <code>_text_</code>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div>
            <div className="font-weight-bold">Bold</div>
            To write your message in <b>bold</b> format, place an asterisk on
            both sides of the text
            <br />
            Example: &nbsp;
            <code>*text*</code>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div>
            <div className="font-weight-bold">Strikethrough</div>
            To strikethrough your message, place a tilde symbol on both sides of
            the text
            <br />
            Example: &nbsp;
            <code>~text~</code>
          </div>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-start">
          <div>
            <div className="font-weight-bold">Monospace</div>
            To write your message in{" "}
            <span className="monospace">monospace</span> format, place three
            backtick symbols on both sides of the text
            <br />
            Example: &nbsp;
            <code>```text```</code>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default FormatTextWa;
