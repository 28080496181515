import React from "react";

const NotFound = () => {
  const token = localStorage.getItem("token");
  return (
    <div className="container-fluid" style={{ height: "100vh", width: "100%" }}>
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <div className="col-12 text-center">
          <div className="error mx-auto mt-4" data-text="404">
            404
          </div>
          <p className="lead text-gray-800 mb-3">Page Not Found</p>
          <a className="lead text-gray-800" href="/">
            <i className="fa fa-arrow-left mr-2"></i>
            Back to {token ? "Dashboard" : "Login"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
