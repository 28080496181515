import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { formatMoney } from "../../../components/FormatMoneyToRupiah";
import moment from "moment";
import CheckFeature from "../../../components/CheckFeature";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const SalesActivityTypeList = (props) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = localStorage.getItem("token");
  const [filteredSalesActivityTypeList, setFilteredSalesActivityTypeList] = useState([]);
  const [productKnowledge, setProductKnowledge] = useState([]);
  const [hotel, setHotel] = useState("");
  //endpoint
  const endpointSalesActivityTypeList = `${process.env.REACT_APP_BASE_URL}/sales-activity-type`;
  const endpointDelete = `${process.env.REACT_APP_BASE_URL}/sales-activity-type/`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Activity Type",
    feature: props.feature,
  });
  const hasFeatureDelete = CheckFeature({
    name: "Delete Sales Activity Type",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Activity Type",
    feature: props.feature,
  });
  const hasFeatureView = CheckFeature({
    name: "View Sales Activity Type",
    feature: props.feature,
  });

  //get productKnowledge
  const getSalesActivityType = async () => {
    try {
      await axios
        .get(endpointSalesActivityTypeList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          console.log(data);

          setProductKnowledge(data);
          setFilteredSalesActivityTypeList(data);
        });
    } catch (error) {
      console.log(error);
      //navigate("/404", { replace: true });
    }
  };
  useEffect(() => {
    getSalesActivityType();

  }, []);

  //search campaign
  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };
    console.log(productKnowledge);
    const result = productKnowledge.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      return (
        (!hotel || data.hotel.uuid === hotel) &&
        (!search || data.name.toLowerCase().match(searchRegex))
      );
    });

    setFilteredSalesActivityTypeList(result);
  }, [search, hotel]);



  //data table column
  const columnsproductKnowledge = [
    {
      name: "#",
      cell: (row, rowIndex) => (currentPage - 1) * rowsPerPage + rowIndex + 1,
      width: "60px",
    },
    {
      name: "Type Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Link to={`${row.uuid}`}>
            <button className="btn btn-aksi btn-edit mr-1">Detail</button>
          </Link>
          {hasFeatureEdit && (
            <Link to={`edit/${row.uuid}`}>
              <button className="btn btn-aksi btn-reset mr-1">Edit</button>
            </Link>
          )}
          {hasFeatureDelete && (
            <button
              className="btn btn-aksi btn-delete"
              onClick={alertDelete(row.uuid, row.name)}
            >
              Delete
            </button>
          )}
        </>
      ),
      width: "225px",
    },

  ];

  // row number
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const lastRowIndex = currentPage * rowsPerPage;


  const currentDataproductKnowledge = filteredSalesActivityTypeList.slice(
    (currentPage - 1) * rowsPerPage,
    lastRowIndex
  );

  //alert delete
  const alertDelete = (uuid, name) => () => {
    Swal.fire({
      title: "Delete This Sales Activity Type?",
      html: `Are you sure want to delete this Sales Activity Type named <b class="text-uppercase">${name}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#fc351c",
      cancelButtonColor: "#F0F0F0",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSalesActivityEvent(uuid, name);
      }
    });
  };

  //function delete campaign
  const deleteSalesActivityEvent = (uuid, name) => {
    try {
      axios
        .delete(endpointDelete + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully deleted the Sales Activity Type with the name <b class="text-uppercase">${name}</b>`,
            "success"
          );
          getSalesActivityType();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };


  return (
    <>
      <h1 className="page-title mb-3">Sales Activity Type List</h1>
      <div className="row">
        <div className="col-12 col-customer-export">
          {hasFeatureAdd && (
            <Link to="create">
              <button className="btn btn-primary btn-grey mr-2">
                <i className="fa fa-plus-square mr-2"></i>
                Create
              </button>
            </Link>
          )}
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generatePDF(
                columnsproductKnowledge,
                filteredSalesActivityTypeList,
                "Sales Activity Type",
                "Sales Activity Type"
              )
            }
          >
            <i className="fas fa-file-pdf mr-2"></i>
            Export to PDF
          </button>
          <button
            className="btn btn-primary btn-grey mr-2 button-export"
            onClick={() =>
              generateExcel(columnsproductKnowledge, filteredSalesActivityTypeList, "Sales Activity Type")
            }
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export to XLSX
          </button>
        </div>
      </div>
      <div className="row filter-search mb-3">

        <div className="col-lg-4 col-md-5 col-sm-6">
          <input
            type="text"
            className="form-control input-search mt-2"
            placeholder="Search by name"
            aria-label="Recipient's username"
            aria-describedby="btn-search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>

      <div className="datatable">
        <DataTable
          dense
          columns={columnsproductKnowledge}
          data={currentDataproductKnowledge}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={filteredSalesActivityTypeList.length}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={setRowsPerPage}
          responsive
        />
      </div>

    </>
  );
};

export default SalesActivityTypeList;
