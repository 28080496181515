import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../custom-pages-css.scss";
import CreatableSelect from "react-select/creatable";
import CheckFeature from "../../components/CheckFeature";

const AddEditHotelAccount = (props) => {
  const navigate = useNavigate();

  const [hotel, setHotel] = useState("");
  const [platform, setPlatform] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hotelList, setHotelList] = useState([]);
  const [platformList, setPlatformList] = useState([]);

  const [hotelName, setHotelName] = useState("");
  const [platformName, setPlatformName] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("fas fa-eye");

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    setEyeClass(!eyeClass);
  };

  const token = localStorage.getItem("token");

  const params = useParams();
  const accountId = params.id;

  //endpoint
  const endpointListHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointView = `${process.env.REACT_APP_BASE_URL}/hotel-accounts/${accountId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/hotel-accounts/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/hotel-accounts/edit/${accountId}`;
  const endpointPlatformList = `${process.env.REACT_APP_BASE_URL}/platforms`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Hotel Account",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Hotel Account",
    feature: props.feature,
  });

  useEffect(() => {
    if (accountId) {
      getAccountById();
    }
    getHotel();
    getPlatforms();
  }, []);

  //get detail hotel
  const getAccountById = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setHotelName(response.data.content.hotel);
        setHotel(response.data.content.uuid_hotel);
        setPlatform(response.data.content.uuid_platform);
        setUsername(response.data.content.id);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/hotel-account", { replace: true });
        }
      });
  };

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointListHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (dataHotel) {
            return { value: dataHotel.uuid, label: dataHotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
      setHotelName(selectedOption.label);
    } else {
      setHotel(null);
      setHotelName(null);
    }
  };

  //create hotel
  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [hotelList]
  );

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const data = {
      uuid_hotel: hotel,
      uuid_platform: platform,
      id: username,
      password: password,
    };
    try {
      if (!accountId) {
        await axios.post(endpointAdd, data, {
          headers: {
            Authorization: token,
          },
        });

        Swal.fire(
          "Success!",
          `Successfully added ${hotelName} account on the ${platformName} platform with username <b class="text-uppercase">${username}</b>`,
          "success"
        );

        navigate("/hotel-account");
      } else {
        await axios.put(endpointEdit, data, {
          headers: {
            Authorization: token,
          },
        });
        Swal.fire(
          "Success!",
          `Successfully edited ${hotelName} account on the ${platformName} platform with username <b class="text-uppercase">${username}</b>`,
          "success"
        );
        navigate("/hotel-account");
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  //get platform
  const getPlatforms = async () => {
    try {
      await axios
        .get(endpointPlatformList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (data) {
            return { value: data.uuid, label: data.name };
          });
          setPlatformList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 404) {
        Swal.fire("Sorry,", `${error.response.data.message}`, "error");
        navigate("/platform", { replace: true });
      }
    }
  };

  //create hotel
  const createPlatform = useCallback(
    (inputValue) => {
      window.open("/platform/add", "_blank").focus();
    },
    [platformList]
  );

  const changePlatform = (selectedOption) => {
    if (selectedOption) {
      setPlatform(selectedOption.value);
      setPlatformName(selectedOption.label);
    } else {
      setPlatform(null);
      setPlatformName(null);
    }
  };

  return (
    <>
      <h1 className="page-title">
        {accountId
          ? hasFeatureEdit
            ? "Edit Hotel Account"
            : "Hotel Account Details"
          : "Add Hotel Account"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/hotel-account">Hotel Account</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {accountId
            ? hasFeatureEdit
              ? "Edit Hotel Account"
              : "Hotel Account Details"
            : "Add Hotel Account"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (accountId && !hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select hotel"
                isClearable={true}
                options={hotelList}
                autoFocus={true}
                value={hotelList.find((obj) => obj.value === hotel)}
                onChange={changeHotel}
                className="select2 select-group"
                id="inputHotel"
                onCreateOption={createHotel}
                required
                isDisabled={accountId && !hasFeatureEdit}
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getHotel()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPlatform"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Platform <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select platform"
                isClearable={true}
                options={platformList}
                autoFocus={true}
                value={platformList.find((obj) => obj.value === platform)}
                onChange={changePlatform}
                className="select2 select-group"
                id="inputPlatform"
                onCreateOption={createPlatform}
                required
                isDisabled={accountId && !hasFeatureEdit}
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getPlatforms()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputUsername"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Username <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="username"
              className="form-control"
              id="inputUsername"
              value={username}
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              required
              disabled={accountId && !hasFeatureEdit}
            />
          </div>
        </div>
        {((accountId && hasFeatureEdit) || (!accountId && hasFeatureAdd)) && (
          <div className="mb-3 row">
            <label
              htmlFor="password"
              className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
            >
              Password <i className="red">*</i>
            </label>
            <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
              <div className="input-group">
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  id="password"
                  className="form-control border-right-0"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  className="btn btn-primary btn-eye"
                  type="button"
                  onClick={() => togglePassword()}
                >
                  <span
                    className={eyeClass ? "fas fa-eye-slash" : "fas fa-eye"}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/hotel-account")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {accountId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((accountId && hasFeatureEdit) ||
              (!accountId && hasFeatureAdd)) && (
              <button className="btn btn-primary btn-blue" type="submit">
                <i
                  className={
                    "fa " + (accountId ? "fa-edit" : "fa-plus-square") + " mr-2"
                  }
                ></i>
                {accountId ? "Edit" : "Add"}
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditHotelAccount;
