import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const generatePDF = (
  columns,
  data,
  title,
  fileName,
  layout = "portrait",
  fontSize = 11.5
) => {
  const columnNames = columns.filter(
    (column) =>
      column.name !== "Action" &&
      column.name !== "Detail Task" &&
      column.name !== "Task Member" &&
      column.name !== "Detail" &&
      column.name !== "Updated Status"
  );

  const getHeaderCell = (text, margin = [0, 8]) => ({
    text,
    bold: true,
    fillColor: "#f9f9f9",
    color: "#000",
    margin: margin,
    border: [false, true, false, true],
    fontSize: 12,
  });

  const getBodyCell = (text, alignment = "left") => ({
    text,
    bold: false,
    margin: [0, 4],
    border: [false, false, false, true],
    fontSize: fontSize,
    alignment: alignment,
  });

  const headerRow = [
    getHeaderCell("No", [5, 8]),
    ...columnNames.slice(1).map((col) => getHeaderCell(col.name)),
  ];

  function capitalizeWords(str) {
    if (typeof str === "string") {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return str;
  }

  const bodyRows = data.map((item, index) => [
    getBodyCell(index + 1, "center"),
    ...columnNames.slice(1).map((col) => {
      if (col.cell) {
        const cellValue = capitalizeWords(col.cell(item));
        return getBodyCell(cellValue);
      } else if (col.selector) {
        const selectorValue = capitalizeWords(col.selector(item));
        return getBodyCell(selectorValue);
      }
      return getBodyCell("");
    }),
  ]);

  const docDefinition = {
    pageOrientation: layout, // Set the page orientation based on the 'layout' parameter
    content: [
      { text: title.toUpperCase(), style: "header" },
      "\n",
      {
        table: {
          widths: ["auto", ...Array(columnNames.length - 1).fill("*")],
          headerRows: 1,
          body: [headerRow, ...bodyRows],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
    },
  };

  pdfMake.createPdf(docDefinition).download(fileName + ".pdf");
};

export const generateExcel = (columns, data, fileName) => {
  const columnData = columns.filter(
    (col) =>
      col.name !== "Action" &&
      col.name !== "Detail Task" &&
      col.name !== "#" &&
      col.name !== "Task Member" &&
      col.name !== "Detail" &&
      col.name !== "Updated Status"
  );
  const columnName = columnData.map((col) => col.name.toUpperCase());

  const exportData = data.map((item) =>
    columnData.map((col) => {
      const selector = col.selector(item);
      if (typeof selector === "string") {
        return selector
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
      return selector;
    })
  );

  const worksheet = XLSX.utils.json_to_sheet([], {
    header: columnName,
  });

  XLSX.utils.sheet_add_json(worksheet, exportData, {
    skipHeader: true,
    origin: "A2",
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${fileName}.xlsx`);
};
