import React from "react";

const ExpandedRow = ({ member }) => {
  return (
    <div className="expanded-rows">
      <div className="title">Task Member List</div>
      <div className="body">
        <div className="table-responsive">
          <table className="table table-sm table-borderless mb-0">
            <tbody>
              {member.map((row, i) => (
                <tr key={row.uuid}>
                  <td scope="row" width="60px">
                    {i + 1}.
                  </td>
                  <td>{row.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExpandedRow;
