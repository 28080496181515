import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import MapComponent from "./MapComponent";
import LeafletMap from "./LeafletMap";
import ShowAttachment from "../../../../components/ShowAttachment";

function AddEditReportActivity() {
  const token = localStorage.getItem("token");
  const params = useParams();
  const navigate = useNavigate();
  const uuid = params.id;
  const idAct = params.idAct;

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [desc, setDesc] = useState("");
  const [pic, setPic] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [oldAttachment, setOldAttachment] = useState(null);
  const [activityName, setActivityName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [status, setStatus] = useState("");
  const [picList, setPicList] = useState([]);
  const [taskStarDate, setTaskStartDate] = useState("");

  const [invalidEndTime, setInvalidEndTime] = useState(false);

  const endpointPIC = `${process.env.REACT_APP_BASE_URL}/pics`;
  const endpointAddActivity = `${process.env.REACT_APP_BASE_URL}/sales-rep-activities/add`;
  const endpointEditActivity = `${process.env.REACT_APP_BASE_URL}/sales-rep-activities/edit/`;
  const endpointDetailActivity = `${process.env.REACT_APP_BASE_URL}/sales-rep-activities/`;
  const endpointGetSalesTaskDetail = `${process.env.REACT_APP_BASE_URL}/sales-tasks/`;

  useEffect(() => {
    getPartnership();
    if (idAct) getActivityDetail();
    else {
      setLatitude(-6.172499406899715);
      setLongitude(106.86311046394741);
    }
  }, []);

  useEffect(() => {
    if (endTime && startTime) {
      const actEndTime = new Date(endTime);
      const actStartTime = new Date(startTime);

      if (actStartTime > actEndTime) {
        setInvalidEndTime(true);
      } else {
        setInvalidEndTime(false);
      }
    }
  }, [endTime, startTime]);

  const getPartnership = async () => {
    try {
      await axios
        .get(endpointGetSalesTaskDetail + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          getPic(data.uuid_company);
          setTaskStartDate(data.start_date);
        });
    } catch (error) {
      navigate("/404", { replace: true });
    }
  };

  //get pic
  const getPic = async (uuid_company) => {
    await axios
      .get(endpointPIC, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const data = response.data.content;
        const filterData = data.filter(
          (pic) => pic.uuid_company === uuid_company
        );
        const options = filterData.map(function (pic) {
          return { value: pic.uuid, label: pic.name + " | " + pic.notes };
        });
        setPicList(options);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate(`/my-task/sales-task/report-activities/` + uuid, {
            replace: true,
          });
        }
      });
  };

  //selected pic
  const changePic = (selectedOptions) => {
    if (selectedOptions) {
      const selectedPic = selectedOptions.map((option) => option.value);
      setPic(selectedPic);
    } else {
      setPic([]);
    }
  };

  function handleAttachment(event) {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      const fileName = event.target.files[0].name.toLowerCase();
      var fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);
      if (
        fileExt === "png" ||
        fileExt === "jpeg" ||
        fileExt === "mp4" ||
        fileExt === "3gp" ||
        fileExt === "pdf" ||
        fileExt === "docx" ||
        fileExt === "xlsx"
      ) {
        if (event.target.files[0].size > 5000000) {
          Swal.fire(
            "Failed to upload file!",
            "The file size must not exceed 5MB",
            "error"
          );
        } else {
          setAttachment(event.target.files[0]);
        }
      } else {
        Swal.fire("Failed,", "File extension is not allowed", "error");
        setAttachment(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } else {
      setAttachment(null);
    }
  }

  const addEditActivity = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("accuracy", accuracy);
    formData.append("description", desc);
    formData.append("uuid_sales_task", uuid);
    if (pic.length < 1) {
      formData.append("pics", []);
    } else {
      for (let i = 0; i < pic.length; i++) {
        formData.append("pics[]", pic[i]);
      }
    }
    if (attachment !== null) {
      formData.append("attachment", attachment);
    }
    // else {
    //   if (oldAttachment !== null) {
    //     formData.append("attachment", oldAttachment);
    //   }
    // }

    formData.append("name", activityName);
    formData.append("start_time", moment(startTime).format("YYYY-MM-DD HH:mm"));
    formData.append("end_time", moment(endTime).format("YYYY-MM-DD HH:mm"));
    formData.append("status", status);

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    Swal.fire({
      title: "Sending Data ...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (idAct) {
      try {
        await axios
          .put(endpointEditActivity + idAct, formData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire({
              title: "Success!",
              text: `Successfully edited activity with name ${activityName}`,
              icon: "success",

              timer: 10000,
            });
            navigate(`/my-task/sales-task/report-activities/` + uuid, {
              replace: true,
            });
          });
      } catch (error) {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 500) {
          Swal.fire("Failed!", "Please complete the data", "error", 100000);
        } else if (error.response.data.status === 400) {
          Swal.fire("Failed!", `${error.response.data.message}`, "error");
        }
      }
    } else {
      try {
        await axios
          .post(endpointAddActivity, formData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully added activity with name <b class="text-uppercase">${activityName}</b>`,
              "success",
              10000
            );
            navigate(`/my-task/sales-task/report-activities/` + uuid, {
              replace: true,
            });
          });
      } catch (error) {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 500) {
          Swal.fire("Failed!", "Please complete the data", "error", 10000);
        } else if (error.response.data.status === 400) {
          Swal.fire(
            "Failed!",
            `${error.response.data.message}`,
            "error",
            10000
          );
        }
      }
    }
  };

  const getActivityDetail = async () => {
    await axios
      .get(endpointDetailActivity + idAct, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const data = response.data.content;
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        setAccuracy(data.accuracy);
        setDesc(data.description);
        const uuidArray = data.pics.map((item) => item.uuid);
        setPic(uuidArray);
        setOldAttachment(data.file);
        setActivityName(data.name);
        setStartTime(moment(data.start_time).format("yyyy-MM-DD hh:mm:ss"));
        setEndTime(moment(data.end_time).format("yyyy-MM-DD hh:mm:ss"));
        setStatus(data.status);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate(`/my-task/sales-task/report-activities/` + uuid, {
            replace: true,
          });
        }
      });
  };

  return (
    <div>
      <h1 className="page-title mb-3">Add Edit Report Activity</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/my-task/sales-task">My Sales Task</a>
        </li>
        <li className="breadcrumb-item">
          <a href={`/my-task/sales-task/report-activities/` + uuid}>
            Report Activity
          </a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Add Report Activity
        </li>
      </ol>
      <form
        onSubmit={(e) => addEditActivity(e)}
        method="post"
        className={"mt-4 px-5 form form-employee"}
      >
        <div className="mb-3 row">
          <label
            htmlFor="activityName"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Activity Name <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="text"
              name="activityName"
              className="form-control"
              id="activityName"
              value={activityName || ""}
              onChange={(e) => setActivityName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="startTime"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Start Time <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="datetime-local"
              name="startTime"
              className="form-control"
              id="startTime"
              value={startTime || ""}
              onChange={(e) => setStartTime(e.target.value)}
              required
              min={taskStarDate + "T00:00"}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="endTime"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            End Time <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <input
              type="datetime-local"
              name="endTime"
              className="form-control"
              id="endTime"
              value={endTime || ""}
              onChange={(e) => setEndTime(e.target.value)}
              required
              min={startTime}
            />
            {invalidEndTime && (
              <small className="red mt-2">
                End Time cannot be less than Start Time.
              </small>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="desc"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Description <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <textarea
              className="form-control"
              rows={3}
              id="desc"
              name="desc"
              value={desc || ""}
              onChange={(e) => setDesc(e.target.value)}
              required
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="attachment"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Attachment
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            {idAct && oldAttachment && (
              <div className="mb-2">
                <ShowAttachment file={oldAttachment} />
              </div>
            )}
            <input
              type="file"
              accept=".png, .jpeg, .mp4, .3gp, .pdf, .docx, .xlsx"
              name="file"
              className="form-control input-file-custom"
              onChange={handleAttachment}
            />
            <small className="red">Max 5MB</small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="pic"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            PIC <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <Select
              placeholder="Select PIC"
              isClearable={true}
              options={picList}
              name="pic"
              autoFocus={false}
              value={pic.map((data) =>
                picList.find((option) => option.value === data)
              )}
              onChange={changePic}
              className="select-product"
              id="pic"
              required
              isMulti
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="status"
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-form-label"
          >
            Status <i className="red">*</i>
          </label>
          <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
            <select
              className="form-control"
              name="status"
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
            >
              <option value="" disabled hidden>
                Select status
              </option>
              <option value="New">New</option>
              <option value="In Progress">In Progress</option>
              <option value="Closed">Closed</option>
              <option value="Canceled">Canceled</option>
            </select>
          </div>
        </div>

        {latitude !== null && longitude !== null ? (
          <>
            <div className="mb-0 mt-4 row">
              <label className="col-5">longitude : {longitude}</label>
              <label className="col-5 text-right">latitude :{latitude}</label>
            </div>
            <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 p-0">
              <LeafletMap
                latitude={latitude}
                longitude={longitude}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
            </div>
            {/* <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 p-0">
              <MapComponent
                latitude={latitude}
                longitude={longitude}
                accuracy={accuracy}
              />
            </div> */}
          </>
        ) : (
          <>
            <b>Fetching location...</b>
            <p>Please accept location permission</p>
          </>
        )}
        <div className="my-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() =>
                navigate(`/my-task/sales-task/report-activities/` + uuid)
              }
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {idAct ? "Cancel" : "Back"}
            </button>
            <button
              className="btn btn-primary btn-blue"
              type="submit"
              disabled={invalidEndTime}
            >
              <i
                className={
                  "fa " + (idAct ? "fa-edit" : "fa-plus-square") + " mr-2"
                }
              ></i>
              {idAct ? "Edit" : "Add"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddEditReportActivity;
