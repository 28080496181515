import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../components/CheckFeature";

const AddProductKnowledge = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [productTypeList, setProductTypeList] = useState([]);
  const [productType, setProductType] = useState("");
  const [otherProductType, setOtherProductType] = useState("");
  const [showOtherType, setShowOtherType] = useState(false);
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const productId = params.id;

  const [formFiles, setFormFiles] = useState([{ filename: "", email: "" }])

  const [productList, setProductList] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);

  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/product-knowledge/${productId}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/product-knowledge/add`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/product-knowledge/edit/${productId}`;
  const endpointProductType = `${process.env.REACT_APP_BASE_URL}/product-knowledge/types`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Product Knowledge",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Product Knowledge",
    feature: props.feature,
  });

  useEffect(() => {
    if (productId) {
      getProductKnowledgeDetail();
    }
    getProductType();
  }, []);

  useEffect(() => {
    if (productType == "other") {
      setShowOtherType(true)
    } else {
      setShowOtherType(false)
    }
  }, [productType]);


  //get campaign
  const getProductKnowledgeDetail = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setName(response.data.content.name);
        setDescription(response.data.content.description);
        setProductType(response.data.content.type);
        setFormFiles(response.data.content.product_knowledge_files);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/product-knowledge", { replace: true });
        }
      });
  };

  //get Product Knowledge list
  const getProductType = async () => {
    try {
      await axios
        .get(endpointProductType, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data?.map(function (item) {
            return { value: item, label: item };
          });
          options.push({ value: "other", label: "other" })
          setProductTypeList(options);
        });
    } catch (error) {
      console.log(error);
      navigate("/404", { replace: true });
    }
  };

  //selected hotel
  const changeProductType = (selectedOption) => {
    if (selectedOption) {
      setProductType(selectedOption.value);
      setFilteredProduct([]);
    } else {
      setProductType("");
      setFilteredProduct([]);
    }
  };

  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    if (productType == "other") {
      var productKnowledgeData = {
        description: description,
        name: name,
        type: otherProductType,
        files: formFiles,

      };
    } else {
      var productKnowledgeData = {
        description: description,
        name: name,
        type: productType,
        files: formFiles,

      };
    }

    try {
      if (!productId) {
        await axios
          .post(endpointAdd, productKnowledgeData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully created a Product Knowledge with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/product-knowledge");
          });
      } else {
        await axios
          .put(endpointEdit, productKnowledgeData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a Product Knowledge with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/product-knowledge");
          });
      }
    } catch (error) {
      console.log(error.response.data.content);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.content}`, "error");
      }
    }
  };

  const handleChange = (i, e) => {
    let newFormValues = [...formFiles];
    newFormValues[i][e.target.name] = e.target.value;
    setFormFiles(newFormValues);
  }

  const addFormFile = () => {
    setFormFiles([...formFiles, { filename: "", link: "" }])
  }

  const removeFormFile = (i) => {
    let newFormValues = [...formFiles];
    newFormValues.splice(i, 1);
    setFormFiles(newFormValues)
  }

  return (
    <>
      <h1 className="page-title">
        {productId
          ? hasFeatureEdit
            ? "Edit Product Knowledge"
            : "Product Knowledge Details"
          : "Create Product Knowledge"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/product-knowledge">Product Knowledge List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {productId
            ? hasFeatureEdit
              ? "Edit Product Knowledge"
              : "Product Knowledge Details"
            : "Create Product Knowledge"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (productId && !hasFeatureEdit && " form-detail")
        }
      >

        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={productId && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Type <i className="red">*</i>
          </label>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <Select
              placeholder="Type"
              isClearable={true}
              options={productTypeList}
              value={productTypeList.filter((item) => item.value === productType)}
              name="product_type"
              onChange={changeProductType}
              className="select2"
              id="inputproducttype"
              isDisabled={productId && !hasFeatureEdit}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            {showOtherType == true && (
              <input
                type="text"
                name="inputName"
                className="form-control"
                id="inputName"
                value={otherProductType}
                onChange={(e) => setOtherProductType(e.target.value)}
                required
                disabled={productId && !hasFeatureEdit}
              />
            )}
          </div>
        </div>


        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Files <i className="red">*</i>
          </label>

          <div className="col-xl-10 col-lg-8 col-md-8 col-sm-12">


            {formFiles.map((element, index) => (
              <div className="row mb-2" key={index}>
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6">
                  <input
                    type="text"
                    placeholder="filename"
                    name="filename"
                    className="form-control mb-2"
                    id="filename"
                    value={element.filename || ""}
                    onChange={e => handleChange(index, e)}
                    required
                    disabled={productId && !hasFeatureEdit}
                  />
                </div>
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-6">
                  <input
                    type="text"
                    placeholder="link"
                    name="link"
                    className="form-control mb-2"
                    id="link"
                    value={element.link || ""}
                    onChange={e => handleChange(index, e)}
                    required
                    disabled={productId && !hasFeatureEdit}
                  />
                </div>
                {
                  index ?
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                      <button
                        typeof="button"
                        onClick={() => removeFormFile(index)}
                        className="btn btn-danger btn-red mb-2"
                        style={{ minWidth: "0px" }}
                      >
                        <i
                          className="fa fa-minus-circle"
                        ></i>
                      </button>
                    </div>
                    : null
                }

              </div>
            ))}
            <div className="row mb-2">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <button
                  className="btn btn-primary btn-green mb-2"
                  type="button"
                  onClick={() => addFormFile()}
                >
                  <i
                    className="fa fa-plus-circle"
                  ></i> Add FIle
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3 row">
          <label
            htmlFor="inputDescription"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Description <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="description"
              id="inputDescription"
              rows="3"
              className="form-control"
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
              required
              disabled={productId && !hasFeatureEdit}
            ></textarea>
          </div>
        </div>

        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/product-knowledge")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {productId ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((productId && hasFeatureEdit) ||
              (!productId && hasFeatureAdd)) && (
                <button
                  className="btn btn-primary btn-blue"
                  type="submit"

                >
                  <i
                    className={
                      "fa " +
                      (productId ? "fa-edit" : "fa-plus-square") +
                      " mr-2"
                    }
                  ></i>
                  {productId ? "Edit" : "Create"}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddProductKnowledge;
