import React, { useState } from "react";
import FormTambah from "./FormTambah";
import TableList from "./TableList";
import CheckFeature from "../../../components/CheckFeature";

const ResponseMessageList = (props) => {
  const [refreshTable, setRefreshTable] = useState(false);

  const handleRefresh = () => {
    setRefreshTable((prevRefresh) => !prevRefresh);
  };

  //check features
  const hasFeatureAdd = CheckFeature({
    name: "Create Response Message",
    feature: props.feature,
  });

  return (
    <>
      <h1 className="page-title mb-3">Response Message</h1>

      {hasFeatureAdd && <FormTambah onRefresh={handleRefresh} />}

      <ul className="nav nav-tabs tab-custom" id="myTab">
        <li className="nav-item mr-1">
          <button
            className="nav-link active"
            id="welcome-tab"
            data-toggle="tab"
            data-target="#welcome"
            type="button"
            role="tab"
            aria-controls="welcome"
            aria-selected="true"
          >
            Welcome
          </button>
        </li>
        <li className="nav-item mr-1">
          <button
            className="nav-link"
            id="invalid-tab"
            data-toggle="tab"
            data-target="#invalid"
            type="button"
            role="tab"
            aria-controls="invalid"
            aria-selected="false"
          >
            Invalid
          </button>
        </li>
        <li className="nav-item mr-1">
          <button
            className="nav-link"
            id="waiting-agent-tab"
            data-toggle="tab"
            data-target="#waiting-agent"
            type="button"
            role="tab"
            aria-controls="waiting-agent"
            aria-selected="false"
          >
            Waiting Agent
          </button>
        </li>
        <li className="nav-item mr-1">
          <button
            className="nav-link"
            id="end-session-tab"
            data-toggle="tab"
            data-target="#end-session"
            type="button"
            role="tab"
            aria-controls="end-session"
            aria-selected="false"
          >
            End Session
          </button>
        </li>
        <li className="nav-item mr-1">
          <button
            className="nav-link"
            id="try-tab"
            data-toggle="tab"
            data-target="#try"
            type="button"
            role="tab"
            aria-controls="try"
            aria-selected="false"
          >
            Try
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="welcome"
          role="tabpanel"
          aria-labelledby="welcome-tab"
        >
          <div className="alert alert-dark mt-4" role="alert">
            The <b>"Welcome"</b> message is used to reply to the first message
            sent by the customer to the WhatsApp chatbot
          </div>
          <TableList
            type="Welcome"
            refresh={refreshTable}
            feature={props.feature}
          />
        </div>
        <div
          className="tab-pane fade"
          id="invalid"
          role="tabpanel"
          aria-labelledby="invalid-tab"
        >
          <div className="alert alert-dark mt-4" role="alert">
            The <b>"Invalid"</b> message is used to reply when the customer's
            reply does not match the keywords
          </div>
          <TableList
            type="Invalid"
            refresh={refreshTable}
            feature={props.feature}
          />
        </div>
        <div
          className="tab-pane fade"
          id="waiting-agent"
          role="tabpanel"
          aria-labelledby="waiting-agent-tab"
        >
          <div className="alert alert-dark mt-4" role="alert">
            The <b>"Waiting Agent"</b> message is used to inform the customer
            before they enter a live chat session
          </div>
          <TableList
            type="Waiting Agent"
            refresh={refreshTable}
            feature={props.feature}
          />
        </div>
        <div
          className="tab-pane fade"
          id="end-session"
          role="tabpanel"
          aria-labelledby="end-session-tab"
        >
          <div className="alert alert-dark mt-4" role="alert">
            The <b>"End Session"</b> message is used as a closing message after
            customer finished live chat with agent
          </div>
          <TableList
            type="End Session"
            refresh={refreshTable}
            feature={props.feature}
          />
        </div>
        <div
          className="tab-pane fade"
          id="try"
          role="tabpanel"
          aria-labelledby="try"
        >
          <div className="alert alert-dark mt-4" role="alert">
            The <b>"Try"</b> message is used to retry chatbot
          </div>
          <TableList
            type="Try"
            refresh={refreshTable}
            feature={props.feature}
          />
        </div>
      </div>
    </>
  );
};

export default ResponseMessageList;
