import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import moment from "moment";

const DetailBroadcast = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const params = useParams();
  const broadcastId = params.id;

  const [varNum, setVarNum] = useState(0);
  const [exampleMessage, setExampleMessage] = useState([]);
  const [exampleHeader, setExampleHeader] = useState("");
  const [headerType, setHeaderType] = useState("");

  const [hotelList, setHotelList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [filteredTarget, setFilteredTarget] = useState([]);
  const [search, setSearch] = useState("");
  const [targetType, setTargetType] = useState("Karyawan");

  const pageSizeOptions = [10, 20, 30, 40, 50, 100, 250];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[6]);

  const [template, setTemplate] = useState("");
  const [sendAt, setSendAt] = useState(null);
  const [messageParams, setMessageParams] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [headerFile, setHeaderFile] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [hotelTarget, setHotelTarget] = useState(null);
  const [description, setDescription] = useState("");
  const [targetEmployee, setTargetEmployee] = useState([]);
  const [targetCustomer, setTargetCustomer] = useState([]);
  const [approved_by, setApprovedBy] = useState("");
  const [success_rate, setSuccessRate] = useState("");
  const [status, setStatus] = useState("");
  const [finalMessage, setFinalMessage] = useState("");

  const [segmentList, setSegmentList] = useState([]);
  const [segment, setSegment] = useState(null);

  //endpoint
  const endpointTemplate = `${process.env.REACT_APP_BASE_URL}/message-templates/`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointEmployee = `${process.env.REACT_APP_BASE_URL}/employees`;
  const endpointCust = `${process.env.REACT_APP_BASE_URL}/customers`;
  const endpointDetail = `${process.env.REACT_APP_BASE_URL}/broadcasts/${broadcastId}`;
  const endpointSegment = `${process.env.REACT_APP_BASE_URL}/customer-segmentations`;

  const changeTarget = (e) => {
    setFilteredTarget([]);
    setTargetList([]);
    setTargetType(e.target.value);
    getTarget(e.target.value);
    setSearch("");
    setSegment(null);
  };

  const getTarget = async (target) => {
    try {
      await axios
        .get(target === "Customer" ? endpointCust : endpointEmployee, {
          headers: {
            Authorization: token,
          },
          params: {
            hasPhoneNumber: "true"
          }
        })
        .then((response) => {
          console.log(response.data.content);
          const data = response.data.content.map(function (data) {
            const obj = {
              uuid: data.uuid,
              name: data.name,
              phone_number: data.phone_number,
            };

            if (target === "Karyawan") {
              obj.hotel = data.hotel;
              obj.department = data.department;
            } else {
              obj.segmentation = data.segmentation;
              obj.hotel = data.hotel;
            }

            return obj;
          });
          setTargetList(data);
          setFilteredTarget(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getTemplateList = async () => {
    try {
      await axios
        .get(endpointTemplate, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (tmp) {
            return {
              value: tmp.uuid,
              label: tmp.name.replace(/_/g, " "),
              message:
                typeof tmp.message === "object"
                  ? tmp.message
                  : JSON.parse(tmp.message),
              header:
                typeof tmp.header === "object"
                  ? tmp.header
                  : JSON.parse(tmp.header),
            };
          });
          setTemplateList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const getTemplateDetail = (uuid) => {
    try {
      axios
        .get(endpointTemplate + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          let message;
          if (typeof response.data.content.message === "string") {
            message = JSON.parse(response.data.content.message);
          } else {
            message = response.data.content.message;
          }
          setVarNum(message.variable_num ? message.variable_num : 0);
          setExampleMessage(message.example ? message.example : "");
          let header;
          if (typeof response.data.content.header === "string") {
            header = JSON.parse(response.data.content.header);
          } else {
            header = response.data.content.header;
          }
          if (header !== null) {
            setHeaderType(header.type);
            setExampleHeader(header.example);
          } else {
            setHeaderType("");
            setExampleHeader("");
          }
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  useEffect(() => {
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
    };

    const result = targetList.filter((data) => {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      if (targetType === "Customer") {
        return (
          (!segment || data.segmentation === segment) &&
          (!hotelTarget || data.hotel === hotelTarget) &&
          (!search || data.name.toLowerCase().match(searchRegex))
        );
      }
      return data.name.toLowerCase().match(searchRegex);
    });

    setFilteredTarget(result);
    setCurrentPage(1);
  }, [search, segment, targetType, hotelTarget]);

  useEffect(() => {
    getHotel();
    getTemplateList();
    getTarget("Karyawan");
    getSegment();
    if (broadcastId) {
      getBroadcast();
    }
  }, []);

  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  // Calculate pagination range
  const lastIndex = currentPage * pageSize;
  const firstIndex = lastIndex - pageSize;
  const currentData = filteredTarget.slice(firstIndex, lastIndex);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page
  };

  const getBroadcast = async () => {
    try {
      await axios
        .get(endpointDetail, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          let message_parameters;
          if (typeof response.data.content.message_parameters === "string") {
            message_parameters = JSON.parse(
              response.data.content.message_parameters
            );
          } else {
            message_parameters = response.data.content.message_parameters;
          }
          getTemplateDetail(response.data.content.uuid_template);
          setTemplate(response.data.content.uuid_template);
          setSendAt(
            moment(response.data.content.send_at).format("YYYY-MM-DD HH:mm:ss")
          );
          if (message_parameters != null) {
            setMessageParams(
              message_parameters != null ? message_parameters : []
            );
          }
          setHeaderText(
            response.data.content.header_text
              ? response.data.content.header_text
              : ""
          );

          setHeaderFile(
            response.data.content.header_file
              ? response.data.content.header_file
              : ""
          );
          setHotel(
            response.data.content.uuid_hotel
              ? response.data.content.uuid_hotel
              : ""
          );
          setDescription(
            response.data.content.description
              ? response.data.content.description
              : ""
          );
          setFinalMessage(
            response.data.content.finalMessage
              ? response.data.content.finalMessage
              : ""
          );
          setTargetEmployee(
            response.data.content.employee_list
              ? response.data.content.employee_list.map(function (data) {
                return data;
              })
              : []
          );
          setTargetCustomer(
            response.data.content.customer_list
              ? response.data.content.customer_list.map(function (data) {
                return data;
              })
              : []
          );
          setStatus(
            response.data.content.status ? response.data.content.status : ""
          );
          setApprovedBy(
            response.data.content.approved_by
              ? response.data.content.approved_by
              : ""
          );
          setSuccessRate(
            response.data.content.success_rate
              ? response.data.content.success_rate
              : ""
          );
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  //get segment list
  const getSegment = async () => {
    try {
      await axios
        .get(endpointSegment, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (segment) {
            return { value: segment.name, label: segment.name };
          });
          setSegmentList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  //selected segment
  const changeSegment = (selectedOption) => {
    if (selectedOption) {
      setSegment(selectedOption.value);
    } else {
      setSegment(null);
    }
  };

  const changeHotelTarget = (selectedOption) => {
    if (selectedOption) {
      setHotelTarget(selectedOption.label);
    } else {
      setHotelTarget(null);
    }
  };
  return (
    <>
      <h1 className="page-title">WhatsApp Broadcast Details</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/whatsapp-broadcast/all">WhatsApp Broadcast List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          WhatsApp Broadcast Details
        </li>
      </ol>
      <form className="mt-4 px-5 form form-employee form-detail">
        <div className="mb-3 row">
          <label
            htmlFor="inputTemplate"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Template <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <Select
              placeholder="Select template"
              options={templateList}
              name="uuid_template"
              value={templateList.find((obj) => obj.value === template)}
              className="select2"
              id="inputTemplate"
              isDisabled={true}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="send_at"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Sent At <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <input
              type="datetime-local"
              name="sendAt"
              className="form-control"
              id="send_at"
              value={sendAt || ""}
              step={1}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Hotel
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <Select
              placeholder="Select hotel"
              isClearable={true}
              options={hotelList}
              name="uuid_hotel"
              value={hotelList.find((obj) => obj.value === hotel)}
              id="inputHotel"
              className="select2"
              isDisabled={true}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDeskripsi"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Description
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <textarea
              name="deskripsi"
              id="inputDeskripsi"
              rows="3"
              className="form-control"
              value={description}
              disabled
            ></textarea>
          </div>
        </div>
        {status === "Sent" && (
          <>
            <div className="mb-3 row">
              <label
                htmlFor="inputApproveBy"
                className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
              >
                Approved by
              </label>
              <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                <input
                  name="inputApproveBy"
                  id="inputApproveBy"
                  type="text"
                  className="form-control"
                  value={approved_by}
                  disabled
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="inputSuccessRate"
                className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
              >
                Success Rate
              </label>
              <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                <input
                  name="inputSuccessRate"
                  id="inputSuccessRate"
                  type="text"
                  className="form-control"
                  value={success_rate}
                  disabled
                />
              </div>
            </div>
          </>
        )}

        <div className="text-with-line col-xl-11 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          MESSAGE
        </div>

        {parseInt(varNum, 10) > 0 &&
          Array.from(Array(parseInt(varNum, 10)), (e, i) => {
            return (
              <div className="row mb-3" key={i}>
                <label
                  htmlFor={"inputVar" + i}
                  className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
                >
                  Contents of Variable {i + 1} <i className="red">*</i>
                </label>
                <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                  <input
                    type="text"
                    id={"inputVar" + i}
                    className="form-control"
                    value={messageParams[i] || ""}
                    required
                    disabled
                  />
                  <small>Example: {exampleMessage[i]}</small>
                </div>
              </div>
            );
          })}
        <div className="mb-3 row">
          <label
            htmlFor="finalMessage"
            className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
          >
            Final Message
          </label>
          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
            <textarea
              name="finalMessage"
              id="finalMessage"
              rows="3"
              className="form-control"
              value={finalMessage}
              disabled
            ></textarea>
          </div>
        </div>

        {exampleHeader && (
          <>
            <div className="text-with-line col-xl-11 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
              HEADER
            </div>
            <div className="row">
              <label
                htmlFor="headerText"
                className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-form-label"
              >
                Header {headerType.toLowerCase()} <i className="red">*</i>
              </label>
              {headerType === "TEXT" && (
                <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                  <input
                    type="text"
                    id="headerText"
                    className="form-control"
                    value={headerText}
                    onChange={(e) => setHeaderText(e.target.value)}
                    disabled
                    required
                  />
                  <small>Example: {exampleHeader}</small>
                </div>
              )}
              {(headerType === "IMAGE" ||
                headerType === "DOCUMENT" ||
                headerType === "VIDEO") && (
                  <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}/` + headerFile}
                      target="_blank"
                    >
                      File Preview
                    </a>
                  </div>
                )}
            </div>
          </>
        )}

        <div className="text-with-line col-xl-11 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          TARGET
        </div>
        <div className="row mb-3 filter-target">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <select
              name="search"
              id="selectTarget"
              className="form-control"
              onChange={(e) => changeTarget(e)}
            >
              <option value="Karyawan">Employee</option>
              <option value="Customer">Customer</option>
            </select>
          </div>
          {targetType === "Customer" && (
            <>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <Select
                  placeholder="Filter by segmentation"
                  isClearable={true}
                  options={segmentList}
                  name="segmentFilter"
                  value={segmentList.find((obj) => obj.value === segment)}
                  onChange={changeSegment}
                  className="select2 input-search"
                  id="filterSegment"
                  classNamePrefix="react-select"
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <Select
                  placeholder="Select hotel"
                  isClearable={true}
                  options={hotelList}
                  name="hotelFilter"
                  value={hotelList.find((obj) => obj.value === hotelTarget)}
                  onChange={changeHotelTarget}
                  className="select2 input-search"
                  id="filterHotel"
                  classNamePrefix="react-select"
                />
              </div>
            </>

          )}
          <div
            className={
              targetType === "Customer"
                ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 pt-2"
                : "col-xl-7 col-lg-8 col-md-8 col-sm-12"
            }
          >
            <input
              type="text"
              className="form-control input-search"
              placeholder="Search by name"
              aria-label="Recipient's username"
              aria-describedby="btn-search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
        <div className="datatable col-xl-11 col-12 p-0">
          Employee Target :{" "}
          {targetEmployee.length > 0 ? targetEmployee.length : 0}
          <br />
          Customer Target :{" "}
          {targetCustomer.length > 0 ? targetCustomer.length : 0}
          <div className="table table-responsive mb-0 mt-3">
            <table className="table table-hover table-sm custom-table">
              <thead>
                <tr>
                  <th scope="col" className="text-center th-checkbox">
                    <input
                      type="checkbox"
                      checked={
                        (search !== "" || segment) && currentData.length > 0
                          ? filteredTarget.every((row) =>
                            targetType === "Customer"
                              ? targetCustomer.some((customer) =>
                                customer.uuid_customer.includes(row.uuid)
                              )
                              : targetEmployee.some((emp) =>
                                emp.uuid_employee.includes(row.uuid)
                              )
                          )
                          : targetType === "Customer"
                            ? targetCustomer.length === targetList.length
                            : targetEmployee.length === targetList.length
                      }
                      disabled
                    />
                  </th>
                  <th scope="col">Name</th>
                  {targetType === "Karyawan" && (
                    <>
                      <th scope="col">Hotel</th>
                      <th scope="col">Department</th>
                      <th scope="col">Phone Number</th>
                    </>
                  )}
                  {targetType === "Customer" && (
                    <>
                      <th scope="col">Hotel</th>
                      <th scope="col">Segmentation</th>
                      <th scope="col">Phone Number</th>
                    </>
                  )}
                  {status === "Sent" && (
                    <>
                      <th scope="col">Status</th>
                      <th scope="col">Meta Status</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row1) => {
                  let matchingData;
                  if (targetType === "Customer") {
                    matchingData = targetCustomer.find(
                      (row2) => row1.uuid === row2.uuid_customer
                    );
                  } else {
                    matchingData = targetEmployee.find(
                      (row2) => row1.uuid === row2.uuid_employee
                    );
                  }
                  return (
                    matchingData && (
                      <tr key={row1.uuid}>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={
                              targetType === "Customer"
                                ? targetCustomer.some((customer) =>
                                  customer.uuid_customer.includes(row1.uuid)
                                )
                                : targetEmployee.some((emp) =>
                                  emp.uuid_employee.includes(row1.uuid)
                                )
                            }
                            disabled
                          />
                        </td>
                        <td>{row1.name}</td>
                        {targetType === "Karyawan" && (
                          <>
                            <td>{row1.hotel}</td>
                            <td>{row1.department}</td>
                            <td>{row1.phone_number}</td>
                          </>
                        )}
                        {targetType === "Customer" && (
                          <>
                            <td>{row1.hotel}</td>
                            <td>{row1.segmentation}</td>
                            <td>{row1.phone_number}</td>
                          </>
                        )}
                        {status === "Sent" && (
                          <>
                            <td className="text-capitalize">
                              {(targetType === "Customer"
                                ? targetCustomer
                                : targetEmployee
                              )
                                .filter((item) =>
                                  item[
                                    targetType === "Customer"
                                      ? "uuid_customer"
                                      : "uuid_employee"
                                  ].includes(row1.uuid)
                                )
                                .map((item) => item.status)
                                .join(", ")}
                            </td>
                            <td className="text-capitalize">
                              {(targetType === "Customer"
                                ? targetCustomer
                                : targetEmployee
                              )
                                .filter((item) =>
                                  item[
                                    targetType === "Customer"
                                      ? "uuid_customer"
                                      : "uuid_employee"
                                  ].includes(row1.uuid)
                                )
                                .map((item) => item.meta_status)
                                .join(", ")}
                            </td>
                          </>
                        )}
                      </tr>
                    )
                  );
                })}
                {/* {currentData.map((row, i) => (
                  <tr
                    key={row.uuid}
                    className={
                      targetType === "Customer"
                        ? targetCustomer.some((customer) =>
                            customer.uuid_customer.includes(row.uuid)
                          )
                          ? "selected"
                          : ""
                        : targetEmployee.some((emp) =>
                            emp.uuid_employee.includes(row.uuid)
                          )
                        ? "selected"
                        : ""
                    }
                  >
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={
                          targetType === "Customer"
                            ? targetCustomer.some((customer) =>
                                customer.uuid_customer.includes(row.uuid)
                              )
                            : targetEmployee.some((emp) =>
                                emp.uuid_employee.includes(row.uuid)
                              )
                        }
                        disabled
                      />
                    </td>
                    <td>{row.name}</td>
                    {targetType === "Karyawan" && (
                      <>
                        <td>{row.hotel}</td>
                        <td>{row.department}</td>
                      </>
                    )}
                    {targetType === "Customer" && (
                      <>
                        <td>{row.segmentation}</td>
                      </>
                    )}
                    {status === "Sent" && (
                      <>
                        <td className="text-capitalize">
                          {(targetType === "Customer"
                            ? targetCustomer
                            : targetEmployee
                          )
                            .filter((item) =>
                              item[
                                targetType === "Customer"
                                  ? "uuid_customer"
                                  : "uuid_employee"
                              ].includes(row.uuid)
                            )
                            .map((item) => item.status)
                            .join(", ")}
                        </td>
                        <td className="text-capitalize">
                          {(targetType === "Customer"
                            ? targetCustomer
                            : targetEmployee
                          )
                            .filter((item) =>
                              item[
                                targetType === "Customer"
                                  ? "uuid_customer"
                                  : "uuid_employee"
                              ].includes(row.uuid)
                            )
                            .map((item) => item.meta_status)
                            .join(", ")}
                        </td>
                      </>
                    )}
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between pagination-table">
            {/* <div>
              Showing {firstIndex + 1} to{" "}
              {Math.min(lastIndex, filteredTarget.length)} of{" "}
              {filteredTarget.length} entries
            </div> */}
            <div>
              <select
                className="form-control form-control-sm"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                {pageSizeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pagination pagination-custom justify-content-center">
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              &lt;&lt;
            </button>
            <button
              type="button"

              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <button className="page-link active">{currentPage}</button>
            <button
              type="button"
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(filteredTarget.length / pageSize)
              }
            >
              &gt;
            </button>
            <button
              type="button"
              className="page-link"
              onClick={() =>
                handlePageChange(Math.ceil(filteredTarget.length / pageSize))
              }
              disabled={
                currentPage === Math.ceil(filteredTarget.length / pageSize)
              }
            >
              &gt;&gt;
            </button>
          </div>
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-11 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={goBack}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Back
            </button>
          </div>
        </div>
      </form >
    </>
  );
};

export default DetailBroadcast;
