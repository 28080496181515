import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { formatMoney } from "../../../components/FormatMoneyToRupiah";
import moment from "moment";
import CheckFeature from "../../../components/CheckFeature";
import Select from "react-select";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const BookingRoomList = (props) => {
    const [search, setSearch] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [onLoad, setOnLoad] = useState('');
    const [message, setMessage] = useState(false);
    const [codeStatus, setCodeStatus] = useState(false);
    //for View Booking List
    const [bookingDataList, setBookingDataList] = useState([]);
    const [dateBookingList, setDateBookingList] = useState("");
    const [hotelList, setHotelList] = useState([]);
    const [meetingRoomList, setMeetingRoomList] = useState([]);
    const [selectedHotel, setSelectedHotel] = useState("");
    const [selectedMeetingRoom, setSelectedMeetingRoom] = useState("");
    const [selectedHotelLabel, setSelectedHotelLabel] = useState("");
    const [dynamicColumns, setDynamicColumns] = useState();
    const [filteredMeetingRoomList, setFilteredMeetingRoomList] = useState([]);
    const [filteredBookingRoomList, setFilteredBookingRoomList] = useState([]);
    const [meetingRoom, setMeetingRoom] = useState([]);

    const [hotel, setHotel] = useState("");


    //endpoint
    const endpointBookingRoomList = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/`;
    const endpointMeetingRoomList = `${process.env.REACT_APP_BASE_URL}/functions/meeting-rooms`;
    const endpointDelete = `${process.env.REACT_APP_BASE_URL}/functions/booking-meeting-rooms/`;
    const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

    //check features
    const hasFeatureEdit = CheckFeature({
        name: "Update Booking Room",
        feature: props.feature,
    });
    const hasFeatureDelete = CheckFeature({
        name: "Delete Booking Room",
        feature: props.feature,
    });
    const hasFeatureAdd = CheckFeature({
        name: "Create Booking Room",
        feature: props.feature,
    });

    const getBookingRoomData = async () => {
        setOnLoad(true);
        setCodeStatus("");
        try {
            const response = await axios
                .get(endpointBookingRoomList, {
                    headers: {
                        Authorization: token,
                    },
                    params: {
                        ...(selectedHotel && { uuid_hotel: selectedHotel }),
                        ...(dateBookingList && { date: dateBookingList }),
                        ...(selectedMeetingRoom && { uuid_meeting_room: selectedMeetingRoom }),
                    },
                });
            const dataRoom = response.data.content;
            setBookingDataList(dataRoom);
            setFilteredBookingRoomList(dataRoom);
            console.log(dataRoom);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getHotel();
    }, []);

    useEffect(() => {
        getMeetingRoom();
    }, [selectedHotel]);

    //search campaign
    useEffect(() => {
        const escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
        };
        const result = meetingRoom.filter((data) => {
            const searchRegex = new RegExp(escapeRegExp(search), "i");
            return (
                (!hotel || data.hotel.uuid === hotel) &&
                (!search || data.name.toLowerCase().match(searchRegex))
            );
        });

        setFilteredMeetingRoomList(result);
    }, [search, hotel]);

    //alert delete
    const alertDelete = (uuid, name) => () => {
        Swal.fire({
            title: "Delete This Booking Room?",
            html: `Are you sure want to delete this Booking room named <b class="text-uppercase">${name}</b>?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#fc351c",
            cancelButtonColor: "#F0F0F0",
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMeetingRoom(uuid, name);
            }
        });
    };

    //function delete campaign
    const deleteMeetingRoom = (uuid, name) => {
        try {
            axios
                .delete(endpointDelete + uuid, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then(() => {
                    Swal.fire(
                        "Success!",
                        `Successfully deleted the Booking room with the name <b class="text-uppercase">${name}</b>`,
                        "success"
                    );
                    getBookingRoomData();
                });
        } catch (error) {
            Swal.fire("Failed!", `${error.response.data.message}`, "error");
        }
    };
    //get MeetingRoom
    const getMeetingRoom = async () => {
        try {
            await axios
                .get(endpointMeetingRoomList, {
                    headers: {
                        Authorization: token,
                    },
                    params: {
                        uuid_hotel: selectedHotel
                    },
                })
                .then((response) => {
                    const data = response.data.content;
                    const options = data.map(function (data) {
                        return { value: data.uuid, label: data.name };
                    });
                    setMeetingRoomList(options);
                });
        } catch (error) {
            console.log(error);
            //navigate("/404", { replace: true });
        }
    };

    const getHotel = async () => {
        try {
            await axios
                .get(endpointHotel, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then((response) => {
                    const data = response.data.content;
                    const options = data.map(function (data) {
                        return { value: data.uuid, label: data.name };
                    });
                    setHotelList(options);
                });
        } catch (error) {
            console.log(error.response.data.message);
            navigate("/404", { replace: true });
        }
    };

    //selected hotel
    const changeHotel = (selectedOption) => {
        if (selectedOption) {
            setSelectedHotel(selectedOption.value);
        } else {
            setSelectedHotel(null);
        }
    };

    const changeMeetingRoom = (selectedOption) => {
        if (selectedOption) {
            setSelectedMeetingRoom(selectedOption.value);
        } else {
            setSelectedMeetingRoom(null);
        }
    };

    //data table column
    const columnsBookingRoom = [
        {
            name: "Booking Title",
            selector: (row) => row.booking_name,
            sortable: true,
        },
        {
            name: "Start Time",
            selector: (row) => moment(row.start_time).format('MMMM Do YYYY, h:mm:ss a'),
            sortable: true,
        },
        {
            name: "End Time",
            selector: (row) => moment(row.end_time).format('MMMM Do YYYY, h:mm:ss a'),
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <Link to={`${row.uuid}`}>
                        <button className="btn btn-aksi btn-edit mr-1">Detail</button>
                    </Link>
                    {hasFeatureEdit && (
                        <Link to={`edit/${row.uuid}`}>
                            <button className="btn btn-aksi btn-reset mr-1">Edit</button>
                        </Link>
                    )}
                    {hasFeatureDelete && (
                        <button
                            className="btn btn-aksi btn-delete"
                            onClick={alertDelete(row.uuid, row.booking_name)}
                        >
                            Delete
                        </button>
                    )}
                </>
            ),
            width: "225px",
        },

    ];
    const lastRowIndex = currentPage * rowsPerPage;
    const currentDataBookingRoom = filteredMeetingRoomList.slice(
        (currentPage - 1) * rowsPerPage,
        lastRowIndex
    );
    return (
        <>
            <h1 className="page-title mb-3">Booking Room List</h1>
            <div className="row mb-3">
                <div className="col-12 col-customer-export">
                    {hasFeatureAdd && (
                        <Link to="create">
                            <button className="btn btn-primary btn-grey mr-2">
                                <i className="fa fa-plus-square mr-2"></i>
                                Create
                            </button>
                        </Link>
                    )}
                </div>
            </div>
            <h6 className=" mb-2">Search Booking Room List</h6>
            <div className="row mb-3 filter-search">

                <div className="col-lg-3 col-md-3 col-sm-12">
                    <input
                        type="date"
                        className="form-control input-search mb-4 "
                        placeholder="Search by customer name"
                        aria-describedby="btn-search"
                        onChange={(e) => setDateBookingList(e.target.value)}
                    />
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                    <Select
                        placeholder="Choose hotel"
                        isClearable={true}
                        options={hotelList}
                        name="uuid_hotel"
                        value={hotelList.find((obj) => obj.value === selectedHotel)}
                        onChange={changeHotel}
                        className="select2 input-search"
                        id="inputHotel"
                        classNamePrefix="react-select"
                    />
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                    <Select
                        placeholder="Choose Meeting Room"
                        isClearable={true}
                        options={meetingRoomList}
                        name="uuid_hotel"
                        value={meetingRoomList.find((obj) => obj.value === selectedMeetingRoom)}
                        onChange={changeMeetingRoom}
                        className="select2 input-search"
                        id="inputMettingRoom"
                        classNamePrefix="react-select"
                    />
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                    <button className="btn btn-aksi btn-edit mr-1 form-control mb-3" type="button" onClick={getBookingRoomData}>
                        Find
                    </button>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="alert alert-primary alert-dismissible fade show " role="alert" style={{ padding: ".40rem 1.25rem" }}>
                        <strong style={{ fontSize: "14px" }}>Please Input Date Below "Search Booking Room List" label, then Click Find</strong>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{ padding: ".40rem 1.25rem" }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="accordion" id="roomChartingList">
                        {bookingDataList.map((data, index) => (
                            <div className="card" key={"main" + index}>
                                <div className="card-header" id={"headingMain" + index}>
                                    <div className="mb-0 row parent-faq-row">
                                        <div
                                            className="col-12"
                                        >
                                            <button
                                                className="btn btn-link btn-block text-left collapsed btn-collapse"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target={"#collapseMain" + index}
                                                aria-expanded="true"
                                                aria-controls={"collapseMain" + index}
                                            >
                                                {data.name} | <span style={{ fontSize: "14px" }}><strong>Total Booking: </strong> {data.bookings.length}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id={"collapseMain" + index}
                                    className="collapse"
                                    aria-labelledby={"headingMain" + index}
                                    data-parent="#roomChartingList"
                                >
                                    <div className="card-body">
                                        <DataTable
                                            dense
                                            columns={columnsBookingRoom}
                                            data={data.bookings}
                                            highlightOnHover
                                            responsive
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>



            </div>
        </>
    );
};

export default BookingRoomList;
