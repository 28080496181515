import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

const ProtectedRoute = ({ name, feature, children ,}) => {
  // const user = localStorage.getItem("user");
  // const token = localStorage.getItem("token");

  // const endpointView = `${process.env.REACT_APP_BASE_URL}/roles/`;

  // const [feature, setFeature] = useState([]);

  // const getUserDetail = async () => {
  //   if (token !== null && user !== null) {
  //     var stringifyUserData = JSON.parse(user);
  //     const uuid_role = stringifyUserData["uuid_role"];
  //     try {
  //       await axios
  //         .get(endpointView + uuid_role, {
  //           headers: {
  //             Authorization: token,
  //           },
  //         })
  //         .then((response) => {
  //           setFeature(response.data.content.features);
  //         });
  //     } catch (error) {
  //       console.log(error.response.data.message);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   getUserDetail();
  // }, []);

  if (feature.length > 0) {
    const result = feature.filter((data) => {
      return data.name.toLowerCase() === name.toLowerCase();
    });
    if (result.length > 0) {
      return children;
    } else {
      return <Navigate to="/404" replace />;
    }
  }
};

export default ProtectedRoute;
