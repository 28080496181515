import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const RestoreBackup = () => {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  //endpoint
  const endpointImport = `${process.env.REACT_APP_BASE_URL}/backups/restore`;

  function handleFile(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    try {
      setLoading(true);
      await axios
        .post(endpointImport, formData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          setLoading(false);

          Swal.fire("Database Restore Successful!", "", "success").then(
            function () {
              window.location.reload();
            }
          );
        })
        .catch((error) => {
          setLoading(false);

          Swal.fire(
            "Database Restore Failed!",
            `${error.response.data.message}`,
            "error"
          );
        });
    } catch (error) {
      setLoading(false);

      Swal.fire(
        "Database Restore Failed!",
        `${error.response.data.message}`,
        "error"
      );
    }
  };

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Please wait..",
        html: "Currently in the process of restoring the database",
        showCancelButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didRender: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loading]);

  return (
    <>
      <h1 className="page-title">Restore Database</h1>
      <div className="card my-4">
        <div className="card-body text-center">
          Upload SQL File
          <form onSubmit={handleUpload} className="form">
            <input
              type="file"
              name="file"
              className="form-control input-file-custom col-lg-6 col-md-8 col-sm-12 mx-auto mt-3"
              onChange={handleFile}
              required
            />
            <button className="btn btn-primary btn-blue mt-3" type="submit">
              <i className="fa fa-upload mr-2"></i>Upload
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default RestoreBackup;
