import React from "react";

export const GenerateNext10Year = () => {
  const currentYear = new Date().getFullYear();
  const next10Years = Array.from(
    { length: 11 },
    (_, index) => currentYear + index
  );

  const options = next10Years.map((year) => ({
    value: year.toString(),
    label: year.toString(),
  }));

  return options;
};
