import React, { useEffect, useState } from "react";
import "../custom-pages-css.scss";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../components/CheckFeature";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'react-tooltip/dist/react-tooltip.css'
import { ModalShowRoomData } from "./ModalShowRoomData";
import { Tooltip } from 'react-tooltip'
import { generatePDF, generateExcel } from "../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const RoomChartingList = (props) => {
    const [reviewList, setReviewList] = useState([]);
    const [search, setSearch] = useState("");
    const [filteredReview, setFilteredReview] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedHotel, setSelectedHotel] = useState("");
    const [selectedHotelLabel, setSelectedHotelLabel] = useState("");
    const [onLoad, setOnLoad] = useState('');
    const [message, setMessage] = useState(false);
    const [codeStatus, setCodeStatus] = useState(false);

    const [dynamicColumns, setDynamicColumns] = useState();
    const [roomChartingList, setRoomChartingList] = useState([]);
    const [combinedData, setCombinedData] = useState([]);

    const [hotel, setHotel] = useState(null);
    const [hotelList, setHotelList] = useState([]);
    const [platform, setPlatform] = useState(null);
    const [platformList, setPlatformList] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState(false);
    const [dataDateModal, setDataDateModal] = useState(false);
    const [dataTypeRoomModal, setDataTypeRoomModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    //endpoint
    const endpointRoomCharting = `${process.env.REACT_APP_BASE_URL}/room-charting`;
    const endpointExportRoomCharting = `${process.env.REACT_APP_BASE_URL}/room-charting/export`;
    const endpointView = `${process.env.REACT_APP_BASE_URL}/reviews`;
    const endpointDelete = `${process.env.REACT_APP_BASE_URL}/reviews/delete/`;
    const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
    const endpointPlatform = `${process.env.REACT_APP_BASE_URL}/platforms`;
    const endpointSync = `${process.env.REACT_APP_BASE_URL}/ota-sync`;

    //get review list
    const getReview = async () => {
        const config = {
            headers: {
                Authorization: token,
            }
        };
        try {
            const response = await axios.get(endpointView, config);
            const data = response.data.content;
            const sortedData = data.sort((a, b) =>
                b.review_date.localeCompare(a.review_date)
            );
            setReviewList(sortedData);
            setFilteredReview(sortedData);
        } catch (error) {
            // console.log(error.response.data.message);
            navigate("/404", { replace: true });
        }
    };

    const getDaysArray = function (s, e) { const a = []; for (const d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) { a.push(new Date(d)); } return a; };
    //get Room Charting list

    const getRoomChartingData = async () => {
        setOnLoad(true);
        setCodeStatus("");
        try {
            const response = await axios
                .get(endpointRoomCharting, {
                    headers: {
                        Authorization: token,
                    },
                    params: {
                        ...(selectedHotel && { hotelId: selectedHotel }),
                        ...(startDate && { startDate: startDate }),
                        ...(endDate && { endDate: endDate }),
                    },
                });
            const dataRoom = response.data.content.hotels;
            const fixedData = dataRoom.map((data) => (
                data.roomTypes.map((data2) => (
                    Object.keys(data2.roomAvails).map((key) => ({ ...data2.roomAvails[key], AvailDate: key, Hotel: data.name, RoomType: data2.Description }))
                ))
            ))
            console.log(dataRoom);
            //console.log(response.data.content.hotels);
            setRoomChartingList(dataRoom);
            setOnLoad(false);
            setMessage(response.data.message)
            setCodeStatus(response.data.status)
        } catch (error) {
            // console.log(error);
            setOnLoad(false);
            setMessage(error.response.data.message)
            setCodeStatus(error.response.data.status)
        }
    };

    useEffect(() => {
        const daylist = getDaysArray(startDate, endDate);
        //data table columns
        const columns = [
            {
                name: "Code",
                selector: (row) => row.Code,
                sortable: true,
            },
            {
                name: "Room Type",
                selector: (row) => row.Description,
                sortable: true,
            },
        ];
        daylist.map((day) => (
            columns.push({
                name: moment(day).format("DD"),
                selector: (row) => row?.roomAvails[moment(day).format("YYYY-MM-DD")]?.AvailableRooms,
                sortable: true,
                cell: (row) => (
                    <>
                        {row?.roomAvails[moment(day).format("YYYY-MM-DD")]?.AvailableRooms ? (
                            <>
                                {row?.roomAvails[moment(day).format("YYYY-MM-DD")]?.AvailableRooms > 0 ? (
                                    <div style={{ margin: "0 auto" }}>
                                        <button className="btn btn-aksi btn-reset mr-1 my-anchor-element" type="button" style={{ width: "100px" }}
                                            onClick={(e) => {
                                                openModal(e);
                                                setDataModal(row?.roomAvails[moment(day).format("YYYY-MM-DD")]);
                                                setDataDateModal(moment(day).format("MMMM DD YYYY"))
                                                setDataTypeRoomModal(row.Description)
                                                //console.log(moment(day).format("YYYY-MM-DD"));
                                                //console.lo g(row?.roomAvails[moment(day).format("YYYY-MM-DD")])
                                            }}
                                        >
                                            {row?.roomAvails[moment(day).format("YYYY-MM-DD")]?.AvailableRooms}
                                        </button>
                                    </div>
                                ) : (
                                    <button className="btn btn-aksi btn-delete mr-1" type="button" style={{ width: "100px" }}
                                        onClick={(e) => {
                                            openModal(e);
                                            setDataModal(row?.roomAvails[moment(day).format("YYYY-MM-DD")]);
                                            setDataDateModal(moment(day).format("MMMM DD YYYY"))
                                            setDataTypeRoomModal(row.Description)
                                            //console.log(moment(day).format("YYYY-MM-DD"));
                                            //console.log(row?.roomAvails[moment(day).format("YYYY-MM-DD")])
                                        }}
                                    >
                                        SOLD
                                    </button>
                                )}
                            </>
                        ) : (
                            <>
                                {row?.roomAvails[moment(day).format("YYYY-MM-DD")]?.TotalAvailableRoom ? (
                                    <div style={{ margin: "0 auto" }}>
                                        <button className="btn btn-aksi btn-edit2  mr-1 my-anchor-element" type="button" style={{ width: "100px" }}
                                            onClick={(e) => {
                                                openModal(e);
                                                setDataModal(row?.roomAvails[moment(day).format("YYYY-MM-DD")]);
                                                setDataDateModal(moment(day).format("MMMM DD YYYY"))
                                                setDataTypeRoomModal(row.Description)
                                                //console.log(moment(day).format("YYYY-MM-DD"));
                                                //console.log(row?.roomAvails[moment(day).format("YYYY-MM-DD")])
                                            }}
                                        >
                                            {row?.roomAvails[moment(day).format("YYYY-MM-DD")]?.TotalAvailableRoom}
                                        </button>
                                    </div>
                                ) : (
                                    <div style={{ margin: "0 auto" }}>
                                        <button className="btn btn-aksi btn-delete mr-1" type="button" style={{ width: "100px" }}
                                            onClick={(e) => {
                                                openModal(e);
                                                setDataModal({
                                                    AvailableRooms: 0,
                                                    ConfirmedGroups: 0,
                                                    FIT: 0,
                                                    OOS: 0,
                                                });
                                                setDataDateModal(moment(day).format("MMMM DD YYYY"))
                                                setDataTypeRoomModal(row.Description)
                                                //console.log(moment(day).format("YYYY-MM-DD"));
                                                //console.log(row?.roomAvails[moment(day).format("YYYY-MM-DD")])
                                            }}
                                        >
                                            Empty
                                        </button>
                                    </div>
                                )}


                            </>
                        )}

                    </>
                ),
            })
        ));
        setDynamicColumns(columns)
    }, [startDate, endDate]);

    useEffect(() => {
        getReview();
        getHotel();
        getPlatform();
    }, []);

    //get hotel list
    const getHotel = async () => {
        try {
            await axios
                .get(endpointHotel, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then((response) => {
                    const data = response.data.content;
                    const options = data.map(function (hotel) {
                        return { value: hotel.code, label: hotel.name };
                    });
                    setHotelList(options);
                });
        } catch (error) {
            //console.log(error.response.data.message);
            navigate("/404", { replace: true });
        }
    };

    //selected hotel
    const changeHotel = (selectedOption) => {
        if (selectedOption) {
            setSelectedHotel(selectedOption.value);
            setSelectedHotelLabel(selectedOption.label);
        } else {
            setHotel(null);
        }
    };

    //get platform list
    const getPlatform = async () => {
        try {
            await axios
                .get(endpointPlatform, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then((response) => {
                    const data = response.data.content;
                    const options = data.map(function (data) {
                        return { value: data.uuid, label: data.name };
                    });
                    setPlatformList(options);
                });
        } catch (error) {
            // console.log(error.response.data.message);
            navigate("/404", { replace: true });
        }
    };

    //search review by hotel
    useEffect(() => {
        const escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
        };

        const result = reviewList.filter((data) => {
            const searchRegex = new RegExp(escapeRegExp(search), "i");

            return (
                (!hotel || data.uuid_hotel === hotel) &&
                (!platform || data.uuid_platform === platform) &&
                (!search || data.customer.toLowerCase().match(searchRegex))
            );
        });

        setFilteredReview(result);
    }, [search, reviewList, hotel, platform]);

    useEffect(() => {
        if (loading) {
            Swal.fire({
                title: "Please wait..",
                html: `Collecting reviews from all platforms`,
                showCancelButton: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didRender: () => {
                    Swal.showLoading();
                },
            });
        }
    }, [loading]);

    const openModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const closeModal = (e) => {
        e.preventDefault();
        setShowModal(false);
    };

    const combineData = (roomTypes, totalRooms) => {
        var combine = [];
        const makeObject = {
            mxId: 0,
            Code: "-",
            Description: "Total Rooms",
            roomAvails: totalRooms
        }
        roomTypes.push(makeObject);
        combine = roomTypes;
        //console.log(combine)
        return combine

    };

    const exportExcel = async () => {
        try {
            const response = await axios
                .get(endpointExportRoomCharting, {
                    responseType: "blob",
                    headers: {
                        Authorization: token,
                    },
                    params: {
                        ...(selectedHotel && { hotelId: selectedHotel }),
                        ...(startDate && { startDate: startDate }),
                        ...(endDate && { endDate: endDate }),
                    },
                });
            // Create a Blob from the response data
            const excelBlob = new Blob([response.data], { type: "application/excel" });

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(excelBlob);

            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute(
                "download",
                `Room_Charting_${selectedHotelLabel ? selectedHotelLabel : "All"}_${startDate}_${endDate}.xlsx`
            ); // Set the desired filename for the downloaded file

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error);
            setMessage(error.response.data.message)
            setCodeStatus(error.response.data.status)
        }
    };

    return (
        <>
            <h1 className="page-title mb-3">Room Charting</h1>
            <div className="alert alert-primary alert-dismissible fade show" role="alert">
                <strong>Please Input "Start Date" & "End Date" Input Then Click Find Button</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="row mb-3 filter-search">

                <div className="col-lg-3 col-md-3 col-sm-12">
                    <label htmlFor="Start Date Input" style={{ fontWeight: "bold" }} >Choose Hotel</label>
                    <Select
                        placeholder="Filter by hotel"
                        isClearable={true}
                        options={hotelList}
                        name="uuid_hotel"
                        value={hotelList.find((obj) => obj.value === hotel)}
                        onChange={changeHotel}
                        className="select2 input-search"
                        id="inputHotel"
                        classNamePrefix="react-select"
                    />
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                    <label htmlFor="Start Date Input" style={{ fontWeight: "bold" }} >Start Date</label>
                    <input
                        type="date"
                        className="form-control input-search mb-4 "
                        placeholder="Search by customer name"
                        aria-describedby="btn-search"
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <label htmlFor="End Date Input" style={{ fontWeight: "bold" }}>End Date</label>
                    <input
                        type="date"
                        className="form-control input-search "
                        placeholder="Search by customer name"
                        aria-describedby="btn-search"
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                    <label htmlFor="End Date Input" style={{ color: "transparent" }}>Find</label>
                    <button className="btn btn-aksi btn-edit mr-1 form-control" type="button" onClick={getRoomChartingData}>
                        Find
                    </button>
                </div>

            </div>


            {onLoad == true && (
                <div className="col-12">
                    <div className="ultraCentered">
                        <h4>Please Wait, Data On Loading Process</h4>
                    </div>
                </div>
            )}
            {onLoad === false && codeStatus == 200 && (
                <>
                    {roomChartingList.length > 0 && (
                        <>
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>Data Berhasil Ditemukan</strong>
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-12 col-customer-export">
                                    <button
                                        className="btn btn-primary btn-grey mr-2 mb-2 button-export"
                                        onClick={exportExcel}
                                    >
                                        <i className="fas fa-file-excel mr-2"></i>
                                        Export to XLSX
                                    </button>
                                </div>
                            </div>
                            <div className="accordion" id="roomChartingList">
                                {roomChartingList.map((data, index) => (
                                    <div className="card" key={"main" + index}>
                                        <div className="card-header" id={"headingMain" + index}>
                                            <div className="mb-0 row parent-faq-row">
                                                <div
                                                    className="col-12"
                                                >
                                                    <button
                                                        className="btn btn-link btn-block text-left collapsed btn-collapse"
                                                        type="button"
                                                        data-toggle="collapse"
                                                        data-target={"#collapseMain" + index}
                                                        aria-expanded="true"
                                                        aria-controls={"collapseMain" + index}
                                                    >
                                                        {data.name}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id={"collapseMain" + index}
                                            className="collapse"
                                            aria-labelledby={"headingMain" + index}
                                            data-parent="#roomChartingList"
                                        >
                                            <div className="card-body">
                                                <DataTable
                                                    dense
                                                    columns={dynamicColumns}
                                                    data={combineData(data?.roomTypes, data?.totalRooms)}
                                                    highlightOnHover
                                                    responsive
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
            {codeStatus == 400 && (
                <div className="col-12">
                    <div className="ultraCentered">
                        <h4>{message}</h4>
                    </div>
                </div>
            )}

            <ModalShowRoomData
                show={showModal}
                onClose={closeModal}
                data={dataModal}
                date={dataDateModal}
                typeRoom={dataTypeRoomModal}
            />
        </>
    );
};

export default RoomChartingList;
