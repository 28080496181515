import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../../components/CheckFeature";

const AddSalesActivityStatus = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const token = localStorage.getItem("token");
  const params = useParams();
  const salesActivityStatusID = params.id;
  //endpoint
  const endpointView = `${process.env.REACT_APP_BASE_URL}/sales-activity-status/${salesActivityStatusID}`;
  const endpointAdd = `${process.env.REACT_APP_BASE_URL}/sales-activity-status`;
  const endpointEdit = `${process.env.REACT_APP_BASE_URL}/sales-activity-status/${salesActivityStatusID}`;
  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update Sales Activity Status",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Activity Status",
    feature: props.feature,
  });
  useEffect(() => {
    if (salesActivityStatusID) {
      getSalesActivityStatusDetail();
    }

  }, []);
  //get campaign
  const getSalesActivityStatusDetail = async () => {
    await axios
      .get(endpointView, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setName(response.data.content.name);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.status === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-activity/status", { replace: true });
        }
      });
  };
  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    var salesActivityStatusData = {
      name: name,
    }

    try {
      if (!salesActivityStatusID) {
        await axios
          .post(endpointAdd, salesActivityStatusData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully created a Sales Activity Status with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/sales-activity/status");
          });
      } else {
        await axios
          .put(endpointEdit, salesActivityStatusData, {
            headers: {
              Authorization: token,
            },
          })
          .then(() => {
            Swal.fire(
              "Success!",
              `Successfully edited a Sales Activity Status with the name <b class="text-uppercase">${name}</b>`,
              "success"
            );
            navigate("/sales-activity/status");
          });
      }
    } catch (error) {
      console.log(error.response.data.content);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.content}`, "error");
      }
    }
  };
  return (
    <>
      <h1 className="page-title">
        {salesActivityStatusID
          ? hasFeatureEdit
            ? "Edit Sales Activity Status"
            : "Sales Activity Status Details"
          : "Create Sales Activity Status"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-activity/status">Sales Activity Status List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {salesActivityStatusID
            ? hasFeatureEdit
              ? "Edit Sales Activity Status"
              : "Sales Activity Status Details"
            : "Create Sales Activity Status"}
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (salesActivityStatusID && !hasFeatureEdit && " form-detail")
        }
      >

        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="inputName"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={salesActivityStatusID && !hasFeatureEdit}
            />
          </div>
        </div>

        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-activity/status")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {salesActivityStatusID ? (hasFeatureEdit ? "Cancel" : "Back") : "Cancel"}
            </button>
            {((salesActivityStatusID && hasFeatureEdit) ||
              (!salesActivityStatusID && hasFeatureAdd)) && (
                <button
                  className="btn btn-primary btn-blue"
                  type="submit"

                >
                  <i
                    className={
                      "fa " +
                      (salesActivityStatusID ? "fa-edit" : "fa-plus-square") +
                      " mr-2"
                    }
                  ></i>
                  {salesActivityStatusID ? "Edit" : "Create"}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSalesActivityStatus;
