import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import ModalShowMap from "../../../components/ModalShowMap";
import ShowAttachment from "../../../components/ShowAttachment";

const DetailSalesRepActivity = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const params = useParams();
  const uuid = params.id;

  const [dataActivity, setDataActivity] = useState([]);
  const [dataPicActivity, setDataPicActivity] = useState([]);
  const [salesName, setSalesName] = useState("");

  const endpointSalesRepActivity = `${process.env.REACT_APP_BASE_URL}/sales-rep-activities/`;

  useEffect(() => {
    if (uuid) {
      getSalesRepActivity();
    }
  }, []);

  const getSalesRepActivity = async () => {
    try {
      await axios
        .get(endpointSalesRepActivity + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const dataPics = response.data.content.pics;
          setDataActivity(data);
          setDataPicActivity(data.pics);
          setSalesName(data.user.employee.name);
          console.log(data);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "60px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      id: "name",
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
      id: "phone_number",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      id: "email",
    },
  ];

  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <h1 className="page-title">Sales Representative Activity List</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-representative-activity">
            Sales Representative Activity List
          </a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Sales Representative Activity Detail
        </li>
      </ol>
      <form className="mt-4 px-5 form form-employee form-detail">
        <div className="mb-3 row">
          <label
            htmlFor="salesName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Sales Name
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="salesName"
              id="salesName"
              className="form-control"
              value={salesName || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="taskName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Task Name
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="taskName"
              id="taskName"
              className="form-control"
              value={dataActivity.task_name || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="partnership"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Partnership
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="partnership"
              id="partnership"
              className="form-control"
              value={dataActivity.partnership || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="activityName"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Activity Name
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="activityName"
              id="activityName"
              className="form-control"
              value={dataActivity.name || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="activityDate"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Created At
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="activityDate"
              id="activityDate"
              className="form-control"
              value={
                moment(dataActivity.createdAt).format("DD MMMM YYYY, HH:mm") ||
                ""
              }
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="updatedAt"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Updated At
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="updatedAt"
              id="updatedAt"
              className="form-control"
              value={
                moment(dataActivity.updatedAt).format("DD MMMM YYYY, HH:mm") ||
                ""
              }
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="status"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Status
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="status"
              id="status"
              className="form-control"
              value={dataActivity.status || ""}
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="description"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Description
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="description"
              id="description"
              rows="3"
              className="form-control"
              value={dataActivity.description || ""}
              readOnly
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="startTime"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Start Time
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="startTime"
              id="startTime"
              className="form-control"
              value={
                moment(dataActivity.start_time).format("DD MMMM YYYY, HH:mm") ||
                ""
              }
              disabled
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="endTime"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            End Time
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="endTime"
              id="endTime"
              className="form-control"
              value={
                moment(dataActivity.end_time).format("DD MMMM YYYY, HH:mm") ||
                ""
              }
              disabled
            />
          </div>
        </div>
        <div className="mb-1 row">
          <label
            htmlFor="activityLocation"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Location
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 col-form-label">
            <ModalShowMap
              latitude={dataActivity.latitude}
              longitude={dataActivity.longitude}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="attachment"
            className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-form-label"
          >
            Attachment
          </label>
          <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 col-form-label">
            {console.log(dataActivity)}
            {dataActivity.file === "undefined" || dataActivity.file === null ? (
              <p>No Attachment</p>
            ) : (
              // <a
              //   href={process.env.REACT_APP_BASE_URL + "/" + dataActivity.file}
              //   target="blank"
              // >
              //   Show File
              // </a>
              <ShowAttachment file={dataActivity.file} />
            )}
          </div>
        </div>

        <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
          PIC
        </div>
        {dataPicActivity !== null && (
          <div className="datatable">
            <DataTable
              dense
              columns={columns}
              data={dataPicActivity}
              highlightOnHover
              pagination
              responsive
            />
          </div>
        )}
        <div className="mb-5 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey "
              onClick={goBack}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Back
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default DetailSalesRepActivity;
