import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="sticky-footer" id="main-footer">
        <div className="container my-auto">
          <div className="d-flex justify-content-between">
            <div className="copyright">
              <span>Copyright &copy; CRM 2023</span>
            </div>
            <div className="copyright">
              <span>Version {process.env.REACT_APP_VERSION}</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
