import React, { useEffect, useState } from "react";
import "../../custom-pages-css.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { generatePDF, generateExcel } from "../../../components/ExportUtils";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DetailSalesActivityEvent = () => {
  const params = useParams();
  const productID = params.id;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");
  //endpoint
  const endpointList = `${process.env.REACT_APP_BASE_URL}/sales-activity-event/${productID}`;

  //get campaign
  const getProductKnowledgeDetail = async () => {
    await axios
      .get(endpointList, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        console.log(response);
        setName(response.data.content.name);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.Event === 401 ||
          error.response.data.Event === 403
        ) {
          navigate("/404", { replace: true });
        } else if (error.response.data.Event === 404) {
          Swal.fire("Sorry,", `${error.response.data.message}`, "error");
          navigate("/sales-activity-event", { replace: true });
        }
      });
  };

  useEffect(() => {
    getProductKnowledgeDetail();
  }, []);

  return (
    <>
      <h1 className="page-title mb-3">Sales Activity Event Detail</h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-activity/event">Sales Activity Event List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Detail Sales Activity Event
        </li>
      </ol>
      <div className="text-with-line col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4 mb-4 p-0">
        General Information
      </div>
      <div className="mb-3 row">
        <label
          htmlFor="inputName"
          className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
        >
          Name
        </label>
        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
          <input
            type="text"
            name="inputName"
            className="form-control"
            id="inputName"
            value={name}
            disabled
          />
        </div>
      </div>
      <div className="mb-5 mt-4">
        <div className="col-12 text-right px-0">
          <button
            className="btn btn-primary btn-grey mr-2"
            onClick={() => navigate("/sales-activity/event")}
            type="button"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailSalesActivityEvent;
