import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import CheckFeature from "../../../components/CheckFeature";
const RenewSalesActivityManage = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [potentialRevenue, setPotentialRevenue] = useState(0);
  const [actualRevenue, setActualRevenue] = useState(0);
  const [potentialSuccess, setPotentialSuccess] = useState(0);
  const [salesActivityTypeList, setSalesActivityTypeList] = useState([]);
  const [salesActivityStatusList, setSalesActivityStatusList] = useState([]);
  const [salesActivityEventList, setSalesActivityEventList] = useState([]);
  const [salesActivityType, setSalesActivityType] = useState("");
  const [salesActivityStatus, setSalesActivityStatus] = useState("");
  const [salesActivityEvent, setSalesActivityEvent] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPIC, setSelectedPIC] = useState("");
  const [picList, setPicList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");

  const token = localStorage.getItem("token");
  const params = useParams();
  const salesActivityId = params.id;

  const [formFiles, setFormFiles] = useState([])
  const [files, setFiles] = useState([])
  const [deletefiles, setDeleteFiles] = useState([])

  //endpoint
  const endpointRenew = `${process.env.REACT_APP_BASE_URL}/sales-activity/renew/${salesActivityId}`;
  const endpointSalesActivityType = `${process.env.REACT_APP_BASE_URL}/sales-activity-type`;
  const endpointSalesActivityStatus = `${process.env.REACT_APP_BASE_URL}/sales-activity-status`;
  const endpointSalesActivityEvent = `${process.env.REACT_APP_BASE_URL}/sales-activity-event`;
  const endpointPIC = `${process.env.REACT_APP_BASE_URL}/pics`;
  const endpointHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;

  //check features
  const hasFeatureRenew = CheckFeature({
    name: "View Sales Activity",
    feature: props.feature,
  });
  const hasFeatureAdd = CheckFeature({
    name: "Create Sales Activity",
    feature: props.feature,
  });


  useEffect(() => {

    getSalesActivityType();
    getSalesActivityEvent();
    getSalesActivityStatus();
    getPIC();
    getHotel();
    console.log(params);
  }, []);

  useEffect(() => {
    console.log(formFiles);
  }, [formFiles]);
  //get hotel list
  const getHotel = async () => {
    try {
      await axios
        .get(endpointHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setHotelList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };
  //get Sales Activity Type
  const getSalesActivityType = async () => {
    try {
      await axios
        .get(endpointSalesActivityType, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data?.map(function (item) {
            return { value: item.uuid, label: item.name };
          });
          setSalesActivityTypeList(options);
        });
    } catch (error) {
      console.log(error);
      navigate("/404", { replace: true });
    }
  };
  const getSalesActivityStatus = async () => {
    try {
      await axios
        .get(endpointSalesActivityStatus, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data?.map(function (item) {
            return { value: item.uuid, label: item.name };
          });
          setSalesActivityStatusList(options);
        });
    } catch (error) {
      console.log(error);
      navigate("/404", { replace: true });
    }
  };
  const getSalesActivityEvent = async () => {
    try {
      await axios
        .get(endpointSalesActivityEvent, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data?.map(function (item) {
            return { value: item.uuid, label: item.name };
          });
          setSalesActivityEventList(options);
        });
    } catch (error) {
      console.log(error);
      navigate("/404", { replace: true });
    }
  };
  const getPIC = async () => {
    try {
      await axios
        .get(endpointPIC, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (pic) {
            return { value: pic.uuid, label: pic.name };
          });
          setPicList(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      //navigate("/404", { replace: true });
    }
  };
  //selected sales activity type
  const changeSalesActivityType = (selectedOption) => {
    if (selectedOption) {
      setSalesActivityType(selectedOption.value);
    } else {
      setSalesActivityType("");
    }
  };
  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setSelectedHotel(selectedOption);
    } else {
      setSelectedHotel(null);
    }
  };
  //selected sales activity status
  const changeSalesActivityStatus = (selectedOption) => {
    if (selectedOption) {
      setSalesActivityStatus(selectedOption.value);
    } else {
      setSalesActivityStatus("");
    }
  };
  //selected sales activity event
  const changeSalesActivityEvent = (selectedOption) => {
    if (selectedOption) {
      setSalesActivityEvent(selectedOption.value);
    } else {
      setSalesActivityEvent("");
    }
  };
  const changePIC = (selectedOption) => {
    if (selectedOption) {
      setSelectedPIC(selectedOption.value);
    } else {
      setSelectedPIC(null);
    }
  };
  //function add or edit
  const addEdit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('description', description);
    formData.append('actual_revenue', actualRevenue);
    formData.append('location', location);
    if (formFiles.length > 0) {
      formFiles.map((result) => {
        formData.append('attachments', result.attachments);
      })
    }
    if (deletefiles.length > 0) {
      deletefiles.map((result) => {
        formData.append('uuid_delete_file[]', result.attachments);
      })
    }

    console.log(formData);
    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    try {
      await axios
        .post(endpointRenew, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully renew a Sales Activity with the name <b class="text-uppercase">${name}</b>`,
            "success"
          );
          navigate("/sales-activity/manage");
        });
    } catch (error) {
      console.log(error.response.data.content);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.content}`, "error");
      }
    }
  };
  const handleChange = (i, e) => {
    let newFormValues = [...formFiles];
    newFormValues[i][e.target.name] = e.target.files[0];
    setFormFiles(newFormValues);
  }
  const addFormFile = () => {
    setFormFiles([...formFiles, { attachments: "" }])
  }
  const removeFormFile = (i) => {
    let newFormValues = [...formFiles];
    newFormValues.splice(i, 1);
    setFormFiles(newFormValues)
  }

  const removeFile = (i, uuid) => {
    console.log('yaampun' + i);
    let newFormValues = [...files];
    newFormValues.splice(i, 1);
    setDeleteFiles([...formFiles, { attachments: uuid }])
    setFiles(newFormValues)
    console.log(newFormValues);
  }
  return (
    <>
      <h1 className="page-title">
        Renew Sales Activity
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/sales-activity/manage">Sales Activity List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Renew Sales Activity
        </li>
      </ol>
      <form
        onSubmit={addEdit}
        method="post"
        className={
          "mt-4 px-5 form form-employee" +
          (salesActivityId && !hasFeatureRenew && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputDescription"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Description
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="description"
              id="inputDescription"
              rows="3"
              className="form-control"
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
              disabled={salesActivityId && !hasFeatureRenew}
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputActualRevenue"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Actual Revenue
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              placeholder="Actual Revenue"
              type="number"
              name="inputActualRevenue"
              className="form-control"
              id="inputActualRevenue"
              value={actualRevenue}
              onChange={(e) => setActualRevenue(e.target.value)}
              disabled={salesActivityId && !hasFeatureRenew}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDescription"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Attachments
          </label>
          <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">

            {formFiles.map((element, index) => (
              <div className="row mb-2" key={index}>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                  <input
                    type="FILE"
                    placeholder="attachments"
                    name="attachments"
                    className="form-control mb-2"
                    id="attachments"
                    onChange={e => handleChange(index, e)}
                    disabled={salesActivityId && !hasFeatureRenew}
                  />
                </div>

                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                  <button
                    type="button"
                    onClick={() => removeFormFile(index)}
                    className="btn btn-danger btn-red mb-2"
                    style={{ minWidth: "0px" }}
                  >
                    <i
                      className="fa fa-minus-circle"
                    ></i>
                  </button>
                </div>
              </div>
            ))}
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <button
                  className="btn btn-primary btn-green mb-2"
                  type="button"
                  onClick={() => addFormFile()}
                >
                  <i
                    className="fa fa-plus-circle"
                  ></i> Add Attachments
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/sales-activity/manage")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {salesActivityId ? (hasFeatureRenew ? "Cancel" : "Back") : "Cancel"}
            </button>
            <button
              className="btn btn-primary btn-blue"
              type="submit"

            >
              <i
                className={
                  "fa " +
                  (salesActivityId ? "fa-edit" : "fa-plus-square") +
                  " mr-2"
                }
              ></i>
              Renew
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RenewSalesActivityManage;
