import React, { useState, useEffect, useCallback } from "react";
import "../../custom-pages-css.scss";
import "./users.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CreatableSelect from "react-select/creatable";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CheckFeature from "../../../components/CheckFeature";

const AddUser = (props) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const params = useParams();
  const userId = params.id;

  //edit user
  const [username, setUsername] = useState("");
  const [role, setRole] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [hotel, setHotel] = useState(null);
  const [department, setDepartment] = useState(null);
  const [socialMedia, setSocialMedia] = useState([]);

  //list
  const [listRole, setListRole] = useState([]);
  const [listHotel, setListHotel] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);

  const endpointGetRole = `${process.env.REACT_APP_BASE_URL}/roles`;
  const endpointAddRole = `${process.env.REACT_APP_BASE_URL}/roles/add`;
  const endpointGetHotel = `${process.env.REACT_APP_BASE_URL}/hotels`;
  const endpointGetDepartment = `${process.env.REACT_APP_BASE_URL}/departments`;
  const endpointAddDepartment = `${process.env.REACT_APP_BASE_URL}/departments/add`;
  const endpointDetailUser = `${process.env.REACT_APP_BASE_URL}/users/${userId}`;
  const endpointEditUser = `${process.env.REACT_APP_BASE_URL}/users/edit/${userId}`;

  //check features
  const hasFeatureEdit = CheckFeature({
    name: "Update User",
    feature: props.feature,
  });

  //role
  const getRole = async () => {
    try {
      await axios
        .get(endpointGetRole, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (role) {
            return { value: role.uuid, label: role.name };
          });
          setListRole(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      }
    }
  };

  const changeRole = (selectedOption) => {
    if (selectedOption) {
      setRole(selectedOption.value);
    } else {
      setRole(null);
    }
  };

  const createRole = useCallback(
    (inputValue) => {
      alertAddRole(inputValue);
    },
    [listRole]
  );

  const alertAddRole = (inputValue) => {
    Swal.fire({
      title: "Create Role",
      input: "text",
      inputLabel: "Role Name",
      showCancelButton: true,
      inputValue: inputValue,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Create",
      inputValidator: (value) => {
        if (!value) {
          return "Role name cannot be empty!";
        } else {
          const check = listRole.filter((data) => {
            return value.toLowerCase() === data.label.toLowerCase();
          });
          if (check.length > 0) {
            return "Role already exist!";
          } else {
            addRole(value);
          }
        }
      },
    });
  };

  const addRole = (value) => {
    const dataRole = {
      name: value,
      features: [],
    };
    try {
      axios
        .post(endpointAddRole, dataRole, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully created <b class="text-uppercase">${value}</b> role`,
            "success"
          );
          getRole();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //hotel
  const getHotel = async () => {
    try {
      await axios
        .get(endpointGetHotel, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (hotel) {
            return { value: hotel.uuid, label: hotel.name };
          });
          setListHotel(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      navigate("/404", { replace: true });
    }
  };

  const changeHotel = (selectedOption) => {
    if (selectedOption) {
      setHotel(selectedOption.value);
    } else {
      setHotel(null);
    }
  };

  const createHotel = useCallback(
    (inputValue) => {
      window.open("/hotel/add", "_blank").focus();
    },
    [listHotel]
  );

  //department
  const getDepartment = async () => {
    try {
      await axios
        .get(endpointGetDepartment, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const options = data.map(function (dpt) {
            return { value: dpt.uuid, label: dpt.name };
          });
          setListDepartment(options);
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      }
    }
  };

  const changeDepartment = (selectedOption) => {
    if (selectedOption) {
      setDepartment(selectedOption.value);
    } else {
      setDepartment(null);
    }
  };

  const createDepartment = useCallback(
    (inputValue) => {
      alertAddDepartment(inputValue);
    },
    [listDepartment]
  );

  const alertAddDepartment = (inputValue) => {
    Swal.fire({
      title: "Add Department",
      input: "text",
      inputLabel: "Department Name",
      showCancelButton: true,
      inputValue: inputValue,
      confirmButtonColor: "#1c5ddc",
      cancelButtonColor: "#fc351c",
      cancelButtonText: "Cancel",
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value) {
          return "Department name cannot be empty!";
        } else {
          const check = listDepartment.filter((data) => {
            return value.toLowerCase() === data.label.toLowerCase();
          });
          if (check.length > 0) {
            return "Department already exists!";
          } else {
            addDepartment(value);
          }
        }
      },
    });
  };

  const addDepartment = (value) => {
    const dataDepartment = {
      name: value,
    };
    try {
      axios
        .post(endpointAddDepartment, dataDepartment, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully added <b class="text-uppercase">${value}</b> department`,
            "success"
          );
          getDepartment();
        });
    } catch (error) {
      Swal.fire("Failed!", `${error.response.data.message}`, "error");
    }
  };

  //get user by id
  const getUserById = async () => {
    await axios
      .get(endpointDetailUser, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setUsername(response.data.content.username);
        setRole(response.data.content.role.uuid);
        setName(response.data.content.employee.name);
        setAddress(response.data.content.employee.address);
        setPhoneNumber(response.data.content.employee.phone_number);
        setEmail(response.data.content.employee.email);
        setPosition(response.data.content.employee.position);
        if (response.data.content.employee.hotel) {
          setHotel(response.data.content.employee.hotel.uuid);
        }
        if (response.data.content.employee.department) {
          setDepartment(response.data.content.employee.department.uuid);
        }
        setSocialMedia(response.data.content.employee.social_media);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (
          error.response.data.status === 401 ||
          error.response.data.status === 403 ||
          error.response.data.status === 404
        ) {
          navigate("/404", { replace: true });
        }
      });
  };

  //edit user
  const editUser = async (e) => {
    e.preventDefault();
    const userData = {
      uuid_role: role,
      name: name,
      address: address,
      phone_number: phoneNumber,
      email: email,
      position: position,
      uuid_hotel: hotel,
      uuid_department: department,
      social_media: socialMedia,
    };
    try {
      await axios
        .patch(endpointEditUser, userData, {
          headers: {
            Authorization: token,
          },
        })
        .then(() => {
          Swal.fire(
            "Success!",
            `Successfully edit user with the username <b class="text-uppercase">${username}</b>`,
            "success"
          );
          navigate("/user");
        });
    } catch (error) {
      console.log(error.response.data.message);
      if (
        error.response.data.status === 401 ||
        error.response.data.status === 403
      ) {
        navigate("/404", { replace: true });
      } else if (error.response.data.status === 500) {
        Swal.fire("Failed!", "Please complete the data", "error");
      } else if (error.response.data.status === 400) {
        Swal.fire("Failed!", `${error.response.data.message}`, "error");
      }
    }
  };

  useEffect(() => {
    if (userId) {
      getUserById();
    }
    getRole();
    getHotel();
    getDepartment();
  }, []);

  return (
    <>
      <h1 className="page-title">
        {hasFeatureEdit ? "Edit User" : "User Details"}
      </h1>
      <ol className="breadcrumb breadcrumb-custom my-3">
        <li className="breadcrumb-item">
          <a href="/user">User List</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {hasFeatureEdit ? "Edit User" : "User Details"}
        </li>
      </ol>

      <form
        onSubmit={editUser}
        method="post"
        className={
          "mt-4 px-5 form form-employee" + (!hasFeatureEdit && " form-detail")
        }
      >
        <div className="mb-3 row">
          <label
            htmlFor="inputUsername"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Username <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="username"
              className="form-control"
              id="inputUsername"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              readOnly
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputRole"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Role <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <CreatableSelect
              placeholder="Select role"
              isClearable={true}
              value={listRole.find((obj) => obj.value === role)}
              options={listRole}
              onChange={changeRole}
              onCreateOption={createRole}
              className="select2"
              id="inputRole"
              autoFocus={true}
              name="uuid_role"
              required
              isDisabled={!hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputName"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Name <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="name"
              className="form-control"
              id="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={!hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputAddress"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Address <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <textarea
              name="address"
              id="inputAddress"
              rows="3"
              className="form-control"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              disabled={!hasFeatureEdit}
            ></textarea>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPhoneNumber"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Phone Number <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <PhoneInput
              international
              defaultCountry="ID"
              value={phoneNumber}
              onChange={setPhoneNumber}
              id="inputPhoneNumber"
              disabled={!hasFeatureEdit}
            />
            <small className="form-text text-danger">
              {phoneNumber
                ? isValidPhoneNumber(phoneNumber)
                  ? undefined
                  : "Invalid phone number"
                : "The phone number is required"}
            </small>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputEmail"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Email <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="email"
              name="email"
              className="form-control"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={!hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputPosition"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Position <i className="red">*</i>
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <input
              type="text"
              name="position"
              className="form-control"
              id="inputPosition"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              required
              disabled={!hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputHotel"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Hotel
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <div className="input-group">
              <CreatableSelect
                placeholder="Select hotel"
                isClearable={true}
                options={listHotel}
                name="uuid_hotel"
                autoFocus={true}
                value={listHotel.find((obj) => obj.value === hotel)}
                onChange={changeHotel}
                className="select2 select-group"
                id="inputHotel"
                onCreateOption={createHotel}
                isDisabled={!hasFeatureEdit}
              />
              <button
                className="btn btn-grey btn-sync"
                type="button"
                onClick={() => getHotel()}
              >
                <i className="fa fa-sync"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label
            htmlFor="inputDepartment"
            className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-form-label"
          >
            Department
          </label>
          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
            <CreatableSelect
              placeholder="Select department"
              isClearable={true}
              options={listDepartment}
              name="uuid_department"
              autoFocus={true}
              value={listDepartment.find((obj) => obj.value === department)}
              onChange={changeDepartment}
              className="select2"
              id="inputDepartment"
              onCreateOption={createDepartment}
              isDisabled={!hasFeatureEdit}
            />
          </div>
        </div>
        <div className="mb-3">
          <i className="red">*</i> Required
        </div>
        <div className="mb-5 mt-4">
          <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 text-right px-0">
            <button
              className="btn btn-primary btn-grey mr-2"
              onClick={() => navigate("/user")}
              type="button"
            >
              <i className="fa fa-arrow-left mr-2"></i>
              {hasFeatureEdit ? "Cancel" : "Back"}
            </button>
            {hasFeatureEdit && (
              <button
                className="btn btn-primary btn-blue"
                type="submit"
                disabled={
                  phoneNumber
                    ? isValidPhoneNumber(phoneNumber)
                      ? undefined
                      : "disabled"
                    : "disabled"
                }
              >
                <i className="fa fa-plus-square mr-2"></i>
                Edit
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AddUser;
